import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  bonusesCreateRulesAllDataEnteredSelector,
  bonusesCreateSelector,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { gamesListSelector } from "../../../../../../../redux/slices/games/gamesSelectors";
import { providersListSelector } from "../../../../../../../redux/slices/providers/providersSelectors";
import RowReview from "./RowReview";
import { categoriesListSelector } from "../../../../../../../redux/slices/categories/categoriesSelectors";
import { gameGroupsListSelector } from "../../../../../../../redux/slices/game-groups/gameGroupSelectors";

const valueForCalculation = {
  0: "Stake",
  1: "Win",
  2: "Net loss",
  3: "Net win",
  4: "Tickets count",
  5: "Highest win / bet ",
};

const RulesReview = () => {
  const createData = useSelector(bonusesCreateSelector);

  const categories = useSelector(categoriesListSelector);
  const gameGroups = useSelector(gameGroupsListSelector);
  const providers = useSelector(providersListSelector);
  const games = useSelector(gamesListSelector);
  const allDataEntered = useSelector(bonusesCreateRulesAllDataEnteredSelector);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        Rules
        <span style={{ marginLeft: "8px" }}>
          {allDataEntered === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview
        name="Value for calculation"
        data={
          createData.rules.valueForCalculation !== null
            ? valueForCalculation[createData.rules.valueForCalculation]
            : null
        }
        required={true}
      />
      <RowReview
        name="Value filter from"
        data={
          createData.rules.valueFilterFrom !== null &&
          parseFloat(createData.rules.valueFilterFrom).toFixed(2)
        }
      />

      <RowReview
        name="Value filter to"
        data={
          createData.rules.valueFilterTo !== null &&
          parseFloat(createData.rules.valueFilterTo).toFixed(2)
        }
      />
      <RowReview
        name="Calculate By"
        data={
          createData.rules.calculateBy === 0
            ? "Providers"
            : createData.rules.calculateBy === 1
            ? "Games"
            : createData.rules.calculateBy === 2
            ? "Groups"
            : ""
        }
        required={true}
      />

      {createData.rules.gameRules.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Games:</div>
          {createData.rules.gameRules.map((gameRule, i) => {
            return (
              <div
                style={{ display: "flex", marginLeft: "12px", gap: "8px" }}
                key={i}
              >
                <div>
                  {games.map((g) => {
                    if (g.id === gameRule.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                          key={g.id}
                        >
                          -{" "}
                          <img
                            src={g.provider?.photo}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "100%",
                            }}
                          />
                          <div>{g.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{gameRule.percent}%</div>
              </div>
            );
          })}
        </div>
      )}

      {createData.rules.providerRules.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Providers :</div>
          {createData.rules.providerRules.map((providerRule, i) => {
            return (
              <div
                style={{ display: "flex", marginLeft: "12px", gap: "8px" }}
                key={i}
              >
                <div>
                  {providers.map((p) => {
                    if (p.id === providerRule.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                          key={p.id}
                        >
                          -{" "}
                          <img
                            src={p.photo}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "100%",
                            }}
                          />
                          <div>{p.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{providerRule.percent}%</div>
              </div>
            );
          })}
        </div>
      )}

      {createData.rules.gameGroupRules.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Game Groups :</div>
          {createData.rules.gameGroupRules.map((x, i) => {
            return (
              <div
                style={{ display: "flex", marginLeft: "12px", gap: "8px" }}
                key={i}
              >
                <div>
                  {gameGroups.map((p) => {
                    if (p.id === x.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                          key={p.id}
                        >
                          - <div>{p.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{x.percent}%</div>
              </div>
            );
          })}
        </div>
      )}

      {createData.rules.gameCategories.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Categories :</div>
          {createData.rules.gameCategories.map((x, i) => {
            return (
              <div
                style={{ display: "flex", marginLeft: "12px", gap: "8px" }}
                key={i}
              >
                <div>
                  {categories.map((p) => {
                    if (p.id === x.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                          key={p.id}
                        >
                          - <div>{p.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{x.percent}%</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RulesReview;
