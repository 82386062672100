import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import AllActivities from "./AllActivities";
import { useDispatch } from "react-redux";
import { addAchievementActivitiesAfterTournaments } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const ActivitiesAfterActivation = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="activities_after_container_wrapper">
        <AllActivities />
      </div>

      <div className="bonuses_activation_activities_after">
        <Button
          type="dashed"
          onClick={() => {
            dispatch(addAchievementActivitiesAfterTournaments());
          }}
          block
          icon={<PlusOutlined />}
          style={{ width: "min-content" }}
        >
          {"Add activity"}
        </Button>
      </div>
    </>
  );
};

export default ActivitiesAfterActivation;
