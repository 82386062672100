import axios from "axios";

let requestInterceptor = {};
let responseInterceptor = {};

export const removeRequestInterceptor = () => {
  axios.interceptors.request.eject(requestInterceptor);
};
export const setRequestInterceptor = () => {
  requestInterceptor = axios.interceptors.request.use((request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.Authorization = "Bearer " + token;
    }
    return request;
  });
};

export const removeResponseInterceptor = () => {
  axios.interceptors.request.eject(responseInterceptor);
};
export const setResponseInterceptor = () => {
  removeResponseInterceptor();
  responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("type");
        alert("Session expired.");
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
};
