import { createAsyncThunk } from "@reduxjs/toolkit";
import freeGamesServices from "../../../../services/freeGamesServices";

export const getSingleFreeGamesReducer = createAsyncThunk(
  "getSingleFreeGamesReducer",
  async (id, thunkApi) => {
    try {
      const res = await freeGamesServices.getSingleFreeGame(id);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
