import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationActivationCode } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationCode } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ActivationCode = () => {
  const dispatch = useDispatch();

  const value = useSelector(bonusesCreateActivationActivationCode);

  return (
    <Form.Item label="Activation code" name="activation_code">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateActivationCode(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default ActivationCode;
