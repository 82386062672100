import { createSlice } from "@reduxjs/toolkit";
import { getProvidersReducer } from "./extra-reducers/getProvidersReducer";

const initialState = {
  providersList: [],
  ui: {
    loadingProvidersList: false,
  },
};

export const providersSLice = createSlice({
  name: "providers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProvidersReducer.pending, (state) => {
      state.ui.loadingProvidersList = true;
    });
    builder.addCase(getProvidersReducer.fulfilled, (state, action) => {
      const array = [];
      action.payload.forEach((x, i) => array.push(x));
      state.providersList = array;
      state.ui.loadingProvidersList = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = providersSLice.actions;

export default providersSLice.reducer;
