import { Avatar, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGamesReducer } from "../../../../redux/slices/games/extra-reducers/getGamesReducer";
import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../../redux/slices/games/gamesSelectors";
import {
  statisticsGameValueSelector,
  statisticsGamesByProviderSelector,
  statisticsGamesDisabledSelector,
  statisticsGamesSelector,
  statisticsProviderSelector,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsGames,
  setStatisticsGameValue,
} from "../../../../redux/slices/statistics/statisticsSlice";
import { getGamesByProviderStatReducer } from "../../../../redux/slices/games/extra-reducers/getGamesByProvider";

const StatisticGamesSelect = () => {
  const statisticsGameValue = useSelector(statisticsGameValueSelector);
  const loadingGamesList = useSelector(loadingGamesListSelector);
  const statisticsGamesDisabled = useSelector(statisticsGamesDisabledSelector);
  const statisticsGamesByProvider = useSelector(
    statisticsGamesByProviderSelector
  );
  const statisticsProvider = useSelector(statisticsProviderSelector);
  const dispatch = useDispatch();
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (statisticsProvider) {
      dispatch(getGamesByProviderStatReducer(statisticsProvider));
    }
  }, [dispatch, statisticsProvider]);

  useEffect(() => {
    const array = [];
    statisticsGamesByProvider.forEach((game) => {
      array.push({
        value: game.id,
        label: game.name,
        icon: game.photo,
      });
    });
    setFilteredArray(array);
  }, [statisticsGamesByProvider, statisticsProvider]);

  return (
    <Select
      value={statisticsGameValue}
      dropdownMatchSelectWidth={false}
      loading={loadingGamesList}
      disabled={
        statisticsGamesDisabled || statisticsGamesByProvider.length === 0
      }
      onChange={(x) => {
        dispatch(setStatisticsGameValue(x));
        dispatch(changeStatisticsGames(x));
      }}
    >
      <Select.Option key={0} value={0}>
        All Games
      </Select.Option>
      {filteredArray.map((item, i) => {
        return (
          <Select.Option key={i} value={item.value}>
            <Avatar src={item.icon} style={{ marginRight: "4px" }} />
            {item.label}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default StatisticGamesSelect;
