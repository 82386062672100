import { Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { bonusesCreateStepSelector } from "../../../../../../redux/slices/bonuses/bonusesSelectors";
import Achievement from "./achievement/Achievement";
import Activation from "./activation/Activation";
import General from "./general/General";
import Review from "./review/Review";
import Rules from "./rules/Rules";
import "./StepsPages.css";

const StepsPageRouter = () => {
  const currentStep = useSelector(bonusesCreateStepSelector);
  return (
    <>
      <Form
        // onFinishFailed={onFinishFailed}
        style={{ width: "100%", padding: "16px" }}
        layout={"vertical"}
      >
        {currentStep === 0 ? (
          <General />
        ) : currentStep === 1 ? (
          <Activation />
        ) : currentStep === 2 ? (
          <Rules />
        ) : currentStep === 3 ? (
          <Achievement />
        ) : currentStep === 4 ? (
          <Review />
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};

export default StepsPageRouter;
