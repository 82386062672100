import React from 'react'
import { Form, Input } from "antd";
import { useDispatch } from 'react-redux';
import { pauseSelector } from '../../../../../../../../redux/slices/bonuses/bonusesSelectors';
import { useSelector } from 'react-redux';
import { changePauseTournaments } from '../../../../../../../../redux/slices/tournaments/tournamentsSlice';
import { pauseTournamentsSelector } from '../../../../../../../../redux/slices/tournaments/tournamentsSelector';

const TournamentPause = () => {
    const pause = useSelector(pauseTournamentsSelector)
    const dispatch = useDispatch()


  return (
    <Form.Item
      label="Pause (minutes)"
      name="pauseTour"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Input
        name={'pauseTour'}
        defaultValue={pause}
        value={pause}
        type='number'
        onChange={(e) => {
            if(!e.target.value) return;
            dispatch(changePauseTournaments(parseInt(e.target.value)))

        }}
      />
    </Form.Item>
  )
}

export default TournamentPause