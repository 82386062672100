import { Button, PageHeader, Table, Pagination } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../layouts/components/Loader";
import {
  bonusesIsDataLoading,
  bonusesPageNumberSelector,
  bonusesPageSizeSelector,
  bonusesTotalRowsSelector,
  bonusParticipationSelector,
  currentPageParticipants,
  isBonusManualSelector,
  perPageParticipants,
  searchBonusParticipantsSelector,
  totalPagesParticipants,
} from "../../../../redux/slices/bonuses/bonusesSelectors";
import { getParticipation } from "../../../../redux/slices/bonuses/extra-reducers/getParticipation";
import AddPlayersButton from "./AddPlayersButton";
import { bonusesParticipationColumns } from "./bonusesParticipationColumns";
import SearchBonusParticipants from "./SearchBonusParticipants";
import {
  changeBonusesPageNumber,
  changeCurrentPageParticipants,
  changePerPageParticipants,
} from "../../../../redux/slices/bonuses/bonusesSlice";

const BonusesParticipation = () => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const dataLoading = useSelector(bonusesIsDataLoading);
  const data = useSelector(bonusParticipationSelector);
  const isManual = useSelector(isBonusManualSelector);
  const page = useSelector(currentPageParticipants);
  const total = useSelector(totalPagesParticipants);
  const perPage = useSelector(perPageParticipants);

  const search = useSelector(searchBonusParticipantsSelector);

  const hanleGoBack = () => goBack();

  const changePage = (value, perPage) => {
    var baseUrl = window.location.href;
    var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);

    dispatch(changePerPageParticipants(perPage))
    dispatch(changeCurrentPageParticipants(value));
    dispatch(getParticipation({ id, value, perPage }));
  };

  useEffect(() => {
    var baseUrl = window.location.href;
    var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);

    dispatch(getParticipation({ id }));
  }, [dispatch]);

  return (
    <div>
      <PageHeader
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        ghost={false}
        onBack={hanleGoBack}
        title="Bonuses Participation"
        extra={
          isManual
            ? [
                <>
                  <SearchBonusParticipants />
                  <AddPlayersButton />
                </>,
              ]
            : [
                <>
                  <SearchBonusParticipants />
                </>,
              ]
        }
      ></PageHeader>

      <div className="bonuses_container">
        <div style={{ minWidth: "fit-content" }}>
          <>
            {dataLoading ? (
              <Loader />
            ) : (
              <>
                <Table
                  columns={bonusesParticipationColumns}
                  dataSource={
                    data && data.filter((x) => x.player_id.includes(search))
                  }
                  className="bonuses_table"
                  rowKey={(record) => record.id}
                  showSorterTooltip={false}
                  pagination={false}
                />
                <Pagination
                  current={page}
                  total={total}
                  onChange={changePage}
                  showSizeChanger={true}
                  pageSize={perPage}
                  className="free_games-pagination"
                />
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default BonusesParticipation;
