import "./Login.css";

import { Form, Input, Button, Checkbox, Spin, Select } from "antd";
import { Row, Col, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { UserOutlined, LockOutlined, FireOutlined } from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { authUser } from "../../../redux/slices/user/user-extra-reducers/authUserReducer";
import { isLoggedInSelector } from "../../../redux/slices/user/userSelectors";
import { useSelector } from "react-redux";
import {
  changeIsLoggedIn,
  changeIsLoggedInToNull,
} from "../../../redux/slices/user/userSlice";

axios.interceptors.request.use();

function Login() {
  const [loading, setLoading] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState();

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const history = useHistory();

  const onFinish = async (values) => {
    dispatch(authUser(values));
    setLoading(false);
  };

  const removeFailedAuth = () => {
    if (isLoggedIn === false) {
      dispatch(changeIsLoggedInToNull());
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      history.push("/dashboard");
    } else if (isLoggedIn) {
      dispatch(changeIsLoggedIn(false));
    }
  }, [dispatch, history, isLoggedIn]);

  return (
    <Content
      className="site-layout-background"
      style={{
        padding: "24px",
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row style={{ width: "350px" }}>
        <Col flex={1} style={{ maxWidth: 350 }}>
          <Typography.Title
            className="site-layout-background"
            style={{ marginTop: 10 }}
          >
            Login
          </Typography.Title>
          <div className="login-warp">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                onChange={removeFailedAuth}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your email",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                onChange={removeFailedAuth}
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              {isLoggedIn === false && (
                <div className="failed_auth">
                  <img src="/svg/failed-auth.svg" alt="failed-auth" />
                  <span>Incorrect email or password</span>
                </div>
              )}

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <div style={{ justifyContent: "flex-start" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                  {loading ? (
                    <Spin style={{ marginLeft: 40, alignSelf: "end" }} />
                  ) : (
                    <div />
                  )}
                </div>
              </Form.Item>
              <p style={{ color: "red" }}>{errorMessage}</p>
            </Form>
          </div>
        </Col>
      </Row>
    </Content>
  );
}

export default Login;
