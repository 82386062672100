import React from "react";
import DateFromToRange from "./DateFromToRange";
import GameProviderSelect from "./GameProviderSelect";
import SearchInput from "./SearchInput";
import StateSelect from "./StateSelect";
import "../../FreeGamesList.css";
import FreeGamesFilter from "./FreeGamesFilter";

const FreeGamesListForm = () => {
  return (
    <div className="free_games_list_form">
      {/* <StateSelect /> */}
      {/* <DateFromToRange /> */}
      <GameProviderSelect />
      {/* <SearchInput /> */}
      <FreeGamesFilter />
    </div>
  );
};

export default FreeGamesListForm;
