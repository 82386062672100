import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import bonusesServices from "../../../../services/bonusesServices";

export const createEditBonusReducer = createAsyncThunk(
  "createEditBonusReducer",
  async ({ id, idRound }, thunkApi) => {
    const bonusesState = thunkApi.getState().bonuses.create;
    const timezoneOffset = new Date().getTimezoneOffset() / 60;



    const casino_id = thunkApi.getState().user.activeCasino;
    const body = {
      casinos: [casino_id],
      lobby: thunkApi.getState().user.lobby,

      name: bonusesState.general.name,
      auto_repeat: bonusesState.general.type,
      period: [
        moment.utc(bonusesState.general.dateFrom)
          .startOf('minute')
          .seconds(0)
          .toISOString()
       ,
       moment.utc(bonusesState.general.dateTo)
          .startOf('minute')
          .seconds(0)
          .toISOString()
          // .add(timezoneOffset, "hours")
          // .utc(true)
          // .toISOString(),
          // .add(timezoneOffset, "hours")
          // .utc(true)
          // .toISOString(),
      ],
      // state: bonusesState.general?.state?.toString(),
      // description: bonusesState.general?.description,
      // public_title: bonusesState.general?.publicTitle,
      // short_text_for_promotions: bonusesState.general?.shortText,
      // long_text_for_promotions: bonusesState.general?.longText,
      // banner_image_url: bonusesState.general?.bannerUrl,
      activation_mode: bonusesState.activation.activationMode.toString(),
      activation_locked_by: bonusesState.activation.activationLockedBy,
      activity_for_starting: bonusesState.activation.activityNeeded.toString(),
      value_for_calculation: bonusesState.rules.valueForCalculation.toString(),
      value_filter_from: bonusesState.rules.valueFilterFrom,
      value_filter_to: bonusesState.rules.valueFilterTo,
      hit_value_calculation_type:
        bonusesState.achievement.hitValueCalculation.toString(),
      minimal_value: bonusesState.achievement.minimalValue,
    };

    //General


    if (bonusesState.general?.state >= 0) {
      body.state = bonusesState.general?.state?.toString();
    }


    if (bonusesState.general?.description) {
      body.description = bonusesState.general?.description;
    }
    if (bonusesState.general?.publicTitle) {
      body.public_title = bonusesState.general?.publicTitle;
    }
    if (bonusesState.general?.shortText) {
      body.short_text_for_promotions = bonusesState.general?.shortText;
    }
    if (bonusesState.general?.longText) {
      body.long_text_for_promotions = bonusesState.general?.longText;
    }
    if (bonusesState.general?.bannerUrl) {
      body.banner_image_url = bonusesState.general?.bannerUrl;
    }

    if (bonusesState.general.infoUrl) {
      body.info_url = bonusesState.general.infoUrl;
    }
    if (bonusesState.general.buttonText) {
      body.button_text = bonusesState.general.buttonText;
    }
    if (bonusesState.general.buttonUrl) {
      body.button_url = bonusesState.general.buttonUrl;
    }
    if (bonusesState.general.progressBarStyle) {
      body.progress_bar_style = bonusesState.general.progressBarStyle;
    }

    if(bonusesState.general.pause){
      body.pause = bonusesState.general?.pause;
    }

    //Activation
    if (bonusesState.activation.activationMode === 1) {
      const data = bonusesState.activation.players.map((x) => {
        return { id: x };
      });
      body.players = data;
      body.carry_players = bonusesState.activation.carryPlayers;
    }

    if (bonusesState.activation.activationMode === 2) {
      body.deposit_min_amount = bonusesState.activation.depositMinAmount;
      body.deposit_sequence = bonusesState.activation.depositSequence;
      body.activation_code = bonusesState.activation.activationCode;
      body.participation_mode = bonusesState.activation.participationMode;
      body.participation_limit = bonusesState.activation.participationLimit;
    }

    if (bonusesState.activation.activitiesAfterBonus.length > 0) {
      body.activities_after_activation = [];
      bonusesState.activation.activitiesAfterBonus.forEach((bonus, i) => {
        if (bonus.type === 0) {
          const obj = {};

          obj.account = bonus.data.account;
          obj.freespins_id = bonus.data.freespins_id;
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.popup_text = bonus.data.text;

          obj.popup_image_url = bonus.data.urlImage;
          obj.hit_value_min = bonus.data.hit_value_min;
          obj.hit_value_max = bonus.data.hit_value_max;
          obj.reward_fixed = bonus.data.rewardFixed;
          body.activities_after_activation.push(obj);
        } else if (bonus.type === 1 || bonus.type === 3) {
          const obj = {};
          obj.freespins_id = bonus.freespins_id;

          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.account = bonus.data.account;

          obj.reward_fixed = bonus.data.rewardFixed;
          obj.max_amount_for_percentage = bonus.data?.max;
          obj.percentage = bonus.data.percentage;
          body.activities_after_activation.push(obj);
        } else if (bonus.type === 2) {
          const obj = {};
          obj.freespins_id = bonus.data.freespins_id;

          obj.offer = bonus.data.offer;
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;

          obj.hit_value_min = bonus.data.hit_value_min;
          obj.hit_value_max = bonus.data.hit_value_max;
          body.activities_after_activation.push(obj);
        }
      });
    }

    //Rules
    if (bonusesState.rules.calculateBy === 0) {
      body.provider_rules = [];
      bonusesState.rules.providerRules.forEach((rule) => {
        if (rule.id === null) return;
        body.provider_rules.push({ id: rule.id, percentage: rule.percent });
      });
    }
    if (bonusesState.rules.calculateBy === 1) {
      body.game_rules = [];
      bonusesState.rules.gameRules.forEach((rule) => {
        if (rule.id === null) return;
        body.game_rules.push({ id: rule.id, percentage: rule.percent });
      });
    }

    if (bonusesState.rules.calculateBy === 2) {
      body.game_group_rules = [];

      bonusesState.rules.gameGroupRules.forEach((rule, i) => {
        if (rule.id === null) return;

        if (rule.percentage) {
          body.game_group_rules.push({
            id: rule.instance_id ? rule.instance_id : rule.id,
            percentage: rule.percentage,
          });
        }

        if (rule.percent) {
          body.game_group_rules.push({
            id: rule.instance_id,
            percentage: rule.percent,
          });
        }
      });
    }

    if (bonusesState.rules.calculateBy === 3) {
      body.game_categories = [];
      bonusesState.rules.gameCategories.forEach((rule) => {
        if (rule.id === null) return;
        body.game_categories.push({ id: rule.id, percentage: rule.percent });
      });
    }

    //Achievement

    if (bonusesState.achievement.activitiesAfterBonus.length > 0) {
      body.activities_after_achievement = [];
      bonusesState.achievement.activitiesAfterBonus.forEach((bonus, i) => {
        if (bonus.type === 0 || bonus.type === 1 || bonus.type === 4) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.account = bonus.data.account;

          obj.freespins_id = bonus.data.account;
          obj.reward_fixed = bonus.data.rewardFixed;
          obj.max_amount_for_percentage = bonus.data.max;
          obj.percentage = bonus.data.percentage;

          if (bonus.type === 0) {
            obj.hit_value_min = bonus.data.hit_value_min;
            obj.hit_value_max = bonus.data.hit_value_max;
          }
          body.activities_after_achievement.push(obj);
        }

        if (bonus.type === 3) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.popup_text = bonus.data.text;

          obj.freespins_id = bonus.data.account
            ? bonus.data.account
            : bonus.data.offer;

          obj.popup_image_url = bonus.data.urlImage;
          obj.hit_value_min = bonus.data.hit_value_min;
          obj.hit_value_max = bonus.data.hit_value_max;
          body.activities_after_achievement.push(obj);
        }

        if (bonus.type === 2) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.freespins_id = bonus.data.offer;
          obj.hit_value_min = bonus.data.hit_value_min;
          obj.hit_value_max = bonus.data.hit_value_max;
          body.activities_after_achievement.push(obj);
        }

        if (bonus.type === 5) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = bonus.type;
          obj.freespins_id = bonus.data.offer;
          obj.offer = bonus.data.offer;
          obj.reward_fixed = bonus.reward_fixed;
          obj.locked_by_bonus = bonus.locked_by_bonus;
          obj.hit_value_min = bonus.data.hit_value_min;
          obj.hit_value_max = bonus.data.hit_value_max;
          body.activities_after_achievement.push(obj);
        }
      });
    }

    if (!bonusesState.isEdit) {
      try {
        const res = await bonusesServices.createBonus(body);

        if (res.status === 200 && bonusesState) {
          return res.data.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue();
      }
    } else {
      try {
        body.bonus_round_id = idRound;

        const res = await bonusesServices.editBonus(body, id);

        if (res.status === 200 && bonusesState) {
          return res.data.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue();
      }
    }
  }
);
