import { tableColumnHandler } from "../../../../utils/tableColumnHandler";
const moment = require("moment");

export const stakesRoundDetailsColumn = [
  {
    ...tableColumnHandler("ID", "id"),
  },
  {
    ...tableColumnHandler("Timestamp", "timestamp"),
    render: (timestamp) => moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    ...tableColumnHandler("Wallet", "type_wallet"),
  },
  {
    ...tableColumnHandler("Type", "type_pretty"),
  },
  {
    ...tableColumnHandler("Amount", "amount"),
    render: (amount) => Number(amount).toFixed(2),
  },
  {
    ...tableColumnHandler("Balance", "balance"),
    render: (balance) => Number(balance).toFixed(2),
  },
  {
    ...tableColumnHandler("External ID", "external_id"),
  },
];
