import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesFilterState } from "../../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesFilterState } from "../../../../../../redux/slices/free-games/freeGamesSlice";

const StateSelect = () => {
  const dispatch = useDispatch();

  const state = useSelector(freeGamesFilterState);

  return (
    <Select
      value={state}
      defaultValue={state}
      dropdownMatchSelectWidth={false}
      onChange={(x) => {
        dispatch(changeFreeGamesFilterState(x));
      }}
      placeholder="- Select State -"
      options={[
        {
          value: 0,
          label: "Created",
        },
        {
          value: 1,
          label: "Waiting",
        },
        {
          value: 2,
          label: "Active",
        },
        {
          value: 3,
          label: "Finished",
        },
        {
          value: 4,
          label: "Evaluated",
        },
      ]}
    />
  );
};

export default StateSelect;
