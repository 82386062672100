import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import bonusesServices from "../../../../services/bonusesServices";
import { useDispatch } from "react-redux";
import { getBonusesRoundsData } from "../../../../redux/slices/bonuses/extra-reducers/getBonusesRoundsData";
import axios from "axios";

const ActionsColumn = ({ record }) => {
  const dispatch = useDispatch();

  const fetchCSV = async () => {
    try {
      const response = await axios.get(`/bonuses/export/${record.id}`, {
        headers: {
          "Content-Type": "text/csv", // Ensure your API returns CSV data
        },
      });

      const csvData = await response.data; // Get text data
      const filename = `bonus_${record.id}.csv`; //Add filename

      const blob = new Blob([csvData], { type: "text/csv" }); //Create blob
      const url = window.URL.createObjectURL(blob); // Create Url - blob:http://back-office.local.betsysbet.com/59795042-9013-4ff4-b234-f90a1ae5bb4 (demo)

      const a = document.createElement("a"); //Create anchor element so you can download trought href
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click(); //Simulate click on link
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a); //Remove elements after action
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <NavLink to={"/bonuses/participation/" + record.id}>
        <Button shape="circle" icon={<TeamOutlined />} />
      </NavLink>
      {record.state === 1 && (
        <Popconfirm
          icon={<DeleteOutlined className="bonuses_table_delete_icon" />}
          title="Are you sure you want to delete this item?"
          okText="Delete"
          cancelText="Cancel"
          onConfirm={() => {
            bonusesServices.deleteRound(record.id).then((res) => {
              dispatch(getBonusesRoundsData(record.bonus_id));
            });
          }}
          okButtonProps={{ danger: true, shape: "round" }}
          cancelButtonProps={{ shape: "round" }}
          showArrow={false}
        >
          <Tooltip title="Delete round">
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="middle"
              danger={true}
            />
          </Tooltip>
        </Popconfirm>
      )}

      <Tooltip title="Export">
        <Button
          onClick={fetchCSV}
          type="default"
          shape="circle"
          size="middle"
          icon={<DownloadOutlined />}
        />
      </Tooltip>

      {record.state < 3 && (
        <Tooltip title="Edit bonus">
          <NavLink
            to={"/bonuses/edit/" + record.bonus_id + "/round/" + record.id}
          >
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EditOutlined />}
            />
          </NavLink>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionsColumn;
