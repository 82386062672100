import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import {
  bonusesDateFromSelector,
  bonusesDateToSelector,
  bonusesTableDataSelector,
} from "../../../../../../redux/slices/bonuses/bonusesSelectors";
import { bonusesTableHeaders } from "../../bonuses-table/bonusesTableHeaders";

const BonusesExportButton = () => {
  const bonusesTableData = useSelector(bonusesTableDataSelector);
  const dateFrom = useSelector(bonusesDateFromSelector);
  const dateTo = useSelector(bonusesDateToSelector);

  if (bonusesTableData[0] === null) return;

  return (
    <CSVLink
      headers={bonusesTableHeaders}
      enclosingCharacter=""
      separator=";"
      data={bonusesTableData}
      filename={`${dateFrom.slice(0, 10)}-${dateTo.slice(0, 10)}-${"Bonuses"} `}
    >
      <Button
        type="link"
        shape="round"
        icon={<DownloadOutlined />}
        size={"middle"}
        style={{ color: "#fff" }}
      >
        Export
      </Button>
    </CSVLink>
  );
};

export default BonusesExportButton;
