import React from "react";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeStakesTransactionId } from "../../../../../redux/slices/stakes/stakeSlice";
import { stakesTransactionIdSelector } from "../../../../../redux/slices/stakes/stakesSelector";

const StakesIdTransaction = () => {
  const transactionId = useSelector(stakesTransactionIdSelector);
  const dispatch = useDispatch();

  return (
    <Input
      style={{ width: "250px" }}
      placeholder="Round ID"
      value={transactionId}
      onChange={(e) => {
        dispatch(changeStakesTransactionId(e.target.value));
      }}
    />
  );
};

export default StakesIdTransaction;
