import Input from "antd/lib/input/Input";
import React from "react";
import { useDispatch } from "react-redux";
import { changeFreeGamesFilterSearch } from "../../../../../../redux/slices/free-games/freeGamesSlice";

const SearchInput = () => {
  const dispatch = useDispatch();
  return (
    <Input
      placeholder="Search..."
      style={{ width: "250px" }}
      onChange={(e) => {
        dispatch(changeFreeGamesFilterSearch(e.target.value));
      }}
    />
  );
};

export default SearchInput;
