import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import {
  stakesDateFromSelector,
  stakesDateToSelector,
  stakesTableDataSelector,
} from "../../../../../redux/slices/stakes/stakesSelector";
import { stakeHeader } from "./stakesHeaders";

const StakesExportButton = () => {
  const stakesTableData = useSelector(stakesTableDataSelector);
  const stakesDateFrom = useSelector(stakesDateFromSelector);
  const stakesDateTo = useSelector(stakesDateToSelector);

  return (
    <CSVLink
      headers={stakeHeader}
      enclosingCharacter=""
      separator=";"
      data={stakesTableData}
      filename={`${stakesDateFrom.slice(0, 10)}-${stakesDateTo.slice(
        0,
        10
      )}- Stakes `}
    >
      <Button
        type="link"
        shape="round"
        icon={<DownloadOutlined />}
        size={"middle"}
        style={{ color: "#fff" }}
      >
        Export
      </Button>
    </CSVLink>
  );
};

export default StakesExportButton;
