import { createSlice } from "@reduxjs/toolkit";
import { createGameReducer } from "./extra-reducers/createGameReducer";
import { editGameReducer } from "./extra-reducers/editGameReducer";
import { getGamesReducer } from "./extra-reducers/getGamesReducer";
import { getSingleGameReducer } from "./extra-reducers/getSingleGameReducer";
import { getGamesByProviderReducer } from "./extra-reducers/getGamesByProvider";
import { getFilteredLobbyGames } from "./extra-reducers/getFilteredLobbyGames";

const initialState = {
  gamesList: [],
  pageNumber: 1,
  totalRows: 0,
  pageSize: 10,
  totalItems: 0,
  filter: {
    provider: null,
    gameGroups: null,
    category: null,
    name: null,
  },
  createEdit: {
    gameCreatedEdited: false,
    isEdit: false,
    name: null,
    provider: null,
    integration: null,
    iframeUrl: null,
    iframeDemoUrl: null,
    enabled: false,
    freeSpinsActive: false,
    usesWhitelist: false,
    gameCategories: [],
    flags: [],
    widgetWidth: 1,
    image: null,
    previewImage: null,
    ordinalNumber: null,
  },
  ui: {
    loadingGamesList: false,
    buttonWaitingResponse: false,
    gameCreateEditFinished: false,
  },
  providerGames: {
    data: [],
    loading: false,
  },
  filteredLobbyGames: {
    data: [],
    loading: false,
    filter: {
      provider: null,
      category: null,
      search: null,
      total: 0,
      page: 1,
      perPage: 100,
    },
  },
  selectedGamesTournament: [],
  selectedGamesKeysTournament: [],
  jackpotGameRulesGames: [],
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    addJackpotGameRulesGames: (state, actions) => {
      if (
        state.jackpotGameRulesGames.some(
          (game) => game.id === actions.payload.id
        )
      )
        return;

      state.jackpotGameRulesGames.push(actions.payload);
    },
    removeJackpotGameRulesGames: (state, actions) => {
      state.jackpotGameRulesGames = state.jackpotGameRulesGames.filter(
        (game) => game.id !== actions.payload
      );
    },
    changeJackpotGameRulesPercentage: (state, actions) => {
      state.jackpotGameRulesGames = state.jackpotGameRulesGames.map((game) => {
        if (game.id === actions.payload.id) {
          game.percentage = actions.payload.percentage;
          return game;
        } else {
          return game;
        }
      });
    },
    changeJackpotGameRulesHiddenPercentage: (state, actions) => {
      state.jackpotGameRulesGames = state.jackpotGameRulesGames.map((game) => {
        if (game.id === actions.payload.id) {
          game.hidden_percentage = actions.payload.percentage;
          return game;
        } else {
          return game;
        }
      });
    },

    resetJackpotGameRulesGames: (state) => {
      state.jackpotGameRulesGames = [];
    },
    setInitialEditJackpotGameRules: (state, action) => {
      state.jackpotGameRulesGames = action.payload;
    },

    resetGamesSlice: (state, action) => initialState,
    changeSelectedGamesTournament: (state, action) => {
      state.selectedGamesTournament = action.payload;
    },
    changeSelectedGamesAllAdded: (state, action) => {
      state.selectedGamesTournament.push(...action.payload);
    },
    changeSelectedGamesKeysTournament: (state, action) => {
      state.selectedGamesKeysTournament = action.payload;
    },
    changeProviderGames: (state) => {
      state.providerGames.data = [];
    },
    changeGamesFilterProvider: (state, action) => {
      state.filter.provider = action.payload;
    },
    changeGamesPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    changeGamesTotalRows: (state, action) => {
      state.totalRows = action.payload;
    },
    changeGamesPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    changeGamesFilterGameGroup: (state, action) => {
      state.filter.gameGroups = action.payload;
    },
    changeGamesIsEdit: (state, action) => {
      state.createEdit.isEdit = action.payload;
    },
    changeGamesName: (state, action) => {
      state.createEdit.name = action.payload;
    },
    changeGamesProvider: (state, action) => {
      state.createEdit.provider = action.payload;
    },
    changeGamesIntegration: (state, action) => {
      state.createEdit.integration = action.payload;
    },
    changeGamesIframeUrl: (state, action) => {
      state.createEdit.iframeUrl = action.payload;
    },
    changeGamesIframeDemoUrl: (state, action) => {
      state.createEdit.iframeDemoUrl = action.payload;
    },
    changeGamesEnabled: (state, action) => {
      state.createEdit.enabled = action.payload;
    },
    changeGamesFreeSpins: (state, action) => {
      state.createEdit.freeSpinsActive = action.payload;
    },
    changeGamesUsersWhitelist: (state, action) => {
      state.createEdit.usesWhitelist = action.payload;
    },
    changeGamesGameCategories: (state, action) => {
      state.createEdit.gameCategories = action.payload;
    },
    changeCategory: (state, action) => {
      state.filter.category = action.payload;
    },
    changeGameName: (state, action) => {
      state.filter.name = action.payload;
    },
    changeGamesFlags: (state, action) => {
      state.createEdit.flags = action.payload;
    },
    changeGamesWidgetWidth: (state, action) => {
      state.createEdit.widgetWidth = action.payload;
    },
    changeOrdinalNumber: (state, action) => {
      state.createEdit.ordinalNumber = action.payload;
    },
    changeGamesImage: (state, action) => {
      state.createEdit.image = action.payload;
    },
    changeGamesPreviewImage: (state, action) => {
      state.createEdit.previewImage = action.payload;
    },
    changeGameCreatedEdited: (state, action) => {
      state.createEdit.gameCreatedEdited = action.payload;
    },
    changeLobbyGamesFilters: (state, action) => {
      state.filteredLobbyGames.filter = {
        ...state.filteredLobbyGames.filter,
        ...action.payload,
      };
    },
    changeLobbyGamesPageNumber: (state, action) => {
      state.filteredLobbyGames.filter.page = action.payload;
    },
    resetLobbyGamesFilters: (state) => {
      state.filteredLobbyGames.filter = initialState.filteredLobbyGames.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGamesReducer.pending, (state) => {
      state.ui.loadingGamesList = true;
    });
    builder.addCase(getGamesReducer.fulfilled, (state, action) => {
      const array = [];

      
      if (
        action.payload.from === "tournament" ||
        action.payload.from === "bonus" ||
        action.payload.from === "jackpot" ||
        action.payload.from === "game-groups"
      ) {
        action.payload.data.data.forEach((x, i) => array.push(x));


        state.totalItems = action.payload.data.total;
        state.gamesList = array;
        state.ui.loadingGamesList = false;
      } else {
        action.payload.forEach((x, i) => array.push(x));
        state.gamesList = array;
        state.ui.loadingGamesList = false;
      }
    });
    builder.addCase(getGamesReducer.rejected, (state) => {
      state.ui.loadingGamesList = false;
    });
    builder.addCase(getGamesByProviderReducer.pending, (state) => {
      state.providerGames.loading = true;
    });
    builder.addCase(getGamesByProviderReducer.fulfilled, (state, action) => {
      state.providerGames.data = action.payload;
      state.providerGames.loading = false;
    });
    builder.addCase(getGamesByProviderReducer.rejected, (state) => {
      state.providerGames.loading = false;
    });
    builder.addCase(getFilteredLobbyGames.pending, (state) => {
      state.filteredLobbyGames.loading = true;
    });
    builder.addCase(getFilteredLobbyGames.fulfilled, (state, action) => {
      state.filteredLobbyGames.data = action.payload.data.map((item) => ({
        id: item.id,
        game_name: item.name,
        game_image: item.photo,
        game_category: item.game_categories[0].name,
        provider_name: item.provider.name,
        provider_image: item.provider.original_photo,
        flags: item.flags[0]?.name || null,
      }));
      state.filteredLobbyGames.filter.total = action.payload.total;
      state.filteredLobbyGames.loading = false;
    });
    builder.addCase(getFilteredLobbyGames.rejected, (state) => {
      state.filteredLobbyGames.loading = false;
    });
    builder.addCase(createGameReducer.pending, (state) => {
      state.ui.buttonWaitingResponse = true;
    });
    builder.addCase(createGameReducer.rejected, (state) => {
      state.ui.buttonWaitingResponse = null;
    });
    builder.addCase(createGameReducer.fulfilled, (state, action) => {
      state.ui.gameCreateEditFinished = true;
      state.ui.buttonWaitingResponse = false;
      state.createEdit.gameCreatedEdited = true;
    });
    builder.addCase(editGameReducer.fulfilled, (state, action) => {
      state.createEdit.gameCreatedEdited = true;
    });
    builder.addCase(getSingleGameReducer.pending, (state) => {
      state.ui.buttonWaitingResponse = true;
    });
    builder.addCase(getSingleGameReducer.rejected, (state) => {
      state.ui.buttonWaitingResponse = null;
    });
    builder.addCase(getSingleGameReducer.fulfilled, (state, action) => {
      const {
        name,
        enabled,
        freespins,
        flags,
        game_categories,
        integration_id,
        i_frame_url,
        i_frame_url_demo,
        photo,
        provider_id,
        uses_whitelist,
        widget_width,
        ordinal_number,
      } = action.payload;

      state.createEdit.name = name;
      state.createEdit.provider = provider_id;
      state.createEdit.integration = integration_id;
      state.createEdit.iframeUrl = i_frame_url;
      state.createEdit.iframeDemoUrl = i_frame_url_demo;
      state.createEdit.enabled = enabled;
      state.createEdit.freeSpinsActive = freespins;
      state.createEdit.ordinalNumber = ordinal_number;
      state.createEdit.flags = [
        ...flags.map((x) => {
          return x.id;
        }),
      ];
      state.createEdit.gameCategories = [
        ...game_categories.map((x) => {
          return x.id;
        }),
      ];
      state.createEdit.usesWhitelist = uses_whitelist;
      state.createEdit.widgetWidth = widget_width;
      state.createEdit.image = photo;
      state.createEdit.previewImage = photo;

      state.ui.buttonWaitingResponse = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCategory,
  changeGameName,
  setInitialEditJackpotGameRules,
  resetJackpotGameRulesGames,
  changeJackpotGameRulesHiddenPercentage,
  changeJackpotGameRulesPercentage,
  removeJackpotGameRulesGames,
  addJackpotGameRulesGames,
  changeSelectedGamesAllAdded,
  changeSelectedGamesKeysTournament,
  changeSelectedGamesTournament,
  changeProviderGames,
  changeGamesFilterProvider,
  changeGamesFilterGameGroup,
  resetGamesSlice,
  changeGamesIsEdit,
  changeGamesName,
  changeGamesProvider,
  changeGamesIframeUrl,
  changeGamesIframeDemoUrl,
  changeGamesEnabled,
  changeGamesFreeSpins,
  changeGamesUsersWhitelist,
  changeGamesGameCategories,
  changeGamesFlags,
  changeGamesWidgetWidth,
  changeGamesImage,
  changeGamesPreviewImage,
  changeGameCreatedEdited,
  changeLobbyGamesFilters,
  changeLobbyGamesPageNumber,
  resetLobbyGamesFilters,
  changeGamesIntegration,
  changeGamesPageNumber,
  changeGamesTotalRows,
  changeGamesPageSize,
  changeOrdinalNumber,
} = gamesSlice.actions;

export default gamesSlice.reducer;
