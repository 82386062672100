import axios from "axios";

const LOBBY_ROUTES = {
  ICONS: "/icons/all-icons",
  ALL_CATEGORIES: "/lobby-versions/preview",
  PUBLISH: "/lobby-versions/publish",
  ALL_WIDGETS: "/lobby-versions/preview",
  CATEGORY: "/pages",
  CATEGORY_EDIT: "/pages/edit",
  CATEGORY_UPDATE: "/pages/update",
  CATEGORY_REORDER: "/pages/reorder",
  CONTENT: "/page-contents",
  CONTENT_EDIT: "/page-contents/edit",
  CONTENT_UPDATE: "page-contents/update",
  CONTENT_REORDER: "page-contents/reorder",
  CONTENT_AREA: "/area-contents",
  FEATURES: "/features",
  FEATURES_EDIT: "features/edit",
  FEATURES_UPDATE: "features/update",
  FEATURES_REORDER: "features/reorder",
};

export const getLobbyCategories = (isMobile, activeCasino) =>
  axios(
    `${LOBBY_ROUTES.ALL_CATEGORIES}/${activeCasino}?isMobile=${isMobile}&casino_id=${activeCasino}`
  );

export const getAllWidgets = (activeCasino, isMobile) =>
  axios(
    `${LOBBY_ROUTES.ALL_WIDGETS}/${activeCasino}?casino_id=${activeCasino}&isMobile=${isMobile}`
  );

export const deleteCategory = (id, activeCasino) =>
  axios.delete(`${LOBBY_ROUTES.CATEGORY}/${id}?casino_id=${activeCasino}`);

export const storeCategory = (allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(LOBBY_ROUTES.CATEGORY, { ...allFields });
};

export const editCategory = (id, allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(`${LOBBY_ROUTES.CATEGORY_UPDATE}/${id}`, { ...allFields });
};

export const reorderCategories = (params, activeCasino) => {
  params.casino_id = activeCasino;
  return axios.post(`${LOBBY_ROUTES.CATEGORY_REORDER}`, { data: params });
};

export const getContentById = (id, activeCasino) =>
  axios(`${LOBBY_ROUTES.CONTENT}/${id}?casino_id=${activeCasino}`);

export const getEditContentById = (id, activeCasino) =>
  axios(`${LOBBY_ROUTES.CATEGORY_EDIT}/${id}?casino_id=${activeCasino}`);

export const deleteContent = (id, activeCasino) =>
  axios.delete(`${LOBBY_ROUTES.CONTENT}/${id}?casino_id=${activeCasino}`);

export const getContentEdit = (id, activeCasino) =>
  axios(`${LOBBY_ROUTES.CONTENT_EDIT}/${id}?casino_id=${activeCasino}`);

export const updateContent = (id, allFields) =>
  axios.post(`${LOBBY_ROUTES.CONTENT_UPDATE}/${id}`, { ...allFields });

export const reorderContent = (params, activeCasino) => {
  return axios.post(`${LOBBY_ROUTES.CONTENT_REORDER}`, {
    data: params,
    casino_id: activeCasino,
  });
};

export const getCateogryEditProps = (id, activeCasino) =>
  axios(`${LOBBY_ROUTES.CATEGORY_EDIT}/${id}?casino_id=${activeCasino}`);

export const storeContentArea = (allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(LOBBY_ROUTES.CONTENT, { ...allFields });
};

export const deleteContentArea = (id, activeCasino) =>
  axios.delete(`${LOBBY_ROUTES.CONTENT_AREA}/${id}?casino_id=${activeCasino}`);

export const getIcons = (activeCasino) =>
  axios(`${LOBBY_ROUTES.ICONS}?casino_id=${activeCasino}`);

export const storeFeatures = (allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(LOBBY_ROUTES.FEATURES, { ...allFields });
};

export const storeFeature = (allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(LOBBY_ROUTES.FEATURES, allFields);
};

export const deleteFeature = (id, activeCasino) =>
  axios.delete(`${LOBBY_ROUTES.FEATURES}/${id}?casino_id=${activeCasino}`);

export const getFeaturesEdit = (id, activeCasino) =>
  axios(`${LOBBY_ROUTES.FEATURES_EDIT}/${id}?casino_id=${activeCasino}`);

export const updateFeature = (id, allFields, activeCasino) => {
  allFields.casino_id = activeCasino;
  return axios.post(`${LOBBY_ROUTES.FEATURES_UPDATE}/${id}`, { ...allFields });
};

export const reorderFeatures = (params, activeCasino) =>
  axios.post(`${LOBBY_ROUTES.FEATURES_REORDER}`, {
    data: params,
    casino_id: activeCasino,
  });

export const publishRequest = (isMobile, activeCasino) =>
  axios(
    `${LOBBY_ROUTES.PUBLISH}/${activeCasino}?isMobile=${isMobile}&casino_id=${activeCasino}`
  );
