import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import AllActivities from "./AllActivities";
import { useDispatch } from "react-redux";
import { addBonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import { useEffect } from "react";
import { getFreeGamesWithoutPaginationReducer } from "../../../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesWithoutPagination";

const ActivitiesAfterActivation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFreeGamesWithoutPaginationReducer());
  }, [dispatch]);
  return (
    <>
      <div className="activities_after_container_wrapper">
        <AllActivities />
      </div>

      <div className="bonuses_activation_activities_after">
        <Button
          type="dashed"
          block
          icon={<PlusOutlined />}
          style={{ width: "min-content" }}
          onClick={() => {
            dispatch(addBonusesCreateActivationActivitiesAfterBonus());
          }}
        >
          {"Add activity"}
        </Button>
      </div>
    </>
  );
};

export default ActivitiesAfterActivation;
