/* eslint-disable array-callback-return */
import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoriesListSelector,
  loadingCategoriesListSelector,
} from "../../../../../../../../redux/slices/categories/categoriesSelectors";
import { bonusesCreateRulesGameCategoriesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { getCategoriesReducer } from "../../../../../../../../redux/slices/categories/extra-reducers/getCategoriesReducer";
import { addBonusesCreateRulesGameCategoriesRules } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const SelectCategory = () => {
  const list = useSelector(categoriesListSelector);
  const loading = useSelector(loadingCategoriesListSelector);
  const gameCategories = useSelector(bonusesCreateRulesGameCategoriesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesReducer());
  }, [dispatch]);

  useEffect(() => {
    if (gameCategories.length === 0) {
      dispatch(
        addBonusesCreateRulesGameCategoriesRules({
          id: null,
          percent: 0,
        })
      );
    }
  }, []);

  return (
    <div className="calculate_by_games_select">
      <Select
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loading}
        onChange={(x) => {
          dispatch(
            addBonusesCreateRulesGameCategoriesRules({
              id: x,
              percent: 0,
            })
          );
        }}
        placeholder="Select Category"
        style={{ width: "100%" }}
      >
        {list.map((item) => {
          let available = true;
          gameCategories.forEach((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item?.provider?.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      {/* <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="middle"
        onClick={() => {
          if (selectedGame) {
            
            setSelectedGame(null);
          }
        }}
      /> */}
    </div>
  );
};

export default SelectCategory;
