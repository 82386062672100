import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateAchievementMinimalValue } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesAchievementMinimalValue } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const MinimalValue = () => {
  const value = useSelector(bonusesCreateAchievementMinimalValue);
  const dispatch = useDispatch();

  return (
    <Form.Item label="Minimal value" name="minimal_value">
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        disabled
        onChange={(e) => {
          dispatch(changeBonusesAchievementMinimalValue(e));
        }}
      />
    </Form.Item>
  );
};

export default MinimalValue;
