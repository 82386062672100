import React from "react";
import ListGamesRules from "./ListGamesRules";
import SelectGame from "./SelectGame";
import GamesPicker from "./GamesPicker";
import GamePicker from "../../../../../../games/game-picker/GamePicker";

const CalculateByGames = () => {
  return (
    <div className="games_calculation_rules_container">
      <ListGamesRules />
      {/* <SelectGame /> */}
      <GamesPicker pageName="tournament" />
    </div>
  );
};

export default CalculateByGames;
