import { Avatar, Table } from "antd";
import { statisticColumnsProviders } from "../statisticColumns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statisticsOrderBy,
  statisticsSortDirection,
  statisticsUiIsDataLoading,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsOrderBy,
  changeStatisticsSortDirection,
} from "../../../../redux/slices/statistics/statisticsSlice";
import { formatAmount } from "../../../../helpers";
import { currencySelector } from "../../../../redux/slices/user/userSelectors";

const StatisticsTableProviders = ({ dataSource }) => {
  const isDataLoading = useSelector(statisticsUiIsDataLoading);
  const orderBy = useSelector(statisticsOrderBy);
  const sortDirection = useSelector(statisticsSortDirection);
  const currency = useSelector(currencySelector);

  const dispatch = useDispatch();

  return (
    <Table
      columns={[
        {
          title: "Provider",
          dataIndex: "provider_name",
          key: "provider_name",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <div
                className="provider"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                {text !== "Bonuses" &&
                  text !== "Total " &&
                  text !== "Total" &&
                  text !== "Freespins" &&
                  text !== "GameBonus" &&
                  text !== "Jackpots" && (
                    <Avatar
                      shape="circle"
                      src={game.provider_photo}
                      style={{ marginRIght: "4px" }}
                      size="default"
                    />
                  )}
                <div style={{ fontWeight, fontSize }}>{text && text}</div>
              </div>
            );
          },
          width: "220px",
        },
        {
          title: "Bet",
          dataIndex: "bet",
          key: "bet",
          searchable: false,

          width: "180px",

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "Win",
          dataIndex: "win",
          key: "win",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Net",
          dataIndex: "turnover",
          key: "turnover",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "Players",
          dataIndex: "players",
          key: "players",
          searchable: false,
        },

        {
          title: "RTP %",
          dataIndex: "rtp",
          key: "rtp",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            if (!text) return null;
            return <p style={{ fontWeight, fontSize }}>{text}%</p>;
          },
        },
        {
          title: "Tickets",
          dataIndex: "tickets",
          key: "tickets",
          searchable: false,
        },

        {
          title: "Ø Bet",
          dataIndex: "avg_bet",
          key: "avg_bet",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Ø Ticket/Player",
          dataIndex: "tickets_player",
          key: "tickets_player",
          searchable: false,
        },
      ]}
      className="statistics_table"
      dataSource={[
        ...dataSource.map((x) => {
          return { ...x, id: x.provider_name };
        }),
      ]}
      rowKey={(record, i) => {
        if (record.id) {
          return record.id;
        }

        if (!record.id) {
          return `${record.bet}-${i}`;
        }
      }}
      // onChange={handleTableChange}
      pagination={false}
      loading={isDataLoading}
      column
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              columns={[
                {
                  title: "--- Name ---",
                  dataIndex: "name",
                  key: "name",
                  searchable: false,
                  width: "400px",
                },
                {
                  title: "--- Win ---",
                  dataIndex: "win",
                  key: "win",
                  searchable: false,
                },
              ]}
              dataSource={[...record.data]}
              rowKey={(record, i) => i}
              pagination={false}
              onChange={(a, b, c) => {
                if (c.column.key !== orderBy || sortDirection === null) {
                  dispatch(changeStatisticsOrderBy(c.column.key));
                  dispatch(changeStatisticsSortDirection("asc"));
                } else if (sortDirection === "asc") {
                  dispatch(changeStatisticsOrderBy(c.column.key));
                  dispatch(changeStatisticsSortDirection("desc"));
                } else if (sortDirection === "desc") {
                  dispatch(changeStatisticsOrderBy(null));
                  dispatch(changeStatisticsSortDirection(null));
                }
              }}
            ></Table>
          );
        },
        rowExpandable: (record) => record.data?.length > 0,
      }}
    ></Table>
  );
};

export default StatisticsTableProviders;
