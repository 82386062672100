import { Form, Image } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { tournamentsCreateBannerImageUrl } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";

const BannerImagePreview = () => {
  const url = useSelector(tournamentsCreateBannerImageUrl);
  return (
    <>
      {url ? (
        <Form.Item
          label="Banner image preview"
          name=""
          style={{ minWidth: "100%", height: "100%" }}
        >
          <Image src={url ?? "error"} style={{ borderRadius: "4px" }} />
        </Form.Item>
      ) : null}
    </>
  );
};

export default BannerImagePreview;
