import moment from "moment";

export const bonusesTableHeaders = [
  {
    label: "Name",
    key: "name",
  },
  {
    label: "From",
    key: "starts_at",
    render: (text, record) => {
      return <>{moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")}</>;
    },
  },
  {
    label: "To",
    key: "ends_at",
    render: (text, record) => {
      return <>{moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")}</>;
    },
  },
  {
    label: "Activation mode",
    key: "activation_mode_pretty",
  },
];
