import { createAsyncThunk } from "@reduxjs/toolkit";
import { providersServices } from "../../../../services/providersServices";

export const getProvidersReducer = createAsyncThunk(
  "getProvidersReducer",
  async (all = true, thunkApi) => {
    try {
      let res;
      if (all) {
        res = await providersServices.getAllProviders();
      } else {
        res = await providersServices.getProviders();
      }

      if (res.status === 200) {
        if (all) {
          return Object.values(res.data);
        } else {
          return res.data.data;
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
