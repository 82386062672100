import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activeGameGroupSelector,
  gameGroupEditingGamesSelector,
} from "../../../redux/slices/game-groups/gameGroupSelectors";
import { addGameInGameGroup } from "../../../redux/slices/game-groups/gameGroupSlice";
import { getGamesReducer } from "../../../redux/slices/games/extra-reducers/getGamesReducer";
import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../redux/slices/games/gamesSelectors";

import GamesPicker from "../../pages/tournaments/tournaments-create-edit/tournaments-steps/steps-pages/rules/calculate-by-games/GamesPicker";

const AddGameGroup = () => {
  const gameList = useSelector(gamesListSelector);
  const loadingGameList = useSelector(loadingGamesListSelector);
  const addedGames = useSelector(gameGroupEditingGamesSelector);
  const activeGameGroup = useSelector(activeGameGroupSelector);

  const [selectedGame, setSelectedGame] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesReducer({ all: true }));
  }, [dispatch]);

  return (
    <div className="game_group_add_game">
      {activeGameGroup !== null && (
        <>
          <GamesPicker pageName={"game-groups"} />{" "}
          {/* <Select
            value={selectedGame}
            dropdownMatchSelectWidth={false}
            loading={loadingGameList}
            onChange={(x) => {
              setSelectedGame(x);
            }}
            placeholder="Add game"
            style={{ width: "100%" }}
          >
            {gameList.map((item) => {
              let available = true;
              addedGames.forEach((x) => {
                if (x.id === item.id) {
                  available = false;
                }
              });
              if (available) {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    <Avatar
                      src={item.photo}
                      style={{ marginRight: "4px" }}
                      size="small"
                    />
                    {item.name}
                  </Select.Option>
                );
              }
            })}
          </Select>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            size="middle"
            onClick={(e) => {
              if (selectedGame) {
                const game =
                  gameList &&
                  gameList.filter((game) => game.id === selectedGame)[0];

                dispatch(addGameInGameGroup(game));
                setSelectedGame(null);
              }
            }}
          /> */}
        </>
      )}
    </div>
  );
};

export default AddGameGroup;
