import axios from "axios";

const defaultRoute = "flags";

const getFlags = () => {
  const result = axios(`/${defaultRoute}`, {});
  return result;
};
const flagsServices = { getFlags };

export default flagsServices;
