import { EditOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import Tooltip from "antd/es/tooltip";
import moment from "moment";
import { NavLink } from "react-router-dom";
import ActionsColumn from "./ActionsColumn";

export const bonusesRoundsColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "From",
    dataIndex: "starts_at",
    key: "starts_at",
    render: (text, record) => {
      return <>{moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")}</>;
    },
  },
  {
    title: "To",
    dataIndex: "ends_at",
    key: "ends_at",
    render: (text, record) => {
      return <>{moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")}</>;
    },
  },
  {
    title: "State",
    dataIndex: "state_pretty",
    key: "state_pretty",
    searchable: false,
  },

  {
    title: "Value for calculation",
    dataIndex: "value_for_calculation_pretty",
    key: "value_for_calculation_pretty",
    searchable: false,
  },
  {
    title: "Hit value calculation type",
    dataIndex: "hit_value_calculation_type_pretty",
    key: "hit_value_calculation_type_pretty",
    searchable: false,
  },
  {
    title: "Minimal value",
    dataIndex: "minimal_value",
    key: "minimal_value",
    searchable: false,
  },
  {
    title: "Filter from",
    dataIndex: "value_filter_from",
    key: "value_filter_from",
    searchable: false,
    render: (text, record) => {
      return parseFloat(record.value_filter_from).toFixed(2) ?? "";
    },
  },
  {
    title: "Filter to",
    dataIndex: "value_filter_to",
    key: "value_filter_to",
    searchable: false,
    render: (text, record) => {
      return parseFloat(record.value_filter_to).toFixed(2) ?? "";
    },
  },
  {
    title: "Actions",
    dataIndex: "ends_at",
    key: "ends_at",
    render: (text, record) => {
      return <ActionsColumn record={record} />;
    },
  },
];
