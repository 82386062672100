/* eslint-disable array-callback-return */
import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGamesReducer } from "../../../../../../../../redux/slices/games/extra-reducers/getGamesReducer";
import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../../../../../../redux/slices/games/gamesSelectors";
import { tournamentsGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { addTournamentsCreateRulesGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const SelectGame = () => {
  const gameList = useSelector(gamesListSelector);
  const loadingGameList = useSelector(loadingGamesListSelector);
  const gameRules = useSelector(tournamentsGameRules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesReducer({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    if (gameRules.length === 0) {
      dispatch(
        addTournamentsCreateRulesGameRules({
          id: null,
          percent: 100,
        })
      );
    }
  }, [dispatch]);

  return (
    <div className="calculate_by_games_select">
      <Select
        showSearch
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loadingGameList}
        onChange={(x) => {
          dispatch(
            addTournamentsCreateRulesGameRules({
              id: gameList.find((game) => game.name === x).id,
              name: x,
              percent: 100,
            })
          );
        }}
        placeholder="Select game"
        style={{ width: "100%" }}
      >
        {gameList.map((item) => {
          let available = true;
          gameRules.forEach((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });

          if (available) {
            return (
              <Select.Option key={item.id} value={item.name}>
                <Avatar
                  src={item?.provider?.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
    </div>
  );
};

export default SelectGame;
