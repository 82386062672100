export const generalReducers = {
  changeBonusesCreateName: (state, action) => {
    state.create.general.name = action.payload;
  },
  changeBonusesCreateDateFrom: (state, action) => {
    state.create.general.dateFrom = action.payload;
  },
  changeBonusesCreateDateTo: (state, action) => {
    state.create.general.dateTo = action.payload;
  },
  changeBonusesCreateState: (state, action) => {
    state.create.general.state = action.payload;
  },
  changeBonusesCreateAchievementPeriodType: (state, action) => {
    state.create.general.achievementPeriodType = action.payload;
  },
  changeBonusesCreateAchievementPeriodTime: (state, action) => {
    state.create.general.achievementPeriodTime = action.payload;
  },
  changeBonusesCreateDescription: (state, action) => {
    state.create.general.description = action.payload;
  },
  changeBonusesCreatePublicTitle: (state, action) => {
    state.create.general.publicTitle = action.payload;
  },
  changeBonusesCreateShortText: (state, action) => {
    state.create.general.shortText = action.payload;
  },
  changeBonusesCreateLongText: (state, action) => {
    state.create.general.longText = action.payload;
  },
  changeBonusesCreateBannerUrl: (state, action) => {
    state.create.general.bannerUrl = action.payload;
  },
  changeBonusesCreateInfoUrl: (state, action) => {
    state.create.general.infoUrl = action.payload;
  },
  changeBonusesCreateButtonText: (state, action) => {
    state.create.general.buttonText = action.payload;
  },
  changeBonusesCreateButtonUrl: (state, action) => {
    state.create.general.buttonUrl = action.payload;
  },
  changeBonusesCreateProgressBar: (state, action) => {
    state.create.general.progressBarStyle = action.payload;
  },
};
