import { Menu } from "antd";
import { useEffect, useState } from "react";
import "./HeaderCustom.css";
import React from "react";
import casinoServices from "../../../services/casinoServices";
import UserIcon from "./user-icon/UserIcon";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activeCasinoSelector,
  isLoggedInSelector,
} from "../../../redux/slices/user/userSelectors";
import { MENU_LINKS, SETTINGS_LINKS } from "./menuLinks";
import {
  changeActiveCasino,
  changeCurrency,
  changeLobby,
} from "../../../redux/slices/user/userSlice";
import { cloneDeep } from "lodash";

const HeaderCustom = () => {
  const activeLink = useSelector(activeCasinoSelector);
  const dispatch = useDispatch();

  const [headerLinks, setHeaderLinks] = useState([]);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const history = useHistory();

  // useEffect(() => {
  //   const x = localStorage.getItem("lobby");
  //   if (x !== null && x !== undefined) {
  //     dispatch(changeLobby(x));
  //   } else {
  //     setTimeout(() => {
  //       dispatch(changeLobby("casino"));
  //     }, 1000);
  //   }
  // }, [dispatch]);

  useEffect(() => {
    const lobbyType = localStorage.getItem("lobby");
    dispatch(changeLobby(lobbyType));
  }, [dispatch]);

  const fetchData = async () => {
    const res = await casinoServices.getCasinos();
    const array = [];
    res.data.data.forEach((casino, i) => {
      if (casino.active === 1) {
        const obj = {};
        dispatch(changeCurrency(casino.currency));
        const arr = cloneDeep(MENU_LINKS);
        obj.key = casino.id;
        obj.currency = casino.currency;
        obj.label = casino.name;
        obj.icon = casino.photo;
        obj.children = [...arr];
        obj.children.forEach((x, index) => {
          x.key = x.label + casino.id;
        });

        array.push(obj);
      }
    });

    dispatch(changeActiveCasino(res.data.data[0]?.id));
    setHeaderLinks(array);
  };

  useEffect(() => {
    try {
      if (localStorage.getItem("token")) {
        fetchData();
      } else {
        dispatch(changeActiveCasino(-1));
      }
    } catch (error) {}
  }, [isLoggedIn]);

  return (
    <header className="header_custom">
      {isLoggedIn && (
        <>
          {" "}
          <Menu
            onClick={(e) => {
              dispatch(changeActiveCasino(e.keyPath[e.keyPath.length - 1]));
              // if (headerLinks && headerLinks.currency) {
              //   dispatch();
              //   console.log(headerLinks);
              // }
              headerLinks.forEach((x) => {
                if (x.key == e.keyPath[e.keyPath.length - 1]) {
                  dispatch(changeCurrency(x.currency));
                }
              });
              history.push(e.item.props.path);
            }}
            selectedKeys={activeLink}
            defaultSelectedKeys={activeLink}
            mode="horizontal"
            items={[...SETTINGS_LINKS, ...headerLinks]}
            className="header_links"
            theme="dark"
            subMenuCloseDelay={0.1}
            triggerSubMenuAction="click"
          />
          <UserIcon />
        </>
      )}
    </header>
  );
};

export default HeaderCustom;
