import axios from "axios";

const defaultRoute = "tournaments";

const getTournaments = (params) => {
  const result = axios(`/${defaultRoute}`, { params });
  return result;
};

const deleteTournament = (id) => {
  const result = axios.delete(`/${defaultRoute}/${id}`);
  return result;
};

const copyTournament = (id) => {
  const result = axios.get(`/${defaultRoute}/copy/${id}`);
  return result;
};

const getSingleTournament = (id, idRound) => {
  const result = axios(`/${defaultRoute}/${id}/round/${idRound}/edit`);

  return result;
};

const createTournament = (body) => {
  const result = axios.post(`/${defaultRoute}`, { ...body });

  return result;
};

const editTournament = (body, id) => {
  const result = axios.post(`/${defaultRoute}/update/${id}`, { ...body });
  return result;
};

const deleteRound = (id) => {
  const result = axios.delete(`/${defaultRoute}/round/${id}`);
  return result;
};

export const tournamentsServices = {
  getTournaments,
  deleteTournament,
  copyTournament,
  getSingleTournament,
  createTournament,
  editTournament,
  deleteRound,
};
