const zones = [
  -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8,
  9, 10, 11, 12,
];

export const primaryTimezones = zones.map((zone) => {
  return {
    value: -zone,
    label: zone < 0 ? `GMT${zone}` : zone === 0 ? `GMT` : `GMT+${zone}`,
  };
});
