import { Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bonusesCreateAchievementActivitiesAfterBonus,
  bonusesTableDataSelector,
  unlockBonusOffer,
} from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesAchievementActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ActivateBonusDisplay = ({ i }) => {
  const dataOptions = useSelector(bonusesTableDataSelector);
  const data = useSelector(bonusesCreateAchievementActivitiesAfterBonus);
  const offer = useSelector(unlockBonusOffer);

  const dispatch = useDispatch();
  if (data[0] === null || dataOptions[0] === null) return;

  const options = dataOptions.map((bonus) => {
    return { value: bonus.id, label: bonus.name };
  });

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Select
        disabled={options.length === 0 ? true : false}
        defaultValue={offer[0]?.data.offer}
        onChange={(x, a) => {
          // const newData = cloneDeep(data);
          // newData[i].data.offer = x;
          dispatch(
            changeBonusesAchievementActivitiesAfterBonus({
              data: { offer: x, offer_name: a.label },
              index: i,
            })
          );
        }}
        placeholder="- Select offer -"
        options={options}
        style={{ width: "30%", height: "min-content" }}
      />

      {/* <Select
        value={data[i]?.data?.reward_fixed}
        onChange={(x) => {
          const newData = cloneDeep(data);
          newData[i].data.reward_fixed = x;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        options={[
          { label: "Fixed", value: 0 },
          { label: "Floating", value: 1 },
        ]}
        style={{ maxWidth: "300px", width: "20%", height: "min-content" }}
      /> */}
      {/* <Select
        value={data[i]?.data?.locked_by_bonus}
        onChange={(x) => {
          const newData = cloneDeep(data);
          newData[i].data.locked_by_bonus = x;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        placeholder="- Unlocked -"
        options={[
          { label: "Offer0", value: 0 },
          { label: "Offer1", value: 1 },
          { label: "Offer2", value: 2 },
        ]}
        style={{ maxWidth: "300px", width: "20%", height: "min-content" }}
      /> */}
      <Input
        placeholder="Hit value min"
        type="text"
        defaultValue={
          data[i]?.data?.hit_value_min &&
          parseFloat(data[i]?.data?.hit_value_min).toFixed(2)
        }
        onChange={(e, b) => {
          if (isNaN(+e.target.value)) return;
          // const newData = cloneDeep(data);
          // newData[i].data.hit_value_min = e.target.value;
          dispatch(
            changeBonusesAchievementActivitiesAfterBonus({
              index: i,
              data: { hit_value_min: e.target.value },
            })
          );
        }}
        style={{ width: "30%", height: "min-content" }}
      />
      <Input
        placeholder="Hit value max"
        defaultValue={
          data[i]?.data?.hit_value_max &&
          parseFloat(data[i]?.data?.hit_value_max).toFixed(2)
        }
        onChange={(e) => {
          if (isNaN(+e.target.value)) return;
          // const newData = cloneDeep(data);
          // newData[i].data.hit_value_max = e.target.value;
          dispatch(
            changeBonusesAchievementActivitiesAfterBonus({
              index: i,
              data: { hit_value_max: e.target.value },
            })
          );
        }}
        type="text"
        style={{ width: "30%", height: "min-content" }}
      />
    </div>
  );
};

export default ActivateBonusDisplay;
