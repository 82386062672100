import React from "react";
import AchievementReview from "./AchievementReview";
import ActivationReview from "./ActivationReview";
import GeneralReview from "./GeneralReview";

import "./Review.css";
import RulesReview from "./RulesReview";

const Review = () => {
  return (
    <div>
      <div className="review_container">
        <GeneralReview />
        <ActivationReview />
        <RulesReview />
        <AchievementReview />
      </div>
    </div>
  );
};

export default Review;
