import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import {
  bonusesCreateGeneralAllDataEntered,
  bonusesCreateSelector,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import BannerImagePreview from "../general/public-info/BannerImagePreview";
import RowReview from "./RowReview";

const stateOptions = {
  0: "Created",
  1: "Waiting",
  2: "Active",
  3: "Finished",
  4: "Evaluated",
};

let timeOptions = [];

for (let i = 0; i < 54; i++) {
  if (i < 23) {
    timeOptions.push({
      value: i + 1,
      label: `${i + 1} hour${i === 0 ? "" : "s"}`,
    });
  } else {
    timeOptions.push({
      value: i + 1,
      label: `${i + 1 - 23} day${i === 23 ? "" : "s"}`,
    });
  }
}

const classNameOptions = {
  0: "",
  1: "meter red nostripes",
  2: "meter green nostripes",
  3: "meter orange nostripes",
  4: "meter red",
  5: "meter green",
  6: "meter orange",
  7: "meter red animate",
  8: "meter green animate",
  9: "meter orange animate",
};

const GeneralReview = () => {
  const createData = useSelector(bonusesCreateSelector);
  const allDataEntered = useSelector(bonusesCreateGeneralAllDataEntered);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        General
        <span style={{ marginLeft: "8px" }}>
          {allDataEntered === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview name="Name" data={createData.general.name} required={true} />
      <RowReview
        name="Recurring"
        data={createData.general.type ? "Yes" : "No"}
      />
      <RowReview
        name="Date From"
        data={
          createData.general.dateFrom
            ? moment(createData.general.dateFrom).format("DD.MM.YYYY HH:mm:ss")
            : ""
        }
        required={true}
      />
      <RowReview
        name="Date To"
        data={
          createData.general.dateTo
            ? moment(createData.general.dateTo).format("DD.MM.YYYY HH:mm:ss")
            : ""
        }
        required={true}
      />

      <RowReview
        name="State"
        data={
          createData.general.state !== null
            ? stateOptions[createData.general.state]
            : null
        }
      />
      <RowReview name="Description" data={createData.general.description} />
      <RowReview name="Public title" data={createData.general.publicTitle} />
      <RowReview name="Short text" data={createData.general.shortText} />
      <RowReview name="Long text " data={createData.general.longText} />
      <RowReview name="Banner image URL" data={createData.general.bannerUrl} />
      <div style={{ maxWidth: "500px", marginLeft: "4px" }}>
        {" "}
        <BannerImagePreview />
      </div>
      <RowReview name="Info url" data={createData.general.infoUrl} />
      <RowReview name="Button text" data={createData.general.buttonText} />
      <RowReview name="Button url" data={createData.general.buttonUrl} />
      <RowReview name="Progress bar style" />
      <div
        className={`${classNameOptions[createData.general.progressBarStyle]}`}
        style={{ width: "25%" }}
      >
        <span style={{ width: "50%" }}></span>
      </div>
    </div>
  );
};

export default GeneralReview;
