import { Divider } from "antd";
import { useSelector } from "react-redux";
import ValueFilterFrom from "./ValueFilterFrom";
import ValueFilterTo from "./ValueFilterTo";
import ValueForCalculation from "./ValueForCalculation";
import "./Rules.css";
import CalculateBy from "./CalculateBy";
import CalculateByProviders from "./calculate-by-providers/CalculateByProviders";
import CalculateByGames from "./calculate-by-games/CalculateByGames";
import CalculateByGroups from "./calculate-by-groups/CalculateByGroups";
import GameGroups from "../../../../../../components/game-groups/GameGroups";
import { tournamentsCalculateBy } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";

const Rules = () => {
  const value = useSelector(tournamentsCalculateBy);
  const bonusInfoMap = [
    <CalculateByProviders />,
    <CalculateByGames />,
    <CalculateByGroups />,
  ];

  return (
    <>
      <div className="bonuses_rules_container ">
        <div className="bonuses_rules_section ">
          <Divider> Rules info</Divider>
          <div className="bonuses_rules_info">
            <ValueForCalculation />
            <ValueFilterFrom />
            <ValueFilterTo />
            <CalculateBy />
            {value === 2 && <GameGroups />}
          </div>
          <Divider> Rules for calculation</Divider>
          <div className="bonuses_rules_info">
            {bonusInfoMap[value] || null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
