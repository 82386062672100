import React from "react";
import { Form, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  bonusesCreateDateFromSelector,
  bonusesCreateDateToSelector,
} from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  changeBonusesCreateDateFrom,
  changeBonusesCreateDateTo,
} from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
const { RangePicker } = DatePicker;

const DataRange = () => {
  const dispatch = useDispatch();
  const dateFrom = useSelector(bonusesCreateDateFromSelector);
  const dateTo = useSelector(bonusesCreateDateToSelector);

  const disabledStartDate = (startValue) => {
    // Disable dates before the current date and time
    if (startValue) {
      return startValue.isBefore(moment(), "day");
    }
    return false;
  };

  const disabledEndDate = (endValue) => {
    // Disable dates in the future
    if (endValue) {
      return endValue.isAfter(moment(), "day");
    }
    return false;
  };

  return (
    <Form.Item
      label="Period (from / to)"
      name="period"
      rules={[{ required: true, message: "This field is required" }]}
      initialValue={[
        dateFrom === null ? null : moment(dateFrom),
        dateFrom === null ? null : moment(dateTo),
      ]}
    >
      <RangePicker
        format="DD-MM-YYYY HH:mm"
        onChange={(x) => {
          dispatch(
            changeBonusesCreateDateFrom(`${moment.utc(x[0])
              .startOf('minute')
              .seconds(0)
              .toISOString()}`)
          );
          dispatch(changeBonusesCreateDateTo(moment.utc(x[1])
          .startOf('minute')
          .seconds(0)
          .toISOString()));
        }}
        showTime={{
          format: "HH:mm",
        }}
        disabledDate={disabledStartDate}
        disabledTime={(current, type) => {
          // Disable times for the start date if it's the same day and earlier than the current time
          if (type === "start" && current && moment().isSame(current, "day")) {
            if (moment(current._d).hour() === moment(new Date()).hour()) {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                disabledMinutes: () => [...Array(moment().minute()).keys()],
                // disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            } else {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                // disabledMinutes: () => [...Array(moment().minute()).keys()],
                // disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            }
          } else if (
            type === "end" &&
            current &&
            moment().isSame(current, "day")
          ) {
            if (moment(current._d).hour() === moment(new Date()).hour()) {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                disabledMinutes: () => [...Array(moment().minute()).keys()],
                // disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            } else {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                // disabledMinutes: () => [...Array(moment().minute()).keys()],
                // disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            }
          }
        }}
      />
    </Form.Item>
  );
};

export default DataRange;
