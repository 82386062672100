import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gameGroupsListSelector,
  loadingGameGroupsListSelector,
} from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";
import { tournamentsGameGroupRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { addTournamentsCreateRulesGameGroupsRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const SelectGameGroups = () => {
  const gameGroupsList = useSelector(gameGroupsListSelector);
  const gameGroupRules = useSelector(tournamentsGameGroupRules);
  const loadingGameGroups = useSelector(loadingGameGroupsListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gameGroupRules.length === 0) {
      dispatch(
        addTournamentsCreateRulesGameGroupsRules({
          id: null,
          percent: 0,
        })
      );
    }
  }, [dispatch]);

  return (
    <div className="calculate_by_providers_select">
      <Select
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loadingGameGroups}
        onChange={(x) => {
          dispatch(
            addTournamentsCreateRulesGameGroupsRules({
              id: x,
              percent: 0,
            })
          );
        }}
        placeholder="Select Game Group"
        style={{ width: "100%" }}
      >
        {gameGroupsList.map((item) => {
          let available = true;
          gameGroupRules.forEach((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
    </div>
  );
};

export default SelectGameGroups;
