import React from "react";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  bonusesDateFromSelector,
  bonusesDateToSelector,
} from "../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  changeBonusesDateFrom,
  changeBonusesDateTo,
} from "../../../../../../redux/slices/bonuses/bonusesSlice";
const { RangePicker } = DatePicker;

const DateRange = () => {
  const dateFrom = useSelector(bonusesDateFromSelector);
  const dateTo = useSelector(bonusesDateToSelector);
  const dispatch = useDispatch();

  return (
    <RangePicker
      format="DD-MM-YYYY"
      onChange={(x) => {
        dispatch(
          changeBonusesDateFrom(`${moment(x[0]).utc(true).toISOString()}`)
        );
        dispatch(changeBonusesDateTo(moment(x[1]).utc(true).toISOString()));
      }}
      value={[moment(dateFrom), moment(dateTo)]}
      style={{ minWidth: "250px" }}
    />
  );
};

export default DateRange;
