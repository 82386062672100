import { Button, PageHeader } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  bonusCreatedSelector,
  bonusesCreateAchievementAllDataEntered,
  bonusesCreateActivationAllDataEntered,
  bonusesCreateGeneralAllDataEntered,
  bonusesCreateIsEditSelector,
  bonusesCreateRulesAllDataEnteredSelector,
  bonusesIsSubmitting,
} from "../../../../../redux/slices/bonuses/bonusesSelectors";
import { resetBonusSlice } from "../../../../../redux/slices/bonuses/bonusesSlice";
import { createEditBonusReducer } from "../../../../../redux/slices/bonuses/extra-reducers/createBonusReduces";
import { useLocation } from "react-router-dom";

const Header = () => {
  const isEdit = useSelector(bonusesCreateIsEditSelector);

  const generalAllData = useSelector(bonusesCreateGeneralAllDataEntered);
  const activationAllData = useSelector(bonusesCreateActivationAllDataEntered);
  const rulesAllData = useSelector(bonusesCreateRulesAllDataEnteredSelector);
  const achievementAllData = useSelector(
    bonusesCreateAchievementAllDataEntered
  );
  const submitting = useSelector(bonusesIsSubmitting);
  const bonusCreated = useSelector(bonusCreatedSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const regex = /\/(\d+)\/round\/(\d+)/;
  const matches = location.pathname.match(regex);

  // Extracted numbers
  const id = matches && matches[1];
  const idRound = matches && matches[2];

  if (bonusCreated) {
    dispatch(resetBonusSlice());
    if (isEdit) {
      history.push("/bonuses/rounds/" + id);
    } else {
      history.push("/bonuses");
    }
  }
  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={isEdit ? "Edit bonus" : "Create bonus"}
      extra={
        <Button
          disabled={
            submitting ||
            generalAllData === false ||
            activationAllData === false ||
            rulesAllData === false ||
            achievementAllData === false
          }
          loading={submitting}
          type="primary"
          htmlType="submit"
          onClick={() => {
            setTimeout(() => {
              dispatch(createEditBonusReducer({ id, idRound }));
            }, 1);
          }}
        >
          {isEdit ? "Edit" : "Create"}
        </Button>
      }
    ></PageHeader>
  );
};

export default Header;
