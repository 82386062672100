const BannerExample = () => {
  return (
    <div className="banner_example">
      <p>
        This is the example of recommended banner size. Only elements of banners
        inside safe zone are visible on mobile & desktop devices.
      </p>

      <div style={{ width: "100%" }}>
        <img
          style={{ width: "100%" }}
          src="/img/safe_zone.png"
          alt="safe-zone"
        />
      </div>
    </div>
  );
};

export default BannerExample;
