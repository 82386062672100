import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleActivity from "./SingleActivity";
import { tournamentsActivitiesAfter } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  changeTournamentsAchievementActivitiesType,
  removeAchievementActivitiesAfterTournaments,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import { getFreeGamesReducer } from "../../../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";

const AllActivities = () => {
  const activities = useSelector(tournamentsActivitiesAfter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFreeGamesReducer());
  }, [dispatch]);

  return (
    <>
      {activities.map((a, i) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "8px",
              marginTop: "24px",
              padding: "8px 0",
            }}
            key={i}
          >
            <Select
              value={a.type}
              options={[
                { label: "Reward", value: 1 },
                { label: "Free Games", value: 2 },
              ]}
              style={{ maxWidth: "200px", width: "20%", height: "min-content" }}
              onChange={(e) => {
                dispatch(
                  changeTournamentsAchievementActivitiesType({
                    index: i,
                    type: e,
                    data: {},
                  })
                );
              }}
            />
            <SingleActivity type={a.type} data={a.data} key={i} i={i} />
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="middle"
              danger={true}
              onClick={() => {
                dispatch(removeAchievementActivitiesAfterTournaments(i));
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default AllActivities;
