import axios from "axios";

const defaultRoute = "bonuses";

const getBonuses = (params) => {
  const result = axios(`/${defaultRoute}`, { params });
  return result;
};

const getSingleBonus = async (id, idRound) => {
  const result = axios(`/${defaultRoute}/${id}/round/${idRound}/edit`);

  return result;
};

const createBonus = (body) => {
  const result = axios.post(`/${defaultRoute}`, { ...body });
  return result;
};

const copyBonus = (idBonus) => {
  const result = axios.get(`/${defaultRoute}/copy/${idBonus}`);
  return result;
};

const deleteBonus = (idBonus) => {
  const result = axios(`/${defaultRoute}/delete/${idBonus}`);
  return result;
};

const getRounds = (id, params) => {
  const result = axios(`/${defaultRoute}/${id}/rounds`, { params });
  return result;
};

const runNextRound = (id) => {
  const result = axios(`/${defaultRoute}/run-the-next-round/${id}`);
  return result;
};

const getParticipation = (id, page = 1, perPage = 20) => {
  const result = axios(`/${defaultRoute}/${id}/participation?page=${page}&per_page=${perPage}`);
  return result;
};
const addParticipation = (body, id) => {
  const result = axios.post(`/${defaultRoute}/${id}/participation`, {
    ...body,
  });
  return result;
};

const editBonus = (body, id) => {
  const result = axios.post(`/${defaultRoute}/update/${id}`, { ...body });

  return result;
};

const isManualBonus = (id) => {
  const result = axios(
    `/${defaultRoute}/${id}/participation/is-manual-activation`
  );
  return result;
};

const deleteRound = (id) => {
  const result = axios.delete(`/${defaultRoute}/bonus-round/${id}`);
  return result;
};

const bonusesServices = {
  getBonuses,
  createBonus,
  copyBonus,
  deleteBonus,
  runNextRound,
  getParticipation,
  editBonus,
  getRounds,
  getSingleBonus,
  addParticipation,
  isManualBonus,
  deleteRound,
};

export default bonusesServices;
