import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsValueFilterFrom } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsValueFilterFrom } from "../../../../../../../redux/slices/tournaments/tournamentsSlice";

const ValueFilterFrom = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsValueFilterFrom);

  return (
    <Form.Item
      label="Value filter from"
      name="value_filter_from"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        placeholder="From"
        onChange={(e) => {
          dispatch(changeTournamentsValueFilterFrom(e));
        }}
      />
    </Form.Item>
  );
};

export default ValueFilterFrom;
