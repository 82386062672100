import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreatePublicTitleSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreatePublicTitle } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const PublicTitle = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreatePublicTitleSelector);

  return (
    <Form.Item label="Public title" name="public_title">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreatePublicTitle(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default PublicTitle;
