import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bonusesIsDataLoading,
  bonusesPageNumberSelector,
  bonusesPageSizeSelector,
  bonusesSortBySelector,
  bonusesSortDirectionSelector,
  bonusesTableDataSelector,
  bonusesTotalRowsSelector,
} from "../../../../../redux/slices/bonuses/bonusesSelectors";
import { bonusesTableColumns } from "./bonusesTableColumns";
import "./BonusesTable.css";
import { activeCasinoSelector } from "../../../../../redux/slices/user/userSelectors";
import { getBonusesDataReducers } from "../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";
import {
  changeBonusesPageNumber,
  changeBonusesSortBy,
  changeBonusesSortDirection,
} from "../../../../../redux/slices/bonuses/bonusesSlice";
import Loader from "../../../../../layouts/components/Loader";

const BonusesTable = () => {
  const activeCasinoId = useSelector(activeCasinoSelector);

  const bonusesData = useSelector(bonusesTableDataSelector);
  const dataLoading = useSelector(bonusesIsDataLoading);

  const sortBy = useSelector(bonusesSortBySelector);
  const sortDirection = useSelector(bonusesSortDirectionSelector);
  const pageNumber = useSelector(bonusesPageNumberSelector);
  const pageSize = useSelector(bonusesPageSizeSelector);
  const totalRows = useSelector(bonusesTotalRowsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBonusesDataReducers());
  }, [activeCasinoId, dispatch, sortBy, sortDirection, pageNumber]);

  if (bonusesData[0] === null) return;

  return (
    <>
      <Table
        columns={bonusesTableColumns}
        dataSource={bonusesData}
        loading={dataLoading}
        className="bonuses_table"
        rowKey={(record) => record.id}
        showSorterTooltip={false}
        pagination={{
          total: totalRows,
          defaultCurrent: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: false,
          onChange: (page) => {
            dispatch(changeBonusesPageNumber(page));
          },
          totalBoundaryShowSizeChanger: true,
          position: "left",
        }}
        onChange={(a, b, c) => {
          if (c.column.key !== sortBy || sortDirection === null) {
            dispatch(changeBonusesSortBy(c.column.key));
            dispatch(changeBonusesSortDirection("asc"));
          } else if (sortDirection === "asc") {
            dispatch(changeBonusesSortBy(c.column.key));
            dispatch(changeBonusesSortDirection("desc"));
          } else if (sortDirection === "desc") {
            dispatch(changeBonusesSortBy(null));
            dispatch(changeBonusesSortDirection(null));
          }
        }}
      />
    </>
  );
};

export default BonusesTable;
