import { createAsyncThunk } from "@reduxjs/toolkit";
import { gamesServices } from "../../../../services/gamesServices";

export const getSingleGameReducer = createAsyncThunk(
  "getSingleGameReducer",
  async (id, thunkApi) => {
    try {
      const res = await gamesServices.getSingleGame(id);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
