import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getParticipation } from "../../../../redux/slices/bonuses/extra-reducers/getParticipation";
import bonusesServices from "../../../../services/bonusesServices";
import { useSelector } from "react-redux";
import { activeCasinoSelector } from "../../../../redux/slices/user/userSelectors";

const AddPlayersButton = () => {
  const [visible, setVisible] = useState();
  const [rawData, setRawData] = useState([]);

  const [successArray, setSuccessArray] = useState([]);
  const [failedArray, setFailedArray] = useState([]);

  const id = useParams().id;
  const casinoId = useSelector(activeCasinoSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible === false) {
      setSuccessArray([]);
      setFailedArray([]);
      setRawData([]);
    }
  }, [visible]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        Add Players
      </Button>
      <Modal
        title="Add players"
        centered
        open={visible}
        onOk={() => {
          const data = rawData.split("\n");
          const body = data.map((x) => {
            return { id: x };
          });
          bonusesServices
            .addParticipation({ players: body, casinos: [casinoId] }, id)
            .then((res) => {
              setSuccessArray(res.data.success);
              setFailedArray(res.data.failed);
              dispatch(getParticipation(id));
            });
        }}
        onCancel={() => {
          setVisible(false);
        }}
        width={"min(90vw,1200px)"}
        bodyStyle={{ height: "min(90vh,600px)" }}
        okText="Add"
        destroyOnClose={true}
        okButtonProps={{
          style: {
            display:
              successArray.length > 0 || failedArray.length > 0
                ? "none"
                : "initial",
          },
        }}
      >
        {successArray.length === 0 && failedArray.length === 0 && (
          <div
            style={{
              background: "#D9EDF7",
              color: "#6080B8",
              padding: "8px",
            }}
          >
            To add players into bonus place every PlayerID on separate line and
            click Add.
          </div>
        )}

        {successArray.length > 0 || failedArray.length > 0 ? (
          <div>
            <div
              style={{
                padding: "8px",
                background: "#DFF0D8",
                color: "#417941",
              }}
            >
              Players added: {successArray.length}
            </div>
            <div
              style={{
                padding: "8px",
                background: "#F2DEDE",
                color: "#C98784",
                marginTop: "32px",
              }}
            >
              Players failed: {failedArray.length}
            </div>
          </div>
        ) : (
          <TextArea
            style={{ marginTop: "16px", height: "90%" }}
            datatype="number"
            value={rawData}
            onChange={(e) => {
              setRawData(e.target.value);
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default AddPlayersButton;
