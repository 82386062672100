import React from "react";
import "./BonusesForm.css";
import BonusesExportButton from "./components/BonusesExportButton";
import BonusesFilterButton from "./components/BonusesFilterButton";
import DateRange from "./components/DateRange";
import Search from "./components/Search";
import State from "./components/State";

const BonusesForm = () => {
  return (
    <div className="bonuses_form">
      <State />
      <DateRange />
      <Search />
      <BonusesFilterButton />
      <BonusesExportButton />
    </div>
  );
};

export default BonusesForm;
