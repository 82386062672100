import { Form, Input, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesCreateCodeSelector } from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesCreateCode } from "../../../../../redux/slices/free-games/freeGamesSlice";
import { InfoCircleOutlined } from "@ant-design/icons";

const FreeGamesCode = () => {
  const dispatch = useDispatch();
  const value = useSelector(freeGamesCreateCodeSelector);
  return (
    <Form.Item label="Code">
      <div className="form_item-code">
        <Input
          value={value}
          defaultValue={value}
          onChange={(e) => {
            dispatch(changeFreeGamesCreateCode(e.target.value));
          }}
        />
        <Tooltip
          color="#001529"
          title="No duplicate 'Code' names allowed for free games."
          arrowPointAtCenter
          align={"center"}
        >
          <div className="form_item-code_info">
            <InfoCircleOutlined />
          </div>
        </Tooltip>
      </div>
    </Form.Item>
  );
};

export default FreeGamesCode;
