import React from "react";
import { useSelector } from "react-redux";
import SingleProviderRule from "./SingleProviderRule";
import { tournamentsProviderRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";

const ListProviderRules = () => {
  const providerRules = useSelector(tournamentsProviderRules);
  return (
    <div
      className="rules_list_all_providers"
      style={{ marginBottom: providerRules.length === 0 ? "0px" : "32px" }}
    >
      {providerRules.map((rule, i) => {
        return (
          <SingleProviderRule
            id={rule.id}
            percent={rule.percent}
            i={i}
            key={rule.id}
          />
        );
      })}
    </div>
  );
};

export default ListProviderRules;
