import { Table } from "antd";
import { statisticColumnsTotal } from "../statisticColumns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statisticsOrderBy,
  statisticsSortDirection,
  statisticsTotalData,
  statisticsUiIsDataLoading,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsOrderBy,
  changeStatisticsSortDirection,
} from "../../../../redux/slices/statistics/statisticsSlice";
import { formatAmount } from "../../../../helpers";
import { currencySelector } from "../../../../redux/slices/user/userSelectors";

const StatisticsTableTotal = ({ dataSource }) => {
  const isDataLoading = useSelector(statisticsUiIsDataLoading);
  const dispatch = useDispatch();
  const currency = useSelector(currencySelector);
  const orderBy = useSelector(statisticsOrderBy);
  const sortDirection = useSelector(statisticsSortDirection);

  return (
    <Table
      columns={[
        {
          title: "Bet",
          dataIndex: "bet",
          key: "bet",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            if (text === "Total" || text === "Total ") {
              return <p style={{ fontWeight, fontSize }}>{text && text}</p>;
            }
            if (
              text === "Jackpots" ||
              text === "Bonuses" ||
              text === "Freespins" ||
              text === "GameBonus"
            ) {
              return <p style={{ fontWeight, fontSize }}>{text && text}</p>;
            }

            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
          width: "220px",
        },
        {
          title: "Win",
          dataIndex: "win",
          key: "win",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "Net",
          dataIndex: "turnover",
          key: "turnover",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "RTP %",
          dataIndex: "rtp",
          key: "rtp",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            if (!text) return null;
            return <p style={{ fontWeight, fontSize }}>{text}%</p>;
          },
        },
        {
          title: "Tickets",
          dataIndex: "tickets",
          key: "tickets",
          searchable: false,
        },
        {
          title: "Players",
          dataIndex: "players",
          key: "players",
          searchable: false,
        },
        {
          title: "Ø  Bet",
          dataIndex: "avg_bet",
          key: "avg_bet",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Ø Ticket/Player",
          dataIndex: "tickets_player",
          key: "tickets_player",
          searchable: false,
        },
      ]}
      className="statistics_table"
      dataSource={[
        ...dataSource.map((x) => {
          return { ...x, id: x.tickets };
        }),
      ]}
      rowKey={(record, i) => {
        if (record.id) {
          return record.id;
        }

        if (!record.id) {
          return `${record.bet}-${i}`;
        }
      }}
      // onChange={handleTableChange}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              columns={[
                {
                  title: "--- Name ---",
                  dataIndex: "name",
                  key: "name",
                  searchable: false,
                  width: "220px",
                },
                {
                  title: "--- Win ---",
                  dataIndex: "win",
                  key: "win",
                  searchable: false,
                },
              ]}
              dataSource={[...record.data]}
              rowKey={(record, i) => {
                return `${record.name}-i`;
              }}
              pagination={false}
              onChange={(a, b, c) => {
                if (c.column.key !== orderBy || sortDirection === null) {
                  dispatch(changeStatisticsOrderBy(c.column.key));
                  dispatch(changeStatisticsSortDirection("asc"));
                } else if (sortDirection === "asc") {
                  dispatch(changeStatisticsOrderBy(c.column.key));
                  dispatch(changeStatisticsSortDirection("desc"));
                } else if (sortDirection === "desc") {
                  dispatch(changeStatisticsOrderBy(null));
                  dispatch(changeStatisticsSortDirection(null));
                }
              }}
            ></Table>
          );
        },
        rowExpandable: (record) => record.data?.length > 0,
      }}
      loading={isDataLoading}
    ></Table>
  );
};

export default StatisticsTableTotal;
