import React, { useEffect, useState } from "react";
import { changeProviderGames } from "../../../../../redux/slices/games/gamesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Select, Tooltip } from "antd";
import { getGamesByProviderReducer } from "../../../../../redux/slices/games/extra-reducers/getGamesByProvider";
import { loadingGamesListSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeStakesGames } from "../../../../../redux/slices/stakes/stakeSlice";
import { stakesGamesSelector } from "../../../../../redux/slices/stakes/stakesSelector";
import { stakesProviderSelector } from "../../../../../redux/slices/stakes/stakesSelector";
import { selectGamesByProvider } from "../../../../../redux/slices/games/gamesSelectors";

const StakesGameNameSelect = () => {
  const dispatch = useDispatch();
  const gamesList = useSelector(selectGamesByProvider);
  const loadingGamesList = useSelector(loadingGamesListSelector);
  const stakesProvider = useSelector(stakesProviderSelector);
  const stakesGames = useSelector(stakesGamesSelector);
  const stakesValue = useSelector(stakesProviderSelector);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    dispatch(changeStakesGames(0));
    dispatch(changeProviderGames());
  }, [dispatch, stakesProvider]);

  useEffect(() => {
    dispatch(getGamesByProviderReducer(stakesValue));
    if (!stakesValue) {
      dispatch(changeStakesGames(0));
    }
  }, [dispatch, stakesValue]);

  useEffect(() => {
    const filteredArray = gamesList.map((game) => ({
      value: game.id,
      label: game.name,
      photo: game.photo,
    }));
    setFilteredArray(filteredArray);
  }, [gamesList]);

  return (
    <Tooltip
      placement="top"
      title={!Boolean(stakesValue) && "Need to choose provider first"}
      arrow
    >
      <Select
        value={stakesGames}
        dropdownMatchSelectWidth={false}
        loading={loadingGamesList}
        disabled={Boolean(!stakesValue)}
        onChange={(x) => {
          dispatch(changeStakesGames(x));
        }}
      >
        <Select.Option key={0} value={0}>
          All Games
        </Select.Option>
        {filteredArray.map((item) => {
          return (
            <Select.Option key={item.value} value={item.value}>
              <Avatar
                src={item.photo}
                style={{ marginRight: "4px" }}
                size="small"
              />
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
    </Tooltip>
  );
};

export default StakesGameNameSelect;
