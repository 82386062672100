import { SyncOutlined } from "@ant-design/icons";
import { Button, PageHeader, Popconfirm, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Loader from "../../../../layouts/components/Loader";
import {
  bonusesIsRoundsDataLoading,
  bonusesPageNumberSelector,
  bonusesPageSizeSelector,
  bonusesRoundsData,
  bonusesTotalRowsSelector,
} from "../../../../redux/slices/bonuses/bonusesSelectors";
import { getBonusesRoundsData } from "../../../../redux/slices/bonuses/extra-reducers/getBonusesRoundsData";
import bonusesServices from "../../../../services/bonusesServices";
import { bonusesRoundsColumns } from "./bonusesRoundsColumns";
import "./BonusesRounds.css";
import {
  changeBonusesPageNumber,
  resetBonusSlice,
} from "../../../../redux/slices/bonuses/bonusesSlice";

const BonusesRounds = () => {
  const [hasActiveRound, setHasActiveRound] = useState(false);
  const [hasWaitingROund, setHasWaitingRound] = useState(false);

  const pageNumber = useSelector(bonusesPageNumberSelector);
  const pageSize = useSelector(bonusesPageSizeSelector);
  const totalRows = useSelector(bonusesTotalRowsSelector);

  const dispatch = useDispatch();
  var baseUrl = window.location.href;
  var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);

  useEffect(() => {
    return () => {
      dispatch(resetBonusSlice());
    };
  }, [dispatch]);

  const dataLoading = useSelector(bonusesIsRoundsDataLoading);
  const data = useSelector(bonusesRoundsData);

  const getRowStyle = (record) => {
    switch (record.state) {
      case 0:
        return "bonuses_rounds_created";
      case 2:
        return "bonuses_rounds_active ";
      default:
        return "";
    }
  };

  useEffect(() => {
    dispatch(getBonusesRoundsData(id));
  }, [dispatch, id]);

  useEffect(() => {
    setHasActiveRound(false);
    setHasWaitingRound(false);

    data.forEach((x) => {
      if (x.state === 2) {
        setHasActiveRound(true);
      }
      if (x.state === 1) {
        setHasWaitingRound(true);
      }
    });
  }, [data]);

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Bonuses Rounds"
        extra={[
          <>
            <Popconfirm
              icon={<SyncOutlined className="bonuses_rounds_popup" />}
              title="Run next round?"
              okText="Run"
              cancelText="Cancel"
              onConfirm={() => {
                if (hasWaitingROund || hasActiveRound) return;
                bonusesServices.runNextRound(id).then((res) => {
                  dispatch(getBonusesRoundsData(id));
                });
              }}
              okButtonProps={{ shape: "round" }}
              cancelButtonProps={{ shape: "round" }}
              showArrow={false}
            >
              <Button danger block disabled={hasWaitingROund || hasActiveRound}>
                Run next round
              </Button>
            </Popconfirm>
          </>,
        ]}
      ></PageHeader>

      <>
        {dataLoading ? (
          <Loader />
        ) : (
          <div className="bonuses_container">
            <div style={{ minWidth: "fit-content" }}>
              <Table
                rowClassName={(record, index) => getRowStyle(record)}
                columns={bonusesRoundsColumns}
                dataSource={data}
                className="bonuses_table"
                rowKey={(record) => record.id}
                showSorterTooltip={false}
                pagination={{
                  total: totalRows,
                  defaultCurrent: pageNumber,
                  defaultPageSize: pageSize,
                  showSizeChanger: false,
                  onChange: (page) => {
                    dispatch(changeBonusesPageNumber(page));
                    dispatch(getBonusesRoundsData(id));
                  },
                  totalBoundaryShowSizeChanger: true,
                  position: "left",
                }}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default BonusesRounds;
