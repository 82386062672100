import { Tag } from "antd";
import moment from "moment";
import ActionsColumn from "./ActionsColumn";

export const bonusesTableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "From",
    dataIndex: "starts_at",
    key: "starts_at",
    render: (text, record) => {
      let data;
      if (record.active_round) {
        data = moment
          .utc(record.active_round?.starts_at)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
      } else {
        data = moment
          .utc(record.next_round?.starts_at)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
      }
      return <>{data}</>;
    },
  },
  {
    title: "To",
    dataIndex: "ends_at",
    key: "ends_at",
    render: (text, record) => {
      let data;
      if (record.active_round) {
        data = moment
          .utc(record.active_round.ends_at)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
      } else {
        data = moment
          .utc(record.next_round?.ends_at)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
      }
      return <>{data}</>;
    },
  },
  {
    title: "Activation mode",
    dataIndex: "activation_mode_pretty",
    key: "activation_mode_pretty",
    searchable: false,
  },
  {
    title: "State",
    dataIndex: "active_round",
    key: "active_round",
    searchable: false,
    render: (text, record) => {
      return (
        <>
          {record.active_round !== null ? (
            <Tag color={"green"}>{"Active"}</Tag>
          ) : (
            <Tag color={"orange"}>{"Inactive"}</Tag>
          )}
        </>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "",
    key: "actions",
    render: (text, record) => <ActionsColumn record={record} />,
  },
];
