export const onSort = (data, key, desc) =>
{
  return(

    desc
      ? data?.sort((a, b) => (a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0))
      : data?.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0))
  )
}

export const onColumnSort = (a, b, prop) =>
    parseFloat(a[prop]) > parseFloat(b[prop]) ? -1 : parseFloat(a[prop]) < parseFloat(b[prop]) ? 1 : 0
  

