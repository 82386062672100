import { Avatar, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  freeGamesCreateFreeGamesArray,
  freeGamesCreateGameProviderSelector,
  freeGamesCreateGameSelector,
  freeGamesMultipleGames,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesCreateGame } from "../../../../../redux/slices/free-games/freeGamesSlice";
import { getGamesReducer } from "../../../../../redux/slices/games/extra-reducers/getGamesReducer";
import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../../../redux/slices/games/gamesSelectors";
import { providersListSelector } from "../../../../../redux/slices/providers/providersSelectors";
import { currencySelector } from "../../../../../redux/slices/user/userSelectors";

const FreeGamesSelectGame = () => {
  const dispatch = useDispatch();
  const game = useSelector(freeGamesCreateGameSelector);

  const provider = useSelector(freeGamesCreateGameProviderSelector);
  const providerList = useSelector(providersListSelector);

  let freespins_multiple_games;
  let freespins;
  providerList.forEach((x) => {
    if (x.id === provider) {
      freespins_multiple_games = x.freespins_multiple_games;
      freespins = x.freespins;
    }
  });

  const gameArray = useSelector(freeGamesCreateFreeGamesArray);

  const multipleFreeGames = useSelector(freeGamesMultipleGames);

  const gameList = useSelector(gamesListSelector);
  const loadingGameList = useSelector(loadingGamesListSelector);
  useEffect(() => {
    if (provider === null || freespins === 0) return;
    dispatch(
      getGamesReducer({
        all: true,
        payload: { provider_id: provider, type: "free_games" },
      })
    );
  }, [dispatch, freespins, provider]);

  return (
    <Form.Item label="Game">
      <Select
        value={game}
        disabled={
          (gameList && gameList.length === 0) ||
          (multipleFreeGames === 0 && gameArray.length > 0 ? true : false)
        }
        dropdownMatchSelectWidth={false}
        onChange={(x) => {
          dispatch(changeFreeGamesCreateGame(x));
        }}
        placeholder="- Select game -"
        loading={loadingGameList}
        style={{ width: "100%" }}
      >
        {gameList.map((item) => {
          let available = true;
          gameArray.map((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });

          if (item?.provider?.id === provider && available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          } else {
            return null;
          }
        })}
      </Select>
    </Form.Item>
  );
};

export default FreeGamesSelectGame;
