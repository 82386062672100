import { Form, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeGamesFreeSpins } from "../../../../../redux/slices/games/gamesSlice";
import { gamesFreeSpinsActiveSelector } from "../../../../../redux/slices/games/gamesSelectors";

const FreeSpinsActive = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesFreeSpinsActiveSelector);

  return (
    <Form.Item label="Free Spins" name="freespins">
      <Switch
        defaultChecked={value}
        onChange={(e) => {
          dispatch(changeGamesFreeSpins(e));
        }}
      />
    </Form.Item>
  );
};

export default FreeSpinsActive;
