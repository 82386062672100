import { Input, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  activeGameGroupSelector,
  gameGroupEditingGamesSelector,
  gameGroupEditingNameSelector,
} from "../../../redux/slices/game-groups/gameGroupSelectors";
import {
  changeChangesMade,
  changeGameGroupEditingName,
} from "../../../redux/slices/game-groups/gameGroupSlice";
import { gamesTableColumns } from "./gamesTableColumns";

const GameGroupGamesList = () => {
  const gameGroupList = useSelector(gameGroupEditingGamesSelector);

  const groupName = useSelector(gameGroupEditingNameSelector);

  const activeGameGroup = useSelector(activeGameGroupSelector);
  const dispatch = useDispatch();

  return (
    <div className="game_group_games_list">
      {activeGameGroup !== null && (
        <Input
          placeholder="Create new group"
          value={groupName}
          onChange={(e) => {
            dispatch(changeChangesMade(true));
            dispatch(changeGameGroupEditingName(e.target.value));
          }}
        />
      )}
      {activeGameGroup !== null ? (
        <Table
          columns={gamesTableColumns}
          dataSource={gameGroupList}
          className="game_group_games_table"
          rowKey={(record) => record.id}
          showSorterTooltip={false}
          pagination={false}
        ></Table>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ccc",
          }}
        >
          Please select game group
        </div>
      )}
    </div>
  );
};

export default GameGroupGamesList;
