import { Pagination, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFreeGamesReducer } from "../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";
import {
  freeGamesDataTableSelector,
  freeGamesLoadingDataTable,
  freeGamesPaginationCurrentPage,
  freeGamesPaginationPerPage,
  freeGamesPaginationTotal,
} from "../../../../../../redux/slices/free-games/freeGamesSelector";
import { freeGamesListColumns } from "./freeGamesListColumns";
import { changeFreeGamesPaginationPage } from "../../../../../../redux/slices/free-games/freeGamesSlice";
import { activeCasinoSelector } from "../../../../../../redux/slices/user/userSelectors";

const FreeGamesListTable = () => {
  const dispatch = useDispatch();

  const data = useSelector(freeGamesDataTableSelector);
  const loading = useSelector(freeGamesLoadingDataTable);
  const total = useSelector(freeGamesPaginationTotal);
  const page = useSelector(freeGamesPaginationCurrentPage);
  const perPage = useSelector(freeGamesPaginationPerPage);
  const activeCasino = useSelector(activeCasinoSelector);

  useEffect(() => {
    dispatch(getFreeGamesReducer());
  }, [dispatch, activeCasino]);

  const changePage = (value) => {
    dispatch(changeFreeGamesPaginationPage(value));
    dispatch(getFreeGamesReducer());
  };

  return (
    <>
      <Table
        columns={freeGamesListColumns}
        className="free_games_list_table"
        dataSource={data}
        loading={loading}
        rowKey={(x) => x.freegame_id}
        pagination={false}
      ></Table>
      <Pagination
        current={page}
        total={total}
        onChange={changePage}
        pageSize={perPage}
        className="free_games-pagination"
      />
    </>
  );
};

export default FreeGamesListTable;
