import React from "react";
import "./TournamentsForm.css";
import { Button, DatePicker, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FilterOutlined } from "@ant-design/icons";
import {
  tournamentsFilterDateFrom,
  tournamentsFilterDateTo,
  tournamentsFilterSearch,
  tournamentsFilterState,
} from "../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  changeTournamentsFilterDateFrom,
  changeTournamentsFilterDateTo,
  changeTournamentsFilterSearch,
  changeTournamentsFilterState,
} from "../../../../../redux/slices/tournaments/tournamentsSlice";
import { getTournamentsReducers } from "../../../../../redux/slices/tournaments/extra-reducers/getTournamentsReducers";
import { STATE_OPTIONS } from "./constants";
const { RangePicker } = DatePicker;

const TournamentsForm = () => {
  const state = useSelector(tournamentsFilterState);
  const dateFrom = useSelector(tournamentsFilterDateFrom);
  const dateTo = useSelector(tournamentsFilterDateTo);
  const search = useSelector(tournamentsFilterSearch);
  const dispatch = useDispatch();

  return (
    <div className="bonuses_form">
      <Select
        value={state}
        dropdownMatchSelectWidth={false}
        onChange={(x) => {
          dispatch(changeTournamentsFilterState(x));
        }}
        allowClear
        placeholder="Select state"
        options={STATE_OPTIONS}
        style={{ minWidth: "120px" }}
      />
      <RangePicker
        format="DD-MM-YYYY"
        onChange={(x) => {
          dispatch(
            changeTournamentsFilterDateFrom(
              `${moment(x[0]).utc(true).toISOString()}`
            )
          );
          dispatch(
            changeTournamentsFilterDateTo(moment(x[1]).utc(true).toISOString())
          );
        }}
        value={[moment(dateFrom), moment(dateTo)]}
        style={{ minWidth: "250px" }}
      />

      <Input
        placeholder="Search..."
        style={{ width: "250px" }}
        value={search}
        onChange={(e) => {
          dispatch(changeTournamentsFilterSearch(e.target.value));
        }}
      />

      <Button
        onClick={() => {
          dispatch(getTournamentsReducers());
        }}
        type="primary"
        shape="round"
        icon={<FilterOutlined />}
        size={"middle"}
      >
        Filter
      </Button>
    </div>
  );
};

export default TournamentsForm;
