import { Divider } from "antd";
import React from "react";

import ActivationMode from "./ActivationMode";
import ActivityNeeded from "./ActivityNeeded";
import "./Activation.css";

const Activation = () => {
  return (
    <div className="bonuses_activation_container">
      <div className="bonuses_activation_section">
        <Divider> Activation info</Divider>
        <div className="activation_general_info">
          <ActivationMode />
          <ActivityNeeded />
        </div>
      </div>
    </div>
  );
};

export default Activation;
