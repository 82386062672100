import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLobbyCategories } from "../../../../services/lobbyServices";
import { changeLobbyActiveCategoryId } from "../lobbySlice";

export const getLobbyDataReducers = createAsyncThunk(
  "getLobbyDataReducers",
  async (_, thunkAPI) => {
    try {
      const { lobby } = await thunkAPI.getState();
      const isMobile = await lobby.isMobile;
      const casino_id = thunkAPI.getState().user.activeCasino;

      const res = await getLobbyCategories(isMobile, casino_id);

      if (res.status !== 200) throw new Error();

      if (!res.data.pages.length) {
        thunkAPI.dispatch(changeLobbyActiveCategoryId(null));

        return res.data;
      }

      thunkAPI.dispatch(changeLobbyActiveCategoryId(res.data.pages[0].id));

      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
