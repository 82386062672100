import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateWidgetWidth } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateWidgetWidth } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const WidgetWidth = () => {
  const state = useSelector(tournamentsCreateWidgetWidth);
  const dispatch = useDispatch();

  return (
    <Form.Item
      label="Widget Width"
      name="ww"
      // rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select WidgetWidth"
        value={state}
        defaultValue={state}
        options={[
          {
            value: 1,
            label: 1,
          },
          {
            value: 2,
            label: 2,
          },
          {
            value: 3,
            label: 3,
          },
          {
            value: 6,
            label: 6,
          },
        ]}
        onChange={(x) => {
          dispatch(changeTournamentsCreateWidgetWidth(x));
        }}
      ></Select>
    </Form.Item>
  );
};

export default WidgetWidth;
