import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { changeTournamentsCreateActivitiesAfterBonus } from "../../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import { tournamentsActivitiesAfter } from "../../../../../../../../../redux/slices/tournaments/tournamentsSelector";
const RewardDisplay = ({ i }) => {
  const data = useSelector(tournamentsActivitiesAfter);
  const dispatch = useDispatch();

  return (
    <div key={"reward_display" + i} style={{ display: "flex", gap: "8px" }}>
      <Select
        value={data[i]?.data?.account}
        onChange={(x) => {
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                account: x,
                freespins_id: x,
              },
            })
          );
        }}
        options={[
          { label: "Internet", value: 0 },
          { label: "Free Bet", value: 1 },
        ]}
        dropdownMatchSelectWidth={false}
        placeholder="- Account -"
      />
      <Input
        placeholder="Reward Fixed"
        defaultValue={parseFloat(data[i]?.data?.rewardFixed)}
        onChange={(e) => {
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                rewardFixed: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
      <Input
        placeholder="Position from"
        defaultValue={parseFloat(data[i]?.data?.positionFrom)}
        onChange={(e) => {
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                positionFrom: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
      <Input
        placeholder="Position to"
        defaultValue={parseFloat(data[i]?.data?.positionTo)}
        onChange={(e) => {
          // const newData = cloneDeep(data);
          // newData[i].data.positionTo = e.target.value;
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                positionTo: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
    </div>
  );
};

export default RewardDisplay;
