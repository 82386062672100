import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import StakesForm from "./stakes-form/StakesForm";
import StakesTable from "./stakes-table/StakesTable";
import StakesRoundDetail from "./stakes-round-detail/StakesRoundDetail";
import "./StakesList.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetStakesSlice } from "../../../redux/slices/stakes/stakeSlice";
import casinoServices from "../../../services/casinoServices";
import { useSelector } from "react-redux";
import { activeCasinoSelector } from "../../../redux/slices/user/userSelectors";
import { changeCurrency } from "../../../redux/slices/user/userSlice";

function StakesList() {
  const dispatch = useDispatch();
  const activeCasino = useSelector(activeCasinoSelector);
  const { goBack } = useHistory();

  const fetchCurrency = async () => {
    const res = await casinoServices.getCasinos();

    res.data.data.forEach((casino, i) => {
      if (activeCasino === casino.id) {
        dispatch(changeCurrency(casino.currency));
      }
    });
  };

  useEffect(() => {
    fetchCurrency();
    return () => {
      dispatch(resetStakesSlice());
    };
  }, [dispatch]);

  const hanleGoBack = () => goBack();

  return (
    <div>
      <PageHeader ghost={false} onBack={hanleGoBack} title="Stakes" />
      <div className="stakes_container">
        <div style={{ minWidth: "fit-content" }}>
          <StakesForm />
          <StakesTable />
          <StakesRoundDetail />
        </div>
      </div>
    </div>
  );
}

export default StakesList;
