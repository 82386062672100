import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { removeGameInGameGroup } from "../../../redux/slices/game-groups/gameGroupSlice";

const GameGroupGamesListActions = ({ record }) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "4px 8px",
      }}
    >
      <Avatar
        src={record?.provider?.photo}
        alt={record.name + " icon"}
        style={{ visibility: "hidden", width: "0" }}
      />
      <Button
        icon={<DeleteOutlined />}
        danger={true}
        type="primary"
        shape="circle"
        onClick={() => {
          dispatch(removeGameInGameGroup(record.id));
        }}
      ></Button>
    </div>
  );
};

export default GameGroupGamesListActions;
