import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user/userSlice";
import statisticsReducer from "./slices/statistics/statisticsSlice";
import providersReducer from "./slices/providers/providersSlice";
import gamesReducer from "./slices/games/gamesSlice";
import categoriesReducer from "./slices/categories/categoriesSlice";
import freeGamesReducer from "./slices/free-games/freeGamesSlice";
import bonusesReducer from "./slices/bonuses/bonusesSlice";
import stakesReducer from "./slices/stakes/stakeSlice";
import gameGroupsReducer from "./slices/game-groups/gameGroupSlice";
import flagsReducer from "./slices/flags/flagsSlice";
import integrationsReducer from "./slices/integrations/integrationsSlice";
import tournamentsReducer from "./slices/tournaments/tournamentsSlice";
import slidesReducer from "./slices/slides/sliderSlice";
import lobbySlice from "./slices/lobby/lobbySlice";
import { getIconsApi } from "./slices/lobby/extra-reducers/getIcons";

export const store = configureStore({
  reducer: {
    user: userReducer,
    statistics: statisticsReducer,
    providers: providersReducer,
    games: gamesReducer,
    categories: categoriesReducer,
    freeGames: freeGamesReducer,
    bonuses: bonusesReducer,
    stakes: stakesReducer,
    gameGroup: gameGroupsReducer,
    flags: flagsReducer,
    integrations: integrationsReducer,
    tournaments: tournamentsReducer,
    slides: slidesReducer,
    lobby: lobbySlice,
    [getIconsApi.reducerPath]: getIconsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(getIconsApi.middleware),
});
