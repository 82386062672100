import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  freeGameDetailSearch,
  selectFreeGameDetails,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import FreeGamesAddPlayersControls from "./freeGamesAddPlayersControls/FreeGamesAddPlayersControls";
import FreeGamesAddPlayersList from "./freeGamesAddPlayersList/FreeGamesAddPlayersList";
import { getSingleFreeGamesDetailsReducer } from "../../../../../redux/slices/free-games/extra-reducers/getSingleFreeGamesDetailsReducer";

const FreeGamesAddPlayers = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const participants = useSelector(selectFreeGameDetails);

  const searchDetail = useSelector(freeGameDetailSearch);
  const searchParticipants = [];

  participants.forEach((participant) => {
    if (participant.external_id.includes(searchDetail)) {
      searchParticipants.push(participant);
    }
  });

  useEffect(() => {
    dispatch(getSingleFreeGamesDetailsReducer(id));
  }, [dispatch, id]);

  return (
    <>
      <FreeGamesAddPlayersControls />
      <FreeGamesAddPlayersList
        participants={participants ? searchParticipants : participants}
        id={id}
      />
    </>
  );
};

export default FreeGamesAddPlayers;
