import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const getIconsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: window.BASE_URL,

    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (!token) return headers;

      headers.set("Authorization", `Bearer ${token}`);
    },
  }),
  endpoints: (build) => ({
    getLobbyIcons: build.query({ query: () => `/icons/all-icons` }),
  }),
});

export const { useGetLobbyIconsQuery } = getIconsApi;
