import React from "react";
import { Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const PopConfirmDelete = ({ name, buttonText = "", onHandleRemove, type }) => (
  <Popconfirm
    icon={<DeleteOutlined />}
    title={`Are you sure you want to delete this ${name}?`}
    okText="Delete"
    okType="danger"
    cancelText="Cancel"
    onConfirm={onHandleRemove}
  >
    <Button icon={<DeleteOutlined />} danger type={type}>
      {buttonText}
    </Button>
  </Popconfirm>
);

export default PopConfirmDelete;
