import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFlagsReducer } from "../../../../../redux/slices/flags/extra-reducers/getFlagsReducer";
import {
  flagsListSelector,
  loadingFlagsListSelector,
} from "../../../../../redux/slices/flags/flagsSelectors";
import { gamesFlagsSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesFlags } from "../../../../../redux/slices/games/gamesSlice";

const GamesFlags = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesFlagsSelector);

  useEffect(() => {
    dispatch(getFlagsReducer());
  }, [dispatch]);

  const data = useSelector(flagsListSelector);
  const loading = useSelector(loadingFlagsListSelector);

  return (
    <Form.Item label="Flags" name="flags" fieldKey="flags">
      <Select
        mode={"multiple"}
        allowClear
        style={{ width: "100%" }}
        placeholder="Please add Flags"
        loading={loading}
        onChange={(e) => {
          dispatch(changeGamesFlags(e));
        }}
        value={value}
        defaultValue={value}
      >
        {data.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default GamesFlags;
