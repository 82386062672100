import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesWidgetWidthSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesWidgetWidth } from "../../../../../redux/slices/games/gamesSlice";

const GamesWidgetWidth = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesWidgetWidthSelector);

  return (
    <Form.Item label="Widget width" name="widget_width" fieldKey="widget_width">
      <Select
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        value={value}
        defaultValue={value}
        options={[
          { label: "1", value: 1 },
          { label: "2", value: 2 },
          { label: "3", value: 3 },
          { label: "6", value: 6 },
        ]}
        onChange={(e) => {
          dispatch(changeGamesWidgetWidth(e));
        }}
      ></Select>
    </Form.Item>
  );
};

export default GamesWidgetWidth;
