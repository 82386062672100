import React, { useId } from "react";
import { PageHeader, Button } from "antd";
import { useHistory } from "react-router-dom";
import TournamentsTable from "./tournaments-table/TournamentsTable";
import TournamentsForm from "./tournaments-form/TournamentsForm";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetTournamentState } from "../../../../redux/slices/tournaments/tournamentsSlice";
import "./Tournaments.css";

function Tournaments() {
  const dispatch = useDispatch();
  const id = useId();
  const { goBack, push } = useHistory();

  useEffect(() => {
    return () => {
      dispatch(resetTournamentState());
    };
  }, [dispatch]);

  const hanleGoBack = () => goBack();
  const handleNavigation = () => push("/tournaments/create");

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        title="Tournaments"
        extra={
          <Button key={id} type="primary" onClick={handleNavigation}>
            <span>Create new</span>
          </Button>
        }
      ></PageHeader>

      <div className="bonuses_container">
        <div style={{ minWidth: "fit-content" }}>
          <TournamentsForm />
          <TournamentsTable />
        </div>
      </div>
    </div>
  );
}

export default Tournaments;
