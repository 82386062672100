import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import statisticsService from "../../../../services/statisticsService";

export const getStatisticsDataReducers = createAsyncThunk(
  "getStatisticsDataReducers",
  async (q, thunkApi) => {
    const statisticsState = thunkApi.getState().statistics;
    const casino_id = thunkApi.getState().user.activeCasino;
    const params = {
      date_from: moment(statisticsState.statisticsDateFrom).format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      ),
      date_to: moment(statisticsState.statisticsDateTo).format(
        "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
      ),
      time_zone: statisticsState.statisticsTimezone * 60,
      group_by: statisticsState.filterBy,
      casino_id: casino_id,
      sort_column: statisticsState.orderBy,
      sort_direction: statisticsState.sortDirection,
    };

    //

    if (statisticsState.statisticsProvider !== 0) {
      params.provider_id = statisticsState.statisticsProvider;
    }
    if (statisticsState.statisticsGames !== 0) {
      params.game_id = statisticsState.statisticsGames;
    }
    if (statisticsState.statisticsCategories !== 0) {
      params.category_id = statisticsState.statisticsCategories;
    }
    try {
      const res = await statisticsService.getStatistics(params);

      if (res.status === 200 && statisticsState) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
