import React from "react";
import { useSelector } from "react-redux";
import SingleGameRule from "./SingleGameRule";
import { tournamentsGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";

const ListGamesRules = () => {
  const gameRules = useSelector(tournamentsGameRules);
  return (
    <div
      className="rules_list_all_games"
      style={{ marginBottom: gameRules.length === 0 ? "0px" : "32px" }}
    >
      {gameRules.map((rule, i) => {
        return (
          <SingleGameRule
            name={rule.name}
            id={rule.id}
            percent={rule.percent}
            i={i}
            key={rule.id}
          />
        );
      })}
    </div>
  );
};

export default ListGamesRules;
