/* eslint-disable array-callback-return */
import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesGameRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { addBonusesCreateRulesGameRules } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import { getGamesReducer } from "../../../../../../../../redux/slices/games/extra-reducers/getGamesReducer";
import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../../../../../../redux/slices/games/gamesSelectors";

const SelectGame = () => {
  const gameList = useSelector(gamesListSelector);
  const loadingGameList = useSelector(loadingGamesListSelector);
  const gameRules = useSelector(bonusesCreateRulesGameRulesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGamesReducer({ all: true }));
  }, [dispatch]);

  useEffect(() => {
    if (gameRules.length === 0) {
      dispatch(
        addBonusesCreateRulesGameRules({
          id: null,
          percent: 100,
        })
      );
    }
  }, []);

  return (
    <div className="calculate_by_games_select">
      <Select
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loadingGameList}
        onChange={(x) => {
          dispatch(
            addBonusesCreateRulesGameRules({
              id: x,
              percent: 100,
            })
          );
        }}
        placeholder="Select game"
        style={{ width: "100%" }}
      >
        {gameList.map((item) => {
          let available = true;
          gameRules.forEach((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item?.provider?.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      {/* <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="middle"
        onClick={() => {
          if (selectedGame) {
          
          }
        }}
      /> */}
    </div>
  );
};

export default SelectGame;
