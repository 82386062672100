import { createAsyncThunk } from "@reduxjs/toolkit";
import { gamesServices } from "../../../../services/gamesServices";
const { getGames } = gamesServices;

export const getFilteredLobbyGames = createAsyncThunk(
  "getFilteredLobbyGames",
  async (page, thunkApi) => {
    try {
      const getState = await thunkApi.getState();
      const casino_id = await getState.user.activeCasino;
      const { provider, category, search, perPage } = await getState.games
        .filteredLobbyGames.filter;

      const params = {
        casino_id,
        ...(provider && { provider_id: provider }),
        ...(category && { category_id: category }),
        name: search,
        page: page,
        per_page: perPage,
      };

      const res = await getGames(params);

      if (res.status !== 200) throw new Error();

      return res.data;
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
