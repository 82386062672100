import { Avatar, Button, Space, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../layouts/components/Loader";
import { getStakesDataReducers } from "../../../../redux/slices/stakes/extra-reducers/getStakesDataReducers";
import {
  changeStakeRound,
  changeStakesPageNumber,
  changeStakesSortBy,
  changeStakesSortDirection,
} from "../../../../redux/slices/stakes/stakeSlice";

import {
  stakesPageNumberSelector,
  stakesPageSizeSelector,
  stakesSortBySelector,
  stakesSortDirectionSelector,
  stakesTableDataSelector,
  stakesTotalRowsSelector,
  stakesUiIsDataLoading,
} from "../../../../redux/slices/stakes/stakesSelector";
import {
  activeCasinoSelector,
  currencySelector,
} from "../../../../redux/slices/user/userSelectors";
import { setStakesDetailRoundModal } from "../../../../redux/slices/stakes/stakeSlice";
import "./StakesTable.css";
import moment from "moment";
import { formatAmount } from "../../../../helpers";
import { EyeOutlined } from "@ant-design/icons";

const StakesTable = () => {
  const dispatch = useDispatch();
  const activeCasino = useSelector(activeCasinoSelector);
  const tableData = useSelector(stakesTableDataSelector);
  const isDataLoading = useSelector(stakesUiIsDataLoading);
  const sortBy = useSelector(stakesSortBySelector);
  const sortDirection = useSelector(stakesSortDirectionSelector);
  const pageNumber = useSelector(stakesPageNumberSelector);
  const pageSize = useSelector(stakesPageSizeSelector);
  const totalRows = useSelector(stakesTotalRowsSelector);
  const currency = useSelector(currencySelector);

  const stakeColumn = [
    // {
    //   title: "Round ID",
    //   dataIndex: "id",
    //   key: "id",
    //   searchable: false,
    //   sorter: true,
    // },
    {
      title: "Player ID",
      dataIndex: "user_uuid",
      key: "user_uuid",
      sorter: true,
    },
    {
      title: "Provider",
      dataIndex: "provider_name",
      key: "provider_name",
      sorter: true,
      render: (text, record) => {
        return (
          <div>
            <Avatar
              src={record.provider_photo}
              style={{ marginRight: "4px" }}
            />
            {record.provider_name}
          </div>
        );
      },
    },
    {
      title: "Game",
      dataIndex: "game_name",
      key: "game_name",
      sorter: true,
      render: (text, record) => (
        <div style={{ marginRight: "4px" }}>
          <Avatar src={record.game_photo} /> {record.game_name}{" "}
        </div>
      ),
    },
    {
      title: "Bet",
      dataIndex: "out",
      key: "out",
      searchable: false,
      sorter: true,
      render: (text, record) => {
        return <div>{formatAmount(record.out, currency)}</div>;
      },

      align: "right",
    },
    {
      title: "Win",
      dataIndex: "in",
      key: "in",
      searchable: false,
      sorter: true,
      render: (text, record) => <div>{formatAmount(record.in, currency)}</div>,
      align: "right",
    },
    {
      title: "RTP",
      dataIndex: "rtp",
      key: "rtp",
      searchable: false,
      sorter: true,
      render: (text, record) => <div>{record.rtp}%</div>,
      align: "right",
    },
    {
      title: "Wallet Type",
      dataIndex: "type_wallet",
      key: "type_wallet",
      width: "150px",
      searchable: false,
      sorter: true,
      align: "center",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: true,
      render: (text, record) => {
        return (
          <div>{moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")}</div>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      sorter: true,
      width: "50px",
      render: (text, record) => {
        return (
          <div onClick={() => handleRoundDetail(record.id)}>
            <Tooltip title="View stake">
              <Button shape="circle" icon={<EyeOutlined />} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getStakesDataReducers());
  }, [activeCasino, dispatch, sortBy, sortDirection, pageNumber]);

  const handleRoundDetail = async (id) => {
    const data = await axios(`/rounds/${id}`);
    dispatch(changeStakeRound(data.data.data));
    dispatch(setStakesDetailRoundModal(true));
  };

  return (
    <>
      <Table
        columns={stakeColumn}
        loading={isDataLoading}
        dataSource={tableData}
        rowKey={(record) => record.id}
        showSorterTooltip={false}
        onRow={(record) => {
          return {
            onClick: async () => {
              if (sortDirection === "asc") {
                dispatch(changeStakesSortDirection("asc"));
              }
              if (sortDirection === "desc") {
                dispatch(changeStakesSortDirection("desc"));
              }

              if (sortDirection === null) {
                dispatch(changeStakesSortDirection(null));
              }
            },
          };
        }}
        pagination={{
          total: totalRows,
          defaultCurrent: pageNumber,
          defaultPageSize: pageSize,
          current: pageNumber,
          showSizeChanger: false,
          totalBoundaryShowSizeChanger: true,
          position: "left",
        }}
        className="stakes_table"
        on
        onChange={(a, b, c) => {
          dispatch(changeStakesPageNumber(a.current));
          if (c.order === "ascend") {
            dispatch(changeStakesSortBy(c.column.key));
            dispatch(changeStakesSortDirection("asc"));
          }
          if (c.order === "descend") {
            dispatch(changeStakesSortBy(c.column.key));
            dispatch(changeStakesSortDirection("desc"));
          }
          if (c.order === undefined) {
            dispatch(changeStakesSortBy(null));
            dispatch(changeStakesSortDirection(null));
          }
        }}
      />
    </>
  );
};

export default StakesTable;
