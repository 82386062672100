import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import bonusesServices from "../../../../services/bonusesServices";

export const getBonusesDataReducers = createAsyncThunk(
  "getBonusesDataReducers",
  async (q, thunkApi) => {
    const bonusesState = thunkApi.getState().bonuses;
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    const casino_id = thunkApi.getState().user.activeCasino;

    let params;

    if (q && q.callFrom && q.callFrom === "external") {
      params = {
        casino_id: casino_id,
        activation_mode: 2,
        per_page: q.perPage ? q.perPage : 1000,
        sort_direction: "desc",
        state: bonusesState.state,
      };
    } else if (q && q.callFrom && q.callFrom === "manual") {
      params = {
        casino_id: casino_id,
        activation_mode: 1,
        sort_direction: "desc",
        state: bonusesState.state,
      };
    } else {
      params = {
        "period[0]": moment(bonusesState.dateFrom)
          .startOf("day")
          .add(timezoneOffset, "hours")
          .utc(true)
          .toISOString(),
        "period[1]": moment(bonusesState.dateTo)
          .endOf("day")
          .add(timezoneOffset, "hours")
          .utc(true)
          .toISOString(),
        casino_id: casino_id,
        page: bonusesState.pageNumber,
        per_page: q && q.perPage ? q.perPage : bonusesState.pageSize,
        activation_mode: q && q.activationMode ? q.activationMode : undefined,
        sort_direction: "desc",
        state: bonusesState.state,
      };
    }

    if (bonusesState.state !== null) {
      params.state = bonusesState.state;
    }
    if (bonusesState.search) {
      params.search = bonusesState.search;
    }
    try {
      const res = await bonusesServices.getBonuses(params);

      if (res.status === 200 && bonusesState) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
