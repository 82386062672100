import { createAsyncThunk } from "@reduxjs/toolkit";
import integrationsService from "../../../../services/integrationsServices";

export const getIntegrationReducer = createAsyncThunk(
  "getIntegrationReducer",
  async (q, thunkApi) => {
    try {
      const res = await integrationsService.getIntegrations();
      if (res.status === 200) {
        return res.data.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
