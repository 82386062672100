import { createAsyncThunk } from "@reduxjs/toolkit";
import { gamesServices } from "../../../../services/gamesServices";
import { changeGamesTotalRows } from "../gamesSlice";

export const getGamesReducer = createAsyncThunk(
  "getGamesReducer",
  async ({ all, from, payload = { type: undefined } }, thunkApi) => {
    try {
      const casino_id = thunkApi.getState().user.activeCasino;
      const gamesState = thunkApi.getState().games;
      const stakesProvider = thunkApi.getState().stakes.stakesProvider;
      const currency = thunkApi.getState().user.currency;

      let res;
      if (all) {
        res = await gamesServices.getAllGames({
          casino_id,
          provider_id: payload.provider_id || gamesState.filter.provider,
          game_group_id: gamesState.filter.gameGroups,
          sort_direction: "desc",
          ...payload,
        });
      } else {

        res = await gamesServices.getGames({
          casino_id,
          provider_id:
            payload.provider_id || gamesState.filter.provider || stakesProvider,
          game_group_id: gamesState.filter.gameGroups,
          sort_direction: "desc",
          page: payload.page || gamesState.pageNumber,
          per_page: payload.per_page || gamesState.pageSize,
          category_id: gamesState.filter.category,
          // total: 
          name: gamesState.filter.name,
          ...payload,
        });



      }
      if (res.status === 200) {
        if (all) {
          const dataArray = Object.values(res.data);

          const data = dataArray.filter((data) => {
            const bets = data.bets;

            return bets.some((bet) => {
              return bet.currency === currency && bet.bet_type !== "bet";
            });
          });

          if (payload.type === "free_games") {
            return data;
          }

          if (!payload.type) {
            return dataArray;
          }
        } else {
          thunkApi.dispatch(changeGamesTotalRows(res.data.total));
          if (from === "tournament" || from === "bonus" || from === "jackpot" || from === 'game-groups') {
            return { from, data: res.data };
          }
          return res.data.data;
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
