import axios from "axios";

const defaultRoute = "integrations";

const getIntegrations = () => {
  const result = axios(`/${defaultRoute}`, {});
  return result;
};
const integrationsService = { getIntegrations };

export default integrationsService;
