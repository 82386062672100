import { Avatar, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesCreateGameProviderSelector } from "../../../../../redux/slices/free-games/freeGamesSelector";
import {
  changeFreeGamesArray,
  changeFreeGamesCreateGame,
  changeFreeGamesCreateGameProvider,
  changeFreeGamesMultipleGames,
  removeFreeGamesArray,
} from "../../../../../redux/slices/free-games/freeGamesSlice";
import { getProvidersReducer } from "../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import {
  providersListSelector,
  loadingProvidersListSelector,
} from "../../../../../redux/slices/providers/providersSelectors";

const FreeGamesGameProvider = () => {
  const dispatch = useDispatch();
  const value = useSelector(freeGamesCreateGameProviderSelector);
  const providersList = useSelector(providersListSelector);
  const loading = useSelector(loadingProvidersListSelector);

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  return (
    <Form.Item label="Game Provider">
      <Select
        dropdownMatchSelectWidth={false}
        loading={loading}
        onChange={(x, a) => {
          dispatch(
            changeFreeGamesMultipleGames(a.data.freespins_multiple_games)
          );
          dispatch(changeFreeGamesCreateGameProvider(x));
          dispatch(changeFreeGamesCreateGame(null));
          dispatch(changeFreeGamesArray([]));
        }}
        placeholder="- Select provider -"
        // showSearch
        value={value}
        defaultValue={value}
      >
        {providersList?.map((item) => {
          return (
            item.freespins > 0 && (
              <Select.Option key={item.id} value={item.id} data={item}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            )
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default FreeGamesGameProvider;
