import React from "react";
import ListGameGroupsRules from "./ListGameGroupsRules";
import SelectGameGroups from "./SelectGameGroups";

const CalculateByGroups = () => {
  return (
    <div className="provider_calculation_rules_container">
      <ListGameGroupsRules />
      <SelectGameGroups />
    </div>
  );
};

export default CalculateByGroups;
