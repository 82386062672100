import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import PopConfirmDelete from "../../../../../components/popConfirmDelete/popConfirmDelete";
import { tableColumnHandler } from "../../../../../../utils/tableColumnHandler";
import { onColumnSort } from "../../../../../../utils/onSort";
import { deleteFreeGameDetail } from "../../../../../../services/freeGamesServices";
import { getSingleFreeGamesDetailsReducer } from "../../../../../../redux/slices/free-games/extra-reducers/getSingleFreeGamesDetailsReducer";
import { useSelector } from "react-redux";
import { selectFreeGamesSingleProps } from "../../../../../../redux/slices/free-games/freeGamesSelector";

const FreeGamesAddPlayersList = ({ participants }) => {
  const dispatch = useDispatch();
  const { freeGamesCreateBetType } = useSelector(selectFreeGamesSingleProps);
  let total_win = 0;

  participants.forEach((participant) => {
    total_win += +participant.win;
  });

  const handleRemovePlayer = async ({
    casino_id,
    freespin_id,
    id,
    player_id,
  }) => {
    const params = { casino_id, freespins_id: freespin_id, id, player_id };

    await deleteFreeGameDetail(params);

    dispatch(getSingleFreeGamesDetailsReducer(freespin_id));
  };

  return (
    <>
      <Table
        columns={[
          {
            ...tableColumnHandler("Player", "external_id"),
            sorter: (a, b) => onColumnSort(a, b, "external_id"),
          },
          {
            ...tableColumnHandler(
              // freeGamesCreateBetType === 0 ?
              "Spins left",
              //  : "Time left",
              "spins_left"
            ),
            sorter: (a, b) => onColumnSort(a, b, "spins_left"),
          },
          {
            ...tableColumnHandler("Total price", "win"),
            sorter: (a, b) => onColumnSort(a, b, "win"),
            render: (_, record) => {
              return parseFloat(record.win).toFixed(2);
            },
          },
          {
            ...tableColumnHandler("Action", "action"),
            width: 100,
            render: (_, record) => {
              return (
                <PopConfirmDelete
                  name="player"
                  onHandleRemove={() => handleRemovePlayer(record)}
                  type="text"
                />
              );
            },
          },
        ]}
        dataSource={participants}
        rowKey={(player) => player.id}
        pagination={{
          total: participants.total,
          defaultCurrent: 1,
          showSizeChanger: true,
          hideOnSinglePage: true,
          style: {
            marginRight: 20,
          },
        }}
      ></Table>
      {total_win > 0 && (
        <div className="total_win">
          Total win:
          <span style={{ fontWeight: "700" }}>
            &nbsp;{total_win.toFixed(2)}
          </span>
        </div>
      )}
    </>
  );
};

export default FreeGamesAddPlayersList;
