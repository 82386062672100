import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getStatisticsDataReducers } from "../../../../redux/slices/statistics/extra-reducers/getStatisticsDataReducers";

const StatisticsFilterButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(getStatisticsDataReducers());
      }}
      type="primary"
      shape="round"
      icon={<FilterOutlined />}
      size={"middle"}
    >
      Filter
    </Button>
  );
};

export default StatisticsFilterButton;
