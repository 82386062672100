import { Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesSearchSelector } from "../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesSearch } from "../../../../../../redux/slices/bonuses/bonusesSlice";

const Search = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesSearchSelector);
  return (
    <Input
      placeholder="Search..."
      style={{ width: "250px" }}
      value={value}
      onChange={(e) => {
        dispatch(changeBonusesSearch(e.target.value));
      }}
    />
  );
};

export default Search;
