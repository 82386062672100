export const statisticsFilterBySelector = (state) => {
  return state.statistics.filterBy;
};

export const statisticsOrderBy = (state) => {
  return state.statistics.orderBy;
};

export const statisticsSortDirection = (state) => {
  return state.statistics.sortDirection;
};

export const statisticsProviderSelector = (state) => {
  return state.statistics.statisticsProvider;
};

export const statisticsGamesSelector = (state) => {
  return state.statistics.statisticsGames;
};

export const statisticsCategoriesSelector = (state) => {
  return state.statistics.statisticsCategories;
};

export const statisticsDateFromSelector = (state) => {
  return state.statistics.statisticsDateFrom;
};

export const statisticsDateToSelector = (state) => {
  return state.statistics.statisticsDateTo;
};

export const statisticsTableDataSelector = (state) => {
  return state.statistics.statisticsTableData;
};
export const statisticsUiIsDataLoading = (state) => {
  return state.statistics.ui.isDataLoading;
};
export const statisticsJackpots = (state) => {
  return state.statistics.additionalData.jackpots;
};
export const statisticsFreeGames = (state) => {
  return state.statistics.additionalData.freeGames;
};
export const statisticsBonuses = (state) => {
  return state.statistics.additionalData.bonuses;
};
export const statisticsTotalData = (state) => {
  return state.statistics.total;
};

export const statisticsGamesDisabledSelector = (state) =>
  state.statistics.statisticsGamesDisabled;

export const statisticsGamesByProviderSelector = (state) =>
  state.statistics.statisticsGamesByProvider;

export const statisticsGameValueSelector = (state) =>
  state.statistics.statisticsGameValue;

export const statisticsTimezone = (state) =>
  state.statistics.statisticsTimezone;
