export const activationReducers = {
  changeBonusesCreateActivationMode: (state, action) => {
    state.create.activation.activationMode = action.payload;
  },
  changeBonusesCreateActivityNeeded: (state, action) => {
    state.create.activation.activityNeeded = action.payload;
  },
  changeBonusesAutoRepeat: (state, action) => {
    state.create.activation.autoRepeat = action.payload;
  },
  changeBonusesCreateActivationPlayers: (state, action) => {
    state.create.activation.players = action.payload;
  },
  changeBonusesCreateActivationCode: (state, action) => {
    state.create.activation.activationCode = action.payload;
  },
  changeBonusesCreateActivationDepositMinAmount: (state, action) => {
    state.create.activation.depositMinAmount = action.payload;
  },
  changeBonusesCreateActivationDepositSequence: (state, action) => {
    state.create.activation.depositSequence = action.payload;
  },
  changeBonusesCreateActivationParticipationMode: (state, action) => {
    state.create.activation.participationMode = action.payload;
  },
  changeBonusesCreateActivationParticipationLimit: (state, action) => {
    state.create.activation.participationLimit = action.payload;
  },
  changeBonusesCreateActivationActivitiesAfterBonus: (state, action) => {
    // state.create.activation.activitiesAfterBonus = action.payload;

    state.create.activation.activitiesAfterBonus[action.payload.index].data = {
      ...state.create.activation.activitiesAfterBonus[action.payload.index]
        .data,
      ...action.payload.data,
    };
  },
  changeActivationActivitiesType: (state, action) => {
    const { index, type } = action.payload;
    state.create.activation.activitiesAfterBonus[index].type = type;
  },
  addBonusesCreateActivationActivitiesAfterBonus: (state) => {
    state.create.activation.activitiesAfterBonus = [
      ...state.create.activation.activitiesAfterBonus,
      state.create.activation.activityCreation,
    ];
  },
  removeBonusesCreateActivationActivitiesAfterBonus: (state, action) => {
    state.create.activation.activitiesAfterBonus =
      // eslint-disable-next-line array-callback-return
      state.create.activation.activitiesAfterBonus.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },

  changeBonusesCreateActivitiesTypeCreation: (state, action) => {
    state.create.activation.activityCreation.type = action.payload;
    state.create.activation.activityCreation.data = {};
  },

  changeBonusesCreateActivitiesData: (state, action) => {
    state.create.activation.activityCreation.data = action.payload;
  },

  resetBonusesCreateActivitiesData: (state, action) => {
    state.create.activation.activityCreation.data = {};
  },
};

//  depositMinAmount: null,
//       depositSequence: null,
//       participationMode: 0,
//       participationLimit: null,
//       activationCode: null,
