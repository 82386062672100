import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Layout, Row, Col } from "antd";
import FreeGamesMoreDetails from "./freeGamesMoreDetails/FreeGamesMoreDetails";
import FreeGamesAddPlayers from "./freeGamesAddPlayers/FreeGamesAddPlayers";
import "./FreeGamesDetails.css";

const FreeGamesDetails = () => {
  const { goBack } = useHistory();

  const hanleGoBack = () => goBack();

  return (
    <Layout className="free-games-details">
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        title="Back to list"
      ></PageHeader>
      <Row className="free-games-details-row">
        <Col span={6} className="free-games-more-details-wrap">
          <FreeGamesMoreDetails className="free-games-more-details" />
        </Col>
        <Col span={18} className="free-games-add-players-wrap">
          <FreeGamesAddPlayers className="free-games-add-players" />
        </Col>
      </Row>
    </Layout>
  );
};

export default FreeGamesDetails;
