import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesReducer } from "./extra-reducers/getCategoriesReducer";

const initialState = {
  categoriesList: [],
  ui: {
    loadingCategoriesList: false,
  },
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoriesReducer.pending, (state) => {
      state.ui.loadingCategoriesList = true;
    });
    builder.addCase(getCategoriesReducer.fulfilled, (state, action) => {
      const array = [];
      action.payload.forEach((x, i) => array.push(x));
      state.categoriesList = array;
      state.ui.loadingCategoriesList = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = categoriesSlice.actions;

export default categoriesSlice.reducer;
