import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { statisticsFilterBySelector } from "../../../../redux/slices/statistics/statisticsSelector";
import { changeFilterBy } from "../../../../redux/slices/statistics/statisticsSlice";

const StatisticFilterBySelect = () => {
  const filterBy = useSelector(statisticsFilterBySelector);
  const dispatch = useDispatch();
  return (
    <Select
      value={filterBy}
      dropdownMatchSelectWidth={false}
      onChange={(x) => {
        dispatch(changeFilterBy(x));
      }}
      options={[
        {
          value: 1,
          label: "Total",
        },
        {
          value: 2,
          label: "Games",
        },
        {
          value: 3,
          label: "Game Providers",
        },
        {
          value: 4,
          label: "Players",
        },
        {
          value: 5,
          label: "Players / Games",
        },
      ]}
    />
  );
};

export default StatisticFilterBySelect;
