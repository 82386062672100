import React from "react";
import StakesDateRange from "./components/StakesDateRange";
import StakesExportButton from "./components/StakesExportButton";
import StakesFilterButton from "./components/StakesFilterButton";
import StakesGameNameSelect from "./components/StakesGameNameSelect";
import StakesIdTransaction from "./components/StakesIdTransaction";
import StakesProviderSelect from "./components/StakesProviderSelect";
import StakesPlayerId from "./components/StakesPlayerId";
import "./StakesForm.css";

const StakesForm = () => {
  return (
    <div className="stakes_form">
      <StakesPlayerId />
      <StakesDateRange />
      <StakesProviderSelect />
      <StakesGameNameSelect />
      {/* <StakesIdTransaction /> */}
      <StakesFilterButton />
      <StakesExportButton />
    </div>
  );
};

export default StakesForm;
