import React from "react";
import ListGamesRules from "./ListGamesRules";
import SelectGame from "./SelectGame";
import GamesPicker from "../../../../../../tournaments/tournaments-create-edit/tournaments-steps/steps-pages/rules/calculate-by-games/GamesPicker";

const CalculateByGames = () => {
  return (
    <div className="games_calculation_rules_container">
      <ListGamesRules />
      {/* <SelectGame /> */}
      <GamesPicker pageName="bonus" />
    </div>
  );
};

export default CalculateByGames;
