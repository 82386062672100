import React, { useEffect } from "react";
import ActivationCode from "./ActivationCode";
import DepositMinAmount from "./DepositMinAmount";
import DepositSequence from "./DepositSequence";
import ParticipationLimit from "./ParticipationLimit";
import ParticipationMode from "./ParticipationMode";
import ActivationLockBy from "./ActivationLockBy";
import { getBonusesDataReducers } from "../../../../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { bonusesTableDataSelector, callFromSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeCallFrom } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ExternalFields = () => {
  const dispatch = useDispatch()
  const bonuses = useSelector(bonusesTableDataSelector);
  const calledFrom = useSelector(callFromSelector)

  useEffect(() => {
    // if(bonuses[0] === null){
      if(calledFrom !== 'external' || bonuses[0] === null){
        dispatch(changeCallFrom('external'))
        dispatch(getBonusesDataReducers({callFrom: 'external'}))
      }
    // }
},[bonuses]);

  return (
    <>
      {/* <DepositMinAmount />
      <DepositSequence /> */}
      <ActivationCode />
      <ActivationLockBy />
      {/* <ParticipationMode />
      <ParticipationLimit /> */}
    </>
  );
};

export default ExternalFields;
