/* eslint-disable array-callback-return */
import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesProviderRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  editBonusesCreateRulesProviderRules,
  removeBonusesCreateRulesProviderRules,
} from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import {
  loadingProvidersListSelector,
  providersListSelector,
} from "../../../../../../../../redux/slices/providers/providersSelectors";

const SingleProviderRule = ({ id, percent, i }) => {
  const providersList = useSelector(providersListSelector);
  const loadingProvidersList = useSelector(loadingProvidersListSelector);
  const providerRules = useSelector(bonusesCreateRulesProviderRulesSelector);

  const dispatch = useDispatch();
  return (
    <div className="rules_single_provider">
      <Select
        value={id}
        dropdownMatchSelectWidth={false}
        loading={loadingProvidersList}
        onChange={(x) => {
          dispatch(editBonusesCreateRulesProviderRules({ i, id: x, percent }));
        }}
        style={{ width: "100%" }}
        placeholder="Select provider"
      >
        {providersList.map((item) => {
          let available = true;
          providerRules.forEach((x) => {
            if (x.id === item.id && x.id !== id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      <Input
        value={percent}
        suffix="%"
        type="number"
        onChange={(e) => {
          dispatch(
            editBonusesCreateRulesProviderRules({
              i,
              id,
              percent: e.target.value,
            })
          );
        }}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        size="middle"
        danger={true}
        onClick={() => {
          dispatch(removeBonusesCreateRulesProviderRules(i));
        }}
      />
    </div>
  );
};

export default SingleProviderRule;
