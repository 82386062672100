import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  changeActivationActivitiesType,
  removeBonusesCreateActivationActivitiesAfterBonus,
} from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import SingleActivity from "./SingleActivity";

const AllActivities = () => {
  const activities = useSelector(bonusesCreateActivationActivitiesAfterBonus);
  const dispatch = useDispatch();

  return (
    <>
      {activities.map((a, i) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "8px",
              marginTop: "24px",
              padding: "8px 0",
            }}
            key={i}
          >
            <Select
              value={a.type}
              options={[
                { label: "Reward", value: 0 },
                // { label: "Lock Amount", value: 1 },
                { label: "Free Games", value: 2 },
                // { label: "Popup", value: 3 },
                // { label: "Unlock Amount", value: 4 },
                // { label: "Activate Bonus", value: 5 },
              ]}
              onChange={(e) => {
                dispatch(changeActivationActivitiesType({ index: i, type: e }));
              }}
              style={{ maxWidth: "200px", width: "20%", height: "min-content" }}
            />
            <SingleActivity type={a.type} key={i} i={i} />
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="middle"
              danger={true}
              onClick={() => {
                dispatch(removeBonusesCreateActivationActivitiesAfterBonus(i));
              }}
            />
          </div>
        );
      })}
    </>
  );
};

export default AllActivities;
