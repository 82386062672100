import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateNameSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateName } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const Name = () => {
  const name = useSelector(bonusesCreateNameSelector);
  const dispatch = useDispatch();
  return (
    <Form.Item
      label="Name"
      name="name"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Input
        name={name}
        defaultValue={name}
        onChange={(e) => {
          dispatch(changeBonusesCreateName(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default Name;
