import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationDepositSequence } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationDepositSequence } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
const DepositSequence = () => {
  const dispatch = useDispatch();

  const value = useSelector(bonusesCreateActivationDepositSequence);

  return (
    <Form.Item label="Deposit Sequence" name="deposit_sequence">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(
            changeBonusesCreateActivationDepositSequence(e.target.value)
          );
        }}
      />
    </Form.Item>
  );
};

export default DepositSequence;
