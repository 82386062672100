import { createAsyncThunk } from "@reduxjs/toolkit";
import bonusesServices from "../../../../services/bonusesServices";

export const getParticipation = createAsyncThunk(
  "getParticipation",
  async ({ id, value, perPage }, thunkApi) => {
    try {
      const res = await bonusesServices.getParticipation(id, value, perPage);
      const res2 = await bonusesServices.isManualBonus(id);
      if (res.status === 200) {
        return {
          array: res.data.data,
          isManual: res2.data.status,
          total: res.data.total,
          to: res.data.to,
          per_page: res.data.per_page,
          current_page: res.data.current_page,
        };
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
