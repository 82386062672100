export const tournamentsFilterState = (state) => {
  return state.tournaments.filter.state;
};
export const tournamentsFilterDateFrom = (state) => {
  return state.tournaments.filter.dateFrom;
};
export const tournamentsFilterDateTo = (state) => {
  return state.tournaments.filter.dateTo;
};

export const tournamentsFilterSearch = (state) => {
  return state.tournaments.filter.search;
};

export const tournamentsTableData = (state) => {
  return state.tournaments.tableData;
};

export const tournamentsTablePageNumber = (state) => {
  return state.tournaments.pagination.pageNumber;
};
export const tournamentsTableTotalRows = (state) => {
  return state.tournaments.pagination.totalRows;
};
export const tournamentsTablePageSize = (state) => {
  return state.tournaments.pagination.pageSize;
};
////// Create
export const tournamentsCreateIsEdit = (state) => {
  return state.tournaments.create.isEdit;
};
export const tournamentsCreateStep = (state) => {
  return state.tournaments.create.step;
};
export const tournamentsAllDataEntered = (state) => {
  if (
    tournamentsGeneralAllDataEntered(state) &&
    tournamentsActivationAllDataEntered(state) &&
    tournamentRulesAllDataEntered(state) &&
    tournamentsAchievementAllDataEntered(state)
  ) {
    return true;
  } else {
    return false;
  }
};
export const tournamentsActivationAllDataEntered = (state) => {
  if (
    state.tournaments.create.activation.activationMode !== null &&
    state.tournaments.create.activation.activityNeeded !== null
  ) {
    return true;
  } else {
    return false;
  }
};
export const tournamentsGeneralAllDataEntered = (state) => {
  if (
    state.tournaments.create.general.name &&
    // state.tournaments.create.general.type !== null &&
    // state.tournaments.create.general.widgetWidth !== null &&
    state.tournaments.create.general.dateFrom &&
    state.tournaments.create.general.dateTo
    //  &&
    // state.tournaments.create.general.state !== null &&
    // state.tournaments.create.general.description &&
    // state.tournaments.create.general.publicTitle &&
    // state.tournaments.create.general.shortText &&
    // state.tournaments.create.general.longText
  ) {
    return true;
  } else {
    return false;
  }
};
export const tournamentRulesAllDataEntered = (state) => {
  if (
    state.tournaments.create.rules.valueForCalculation !== null &&
    state.tournaments.create.rules.valueFilterFrom !== null &&
    state.tournaments.create.rules.valueFilterTo !== null
  ) {
    return true;
  } else {
    return false;
  }
};
export const tournamentsAchievementAllDataEntered = (state) => {
  if (state.tournaments.create.achievement.hitValueCalculation !== null) {
    return true;
  } else {
    return false;
  }
};
////////////// General Create
export const tournamentsCreateName = (state) => {
  return state.tournaments.create.general.name;
};
export const tournamentsCreateIdentifier = (state) => {
  return state.tournaments.create.general.identifier;
};
export const tournamentsCreateType = (state) => {
  return state.tournaments.create.general.type;
};
export const tournamentsCreateWidgetWidth = (state) => {
  return state.tournaments.create.general.widgetWidth;
};
export const tournamentsCreateDateFrom = (state) => {
  return state.tournaments.create.general.dateFrom;
};
export const tournamentsCreateDateTo = (state) => {
  return state.tournaments.create.general.dateTo;
};
export const tournamentsCreateState = (state) => {
  return state.tournaments.create.general.state;
};
export const tournamentsCreateStatePretty = (state) => {
  return state.tournaments.create.general.state_pretty;
};
export const tournamentsCreateDescription = (state) => {
  return state.tournaments.create.general.description;
};
export const tournamentsCreatePublicTitle = (state) => {
  return state.tournaments.create.general.publicTitle;
};
export const tournamentsCreateShortText = (state) => {
  return state.tournaments.create.general.shortText;
};
export const tournamentsCreateLongText = (state) => {
  return state.tournaments.create.general.longText;
};
export const tournamentsCreateBannerImageUrl = (state) => {
  return state.tournaments.create.general.bannerImageUrl;
};
export const tournamentsCreateBackgroundImageUrl = (state) => {
  return state.tournaments.create.general.backgroundImageUrl;
};
/////// Tournament Rounds ////////
export const selectTournamentsCurrentPagination = (state) => {
  return state.tournaments.rounds.pagination;
};
export const selectIsOnTournamentCurrentPage = (state) => {
  return state.tournaments.rounds.isOnCurrentPage;
};
/////// Activation

export const tournamentsCreateActivationMode = (state) => {
  return state.tournaments.create.activation.activationMode;
};
export const tournamentsCreateActivityNeeded = (state) => {
  return state.tournaments.create.activation.activityNeeded;
};

//////// Rules

export const tournamentsValueForCalculation = (state) => {
  return state.tournaments.create.rules.valueForCalculation;
};
export const tournamentsValueFilterFrom = (state) => {
  return state.tournaments.create.rules.valueFilterFrom;
};
export const tournamentsValueFilterTo = (state) => {
  return state.tournaments.create.rules.valueFilterTo;
};
export const tournamentsCalculateBy = (state) => {
  return state.tournaments.create.rules.calculateBy;
};
export const tournamentsProviderRules = (state) => {
  return state.tournaments.create.rules.providerRules;
};
export const tournamentsGameRules = (state) => {
  return state.tournaments.create.rules.gameRules;
};
export const tournamentsGameGroupRules = (state) => {
  return state.tournaments.create.rules.gameGroupRules;
};

//Achievement

export const tournamentsCreateAchievementHitValueCalculation = (state) => {
  return state.tournaments.create.achievement.hitValueCalculation;
};
export const tournamentsCreateAchievementMinimalValue = (state) => {
  return state.tournaments.create.achievement.minimalValue;
};
export const tournamentsActivitiesAfter = (state) => {
  return state.tournaments.create.achievement.activitiesAfterBonus;
};

export const tournamentsCreateAchievementActivityType = (state) => {
  return state.tournaments.create.achievement.activityCreation.type;
};
export const tournamentsCreateAchievementActivityData = (state) => {
  return state.tournaments.create.achievement.activityCreation.data;
};

///
export const tournamentsCreateData = (state) => {
  return state.tournaments.create;
};

////// UI
export const tournamentsTableDataLoading = (state) => {
  return state.tournaments.ui.tableDataLoading;
};
export const tournamentsCreateSubmitting = (state) => {
  return state.tournaments.ui.submitting;
};
export const tournamentsIsTournamentCreated = (state) => {
  return state.tournaments.ui.isTournamentCreated;
};

export const failedToCreateTournamentSelector = (state) =>
  state.tournaments.ui.failedToCreateTournament;

export const failedToCreateTournamentMessageSelector = (state) =>
  state.tournaments.ui.failedToCreateTournamentMessage;

  export const pauseTournamentsSelector = state => state.tournaments.create.general.pause;