import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../layouts/components/Loader";
import {
  bonusesCreateIsEditSelector,
  bonusesIsDataLoading,
  bonusesTableDataSelector,
} from "../../../../redux/slices/bonuses/bonusesSelectors";
import {
  changeBonusesCreateIsEdit,
  resetBonusSlice,
} from "../../../../redux/slices/bonuses/bonusesSlice";
import { getSingleBonusDataReducers } from "../../../../redux/slices/bonuses/extra-reducers/getSingleBonusDataReducers";
import BonusesSteps from "./components/BonusesSteps";
import Header from "./components/Header";
import StepsPageRouter from "./components/steps-pages/StepsPageRouter";
import { getBonusesDataReducers } from "../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";

const BonusesCreateEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isDataLoading = useSelector(bonusesIsDataLoading);
  const isEdit = useSelector(bonusesCreateIsEditSelector);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      const regex = /\/(\d+)\/round\/(\d+)/;
      const matches = location.pathname.match(regex);

      // Extracted numbers
      const id = matches && matches[1];
      const idRound = matches && matches[2];
      dispatch(changeBonusesCreateIsEdit(true));
      dispatch(getSingleBonusDataReducers({ id, idRound }));
    } else {
      dispatch(changeBonusesCreateIsEdit(false));
    }
    return () => {
      dispatch(resetBonusSlice());
    };
  }, [dispatch, location.pathname]);


 

  return (
    <div>
      {isEdit && isDataLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Header />
          <BonusesSteps />
          <StepsPageRouter />
        </>
      )}
    </div>
  );
};

export default BonusesCreateEdit;
