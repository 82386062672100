import { Divider } from "antd";
import React, { useEffect } from "react";
import HitValueCalculation from "./HitValueCalculation";
import MinimalValue from "./MinimalValue";
import "./Achievement.css";
import ActivitiesAfterActivation from "./activities-after-achievement/ActivitiesAfterActivation";
import { useDispatch } from "react-redux";
import { getFreeGamesWithoutPaginationReducer } from "../../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesWithoutPagination";

const Achievement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFreeGamesWithoutPaginationReducer());
  }, [dispatch]);

  return (
    <div className="bonuses_achievement_container">
      <div className="bonuses_achievement_section">
        <Divider> Activation info</Divider>
        <div className="achievement_general_info">
          <HitValueCalculation />
          <MinimalValue />
        </div>
      </div>
      <div className="bonuses_achievement_section">
        <Divider>Activities after tournament achievement</Divider>
        <ActivitiesAfterActivation />
      </div>
    </div>
  );
};

export default Achievement;
