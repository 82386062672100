import React from "react";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  stakesDateFromSelector,
  stakesDateToSelector,
} from "../../../../../redux/slices/stakes/stakesSelector";
import {
  changeStakesDateFrom,
  changeStakesDateTo,
} from "../../../../../redux/slices/stakes/stakeSlice";
const { RangePicker } = DatePicker;

const StakesDateRange = () => {
  const dispatch = useDispatch();
  const dateFrom = useSelector(stakesDateFromSelector);
  const dateTo = useSelector(stakesDateToSelector);

  return (
    <RangePicker
      format="DD-MM-YYYY"
      onChange={(x) => {
        dispatch(
          changeStakesDateFrom(`${moment(x[0]).utc(true).toISOString()}`)
        );
        dispatch(changeStakesDateTo(`${moment(x[1]).utc(true).toISOString()}`));
      }}
      value={[moment(dateFrom), moment(dateTo)]}
      style={{ minWidth: "250px" }}
    />
  );
};

export default StakesDateRange;
