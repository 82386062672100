import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateActivityNeeded } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateActivityNeeded } from "../../../../../../../redux/slices/tournaments/tournamentsSlice";

const ActivityNeeded = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsCreateActivityNeeded);

  return (
    <Form.Item
      label="Activity needed for starting"
      name="activity_for_starting"
      initialValue={value}
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Please select"
        value={value}
        onChange={(e) => {
          dispatch(changeTournamentsCreateActivityNeeded(e));
        }}
        disabled
        options={[
          {
            value: 0,
            label: "Next entry to casino",
          },
          // {
          //   value: 1,
          //   label: "Registration",
          // },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default ActivityNeeded;
