import { Divider, Input } from "antd";
import { useDispatch } from "react-redux";
import Name from "./info/Name";
import Identifier from "./info/Identifier";
import Type from "./info/Type";
import WidgetWidth from "./info/WidgetWidth";
import DateRange from "./info/DateRange";
import State from "./info/State";
import Description from "./info/Description";
import PublicTitle from "./info/PublicTitle";
import ShortText from "./info/ShortText";
import LongText from "./info/LongText";
import BannerImageUrl from "./info/BannerImageUrl";
import BackgroundImageUrl from "./info/BackgroundImageUrl";
import BackgroundImagePreview from "./info/BackgroundImagePreview";
import BannerImagePreview from "./info/BannerImagePreview";
import BannerExample from "./info/BannerExample";
import TournamentPause from "./info/TournamentPause";

const General = () => {
  const dispatch = useDispatch();

  return (
    <div className="bonuses_general_container">
      <div className="bonuses_general_section">
        <Divider> General info</Divider>
        <div className="bonuses_general_info">
          <Name />
          {/*<Identifier /> */}
          <Type />
          {/* <WidgetWidth /> */}
          <DateRange />
          <TournamentPause />
          <State />
          <Description />
        </div>
      </div>
      <div className="bonuses_general_section">
        <Divider>Public information for players</Divider>
        <div className="bonuses_general_public">
          <PublicTitle />
          <ShortText />
          <LongText />
          <BannerImageUrl />
          <BannerImagePreview />
          {/* <BackgroundImageUrl /> */}
          {/* <BackgroundImagePreview /> */}
          <BannerExample />
        </div>
      </div>
    </div>
  );
};

export default General;
