import { Select } from "antd";
import React from "react";
import { primaryTimezones } from "../../../../helper/primaryTimezones";
import { useDispatch } from "react-redux";
import { changeStatisticsTimezone } from "../../../../redux/slices/statistics/statisticsSlice";
import { useSelector } from "react-redux";
import { statisticsTimezone } from "../../../../redux/slices/statistics/statisticsSelector";
import getDefaultTimezone from "../../../../helper/getDefaultTimezone";

const StatisticsTimeZone = () => {
  const dispatch = useDispatch();
  const timezone = useSelector(statisticsTimezone);

  return (
    <Select
      value={timezone}
      dropdownMatchSelectWidth={false}
      onChange={(x) => {
        dispatch(changeStatisticsTimezone(x));
      }}
      options={primaryTimezones}
    />
  );
};

export default StatisticsTimeZone;
