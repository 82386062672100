import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesGameGroupsRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { addBonusesCreateRulesGameGroupsRules } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import {
  gameGroupsListSelector,
  loadingGameGroupsListSelector,
} from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";

const SelectGameGroups = () => {
  const gameGroupsList = useSelector(gameGroupsListSelector);
  const gameGroupRules = useSelector(bonusesCreateRulesGameGroupsRulesSelector);
  const loadingGameGroups = useSelector(loadingGameGroupsListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gameGroupRules.length === 0) {
      dispatch(
        addBonusesCreateRulesGameGroupsRules({
          id: null,
          percent: 0,
        })
      );
    }
  }, []);

  return (
    <div className="calculate_by_providers_select">
      <Select
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loadingGameGroups}
        onChange={(x) => {
          dispatch(
            addBonusesCreateRulesGameGroupsRules({
              id: x,
              percent: 0,
            })
          );
        }}
        placeholder="Select Game Group"
        style={{ width: "100%" }}
      >
        {gameGroupsList.map((item) => {
          let available = true;
          gameGroupRules.forEach((x) => {
            if (x.ruleable) {
              if (x.ruleable.id === item.id) {
                available = false;
              }
            }

            if (!x.ruleable) {
              if (x.id === item.id) {
                available = false;
              }
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      {/* <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="middle"
        onClick={() => {
          if (selectedGameGroups) {
           
            setGameGroups(null);
          }
        }}
      /> */}
    </div>
  );
};

export default SelectGameGroups;
