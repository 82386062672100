import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getBonusesDataReducers } from "../../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";

const BonusesFilterButton = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(getBonusesDataReducers());
      }}
      type="primary"
      shape="round"
      icon={<FilterOutlined />}
      size={"middle"}
    >
      Filter
    </Button>
  );
};

export default BonusesFilterButton;
