import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  bonusesCreateActivationAllDataEntered,
  bonusesCreateSelector,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import RowReview from "./RowReview";
import { selectFreeGamesDataWithoutPagination } from "../../../../../../../redux/slices/free-games/freeGamesSelector";

const ActivationReview = () => {
  const createData = useSelector(bonusesCreateSelector);
  const allDataEntered = useSelector(bonusesCreateActivationAllDataEntered);

  const freeGames = useSelector(selectFreeGamesDataWithoutPagination);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        Activation
        <span style={{ marginLeft: "8px" }}>
          {allDataEntered === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview
        name="Activation Mode"
        data={
          createData.activation.activationMode === 0
            ? "Automatic"
            : createData.activation.activationMode === 1
            ? "Manual"
            : "External"
        }
        required={true}
      />
      <RowReview
        name="Activity needed for starting"
        data={
          createData.activation.activityNeeded === 0
            ? "Next entry to casino"
            : createData.activation.activityNeeded === 1
            ? "Select from offer"
            : "First entry to casino"
        }
        required={true}
      />
      {createData.activation.activationMode === 1 && (
        <RowReview
          name="Carry players to the next round"
          data={createData.activation.carryPlayers ? "Yes" : "No"}
          // required={true}
        />
      )}
      {/* {createData.activation.activationMode === 2 && (
        <RowReview
          name="Deposit Min Amount"
          data={createData.activation.depositMinAmount}
        />
      )}
      {createData.activation.activationMode === 2 && (
        <RowReview
          name="Deposit Sequence"
          data={createData.activation.depositSequence}
        />
      )} */}
      {createData.activation.activationMode === 2 && (
        <RowReview
          name="Activation code"
          data={createData.activation.activationCode}
        />
      )}
      {/* {createData.activation.activationMode === 2 && (
        <RowReview
          name="Participation Mode"
          data={
            createData.activation.participationMode === 0 ? "Single" : "Multi"
          }
        />
      )}
      {createData.activation.activationMode === 2 && (
        <RowReview
          name="Participation Limit"
          data={createData.activation.participationLimit}
        />
      )} */}

      <div style={{ marginLeft: "4px" }}>
        <span style={{ borderBottom: "2px solid #f0f0f0" }}>
          Activities after bonus activation :
        </span>
      </div>

      {createData.activation.activitiesAfterBonus.map((activity) => {
        if (activity.type === 0) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Reward : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity.data.account === 0 ? "Internet" : "Free Bet"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Fixed or min amount"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                {/* <RowReview
                  name="Percentage of hit value"
                  data={
                    activity.data.percentage === undefined
                      ? ""
                      : activity.data.percentage + "%"
                  }
                  style={{ marginBottom: "0px" }}
                /> */}
                {/* <RowReview
                  name="Max amount"
                  data={
                    activity.data.max === undefined ? "" : activity.data.max
                  }
                  style={{ marginBottom: "0px" }}
                /> */}
              </div>
            </div>
          );
        } else if (activity.type === 3) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Lock Amount : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity.data.account === 0 ? "Financial" : "Loyalty"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Fixed or min amount"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Percentage of hit value"
                  data={
                    activity.data.percentage === undefined
                      ? ""
                      : activity.data.percentage + "%"
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Max amount"
                  data={
                    activity.data.max === undefined ? "" : activity.data.max
                  }
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 0) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Popup : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Popup text"
                  data={activity.data.text}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Url of popup image"
                  data={activity.data.urlImage}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 2) {
          let name;
          freeGames.map((x) => {
            if (x.id === activity.data?.offer) {
              name = x.name;
            }
          });
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Free Games : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Offer"
                  data={name}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else return <></>;
      })}
    </div>
  );
};

export default ActivationReview;
