import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsDataReducers } from "../../../../redux/slices/statistics/extra-reducers/getStatisticsDataReducers";
import {
  statisticsFilterBySelector,
  statisticsOrderBy,
  statisticsSortDirection,
  statisticsTableDataSelector,
} from "../../../../redux/slices/statistics/statisticsSelector";
import { activeCasinoSelector } from "../../../../redux/slices/user/userSelectors";
import "./StatisticsTable.css";
import StatisticsTableGames from "./StatisticsTableGames";
import StatisticsTablePlayerGames from "./StatisticsTablePlayerGames";
import StatisticsTablePlayers from "./StatisticsTablePlayers";
import StatisticsTableProviders from "./StatisticsTableProviders";
import StatisticsTableTotal from "./StatisticsTableTotal";

const StatisticsTable = () => {
  const statisticsTableData = useSelector(statisticsTableDataSelector);
  const statisticsFilterBy = useSelector(statisticsFilterBySelector);
  const activeCasino = useSelector(activeCasinoSelector);
  const dispatch = useDispatch();
  const orderBy = useSelector(statisticsOrderBy);
  const sortDirection = useSelector(statisticsSortDirection);

  useEffect(() => {
    dispatch(getStatisticsDataReducers());
  }, [dispatch, activeCasino, statisticsFilterBy, orderBy, sortDirection]);

  return (
    <>
      {statisticsFilterBy === 1 ? (
        <StatisticsTableTotal dataSource={statisticsTableData} />
      ) : statisticsFilterBy === 2 ? (
        <StatisticsTableGames dataSource={statisticsTableData} />
      ) : statisticsFilterBy === 3 ? (
        <StatisticsTableProviders dataSource={statisticsTableData} />
      ) : statisticsFilterBy === 4 ? (
        <StatisticsTablePlayers dataSource={statisticsTableData} />
      ) : (
        <StatisticsTablePlayerGames dataSource={statisticsTableData} />
      )}
    </>
  );
};

export default StatisticsTable;
