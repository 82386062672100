import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Popconfirm, Tag, Tooltip } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFreeGamesReducer } from "../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";
import freeGamesServices from "../../../../../../services/freeGamesServices";
import axios from "axios";

export const freeGamesListColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    searchable: false,
    render: (text, record) => {
      return <div>{record.freegame_id}</div>;
    },
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    searchable: false,
    render: (text, record) => {
      return <div>{record.freegame_name}</div>;
    },
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
    render: (text, record) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {/* <Avatar
            src={record.provider_photo}
            alt={record.name + " icon"}
            style={{ width: "32px", height: "32px" }}
          /> */}
          <span>{record.provider_name}</span>
        </div>
      );
    },
  },
  {
    title: "Game",
    dataIndex: "game_name",
    key: "game_name",
    searchable: false,
    sorter: {
      compare: (a, b) => a.game_name - b.game_name,
    },
  },

  {
    title: "Players",
    dataIndex: "players",
    key: "players",
    searchable: false,
    sorter: {
      compare: (a, b) => a.players - b.players,
    },
  },

  // {
  //   title: "Total win",
  //   dataIndex: "total_win",
  //   key: "total_win",
  //   searchable: false,
  //   sorter: {
  //     compare: (a, b) => a.total_win - b.total_win,
  //   },
  // },

  {
    title: "From",
    dataIndex: "start",
    key: "start",
    searchable: false,
    render: (text, record) => {
      return (
        <div>
          {moment.utc(record.start).local().format("DD-MM-YYYY HH:mm:ss")}
        </div>
      );
    },
  },
  {
    title: "To",
    dataIndex: "end",
    key: "end",
    searchable: false,
    render: (text, record) => {
      return (
        <div>
          {moment.utc(record.end).local().format("DD-MM-YYYY HH:mm:ss")}
        </div>
      );
    },
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    searchable: false,
    render: (text, record) => {
      return (
        <div>
          {record.state === 0 ? (
            <Tag color={"blue"}>{"Created"}</Tag>
          ) : record.state === 1 ? (
            <Tag color={"yellow"}>{"Waiting"}</Tag>
          ) : record.state === 2 ? (
            <Tag color={"green"}>{"Active"}</Tag>
          ) : record.state === 3 ? (
            <Tag color={"orange"}>{"Finished"}</Tag>
          ) : record.state === 4 ? (
            <Tag color={"red"}>{"Evaluated"}</Tag>
          ) : (
            <></>
          )}
        </div>
      );
    },
  },

  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    searchable: false,
    render: (text, record) => {
      return (
        <div>
          {record.account === "INTERNET" ? (
            <Tag color={"green"}>{"Financial"}</Tag>
          ) : (
            <Tag color={"green"}>{"Loyalty"}</Tag>
          )}
        </div>
      );
    },
  },
  {
    title: "Actions",
    dataIndex: "account",
    key: "controls",
    searchable: false,
    render: (text, record) => {
      return <Controls record={record} />;
    },
  },
];

const Controls = ({ record }) => {
  const dispatch = useDispatch();

  const fetchCSV = async () => {
    try {
      const response = await axios.get(
        `/freespins/export/${record.freegame_id}`,
        {
          headers: {
            "Content-Type": "text/csv", // Ensure your API returns CSV data
          },
        }
      );

      const csvData = await response.data; // Get text data
      const filename = `freespins_${record.freegame_id}.csv`; //Add filename

      const blob = new Blob([csvData], { type: "text/csv" }); //Create blob
      const url = window.URL.createObjectURL(blob); // Create Url - blob:http://back-office.local.betsysbet.com/59795042-9013-4ff4-b234-f90a1ae5bb4 (demo)

      const a = document.createElement("a"); //Create anchor element so you can download trought href
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click(); //Simulate click on link
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a); //Remove elements after action
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ display: "flex", gap: "16px", justifyContent: "center" }}>
      {/* <Popconfirm
        icon={<CopyOutlined className="bonuses_table_copy_icon" />}
        title="Are you sure you want to copy this item?"
        okText="Copy"
        cancelText="Cancel"
        onConfirm={() => {
          freeGamesServices
            .getSingleFreeGame(record.freegame_id)
            .then((res) => {
              const {
                account_type,
                casino_id,
                code,
                description,
                end_at,
                games,
                name,
                provider_id,
                spins,
                start_at,
              } = res.data;
              const body = {
                casino_id,
                provider_id,
                name,
                code: code + " copy",
                description,
                period: [start_at, end_at],
                amount: spins,
                force: true,
                account: account_type,
                game: [
                  ...games.map((x) => {
                    return {
                      game_id: x.identifier,
                      bet: x.pivot.bet,
                      bet_type: x.pivot.bet_type,
                    };
                  }),
                ],
              };
              freeGamesServices.createFreeGame(body).then((res) => {
                dispatch(getFreeGamesReducer());
              });
            });
        }}
        okButtonProps={{ shape: "round" }}
        cancelButtonProps={{ shape: "round" }}
        showArrow={false}
      >
        <Tooltip title="Copy bonus">
          <Button
            type="primary"
            shape="circle"
            size="middle"
            icon={<CopyOutlined />}
          />
        </Tooltip>
      </Popconfirm> */}

      <Popconfirm
        icon={<DeleteOutlined className="bonuses_table_delete_icon" />}
        title="Are you sure you want to delete this item?"
        okText="Delete"
        cancelText="Cancel"
        onConfirm={() => {
          freeGamesServices.deleteFreeGame(record.freegame_id).then((res) => {
            dispatch(getFreeGamesReducer());
          });
        }}
        okButtonProps={{ danger: true, shape: "round" }}
        cancelButtonProps={{ shape: "round" }}
        showArrow={false}
      >
        <Tooltip title="Delete free game">
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            size="middle"
            danger={true}
          />
        </Tooltip>
      </Popconfirm>
      {/* <Tooltip title="Edit free game">
        <NavLink to={"/free-games/edit/" + record.freegame_id}>
          <Button
            type="default"
            shape="circle"
            size="middle"
            icon={<EditOutlined />}
          />
        </NavLink>
      </Tooltip> */}
      <Tooltip title="Detail page">
        <NavLink to={`/free-games/statistics/${record.freegame_id}`}>
          <Button
            type="default"
            shape="circle"
            size="middle"
            icon={<FileSearchOutlined />}
          />
        </NavLink>
      </Tooltip>

      <Tooltip title="Export">
        <Button
          onClick={fetchCSV}
          type="default"
          shape="circle"
          size="middle"
          icon={<DownloadOutlined />}
        />
      </Tooltip>
    </div>
  );
};
