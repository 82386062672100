import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeFreeGamesCreateSpins,
  changeFreeGamesFreeSpinsAvailable,
  changeFreeGamesTime,
  changeFreeGamesType,
  changeFreeGamesTypeDisabled,
} from "../../../../../redux/slices/free-games/freeGamesSlice";
import { useSelector } from "react-redux";
import {
  freeGamesCreateGameProviderSelector,
  freeGamesCreateTypeSelector,
  freeGamesFreeSpinsAvailableSelector,
  freeGamesTypeDisabledSelector,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import { providersListSelector } from "../../../../../redux/slices/providers/providersSelectors";

const FreeGamesType = () => {
  const dispatch = useDispatch();
  const typeDisabled = useSelector(freeGamesTypeDisabledSelector);
  const freeSpinsAvailable = useSelector(freeGamesFreeSpinsAvailableSelector);
  // const [typeDisabled, setTypeDisabled] = useState(0);
  const value = useSelector(freeGamesCreateTypeSelector);
  const providersList = useSelector(providersListSelector);
  const providerId = useSelector(freeGamesCreateGameProviderSelector);

  useEffect(() => {
    if (!providersList || !providerId) return;
    const prov = providersList.filter((provider) => provider.id === providerId);

    dispatch(changeFreeGamesTypeDisabled(prov[0].freespins_time));

    dispatch(changeFreeGamesFreeSpinsAvailable(prov[0].freespins));
    dispatch(changeFreeGamesType(0));
    dispatch(changeFreeGamesCreateSpins(0));
    dispatch(changeFreeGamesTime(0));
    // dispatch();
  }, [providersList, providerId]);

  return (
    <Form.Item label="Type:">
      <Select
        value={value}
        defaultValue={value}
        disabled={!freeSpinsAvailable}
        onChange={(e) => {
          dispatch(changeFreeGamesType(parseInt(e)));
        }}
        options={
          typeDisabled
            ? [
                { value: 0, label: "Spins" },
                { value: 1, label: "Time" },
              ]
            : [{ value: 0, label: "Spins" }]
        }
        type="number"
      />
    </Form.Item>
  );
};

export default FreeGamesType;
