import axios from "axios";

const defaultRoute = "statistics";

const getStatistics = (params) => {
  const result = axios(`/${defaultRoute}`, { params });

  return result;
};
const statisticsService = { getStatistics };

export default statisticsService;
