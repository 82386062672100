import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCalculateBy } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCalculateBy } from "../../../../../../../redux/slices/tournaments/tournamentsSlice";

const CalculateBy = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsCalculateBy);

  return (
    <Form.Item label="Calculate By" name="calculate_by" initialValue={value}>
      <Select
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeTournamentsCalculateBy(e));
        }}
        options={[
          {
            value: 0,
            label: "Providers",
          },
          {
            value: 1,
            label: "Games",
          },
          {
            value: 2,
            label: "Groups",
          },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default CalculateBy;
