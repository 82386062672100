import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsValueFilterTo } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsValueFilterTo } from "../../../../../../../redux/slices/tournaments/tournamentsSlice";

const ValueFilterTo = () => {
  const value = useSelector(tournamentsValueFilterTo);
  const dispatch = useDispatch();
  return (
    <Form.Item
      label="Value filter to"
      name="value_filter_to"
      style={{ width: "100%" }}
      rules={[{ required: true, message: "This field is required" }]}
    >
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        placeholder="To"
        onChange={(e) => {
          dispatch(changeTournamentsValueFilterTo(e));
        }}
      />
    </Form.Item>
  );
};

export default ValueFilterTo;
