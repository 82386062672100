import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getStakesDataReducers } from "./extra-reducers/getStakesDataReducers";

const initialState = {
  stakesSortBy: null,
  stakesSortDirection: null,
  pageNumber: 1,
  totalRows: 0,
  pageSize: 10,
  stakesPlayerId: null,
  stakesTransactionId: null,
  stakesProvider: null,
  stakesGames: 0,
  stakesDateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  stakesDateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  stakesTableData: [],
  isModalOpen: false,
  round: {},
  ui: {
    isDataLoading: false,
  },
};

export const stakesSlice = createSlice({
  name: "stakesSlice",
  initialState,
  reducers: {
    resetStakesSlice: (state) => initialState,
    changeStakesSortBy: (state, action) => {
      state.stakesSortBy = action.payload;
      // state.pageNumber = 1;
    },
    changeStakesSortDirection: (state, action) => {
      state.stakesSortDirection = action.payload;
      // state.pageNumber = 1;
    },
    changeStakesPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    changeStakesPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    changeStakesPlayerId: (state, action) => {
      state.stakesPlayerId = action.payload;
      state.pageNumber = 1;
    },
    changeStakesTransactionId: (state, action) => {
      state.stakesTransactionId = action.payload;
      state.pageNumber = 1;
    },
    changeStakesGames: (state, action) => {
      state.stakesGames = action.payload;
    },
    changeStakesDateFrom: (state, action) => {
      state.stakesDateFrom = action.payload.slice(0, 10) + " 00:00:00";
      state.pageNumber = 1;
    },
    changeStakesDateTo: (state, action) => {
      state.stakesDateTo = action.payload.slice(0, 10) + " 23:59:59";
      state.pageNumber = 1;
    },
    changeStakesProvider: (state, action) => {
      state.stakesProvider = action.payload;
      state.pageNumber = 1;
    },
    setStakesDetailRoundModal: (state, action) => {
      state.isModalOpen = action.payload;
    },
    changeStakeRound: (state, action) => {
      state.round = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStakesDataReducers.pending, (state) => {
      state.ui.isDataLoading = true;
    });
    builder.addCase(getStakesDataReducers.fulfilled, (state, action) => {
      state.ui.isDataLoading = false;
      state.stakesTableData = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(getStakesDataReducers.rejected, (state, action) => {
      state.ui.isDataLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetStakesSlice,
  changeStakesSortBy,
  changeStakesSortDirection,
  changeStakesPageNumber,
  changeStakesPlayerId,
  changeStakesTransactionId,
  changeStakesGames,
  changeStakesDateFrom,
  changeStakesDateTo,
  changeStakesProvider,
  setStakesDetailRoundModal,
  changeStakesPageSize,
  changeStakeRound,
} = stakesSlice.actions;

export default stakesSlice.reducer;
