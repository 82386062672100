import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersReducer } from "../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import {
  loadingProvidersListSelector,
  providersListSelector,
} from "../../../../redux/slices/providers/providersSelectors";
import { statisticsProviderSelector } from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsProvider,
  restartGamesByProvider,
} from "../../../../redux/slices/statistics/statisticsSlice";

const StatisticsProvidersSelect = () => {
  const providersList = useSelector(providersListSelector);
  const loadingProvidersList = useSelector(loadingProvidersListSelector);
  const statisticsProvider = useSelector(statisticsProviderSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  return (
    <Select
      value={statisticsProvider}
      dropdownMatchSelectWidth={false}
      loading={loadingProvidersList}
      onChange={(x) => {
        if (x === 0) {
          dispatch(restartGamesByProvider());
        }
        dispatch(changeStatisticsProvider(x));
      }}
    >
      <Select.Option key={0} value={0}>
        All Game Providers
      </Select.Option>
      {providersList.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            <Avatar
              src={item.photo}
              style={{ marginRight: "4px" }}
              size="small"
            />
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default StatisticsProvidersSelect;
