import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationActivationMode } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  changeBonusesCreateActivationMode,
  changeBonusesCreateActivityNeeded,
} from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const ActivationMode = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateActivationActivationMode);
  return (
    <Form.Item
      label="Activation mode"
      name="activation_mode"
      rules={[{ required: true, message: "This field is required" }]}
      initialValue={value}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Please select"
        value={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateActivityNeeded(0));
          dispatch(changeBonusesCreateActivationMode(e));
        }}
        options={[
          {
            value: 0,
            label: "Automatic",
          },
          {
            value: 1,
            label: "Manual",
          },
          {
            value: 2,
            label: "External",
          },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default ActivationMode;
