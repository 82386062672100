import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Avatar, Table, Divider } from "antd";
import { getSingleFreeGamesReducer } from "../../../../../redux/slices/free-games/extra-reducers/getSingleFreeGamesReducer";
import {
  selectFreeGamesSingleProps,
  freeGamesLoadingDataTable,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import { freeGamesMoreDetailsColumns } from "./tableColumns";
import Loader from "../../../../../layouts/components/Loader";
import "./FreeGamesMoreDetails.css";
const { Title } = Typography;

const FreeGamesMoreDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useSelector(freeGamesLoadingDataTable);
  const {
    freeGamesCreateBetType,
    freeGamesArray,
    freeGamesCreateName,
    freeGamesCreateCode,
    freeGamesCreateDateFrom,
    freeGamesCreateDateTo,
    freeGamesCreateSpins,
    freeGamesProviderName,
    freeGamesProviderImage,
  } = useSelector(selectFreeGamesSingleProps);

  useEffect(() => {
    dispatch(getSingleFreeGamesReducer(id));
  }, [dispatch, id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Title level={3} style={{ fontWeight: "500" }}>
          {freeGamesCreateName}
        </Title>
        <Divider style={{ marginTop: "10px" }} />
        <Title level={4} style={{ marginBottom: "0", fontWeight: "500" }}>
          Parameters
        </Title>
        <Divider style={{ marginTop: "10px" }} />
        <h4>
          <b>Code</b>: {freeGamesCreateCode}
        </h4>
        <h4>
          <b>Start - end: </b>
          {` ${freeGamesCreateDateFrom} - ${freeGamesCreateDateTo}`}
        </h4>
        <h4>
          <b>Game provider </b>:
          <span>
            <Avatar
              src={freeGamesProviderImage}
              style={{ margin: "0 6px" }}
              size="medium"
            />
            <span>{freeGamesProviderName}</span>
          </span>
        </h4>
        <h4>
          <b>
            {/* {freeGamesCreateBetType === 0 ? " */}
            {"Spins: "}
            {/* " : "Time: "} */}
          </b>
          {freeGamesCreateSpins}
        </h4>
      </div>
      <Table
        columns={freeGamesMoreDetailsColumns}
        dataSource={freeGamesArray}
        rowKey={(game) => game.games_id}
        pagination={false}
        className="free-games-details-table"
      />
    </>
  );
};

export default FreeGamesMoreDetails;
