/* eslint-disable array-callback-return */
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesGameCategoriesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  editBonusesCreateRulesGameCategoriesRules,
  removeBonusesCreateRulesGameCategoriesRules,
} from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

import {
  categoriesListSelector,
  loadingCategoriesListSelector,
} from "../../../../../../../../redux/slices/categories/categoriesSelectors";

const SingleCategory = ({ id, percent, i }) => {
  const list = useSelector(categoriesListSelector);
  const loading = useSelector(loadingCategoriesListSelector);
  const gameCategories = useSelector(bonusesCreateRulesGameCategoriesSelector);

  const dispatch = useDispatch();
  return (
    <div className="rules_single_game">
      <Select
        value={id}
        dropdownMatchSelectWidth={false}
        loading={loading}
        onChange={(x) => {
          dispatch(
            editBonusesCreateRulesGameCategoriesRules({ i, id: x, percent })
          );
        }}
        style={{ width: "100%" }}
        placeholder="Select Category"
      >
        {list.map((item) => {
          let available = true;
          gameCategories.forEach((x) => {
            if (x.id === item.id && x.id !== id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      <Input
        value={percent}
        suffix="%"
        type="number"
        onChange={(e) => {
          dispatch(
            editBonusesCreateRulesGameCategoriesRules({
              i,
              id,
              percent: e.target.value,
            })
          );
        }}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        size="middle"
        danger={true}
        onClick={() => {
          dispatch(removeBonusesCreateRulesGameCategoriesRules(i));
        }}
      />
    </div>
  );
};

export default SingleCategory;
