import axios from "axios";

const getGameGroups = async (params) => {
  const result = await axios.get("/groups", { params });
  return result;
};

const getSingleGameGroup = async (id) => {
  const result = await axios.get("/groups/" + id);
  return result;
};

const createGameGroup = async (body) => {
  const result = await axios.post("/groups", { ...body });
  return result;
};

const updateGameGroup = async (id, games, name) => {
  const result = await axios.post("/groups/update/" + id, {
    games,
    name,
  });
  return result;
};

const deleteGameGroup = async (id) => {
  const result = await axios.get("/groups/delete/" + id);
  return result;
};

export const gameGroupServices = {
  getGameGroups,
  createGameGroup,
  getSingleGameGroup,
  deleteGameGroup,
  updateGameGroup,
};
