export const achievementReducers = {
  changeBonusesAchievementHitValueCalculation: (state, action) => {
    state.create.achievement.hitValueCalculation = action.payload;
  },
  changeBonusesAchievementMinimalValue: (state, action) => {
    state.create.achievement.minimalValue = action.payload;
  },
  changeBonusesAchievementActivitiesAfterBonus: (state, action) => {
    state.create.achievement.activitiesAfterBonus[action.payload.index].data = {
      ...state.create.achievement.activitiesAfterBonus[action.payload.index]
        .data,
      ...action.payload.data,
    };
  },
  changeAchievementActivitiesType: (state, action) => {
    const { index, type } = action.payload;
    state.create.achievement.activitiesAfterBonus[index].type = type;
  },
  addBonusesCreateAchievementActivitiesAfterBonus: (state) => {
    state.create.achievement.activitiesAfterBonus = [
      ...state.create.achievement.activitiesAfterBonus,
      state.create.achievement.activityCreation,
    ];
  },
  removeBonusesCreateAchievementActivitiesAfterBonus: (state, action) => {
    state.create.achievement.activitiesAfterBonus =
      // eslint-disable-next-line array-callback-return
      state.create.achievement.activitiesAfterBonus.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },
  changeBonusesCreateAchievementActivitiesTypeCreation: (state, action) => {
    state.create.achievement.activityCreation.type = action.payload;
    state.create.achievement.activityCreation.data = {};
  },

  changeBonusesCreateAchievementActivitiesData: (state, action) => {
    state.create.achievement.activityCreation.data = action.payload;
  },

  resetBonusesCreateAchievementActivitiesData: (state, action) => {
    state.create.achievement.activityCreation.data = {};
  },
};
