import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateDescription } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateDescription } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import ReactQuill from "react-quill";
import "./Description.css";

import "react-quill/dist/quill.snow.css";

const Description = () => {
  const desc = useSelector(tournamentsCreateDescription);
  const dispatch = useDispatch();

  return (
    <>
      <Form.Item
        label="Description"
        name="description"
        // rules={[{ required: true, message: "This field is required" }]}
      >
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: [
              { header: [1, 2, 3, 4, 5, 6, false] },
              "bold",
              "italic",
              "underline",
              "strike",
              { list: "ordered" },
              { list: "bullet" },
              { align: [] },
              { 'color': [] }, { 'background': [] }
            ],
          }}
          className="quill"
          value={desc}
          onChange={(e) => {
            dispatch(changeTournamentsCreateDescription(e));
          }}
        />
        <div className="hidden_div"></div>
        {/* !!! DON'T TOUCH --> re-renderer */}
      </Form.Item>
    </>
  );
};

export default Description;
