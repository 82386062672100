import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateShortText } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateShortText } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const ShortText = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsCreateShortText);
  return (
    <Form.Item
      label="Short text for promotions page title"
      name="short_text_for_promotions"
      // rules={[{ required: true, message: "This field is required" }]}
    >
      <TextArea
        columns={4}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeTournamentsCreateShortText(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default ShortText;
