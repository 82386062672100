import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import RowReview from "./RowReview";
import {
  tournamentsActivationAllDataEntered,
  tournamentsCreateData,
} from "../../../../../../../redux/slices/tournaments/tournamentsSelector";

const ActivationReview = () => {
  const createData = useSelector(tournamentsCreateData);

  const activationAllData = useSelector(tournamentsActivationAllDataEntered);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        Activation
        <span style={{ marginLeft: "8px" }}>
          {activationAllData === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview
        name="Activation Mode"
        data={
          createData.activation.activationMode === 0
            ? "Automatic"
            : createData.activation.activationMode === 1
            ? "Manual"
            : createData.activation.activationMode === 2
            ? "External"
            : ""
        }
        required={true}
      />
      <RowReview
        name="Activity needed for starting"
        data={
          createData.activation.activityNeeded === 0
            ? "Next entry to casino"
            : createData.activation.activityNeeded === 1
            ? "Registration"
            : ""
        }
        required={true}
      />
    </div>
  );
};

export default ActivationReview;
