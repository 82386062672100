import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tournamentsCreateIsEdit,
  tournamentsCreateState,
  tournamentsCreateStatePretty,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateState } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const State = () => {
  const state = useSelector(tournamentsCreateState);
  const statePretty = useSelector(tournamentsCreateStatePretty);
  const dispatch = useDispatch();
  const isEdit = useSelector(tournamentsCreateIsEdit);
  const [initialState, setInitialState] = useState();

  useEffect(() => {
    setInitialState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let stateOptions = [];

  if (!isEdit) {
    stateOptions = [
      {
        value: 0,
        label: "Created",
      },
      // {
      //   value: 1,
      //   label: "Waiting",
      // },
      // {
      //   value: 2,
      //   label: "Active",
      // },
      // {
      //   value: 3,
      //   label: "Finished",
      // },
      // {
      //   value: 4,
      //   label: "Evaluated",
      // },
      // {
      //   value: 5,
      //   label: "Canceled",
      // },
    ];
  } else if (initialState === 1) {
    stateOptions = [
      {
        value: 0,
        label: "Created",
      },
      {
        value: 1,
        label: "Waiting",
      },
      {
        value: 5,
        label: 'Canceled'
      }
    ];
  } else if (initialState === 0) {
    stateOptions = [
      {
        value: 0,
        label: "Created",
      }, 
      {
        value: 1,
        label: "Waiting",
      },
    ];
  } else if (initialState === 2) {
    stateOptions = [
      {
        value: 2,
        label: "Active",
      },
      {
        value: 3,
        label: "Finished",
      },
      {
        value: 5,
        label: 'Canceled'
      }
    ];
  } else if (initialState === 3) {
    stateOptions = [
      {
        value: 3,
        label: "Finished",
      },
    ];
  }

  return (
    <Form.Item
      label="State"
      name="state"
      // rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select State"
        value={state}
        defaultValue={statePretty}
        options={stateOptions}
        onChange={(x) => {
          dispatch(changeTournamentsCreateState(x));
        }}
      ></Select>
    </Form.Item>
  );
};

export default State;
