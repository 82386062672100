import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFeaturesEdit } from "../../../../services/lobbyServices";
import { setFeatureEdit } from "../lobbySlice";
const _ = require("lodash");

export const getLobbyFeaturesEditReducers = createAsyncThunk(
  "getLobbyFeaturesEditReducers",
  async (id, thunkAPI) => {
    const casino_id = thunkAPI.getState().user.activeCasino;
    try {
      const res = await getFeaturesEdit(id, casino_id);

      if (res.status !== 200) throw new Error();
      const { title, order, size } = res.data.data;

      const getData = {
        title,
        order,
        size,
      };

      const deepCopyData = _.cloneDeep(getData);

      thunkAPI.dispatch(setFeatureEdit(deepCopyData));

      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
