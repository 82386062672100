import React from "react";
import { Form, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { range } from "../../../../../../../../utils/range";

import {
  tournamentsCreateDateFrom,
  tournamentsCreateDateTo,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  changeTournamentsCreateDateFrom,
  changeTournamentsCreateDateTo,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const { RangePicker } = DatePicker;

const DateRange = () => {
  const dispatch = useDispatch();
  const dateFrom = useSelector(tournamentsCreateDateFrom);
  const dateTo = useSelector(tournamentsCreateDateTo);
  const now = moment();

  const disabledStartDate = (startValue) => {
    // Disable dates before the current date and time
    if (startValue) {
      return startValue.isBefore(now, "day");
    }
    return false;
  };

  return (
    <Form.Item
      label="Period (from / to)"
      name="period"
      rules={[{ required: true, message: "This field is required" }]}
      initialValue={[dateFrom && moment(dateFrom), dateTo && moment(dateTo)]}
    >
      <RangePicker
        format="DD-MM-YYYY HH:mm"
        onChange={(x) => {
          if (!x) return;

         

          dispatch(
            changeTournamentsCreateDateFrom(
              `${moment.utc(x[0])
                .startOf('minute')
                .seconds(0)
                .toISOString()}`
            )
          );
          dispatch(
            changeTournamentsCreateDateTo(moment.utc(x[1])
            .startOf('minute')
            .seconds(0)
            .toISOString())
          );
        }}
        showTime={true}
        disabledDate={disabledStartDate}
        disabledTime={(current, type) => {
          // Disable times for the start date if it's the same day and earlier than the current time
          if (type === "start" && current && moment().isSame(current, "day")) {
            if (moment(current._d).hour() === moment(new Date()).hour()) {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                disabledMinutes: () => [...Array(moment().minute()).keys()],
                disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            } else {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                // disabledMinutes: () => [...Array(moment().minute()).keys()],
                disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            }
          } else if (
            type === "end" &&
            current &&
            moment().isSame(current, "day")
          ) {
            if (moment(current._d).hour() === moment(new Date()).hour()) {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                disabledMinutes: () => [...Array(moment().minute()).keys()],
                disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            } else {
              return {
                disabledHours: () => [...Array(moment().hour()).keys()],
                // disabledMinutes: () => [...Array(moment().minute()).keys()],
                disabledSeconds: () => [...Array(moment().second()).keys()],
              };
            }
          }
        }}
      />
    </Form.Item>
  );
};

export default DateRange;
