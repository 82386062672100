import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RowReview from "./RowReview";
import {
  tournamentsAchievementAllDataEntered,
  tournamentsCreateData,
} from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { getFreeGamesReducer } from "../../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";
import { freeGamesDataTableSelector } from "../../../../../../../redux/slices/free-games/freeGamesSelector";

const AchievementReview = () => {
  const dispatch = useDispatch();

  const createData = useSelector(tournamentsCreateData);

  const achivementlAllData = useSelector(tournamentsAchievementAllDataEntered);

  const freeSpins = useSelector(freeGamesDataTableSelector);

  useEffect(() => {
    dispatch(getFreeGamesReducer);
  }, [dispatch]);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        Achievement
        <span style={{ marginLeft: "8px" }}>
          {achivementlAllData === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>

      <RowReview
        name="Hit value calculation"
        data={
          createData.achievement.hitValueCalculation === 0 ? "Time" : "Value"
        }
        required={true}
      />
      <RowReview
        name="Minimal value"
        data={createData.achievement.minimalValue}
      />

      <div style={{ marginLeft: "4px" }}>
        <span style={{ borderBottom: "2px solid #f0f0f0" }}>
          Activities after tournament achievement :
        </span>
      </div>

      {createData.achievement.activitiesAfterBonus.map((activity) => {
        if (activity.type === 1) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Reward : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity.data.account === 0 ? "Internet" : "Free Bet"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Reward fixed"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Position from"
                  data={activity.data.positionFrom}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Position to"
                  data={activity.data.positionTo}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 2) {
          {
            /* TODO add name of bonus */
          }
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Free Games : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Offer"
                  data={activity.data.offer}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Position from"
                  data={activity.data.positionFrom}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Position to"
                  data={activity.data.positionTo}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else return <></>;
      })}
    </div>
  );
};

export default AchievementReview;
