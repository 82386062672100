import ListProviderRules from "./ListProviderRules";
import SelectProvider from "./SelectProvider";

const CalculateByProviders = () => {
  return (
    <div className="provider_calculation_rules_container">
      <ListProviderRules />
      <SelectProvider />
    </div>
  );
};

export default CalculateByProviders;
