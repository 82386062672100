import { createAsyncThunk } from "@reduxjs/toolkit";
import { gameGroupServices } from "../../../../services/gameGroupServices";
import { changeActiveGameGroup } from "../gameGroupSlice";
import { getGameGroupsReducer } from "./getGameGroupsReducer";

export const updateGameGroupsReducer = createAsyncThunk(
  "updateGameGroupsReducer",
  async (q, thunkApi) => {
    try {
      const active_casino = thunkApi.getState().user.activeCasino;
      const state = thunkApi.getState().gameGroup;
      const id = state.gameGroupsList[state.activeGameGroup].id;
      const games = [];
      state.gameGroupEditingGames.forEach((x) => {
        games.push(x.id);
      });
      const name = state.gameGroupEditingName;
      const res = await gameGroupServices.updateGameGroup(
        id,
        games,
        name,
        active_casino
      );
      thunkApi.dispatch(getGameGroupsReducer());
      thunkApi.dispatch(changeActiveGameGroup(null));
      if (res.status === 200) {
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
