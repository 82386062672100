import { createAsyncThunk } from "@reduxjs/toolkit";
import bonusesServices from "../../../../services/bonusesServices";

export const getSingleBonusDataReducers = createAsyncThunk(
  "getSingleBonusDataReducers",
  async ({ id, idRound }, thunkApi) => {
    try {
      const res = await bonusesServices.getSingleBonus(id, idRound);

      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
