import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import Tooltip from "antd/es/tooltip";
import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { tournamentsServices } from "../../../../../services/tournaments";
import { getTournamentsReducers } from "../../../../../redux/slices/tournaments/extra-reducers/getTournamentsReducers";
import { useSelector } from "react-redux";
import {
  tournamentsTableData,
  tournamentsTablePageNumber,
} from "../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsPageNumber } from "../../../../../redux/slices/tournaments/tournamentsSlice";

const ActionsColumn = ({ record }) => {
  const dispatch = useDispatch();

  const tournamentData = useSelector(tournamentsTableData);
  const tournamentPageNum = useSelector(tournamentsTablePageNumber);

  return (
    <>
      <Space size="middle">
        <Tooltip title="View tournament rounds">
          <NavLink to={"/tournaments/" + record.id + "/rounds"}>
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EyeOutlined />}
            />
          </NavLink>
        </Tooltip>
        <Popconfirm
          autoAdjustOverflow={false}
          icon={<CopyOutlined className="bonuses_table_copy_icon" />}
          title="Are you sure you want to copy this item?"
          okText="Copy"
          cancelText="Cancel"
          onConfirm={() => {
            tournamentsServices.copyTournament(record.id).then((res) => {
              dispatch(getTournamentsReducers());
            });
          }}
          okButtonProps={{ shape: "round" }}
          cancelButtonProps={{ shape: "round" }}
          showArrow={false}
        >
          <Tooltip title="Copy Tournament">
            <Button
              type="primary"
              shape="circle"
              size="middle"
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Popconfirm>

        <Tooltip title="Delete">
          <Popconfirm
            icon={<DeleteOutlined className="bonuses_table_delete_icon" />}
            title="Are you sure you want to delete this item?"
            okText="Delete"
            cancelText="Cancel"
            onConfirm={() => {
              if (tournamentData.length === 1 && tournamentPageNum > 1) {
                dispatch(changeTournamentsPageNumber(tournamentPageNum - 1));
              }
              tournamentsServices.deleteTournament(record.id).then((res) => {
                dispatch(getTournamentsReducers());
              });
            }}
            okButtonProps={{ danger: true, shape: "round" }}
            cancelButtonProps={{ shape: "round" }}
            showArrow={false}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size="middle"
              danger={true}
            />
          </Popconfirm>
        </Tooltip>
      </Space>
    </>
  );
};

export default ActionsColumn;
