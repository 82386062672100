import { Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ActivationMode from "./ActivationMode";
import ActivitiesAfterActivation from "./activities-after-bonus/ActivitiesAfterActivation";
import ActivityNeeded from "./ActivityNeeded";
import "./Activation.css";
import ExternalFields from "./external-fields/ExternalFields";
import {
  bonusesCreateActivationActivationMode,
  bonusesCreateIsEditSelector,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import Players from "./external-fields/Players";
import CarryPlayers from "./CarryPlayers";

const Activation = () => {
  const activationMode = useSelector(bonusesCreateActivationActivationMode);
  const isEdit = useSelector(bonusesCreateIsEditSelector);

  return (
    <div className="bonuses_activation_container">
      <div className="bonuses_activation_section">
        <Divider> Activation info</Divider>
        <div className="activation_general_info">
          <ActivationMode />
          <ActivityNeeded />
          {activationMode === 1 && <CarryPlayers />}
          {activationMode === 1 && !isEdit && <Players />}
          {activationMode === 2 && <ExternalFields />}
        </div>
      </div>
      <div className="bonuses_activation_section">
        <Divider>Activities after bonus activation</Divider>
        <ActivitiesAfterActivation />
      </div>
    </div>
  );
};

export default Activation;
