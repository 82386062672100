import { Progress, Tag } from "antd";
import moment from "moment";
import { tableColumnHandler } from "../../../../utils/tableColumnHandler";

const StateTableCol = (_, record) => {
  switch (record.state) {
    case -2:
      return <Tag color="red">DNF</Tag>;
    case 0:
      return <Tag color="yellow">Created</Tag>;
    case 1:
      return <Tag color="blue">Active</Tag>;
    case 2:
      return <Tag color="green">Finished</Tag>;
    case 3:
      return <Tag color="blue">Evaluated</Tag>;
    default:
      return null;
  }
};

const ProgressTableCol = (text) => (
  <div style={{ padding: "8px 16px", minWidth: "200px" }}>
    <Progress percent={parseFloat(text).toFixed(2)} />
  </div>
);

export const bonusesParticipationColumns = [
  tableColumnHandler("Player ID", "player_id"),
  {
    ...tableColumnHandler("State", "state", { searchable: false }),
    render: (_, record) => StateTableCol(_, record),
  },

  {
    ...tableColumnHandler("Progress", "progress"),
    render: (text) => ProgressTableCol(text),
  },
  {
    title: "Current Value",
    dataIndex: "current_value",
    key: "current_value",
    render: (text, record) => {
      return <div>{parseFloat(text).toFixed(2)}</div>;
    },
  },

  {
    ...tableColumnHandler("Started", "started_at"),
    render: (text) => <>{moment(text).format("DD-MM-YYYY HH:mm:ss")}</>,
  },
  // {
  //   ...tableColumnHandler("Updated", "updated_at"),
  //   render: (text) =>
  //     text ? <>{moment(text).format("DD-MM-YYYY HH:mm:ss")}</> : null,
  // },
  {
    ...tableColumnHandler("Evaluated at", "evaluated_at"),
    render: (text, record) => {
      if (record.evaluated_at.length === 0) return;
      return (
        record.state === 3 && <>{moment(text).format("DD-MM-YYYY HH:mm:ss")}</>
      );
    },
  },
];
