import axios from "axios";

const defaultRoute = "rounds";

const getStakes = (params) => {
  const result = axios(`/${defaultRoute}`, { params });
  return result;
};
const stakesServices = { getStakes };

export default stakesServices;
