import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateType } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateType } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const Type = () => {
  const state = useSelector(tournamentsCreateType);
  const dispatch = useDispatch();

  return (
    <Form.Item
      label="Recurring"
      name="Recurring"
      // rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select Type"
        value={state}
        defaultValue={state}
        options={[
          {
            value: 0,
            label: "No",
          },
          {
            value: 1,
            label: "Yes",
          },
        ]}
        onChange={(x) => {
          dispatch(changeTournamentsCreateType(x));
        }}
      ></Select>
    </Form.Item>
  );
};

export default Type;
