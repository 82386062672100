import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesValueFilterToSelector } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateRulesValueTo } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const ValueFilterTo = () => {
  const value = useSelector(bonusesCreateRulesValueFilterToSelector);
  const dispatch = useDispatch();
  return (
    <Form.Item
      label="Value filter to"
      name="value_filter_to"
      style={{ width: "100%" }}
    >
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        placeholder="To"
        onChange={(e) => {
          dispatch(changeBonusesCreateRulesValueTo(e));
        }}
      />
    </Form.Item>
  );
};

export default ValueFilterTo;
