import React from "react";

const RowReview = ({ name, data, required, style }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "4px",
        padding: "0 4px",
        fontSize: "15px",
        fontWeight: "400",
        marginBottom: "4px",
        ...style,
      }}
    >
      <p style={{ marginBottom: "0px" }}>
        {name}
        {/* {required && <span style={{ color: "red", marginLeft: "2px" }}>*</span>} */}
        <span style={{ marginLeft: "4px", marginBottom: "0" }}>:</span>
      </p>
      <p
        style={{
          fontWeight: "600",
          marginBottom: "0",
        }}
      >
        {data ? data : ""}
        {required && !data && data !== 0 && (
          <span
            style={{
              color: "red",
              marginLeft: "2px",
              fontSize: "14px",
            }}
          >
            required field
          </span>
        )}
      </p>
    </div>
  );
};

export default RowReview;
