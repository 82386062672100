import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Form, InputNumber, Select, Typography } from "antd";
import {
  freeGamesCreateBetSelector,
  freeGamesCreateFreeGamesArray,
  freeGamesCreateGameProviderSelector,
  freeGamesCreateGameSelector,
  freeGamesMultipleGames,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesCreateBet } from "../../../../../redux/slices/free-games/freeGamesSlice";
import { gamesListSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { currencySelector } from "../../../../../redux/slices/user/userSelectors";

const FreeGamesBet = () => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [bet, setBet] = useState(undefined);

  const [totalBet, setTotalBet] = useState(undefined);

  const [gameData, setGameData] = useState(undefined);
  const [betOptions, setBetOptions] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [formattedOptions, setFormattedOptions] = useState(undefined);

  const games = useSelector(gamesListSelector);
  const provider = useSelector(freeGamesCreateGameProviderSelector);
  const gamesArray = useSelector(freeGamesCreateFreeGamesArray);
  const casinoCurrency = useSelector(currencySelector);

  const selectedFreeGame = useSelector(freeGamesCreateGameSelector);
  const multipleFreeGames = useSelector(freeGamesMultipleGames);

  const handleBetChange = (value) => {
    setBet(value);
    getTotalBet(value);
    dispatch(changeFreeGamesCreateBet(value));
  };

  const getTotalBet = (bet) => {
    if (!betOptions) return;
    if (!bet) return;

    if (betOptions.bet_type === "line_bet") {
      if(!betOptions.max_bet_ways){
        setTotalBet(bet * betOptions.min_bet_ways);
      }
      if(betOptions.max_bet_ways){
        setTotalBet(bet * betOptions.max_bet_ways);

      }
    }

    if (betOptions.bet_type === "total_bet") {
      setTotalBet(bet);
    }

    if (betOptions.bet_type === "bet") {
      setFormattedOptions(undefined);
      setTotalBet(`Game can't have free games...`);
    }
  };

  useEffect(() => {
    setGameData(
      games && games.filter((game) => game.id === selectedFreeGame)[0]
    );
  }, [games, selectedFreeGame]);

  useEffect(() => {
    setBetOptions(
      gameData &&
        gameData.bets.filter((bet) => bet.currency === casinoCurrency)[0]
    );
  }, [gameData, casinoCurrency]);

  useEffect(() => {
    if (betOptions && betOptions.bet_type === "line_bet") {
      setOptions(betOptions && JSON.parse(betOptions.unit_bets));
    }
    if (betOptions && betOptions.bet_type === "total_bet") {
      setOptions(betOptions && JSON.parse(betOptions.total_bets));
    }
  }, [betOptions]);

  useEffect(() => {
    setFormattedOptions(
      options &&
        options.map((option) => {
          return { value: option, label: `${option}` };
        })
    );
  }, [options, provider]);

  useEffect(() => {
    setBet(undefined);
    setTotalBet(undefined);
  }, [provider, gameData]);

  useEffect(() => {
    setFormattedOptions(undefined);
  }, [provider]);


  return (
    <Form.Item label="Bet">
      {/* <InputNumber
        value={bet}
        step={0.01}
        precision={2}
        onChange={handleBetChange}
      /> */}
      <Select
        value={bet && bet}
        onChange={handleBetChange}
        options={formattedOptions && formattedOptions}
        disabled={
          !formattedOptions ||
          (multipleFreeGames === 0 && gamesArray.length > 0)
            ? true
            : false
        }
        placeholder="- Select bet -"
      />
      <Divider type="horizontal" />

      <Text>
        Total bet: {totalBet !== undefined ? `${totalBet} ${casinoCurrency}` : "..."}
      </Text>
    </Form.Item>
  );
};

export default FreeGamesBet;
