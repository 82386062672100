import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCateogryEditProps } from "../../../../services/lobbyServices";
import { setCategory } from "../lobbySlice";
const _ = require("lodash");

export const getCategoryEditReducer = createAsyncThunk(
  "getCategoryEditReducer",
  async (id, thunkAPI) => {
    const { lobby } = thunkAPI.getState();
    const casino_id = thunkAPI.getState().user.activeCasino;
    try {
      const res = await getCateogryEditProps(id, casino_id);

      if (res.status !== 200 && !lobby) throw new Error();

      const {
        lobby_id,
        enabled,
        page_id,
        title,
        icon_id,
        starts_at,
        ends_at,
        visibility,
        mobile,
      } = res.data.data;

      const getData = {
        lobby_id,
        enabled,
        page_id,
        title,
        icon_id,
        starts_at,
        ends_at,
        visibility,
        mobile,
      };

      const deepCopyData = _.cloneDeep(getData);

      thunkAPI.dispatch(setCategory(deepCopyData));
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
