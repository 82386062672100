import { Divider } from "antd";
import "./General.css";
import Name from "./info/Name";
import DataRange from "./info/DataRange";
import State from "./info/State";
import Description from "./info/Description";
import ShortText from "./public-info/ShortText";
import LongText from "./public-info/LongText";
import PublicTitle from "./public-info/PublicTitle";
import BannerImageUrl from "./public-info/BannerImageUrl";
import BannerImagePreview from "./public-info/BannerImagePreview";
import InfoUrl from "./public-info/InfoUrl";
import ProgressBarStyle from "./public-info/ProgressBarStyle";
import ButtonText from "./public-info/ButtonText";
import ButtonUrl from "./public-info/ButtonUrl";
import Type from "./info/Type";
import Pause from "./info/Pause";

const General = () => {
  return (
    <div className="bonuses_general_container">
      <div className="bonuses_general_section">
        <Divider> General info</Divider>
        <div className="bonuses_general_info">
          <Name />
          <Type />
          <DataRange />
          <Pause />
          {/* <AchievementPeriod /> */}
          <State />
          <Description />
        </div>
      </div>
      <div className="bonuses_general_section">
        <Divider>Public information for players</Divider>
        <div className="bonuses_general_public">
          <PublicTitle />
          <ShortText />
          <LongText />
          <BannerImageUrl />
          <BannerImagePreview />
          <InfoUrl />
          <ButtonText />
          <ButtonUrl />
          <ProgressBarStyle />
        </div>
      </div>
    </div>
  );
};

export default General;
