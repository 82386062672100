import React from "react";
import StatisticFilterBySelect from "./StatisticFilterBySelect";
import StatisticCategories from "./StatisticCategories";
import StatisticsDateRange from "./StatisticsDateRange";
import StatisticsProvidersSelect from "./StatisticsProvidersSelect";
import StatisticGamesSelect from "./StatisticGamesSelect";
import StatisticsExportButton from "./StatisticsExportButton";
import StatisticsFilterButton from "./StatisticsFilterButton";
import StatisticsTimeZone from "./StatisticsTimeZone";

const StatisticsForm = () => {
  return (
    <div className="statistics_form">
      <StatisticFilterBySelect />
      <StatisticsDateRange />
      <StatisticsTimeZone />
      <StatisticsProvidersSelect />
      <StatisticGamesSelect />
      <StatisticCategories />
      <StatisticsFilterButton />
      <StatisticsExportButton />
    </div>
  );
};

export default StatisticsForm;
