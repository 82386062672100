import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoriesServices } from "../../../../services/categoriesServices";

export const getCategoriesReducer = createAsyncThunk(
  "getCategoriesReducer",
  async (q, thunkApi) => {
    try {
      const res = await categoriesServices.getCategories();
      if (res.status === 200) {
        return res.data.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
