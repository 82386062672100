import React from "react";
import "antd/dist/antd.min.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./views/pages/login/Login";
import {
  setRequestInterceptor,
  setResponseInterceptor,
} from "./views/pages/login/Interceptor";
import MainLayout from "./layouts/Main";
import "./App.css";
import LayoutCustom from "./layouts/layout-custom/LayoutCustom";

function App() {
  if (localStorage.getItem("token")) {
    setRequestInterceptor();
    setResponseInterceptor();
  }

  return (
    <BrowserRouter>
      <LayoutCustom>
        <Switch>
          <Route exact path="/login" component={Login} /> <MainLayout />
          <Redirect from="*" exact to="/" />
        </Switch>
      </LayoutCustom>
    </BrowserRouter>
  );
}

export default App;
