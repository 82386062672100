import React, { useEffect, useId } from "react";
import { Button, PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import BonusesTable from "./bonuses-table/BonusesTable";
import BonusesForm from "./bonuses-form/BonusesForm";
import { useDispatch } from "react-redux";
import { resetBonusSlice } from "../../../../redux/slices/bonuses/bonusesSlice";
import "./Bonuses.css";

function Bonuses() {
  const dispatch = useDispatch();
  const id = useId();
  const { goBack, push } = useHistory();

  useEffect(() => {
    return () => {
      dispatch(resetBonusSlice());
    };
  }, [dispatch]);

  const hanleGoBack = () => goBack();
  const handleNavigation = () => push("/bonuses/create");

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        title="Bonuses"
        extra={
          <Button key={id} type="primary" onClick={handleNavigation}>
            <span>Create new</span>
          </Button>
        }
      ></PageHeader>

      <div className="bonuses_container">
        <div style={{ minWidth: "fit-content" }}>
          <BonusesForm />
          <BonusesTable />
        </div>
      </div>
    </div>
  );
}

export default Bonuses;
