import { useEffect, useRef, useState } from "react";
import "./GamesPicker.css";
import { useDispatch } from "react-redux";
import { getGamesReducer } from "../../../../../../../../redux/slices/games/extra-reducers/getGamesReducer";
import { useSelector } from "react-redux";
import { tournamentsGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { addTournamentsCreateRulesGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { providersListSelector } from "../../../../../../../../redux/slices/providers/providersSelectors";
import { categoriesListSelector } from "../../../../../../../../redux/slices/categories/categoriesSelectors";
import { getCategoriesReducer } from "../../../../../../../../redux/slices/categories/extra-reducers/getCategoriesReducer";
import Search from "antd/lib/input/Search";
import { FilterOutlined } from "@ant-design/icons";
import { gamesColumns, selectedGamesColumns } from "./tableFormatting";
import {
  gamesListSelector,
  selectFilteredLobbyGamesPage,
  selectFilteredLobbyGamesPerPage,
  selectFilteredLobbyTotalRows,
  selectedGamesKeysTournamentSelector,
  selectedGamesTournamentSelector,
  totalGamesSelector,
} from "../../../../../../../../redux/slices/games/gamesSelectors";
import {
  addJackpotGameRulesGames,
  changeLobbyGamesPageNumber,
  changeSelectedGamesAllAdded,
  changeSelectedGamesKeysTournament,
  changeSelectedGamesTournament,
} from "../../../../../../../../redux/slices/games/gamesSlice";
import { addBonusesCreateRulesGameRules } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import { addGameInGameGroup } from "../../../../../../../../redux/slices/game-groups/gameGroupSlice";

const GamesPicker = ({ pageName }) => {
  const dispatch = useDispatch();
  const games = useSelector(gamesListSelector);
  const providers = useSelector(providersListSelector);
  const gameCategories = useSelector(categoriesListSelector);
  const selectedGames = useSelector(selectedGamesTournamentSelector);
  const totalGames = useSelector(totalGamesSelector);

  const page = useSelector(selectFilteredLobbyGamesPage);
  const perPage = useSelector(selectFilteredLobbyGamesPerPage);

  const [provider, setProvider] = useState(undefined);
  const [category, setCategory] = useState(undefined);
  const [gameName, setGameName] = useState(undefined);

  useEffect(() => {
    filterGames();
  }, [provider, category]);

  useEffect(() => {
    if (gameName && gameName.length > 2) filterGames();
    if (!gameName) filterGames();
  }, [gameName]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setSelectedRowKeys(selectedGames.map((game) => game.id));
  }, [selectedGames]);

  useEffect(() => {
    dispatch(getGamesReducer({all: false, from: pageName, payload: { per_page: 100 } }));
    dispatch(getCategoriesReducer());
  }, [dispatch]);

  const onSelectHandler = (e) => {
    if (selectedGames.some((game) => game.id === e.id)) {
      dispatch(
        changeSelectedGamesTournament(
          selectedGames.filter((game) => game.id !== e.id)
        )
      );
    } else {
      dispatch(changeSelectedGamesTournament([...selectedGames, e]));
    }
  };

  const onSelectAllHandler = (_, b, c) => {
    if (!c.every((game) => selectedGames.some((x) => x.id === game.id))) {
      dispatch(changeSelectedGamesAllAdded(c));
    } else {
      const arr = selectedGames.filter((game) => {
        return !c.some((x) => x.id === game.id);
      });
      dispatch(changeSelectedGamesTournament(arr));
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: onSelectHandler,
    onSelectAll: onSelectAllHandler,
  };

  // useEffect(() => {
  //   if (gameRules.length === 0) {
  //     dispatch(
  //       addTournamentsCreateRulesGameRules({
  //         id: null,
  //         percent: 100,
  //       })
  //     );
  //   }
  // }, []);

  const getOptions = (list, initialLabel) => {
    const formattedOptions = list.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }));
    return [...formattedOptions];
  };

  const filterGames = () => {
    dispatch(changeLobbyGamesPageNumber(1));
    dispatch(
      getGamesReducer({
        all: false,
        from: pageName,
        payload: {
          type: 'game-groups',
          provider_id: provider,
          per_page: 100,
          category_id: category,
          name: gameName,
          page: undefined,
        },
      })
    );
  };

  const handlePaginationPage = (page) => {
    dispatch(changeLobbyGamesPageNumber(page));
    dispatch(
      getGamesReducer({
        all: false,
        from: pageName,
        payload: {
          provider_id: provider,
          per_page: 100,
          category_id: category,
          name: gameName,
          page: page,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(changeSelectedGamesTournament([]));
    setCategory(undefined);
    setGameName(undefined);
    setProvider(undefined);
  }, []);

  const exitModal = () => {
    dispatch(changeSelectedGamesTournament([]));
    setCategory(undefined);
    setGameName(undefined);
    setProvider(undefined);
    setIsModalVisible(false);
  };

  const AddGamesToRules = () => {
    if (pageName === "tournament") {
      selectedGames.forEach((game) =>
        dispatch(
          addTournamentsCreateRulesGameRules({
            id: game.id,
            name: game.name,
            percent: 100,
          })
        )
      );
    }

    if (pageName === "bonus") {
      selectedGames.forEach((game) =>
        dispatch(
          addBonusesCreateRulesGameRules({
            id: game.id,
            name: game.name,
            percent: 100,
          })
        )
      );
    }

    if (pageName === "jackpot") {
      selectedGames.forEach((game) => {
        const newGamesObject = {
          ...game,
          percentage: 100,
          hidden_percentage: 100,
        };
        dispatch(addJackpotGameRulesGames(newGamesObject));
      });
    }

    if (pageName === "game-groups") {
      selectedGames.forEach((game) => {
        dispatch(addGameInGameGroup(game));
      });
    }

    dispatch(changeSelectedGamesTournament([]));
    setCategory(undefined);
    setGameName(undefined);
    setProvider(undefined);
    setIsModalVisible(false);
  };


  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        + Add games
      </Button>

      <Modal
        title={"Game picker"}
        okText="Save"
        open={isModalVisible}
        onCancel={exitModal}
        onOk={AddGamesToRules}
        className="game_picker_modal"
      >
        <Form
          layout="horizontal"
          className="game_picker_form"
          onFinish={filterGames}
        >
          <Form.Item>
            <Select
              style={{ width: 200 }}
              placeholder="All game providers"
              options={getOptions(providers)}
              allowClear
              onChange={(e) => {
                setProvider(e);
              }}
            ></Select>
          </Form.Item>

          <Form.Item>
            <Select
              style={{ width: 200 }}
              placeholder="All game category"
              options={getOptions(gameCategories)}
              allowClear
              onChange={(e) => {
                setCategory(e);
              }}
            ></Select>
          </Form.Item>

          <Form.Item>
            <Input
              style={{ width: 250 }}
              placeholder="Search game"
              allowClear
              onChange={(e) => {
                setGameName(e.target.value);
              }}
            ></Input>
          </Form.Item>

          {/* <Form.Item>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={filterGames}
            >
              Filter
            </Button>
          </Form.Item> */}
        </Form>

        <Row className="table_layout">
          <Table
            rowSelection={rowSelection}
            className="game_table"
            columns={gamesColumns}
            dataSource={games}
            scroll={{ y: "calc(61vh - 60px)" }}
            rowKey={(record) => record.id}
            pagination={{
              total: totalGames,
              defaultCurrent: 1,
              current: page,
              defaultPageSize: perPage,
              showSizeChanger: false,
              onChange: (page) => handlePaginationPage(page),
              totalBoundaryShowSizeChanger: true,
              style: {
                marginRight: 20,
              },
            }}
          ></Table>
          <Table
            dataSource={selectedGames}
            rowKey={(record) => {
              return record.id;
            }}
            className="selected_game_table"
            columns={selectedGamesColumns}
            scroll={{ y: "calc(61vh - 50px)" }}
            pagination={false}
          ></Table>
        </Row>
      </Modal>
    </>
  );
};

export default GamesPicker;
