import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";

import { FilterOutlined } from "@ant-design/icons";
import {
  lobbyCurrentGamesColumns,
  lobbyGamesColumns,
} from "../../lobbies-management/constants/tableColumns";
import { useSelector } from "react-redux";
import { providersListSelector } from "../../../../redux/slices/providers/providersSelectors";
import { useDispatch } from "react-redux";
import { getCategoriesReducer } from "../../../../redux/slices/categories/extra-reducers/getCategoriesReducer";
import {
  categoriesListSelector,
  loadingCategoriesListSelector,
} from "../../../../redux/slices/categories/categoriesSelectors";

const GamePicker = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentGamesList, setCurrentGamesList] = useState([]);
  const [selectedGamesList, setSelectedGamesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(1);

  const gameCategories = useSelector(categoriesListSelector);
  const loadingCategories = useSelector(loadingCategoriesListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesReducer());
  }, []);

  const providers = useSelector(providersListSelector);

  const handleCancel = () => {
    setIsModalVisible(false);
    // dispatch(discardGamePickerGames());
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getOptions = (list, initialLabel) => {
    const formattedOptions = list.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    return [...formattedOptions];
  };

  return (
    <>
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        style={{ background: "#1890FF", color: "white" }}
      >
        + Add Games
      </Button>
      {isModalVisible && (
        <Modal
          title="Game picker"
          open={isModalVisible}
          //   onOk={handleOk}
          onCancel={handleCancel}
          //   afterClose={resetModal}
          destroyOnClose={true}
          centered
          okText="Save"
          width={1600}
          bodyStyle={{ height: "calc(100vh - 150px)" }}
          zIndex={1001}
          className="lobby-add-games"
        >
          <Form
            // onValuesChange={handleOnChange}
            labelCol={{ span: 4 }}
            layout="horizontal"
            className="lobby-add-games-filters"
          >
            <Form.Item name="provider" className="lobby-add-games-item">
              <Select
                placeholder="All game providers"
                showSearch
                filterOption={filterOption}
                options={getOptions(providers)}
                allowClear
              />
            </Form.Item>
            <Form.Item name="category" className="lobby-add-games-item">
              <Select
                placeholder="All game categories"
                showSearch
                // filterOption={filterOption}
                options={getOptions(gameCategories)}
                allowClear
              />
            </Form.Item>
            <Tooltip
              placement="top"
              title="Required minimum 3 characters for results"
              arrow
            >
              <Form.Item name="search" className="lobby-add-games-item">
                <Input placeholder="Search game" allowClear />
              </Form.Item>
            </Tooltip>
            <Button
              onClick={() => {
                // dispatch(getFilteredLobbyGames(1));
                // dispatch(changeLobbyGamesPageNumber(1));
                // setFlag(false);
              }}
              type="primary"
              icon={<FilterOutlined />}
              size={"middle"}
              style={{ marginLeft: "20px", alignSelf: "flex-start" }}
            >
              Filter
            </Button>
            <div className="lobby-add-games-tags">
              <Tag color="default">Preview selected games</Tag>
              <Tag
                color="default"
                style={{ marginRight: "0" }}
              >{`Current number: ${currentGamesList.length}`}</Tag>
            </div>
          </Form>
          <Row>
            <Col span={15}>
              <Table
                columns={lobbyGamesColumns}
                // dataSource={games}
                loading={loading}
                rowKey={(record) => record.id}
                rowSelection={{
                  type: "checkbox",
                  preserveSelectedRowKeys: true,
                }}
                className="lobby-add-games-table"
                scroll={{ y: "calc(61vh - 53px)" }}
                pagination={{
                  //   total: totalRows,
                  defaultCurrent: 1,
                  //   current: page,
                  //   defaultPageSize: perPage,
                  showSizeChanger: false,
                  //   onChange: (page) => handlePaginationPage(page),
                  totalBoundaryShowSizeChanger: true,
                  style: {
                    marginRight: 20,
                  },
                }}
              />
            </Col>
            <Col span={9}>
              <Table
                columns={lobbyCurrentGamesColumns}
                dataSource={currentGamesList}
                rowKey={(record) => record.id}
                pagination={false}
                className="lobby-add-games-current-table"
                scroll={{ y: "calc(61vh - 53px)" }}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default GamePicker;
