export const bonusesTableDataSelector = (state) => {
  return state.bonuses.bonusesTableData;
};

export const searchBonusParticipantsSelector = (state) => {
  return state.bonuses.searchBonusParticipants;
};

export const bonusesRoundsData = (state) => {
  return state.bonuses.bonusesRounds;
};
export const bonusParticipationSelector = (state) => {
  return state.bonuses.bonusParticipation;
};
export const isBonusManualSelector = (state) => {
  return state.bonuses.isBonusManual;
};
export const bonusesIsDataLoading = (state) => {
  return state.bonuses.ui.isDataLoading;
};
export const bonusesIsRoundsDataLoading = (state) => {
  return state.bonuses.ui.isRoundsDataLoading;
};
export const bonusesIsSubmitting = (state) => {
  return state.bonuses.ui.submitting;
};
export const bonusCreatedSelector = (state) => {
  return state.bonuses.ui.bonusCreated;
};
export const bonusesStateSelector = (state) => {
  return state.bonuses.state;
};

export const bonusesDateFromSelector = (state) => {
  return state.bonuses.dateFrom;
};
export const bonusesDateToSelector = (state) => {
  return state.bonuses.dateTo;
};
export const bonusesSearchSelector = (state) => {
  return state.bonuses.search;
};
export const bonusesSortBySelector = (state) => {
  return state.bonuses.bonusesSortBy;
};
export const bonusesSortDirectionSelector = (state) => {
  return state.bonuses.bonusesSortDirection;
};
export const bonusesPageNumberSelector = (state) => {
  return state.bonuses.pageNumber;
};
export const bonusesTotalRowsSelector = (state) => {
  return state.bonuses.totalRows;
};
export const bonusesPageSizeSelector = (state) => {
  return state.bonuses.pageSize;
};

export const bonusesCreateSelector = (state) => {
  return state.bonuses.create;
};
export const bonusesCreateIsEditSelector = (state) => {
  return state.bonuses.create.isEdit;
};
export const bonusesCreateStepSelector = (state) => {
  return state.bonuses.create.step;
};

export const bonusesCreateNameSelector = (state) => {
  return state.bonuses.create.general.name;
};
export const bonusesCreateTypeSelector = (state) => {
  return state.bonuses.create.general.type;
};
export const bonusesCreateDateFromSelector = (state) => {
  return state.bonuses.create.general.dateFrom;
};
export const bonusesCreateDateToSelector = (state) => {
  return state.bonuses.create.general.dateTo;
};
export const bonusesCreateAchievementPeriodType = (state) => {
  return state.bonuses.create.general.achievementPeriodType;
};
export const bonusesCreateAchievementPeriodTime = (state) => {
  return state.bonuses.create.general.achievementPeriodTime;
};
export const bonusesCreateStateSelector = (state) => {
  return state.bonuses.create.general.state;
};
export const bonusesCreateDescriptionSelector = (state) => {
  return state.bonuses.create.general.description;
};
export const bonusesCreatePublicTitleSelector = (state) => {
  return state.bonuses.create.general.publicTitle;
};
export const bonusesCreateShortTextSelector = (state) => {
  return state.bonuses.create.general.shortText;
};
export const bonusesCreateLongTextSelector = (state) => {
  return state.bonuses.create.general.longText;
};
export const bonusesCreateBannerImageUrlSelector = (state) => {
  return state.bonuses.create.general.bannerUrl;
};
export const bonusesCreateGeneralAllDataEntered = (state) => {
  if (
    state.bonuses.create.general.name &&
    state.bonuses.create.general.dateFrom &&
    state.bonuses.create.general.dateTo
  )
    return true;
  else return false;
};

export const bonusesCreateGeneralInfoUrlSelector = (state) => {
  return state.bonuses.create.general.infoUrl;
};
export const bonusesCreateGeneralButtonTextSelector = (state) => {
  return state.bonuses.create.general.buttonText;
};
export const bonusesCreateGeneralButtonUrlSelector = (state) => {
  return state.bonuses.create.general.buttonUrl;
};
export const bonusesCreateGeneralProgressBarSelector = (state) => {
  return state.bonuses.create.general.progressBarStyle;
};

//
export const bonusesCreateActivationAllDataEntered = (state) => {
  if (
    state.bonuses.create.activation.activationMode !== null &&
    state.bonuses.create.activation.activityNeeded !== null
  ) {
    return true;
  } else {
    return false;
  }
};
export const bonusesCreateActivationActivationMode = (state) => {
  return state.bonuses.create.activation.activationMode;
};
export const bonusesCreateActivationAutoRepeat = (state) => {
  return state.bonuses.create.activation.autoRepeat;
};
export const bonusesCreateActivationActivityNeeded = (state) => {
  return state.bonuses.create.activation.activityNeeded;
};
export const bonusesCreateActivationPlayers = (state) => {
  return state.bonuses.create.activation.players;
};
export const bonusesCreateActivationActivationCode = (state) => {
  return state.bonuses.create.activation.activationCode;
};
export const bonusesCreateActivationActivitiesAfterBonus = (state) => {
  return state.bonuses.create.activation.activitiesAfterBonus;
};
export const bonusesCreateActivationActivitiesType = (state) => {
  return state.bonuses.create.activation.activityCreation.type;
};
export const bonusesCreateActivationActivitiesAfterData = (state) => {
  return state.bonuses.create.activation.activityCreation.data;
};
export const bonusesCreateActivationDepositMinAmount = (state) => {
  return state.bonuses.create.activation.depositMinAmount;
};
export const bonusesCreateActivationDepositSequence = (state) => {
  return state.bonuses.create.activation.depositSequence;
};
export const bonusesCreateActivationParticipationMode = (state) => {
  return state.bonuses.create.activation.participationMode;
};
export const bonusesCreateActivationParticipationLimit = (state) => {
  return state.bonuses.create.activation.participationLimit;
};
//

export const bonusesCreateRulesAllDataEnteredSelector = (state) => {
  if (state.bonuses.create.rules.valueForCalculation !== null) {
    return true;
  } else {
    return false;
  }
};
export const bonusesCreateRulesValueForCalculationSelector = (state) => {
  return state.bonuses.create.rules.valueForCalculation;
};
export const bonusesCreateRulesValueFilterFromSelector = (state) => {
  return state.bonuses.create.rules.valueFilterFrom;
};
export const bonusesCreateRulesValueFilterToSelector = (state) => {
  return state.bonuses.create.rules.valueFilterTo;
};
export const bonusesCreateRulesProviderRulesSelector = (state) => {
  return state.bonuses.create.rules.providerRules;
};
export const bonusesCreateRulesGameRulesSelector = (state) => {
  return state.bonuses.create.rules.gameRules;
};
export const bonusesCreateRulesGameGroupsRulesSelector = (state) => {
  return state.bonuses.create.rules.gameGroupRules;
};
export const bonusesCreateRulesGameCategoriesSelector = (state) => {
  return state.bonuses.create.rules.gameCategories;
};
export const bonusesCreateRulesCalculateBySelector = (state) => {
  return state.bonuses.create.rules.calculateBy;
};

//

export const bonusesCreateAchievementAllDataEntered = (state) => {
  if (state.bonuses.create.achievement.hitValueCalculation !== null) {
    return true;
  } else {
    return false;
  }
};
export const bonusesCreateAchievementHitValueCalculation = (state) => {
  return state.bonuses.create.achievement.hitValueCalculation;
};
export const bonusesCreateAchievementMinimalValue = (state) => {
  return state.bonuses.create.achievement.minimalValue;
};
export const bonusesCreateAchievementActivitiesAfterBonus = (state) => {
  return state.bonuses.create.achievement.activitiesAfterBonus;
};
export const bonusesCreateAchievementActivityType = (state) => {
  return state.bonuses.create.achievement.activityCreation.type;
};
export const bonusesCreateAchievementActivityData = (state) => {
  return state.bonuses.create.achievement.activityCreation.data;
};

export const bonusesCreateActivationCarryPlayers = (state) =>
  state.bonuses.create.activation.carryPlayers;

export const unlockBonusOffer = (state) =>
  state.bonuses.create.achievement.activitiesAfterBonus;

// Pagination for bonus participans

export const totalPagesParticipants = (state) =>
  state.bonuses.totalPagesParticipants;

export const toParticipants = (state) => state.bonuses.toParticipants;

export const perPageParticipants = (state) => state.bonuses.perPageParticipants;

export const currentPageParticipants = (state) =>
  state.bonuses.currentPageParticipants;

  export const pauseSelector = state => state.bonuses.create.general.pause;

  export const activationLockedBySelector = state => state.bonuses.create.activation.activationLockedBy;

  export const callFromSelector = state => state.bonuses.callFrom;