import { createAsyncThunk } from "@reduxjs/toolkit";
import { tournamentsServices } from "../../../../services/tournaments";
import moment from "moment";

export const createEditTournament = createAsyncThunk(
  "createEditTournament",
  async ({ id, round_id }, thunkApi) => {
    const state = thunkApi.getState().tournaments.create;

    const casino_id = thunkApi.getState().user.activeCasino;

    const body = {
      casinos: [casino_id],
      lobby: thunkApi.getState().user.lobby,

      name: state.general.name,
      identifier: state.general.identifier,
      type: state.general.type,
      pause: state.general.pause,
      widget_width: state.general.widgetWidth,
      starts_at: moment.utc(state.general.dateFrom)
      .startOf('minute')
      .seconds(0)
      .toISOString(),
      ends_at: 
      moment.utc(state.general.dateTo)
          .startOf('minute')
          .seconds(0)
          .toISOString(),
      period: [
        moment.utc(state.general.dateFrom)
          .startOf('minute')
          .seconds(0)
          .toISOString()
        ,
        moment.utc(state.general.dateTo)
          .startOf('minute')
          .seconds(0)
          .toISOString()
      ],
      state: state.general.state.toString(),
      description: state.general.description,
      public_title: state.general.publicTitle,
      short_text_for_promotions: state.general.shortText,
      long_text_for_promotions: state.general.longText,
      banner_image_url: state.general.bannerImageUrl,
      background_url: state.general.backgroundImageUrl,
      activation_mode: state.activation.activationMode.toString(),
      activity_for_starting: state.activation.activityNeeded.toString(),
      value_for_calculation: state.rules.valueForCalculation.toString(),
      value_filter_from: state.rules.valueFilterFrom,
      value_filter_to: state.rules.valueFilterTo,
      hit_value_calculation_type:
        state.achievement.hitValueCalculation.toString(),
      minimal_value: state.achievement.minimalValue,
    };

    //Rules
    if (state.rules.calculateBy === 0) {
      body.provider_factors = [];
      state.rules.providerRules.forEach((rule) => {
        if (rule.id === null) return;
        body.provider_factors.push({
          instance_id: rule.id,
          percentage: rule.percent,
        });
      });
    }

    if (state.rules.calculateBy === 1) {
      body.game_factors = [];
      state.rules.gameRules.forEach((rule) => {
        if (rule.id === null) return;

        body.game_factors.push({
          instance_id: rule.id,
          percentage: rule.percent,
        });
      });
    }

    if (state.rules.calculateBy === 2) {
      body.game_category_factors = [];
      state.rules.gameGroupRules.forEach((rule) => {
        if (rule.id === null) return;

        body.game_category_factors.push({
          instance_id: rule.id,
          percentage: rule.percent,
        });
      });
    }

    //Achievement

    if (state.achievement.activitiesAfterBonus.length > 0) {
      body.activities_after_achievement = [];
      state.achievement.activitiesAfterBonus.forEach((tournament, i) => {
        if (tournament.type === 1) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = tournament.type;
          obj.account =
            tournament.data?.account >= 0
              ? tournament.data?.account
              : ""
              ? tournament.data?.account.toString()
              : "";
          obj.reward_fixed = parseFloat(
            tournament.data?.rewardFixed ? tournament.data?.rewardFixed : 0
          );
          obj.position_from = parseFloat(
            tournament.data?.positionFrom ? tournament.data?.positionFrom : 0
          );
          obj.position_to = parseFloat(
            tournament.data?.positionTo ? tournament.data?.positionTo : 0
          );
          body.activities_after_achievement.push(obj);
        }

        if (tournament.type === 2) {
          const obj = {};
          obj.ordinal_number = i;
          obj.activity_type = tournament.type;

          obj.freespins_id = +tournament.data.freespins_id;
          obj.position_from = parseFloat(
            tournament.data?.positionFrom ? tournament.data?.positionFrom : 0
          );
          obj.position_to = parseFloat(
            tournament.data?.positionTo ? tournament.data?.positionTo : 0
          );

          body.activities_after_achievement.push(obj);
        }
      });
    }

    if (!state.isEdit) {
      try {
        const res = await tournamentsServices.createTournament(body);

        if (res.status === 200 && state) {
          return res.data.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue(err.response.data.errors.name[0]);
      }
    } else {
      body.round_id = round_id;
      try {
        const res = await tournamentsServices.editTournament(body, id);

        if (res.status === 200 && state) {
          return res.data.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue();
      }
    }
  }
);
