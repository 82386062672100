import { Menu } from "antd";
import React from "react";
import axios from "axios";
import { removeRequestInterceptor } from "../../views/pages/login/Interceptor";

export const handleLogout = () => {
  axios.get("/logout").then((response) => {
    removeRequestInterceptor();
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("lobby");
    window.location.reload();
  });
};

const Index = () => {
  return (
    <Menu>
      <Menu.Item style={{ minWidth: 100 }} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default Index;
