import Loading from "../layouts/components/Loading";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import React from "react";
import ROUTES from "./routes";

const Router = () => (
  <React.Suspense fallback={<Loading />}>
    <Switch>
      {ROUTES.map((item, index) => (
        <PrivateRoute
          exact
          path={item.route}
          component={item.component}
          key={index}
        />
      ))}

      <Redirect from="*" to="/404" />
    </Switch>
  </React.Suspense>
);

export default Router;
