import { Avatar } from "antd";

export const statisticColumnsGames = [
  {
    title: "Game Provider",
    dataIndex: "provider_name",
    key: "provider_name",
    searchable: false,
    alignItems: "center",

    render: (text, game) => {
      const fontWeight = text === "Total" || text === "Total " ? "900" : "400";
      const fontSize = text === "Total" || text === "Total " ? 16 : 14;
      return (
        <div>
          {game.provider_name !== "Total" &&
            game.provider_name !== "Total " &&
            game.provider_name !== "Jackpots" &&
            game.provider_name !== "Tournaments" &&
            game.provider_name !== "Bonuses" && (
              <Avatar
                shape="circle"
                src={game.provider_photo}
                style={{ marginRight: "4px" }}
                size="default"
              />
            )}
          <p style={{ fontWeight, fontSize }}>{text}</p>
        </div>
      );
    },
    width: "160px",
  },
  {
    title: "Game",
    dataIndex: "game_name",
    key: "game_name",
    searchable: false,

    render: (text, game) => {
      return (
        <div>
          {game.provider_name !== "Total" &&
            game.provider_name !== "Total " &&
            game.provider_name !== "Freespins" &&
            game.provider_name !== "Jackpots" &&
            game.provider_name !== "Tournaments" &&
            game.provider_name !== "Bonuses" && (
              <Avatar
                shape="circle"
                src={game.game_photo}
                style={{ marginRight: "4px" }}
                size="default"
              />
            )}
          {game.game_name}{" "}
        </div>
      );
    },
    width: "260px",
  },
  {
    title: "Bet",
    dataIndex: "bet",
    key: "bet",
    searchable: false,

    width: "160px",
  },
  {
    title: "Win",
    dataIndex: "win",
    key: "win",
    searchable: false,
  },
  {
    title: "Net",
    dataIndex: "turnover",
    key: "turnover",
    searchable: false,
  },
  {
    title: "RTP %",
    dataIndex: "rtp",
    key: "rtp",
    searchable: false,
  },
  {
    title: "Tickets",
    dataIndex: "tickets",
    key: "tickets",
    searchable: false,
  },
  {
    title: "Players",
    dataIndex: "players",
    key: "players",
    searchable: false,
  },
  {
    title: "Ø Bet",
    dataIndex: "avg_bet",
    key: "avg_bet",
    searchable: false,
  },
  {
    title: "Ø Ticket/Player",
    dataIndex: "tickets_player",
    key: "tickets_player",
    searchable: false,
  },
];

export const statisticColumnsTotal = [
  {
    title: "Bet",
    dataIndex: "bet",
    key: "bet",
    searchable: false,

    render: (text, game) => {
      const fontWeight = text === "Total" || text === "Total " ? "900" : "400";
      const fontSize = text === "Total" || text === "Total " ? 16 : 14;
      return <p style={{ fontWeight, fontSize }}>{text}</p>;
    },
    width: "220px",
  },
  {
    title: "Win",
    dataIndex: "win",
    key: "win",
    searchable: false,
  },

  {
    title: "Net",
    dataIndex: "turnover",
    key: "turnover",
    searchable: false,
  },
  {
    title: "RTP %",
    dataIndex: "rtp",
    key: "rtp",
    searchable: false,
  },
  {
    title: "Tickets",
    dataIndex: "tickets",
    key: "tickets",
    searchable: false,
  },
  {
    title: "Players",
    dataIndex: "players",
    key: "players",
    searchable: false,
  },
  {
    title: "Ø  Bet",
    dataIndex: "avg_bet",
    key: "avg_bet",
    searchable: false,
  },
  {
    title: "Ø Ticket/Player",
    dataIndex: "tickets_player",
    key: "tickets_player",
    searchable: false,
  },
];

export const statisticColumnsProviders = [
  {
    title: "Provider",
    dataIndex: "provider_name",
    key: "provider_name",
    searchable: false,

    render: (text, game) => {
      const fontWeight = text === "Total" || text === "Total " ? "900" : "400";
      const fontSize = text === "Total" || text === "Total " ? 16 : 14;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {text !== "Bonuses" &&
            text !== "Total " &&
            text !== "Total" &&
            text !== "Freespins" &&
            text !== "Jackpots" && (
              <Avatar
                shape="circle"
                src={game.provider_photo}
                style={{ marginRIght: "4px" }}
                size="default"
              />
            )}
          <div style={{ fontWeight, fontSize }}>{text}</div>
        </div>
      );
    },
    width: "220px",
  },
  {
    title: "Bet",
    dataIndex: "bet",
    key: "bet",
    searchable: false,

    width: "180px",
  },

  {
    title: "Win",
    dataIndex: "win",
    key: "win",
    searchable: false,
  },
  {
    title: "Net",
    dataIndex: "turnover",
    key: "turnover",
    searchable: false,
  },

  {
    title: "Players",
    dataIndex: "players",
    key: "players",
    searchable: false,
  },

  {
    title: "RTP %",
    dataIndex: "rtp",
    key: "rtp",
    searchable: false,
  },
  {
    title: "Tickets",
    dataIndex: "tickets",
    key: "tickets",
    searchable: false,
  },

  {
    title: "Ø Bet",
    dataIndex: "avg_bet",
    key: "avg_bet",
    searchable: false,
  },
  {
    title: "Ø Ticket/Player",
    dataIndex: "tickets_player",
    key: "tickets_player",
    searchable: false,
  },
];

export const statisticColumnsPlayers = [
  {
    title: "Player ID",
    dataIndex: "player_id",
    key: "player_id",
    searchable: false,

    render: (text, game) => {
      const fontWeight = text === "Total" || text === "Total " ? "900" : "400";
      const fontSize = text === "Total" || text === "Total " ? 16 : 14;
      return <p style={{ fontWeight, fontSize }}>{text}</p>;
    },
    width: "220px",
  },
  {
    title: "Bet",
    dataIndex: "bet",
    key: "bet",
    searchable: false,

    width: "200px",
  },
  {
    title: "Win",
    dataIndex: "win",
    key: "win",
    searchable: false,
  },

  {
    title: "Net",
    dataIndex: "turnover",
    key: "turnover",
    searchable: false,
  },

  {
    title: "RTP %",
    dataIndex: "rtp",
    key: "rtp",
    searchable: false,
  },
  {
    title: "Tickets",
    dataIndex: "tickets",
    key: "tickets",
    searchable: false,
  },
  {
    title: "Ø  Bet",
    dataIndex: "avg_bet",
    key: "avg_bet",
    searchable: false,
  },
  {
    title: "Ø Ticket/Player",
    dataIndex: "tickets_player",
    key: "tickets_player",
    searchable: false,
  },
];

export const statisticColumnsPlayerGames = [
  {
    title: "Player ID",
    dataIndex: "player_id",
    key: "player_id",
    searchable: false,

    render: (text, game) => {
      const fontWeight = text === "Total" || text === "Total " ? "900" : "400";
      const fontSize = text === "Total" || text === "Total " ? 16 : 14;
      return <p style={{ fontWeight, fontSize }}>{text}</p>;
    },
    width: "200px",
  },
  {
    title: "Game Name",
    dataIndex: "game_name",
    key: "game_name",
    searchable: false,

    render: (text, game) => {
      if (!game.game_photo) return;
      return (
        <div>
          <Avatar
            shape="circle"
            src={game.game_photo}
            style={{ marginRight: "4px" }}
            size="default"
          />
          {game.game_name}{" "}
        </div>
      );
    },
    width: "220px",
  },
  {
    title: "Bet",
    dataIndex: "bet",
    key: "bet",
    searchable: false,

    width: "200px",
  },

  {
    title: "Win",
    dataIndex: "win",
    key: "win",
    searchable: false,
  },

  {
    title: "Net",
    dataIndex: "turnover",
    key: "turnover",
    searchable: false,
  },

  {
    title: "RTP %",
    dataIndex: "rtp",
    key: "rtp",
    searchable: false,
  },
  {
    title: "Tickets",
    dataIndex: "tickets",
    key: "tickets",
    searchable: false,
  },
  {
    title: "Ø  Bet",
    dataIndex: "avg_bet",
    key: "avg_bet",
    searchable: false,
  },
  {
    title: "Ø Ticket/Player",
    dataIndex: "tickets_player",
    key: "tickets_player",
    searchable: false,
  },
];
