export const freeGamesDataTableSelector = (state) => {
  return state.freeGames.dataTable;
};
export const selectFreeGamesSingleProps = (state) => {
  return state.freeGames.create;
};
export const freeGamesIsEditSelector = (state) => {
  return state.freeGames.create.freeGamesIsEdit;
};
export const freeGamesCreateNameSelector = (state) => {
  return state.freeGames.create.freeGamesCreateName;
};
export const freeGamesCreateCodeSelector = (state) => {
  return state.freeGames.create.freeGamesCreateCode;
};
export const freeGamesCreateDescriptionSelector = (state) => {
  return state.freeGames.create.freeGamesCreateDescription;
};
export const freeGamesCreateDateFromSelector = (state) => {
  return state.freeGames.create.freeGamesCreateDateFrom;
};
export const freeGamesCreateDateToSelector = (state) => {
  return state.freeGames.create.freeGamesCreateDateTo;
};
export const freeGamesCreateTypeSelector = (state) => {
  return state.freeGames.create.freeGamesCreateType;
};
export const freeGamesCreateSpinsSelector = (state) => {
  return state.freeGames.create.freeGamesCreateSpins;
};
export const freeGamesCreateTimeSelector = (state) => {
  return state.freeGames.create.freeGamesCreateTime;
};
export const freeGamesCreateWinsSelector = (state) => {
  return state.freeGames.create.freeGamesCreateWins;
};
export const freeGamesCreateGameProviderSelector = (state) => {
  return state.freeGames.create.freeGamesCreateGameProvider;
};
export const freeGamesCreateGameSelector = (state) => {
  return state.freeGames.create.freeGamesCreateGame;
};
export const freeGamesCreateBetTypeSelector = (state) => {
  return state.freeGames.create.freeGamesCreateBetType;
};
export const freeGamesCreateLinesSelector = (state) => {
  return state.freeGames.create.freeGamesCreateLines;
};
export const freeGamesCreateCoinSizeSelector = (state) => {
  return state.freeGames.create.freeGamesCreateCoinSize;
};
export const freeGamesCreateCoinCountSelector = (state) => {
  return state.freeGames.create.freeGamesCreateCoinCount;
};
export const freeGamesCreateBetSelector = (state) => {
  return state.freeGames.create.freeGamesCreateBet;
};
export const freeGamesCreateFreeGamesArray = (state) => {
  return state.freeGames.create.freeGamesArray;
};

export const freeGamesFilterState = (state) => {
  return state.freeGames.filter.state;
};
export const freeGamesFilterDateFrom = (state) => {
  return state.freeGames.filter.dateFrom;
};
export const freeGamesFilterDateTo = (state) => {
  return state.freeGames.filter.dateTo;
};
export const freeGamesFilterProvider = (state) => {
  return state.freeGames.filter.provider;
};
export const freeGamesFilterSearch = (state) => {
  return state.freeGames.filter.search;
};
export const freeGamesLoadingDataTable = (state) => {
  return state.freeGames.ui.loadingDataTable;
};
export const freeGamesCreatedSelector = (state) => {
  return state.freeGames.ui.freeGamesCreated;
};
export const selectFreeGameDetails = (state) => {
  return state.freeGames.details.data.map((item) => ({
    ...item,
    casino_id: state.user.activeCasino,
  }));
};
export const selectFreeGamesDataWithoutPagination = (state) => {
  return state.freeGames.freeGamesDataWithoutPagination.data;
};
export const selectFreeGamesDataWithoutPaginationLoading = (state) => {
  return state.freeGames.freeGamesDataWithoutPagination.loading;
};

export const freeGamesPaginationTotal = (state) =>
  state.freeGames.pagination.total;

export const freeGamesPaginationCurrentPage = (state) =>
  state.freeGames.pagination.page;

export const freeGamesPaginationLastPage = (state) =>
  state.freeGames.pagination.lastPage;

export const freeGamesPaginationPerPage = (state) =>
  state.freeGames.pagination.perPage;

export const freeGamesMultipleGames = (state) =>
  state.freeGames.freeGamesMultipleGames;

export const freeGameDetailSearch = (state) =>
  state.freeGames.details.searchDetails;

export const freeGamesTypeDisabledSelector = (state) =>
  state.freeGames.create.freeGamesTypeDisabled;

export const freeGamesFreeSpinsAvailableSelector = (state) =>
  state.freeGames.create.freeGamesFreeSpinsAvailable;
