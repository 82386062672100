import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesOrdinalNumberSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeOrdinalNumber } from "../../../../../redux/slices/games/gamesSlice";

const GamesOrdinalNumber = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesOrdinalNumberSelector);
  return (
    <Form.Item label="Ordinal Number" name="ordinal_number">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeOrdinalNumber(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default GamesOrdinalNumber;
