import { Input, Select } from "antd";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";
import {
  selectFreeGamesDataWithoutPagination,
  selectFreeGamesDataWithoutPaginationLoading,
} from "../../../../../../../../../redux/slices/free-games/freeGamesSelector";

const FreeGamesDisplay = ({ i }) => {
  const data = useSelector(bonusesCreateActivationActivitiesAfterBonus);

  const freeGames = useSelector(selectFreeGamesDataWithoutPagination);

  const loading = useSelector(selectFreeGamesDataWithoutPaginationLoading);

  const possibleOptions = freeGames
    .map((x) => {
      return { label: x.name, value: x.id };
    })
    .filter(Boolean);

  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Select
        loading={loading}
        value={data[i]?.data?.offer}
        onChange={(x) => {
          // const newData = cloneDeep(data);
          // newData[i].data.offer = x;

          // freeGames.forEach((q) => {
          //   if (q.offer === x) {
          //     newData[i].data.label = q.name;
          //   }
          // });
          // dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));

          dispatch(
            changeBonusesCreateActivationActivitiesAfterBonus({
              index: i,
              data: {
                offer: x,
                freespins_id: x,
              },
            })
          );
        }}
        placeholder="- Select offer -"
        options={possibleOptions}
        style={{ maxWidth: "300px", width: "30%", height: "min-content" }}
      />
      {/* <Input
        placeholder="Hit value min"
        value={data[i]?.data?.hit_value_min}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.hit_value_min = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
        type="number"
      /> */}
      {/* <Input
        placeholder="Hit value max"
        value={data[i]?.data?.hit_value_max}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.hit_value_max = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
        type="number"
      /> */}
    </div>
  );
};

export default FreeGamesDisplay;
