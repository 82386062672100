import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Table } from "antd";
import { setStakesDetailRoundModal } from "../../../../redux/slices/stakes/stakeSlice";
import {
  selectStakesRoundIsModalOpen,
  stakeRoundSelector,
} from "../../../../redux/slices/stakes/stakesSelector";
import { stakesRoundDetailsColumn } from "./stakesRoundDetailsColumn";
import "./StakesRoundDetail.css";
const moment = require("moment");

const StakesRoundDetail = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectStakesRoundIsModalOpen);
  const data = useSelector(stakeRoundSelector);

  const handleCancel = () => {
    dispatch(setStakesDetailRoundModal(false));
  };

  const handleOk = () => {
    dispatch(setStakesDetailRoundModal(false));
  };

  return (
    <Modal
      title="Round details"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      width={1400}
    >
      <div className="stakes-round-detail-wrap">
        <div>
          <p>
            Played ID:{" "}
            <span>
              <b>{data.user_uuid}</b>
            </span>
          </p>
          <p>
            Turnover: <b>{Number(data.turnover).toFixed(4)}</b>
          </p>
          <p>
            RTP: <b>{Number(data.rtp).toFixed(4)}</b>
          </p>
          <p>
            ID: <b>{data.id}</b>
          </p>
        </div>
        <div>
          <p className="stakes-round-detail-game-name">{data.game_name}</p>
          <p className="stakes-round-detail-game-provider">
            <b>{data.provider_name}</b>
          </p>
          <p>
            Round ID: <b>{data.external_id}</b>
          </p>
          <p>
            Start: <b>{moment(data.timestamp).format("YYYY-MM-DD HH:mm:ss")}</b>
          </p>
        </div>
      </div>
      <h2>Transactions</h2>
      <Table
        className="stakes-round-detail-table"
        columns={stakesRoundDetailsColumn}
        dataSource={data.transactions}
        rowKey={(record) => record.id}
      />
    </Modal>
  );
};

export default StakesRoundDetail;
