import axios from "axios";

const authUser = async (values) => {
  try {
    const result = await axios.post("/login", values);
    return result;
  } catch (error) {
    throw error;
  }
};
const userServices = { authUser };

export default userServices;
