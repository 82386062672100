import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadingGameGroupsListSelector } from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";
import { gameGroupsListSelector } from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";
import { tournamentsGameGroupRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  editTournamentsCreateRulesGameGroupsRules,
  removeTournamentsCreateRulesGameGroupsRules,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const SingleGameGroupRule = ({ id, percent, i }) => {
  const gameGroupsList = useSelector(gameGroupsListSelector);
  const loadingGameGroupsList = useSelector(loadingGameGroupsListSelector);
  const gameGroupsRules = useSelector(tournamentsGameGroupRules);

  const dispatch = useDispatch();
  return (
    <div className="rules_single_provider">
      <Select
        value={id}
        dropdownMatchSelectWidth={false}
        loading={loadingGameGroupsList}
        onChange={(x) => {
          dispatch(
            editTournamentsCreateRulesGameGroupsRules({ i, id: x, percent })
          );
        }}
        style={{ width: "100%" }}
        placeholder="Select Game Group"
      >
        {gameGroupsList.map((item) => {
          let available = true;
          gameGroupsRules.forEach((x) => {
            if (x.id === item.id && x.id !== id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      <Input
        value={percent}
        suffix="%"
        type="number"
        onChange={(e) => {
          dispatch(
            editTournamentsCreateRulesGameGroupsRules({
              i,
              id,
              percent: e.target.value,
            })
          );
        }}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        size="middle"
        danger={true}
        onClick={() => {
          dispatch(removeTournamentsCreateRulesGameGroupsRules(i));
        }}
      />
    </div>
  );
};

export default SingleGameGroupRule;
