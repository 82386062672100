import { Avatar, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesIntegrationSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesIntegration } from "../../../../../redux/slices/games/gamesSlice";
import { getIntegrationReducer } from "../../../../../redux/slices/integrations/extra-reducers/getIntegrationsReducer";
import {
  integrationsListSelector,
  loadingIntegrationsListSelector,
} from "../../../../../redux/slices/integrations/integrationsSelectors";

const GameIntegrations = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesIntegrationSelector);

  const integrationsList = useSelector(integrationsListSelector);
  const loading = useSelector(loadingIntegrationsListSelector);

  useEffect(() => {
    dispatch(getIntegrationReducer());
  }, [dispatch]);

  return (
    <Form.Item label="Integrations" name="Integrations">
      <Select
        dropdownMatchSelectWidth={false}
        loading={loading}
        onChange={(x) => {
          dispatch(changeGamesIntegration(x));
        }}
        showSearch
        placeholder="Integrations"
        value={value}
        defaultValue={value}
      >
        {integrationsList.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              <Avatar
                src={item.photo}
                style={{ marginRight: "4px" }}
                size="small"
              />
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default GameIntegrations;
