import React from 'react'
import { Form, Input } from "antd";
import { useDispatch } from 'react-redux';
import { pauseSelector } from '../../../../../../../../redux/slices/bonuses/bonusesSelectors';
import { useSelector } from 'react-redux';
import { changePause } from '../../../../../../../../redux/slices/bonuses/bonusesSlice';

const Pause = () => {
    const pause = useSelector(pauseSelector)
    const dispatch = useDispatch()


  return (
    <Form.Item
      label="Pause (minutes)"
      name="pause"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Input
        name={'pause'}
        value={pause}
        defaultValue={pause}
        type='number'
        onChange={(e) => {
            if(!e.target.value) return;

            dispatch(changePause(parseInt(e.target.value)))

        }}
      />
    </Form.Item>
  )
}

export default Pause