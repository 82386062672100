import { Avatar, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gamesIsEditSelector,
  gamesProviderSelector,
} from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesProvider } from "../../../../../redux/slices/games/gamesSlice";
import { getProvidersReducer } from "../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import {
  loadingProvidersListSelector,
  providersListSelector,
} from "../../../../../redux/slices/providers/providersSelectors";

const GamesProvider = () => {
  const dispatch = useDispatch();
  const isEdit = useSelector(gamesIsEditSelector);
  const value = useSelector(gamesProviderSelector);

  const providersList = useSelector(providersListSelector);
  const loadingProvidersList = useSelector(loadingProvidersListSelector);

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  return (
    <Form.Item label="Provider" name="provider">
      <Select
        disabled={isEdit}
        dropdownMatchSelectWidth={false}
        loading={loadingProvidersList}
        onChange={(x) => {
          dispatch(changeGamesProvider(x));
        }}
        showSearch
        placeholder="Game Providers"
        value={value}
        defaultValue={value}
      >
        {providersList.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              <Avatar
                src={item.photo}
                style={{ marginRight: "4px" }}
                size="small"
              />
              {item.name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default GamesProvider;
