import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateAchievementMinimalValue } from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateMinimalValue } from "../../../../../../../redux/slices/tournaments/tournamentsSlice";

const MinimalValue = () => {
  const value = useSelector(tournamentsCreateAchievementMinimalValue);
  const dispatch = useDispatch();

  return (
    <Form.Item label="Minimal value" name="minimal_value">
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        disabled
        onChange={(e) => {
          dispatch(changeTournamentsCreateMinimalValue(e));
        }}
      />
    </Form.Item>
  );
};

export default MinimalValue;
