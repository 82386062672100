import axios from "axios";

const defaultRoute = "/games";

const getGames = async (params) => {
  const result = await axios(defaultRoute, { params });
  return result;
};
const getAllGames = async (params) => {
  const result = await axios.get(defaultRoute + "/all-games", { params });

  return result;
};

const getSingleGame = async (id) => {
  const result = await axios(defaultRoute + `/${id}`);
  return result;
};

const toggleEnabled = async (id) => {
  const result = await axios(defaultRoute + `/toggle-enabled/${id}`);
  return result;
};

const createGame = async (params) => {
  const result = await axios.post(defaultRoute, { ...params });
  return result;
};

const editGame = async (body, id) => {
  const result = await axios.post(defaultRoute + `/update/${id}`, { ...body });
  return result;
};

export const getAllGamesByProvider = async (id, params) =>
  await axios(`${defaultRoute}/provider/${id}`, { params });

export const gamesServices = {
  getGames,
  createGame,
  editGame,
  getSingleGame,
  toggleEnabled,
  getAllGames,
};
