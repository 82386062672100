import { Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import General from "./general/General";
import { tournamentsCreateStep } from "../../../../../../redux/slices/tournaments/tournamentsSelector";
import Activation from "./activation/Activation";
import Rules from "./rules/Rules";
import Achievement from "./achievement/Achievement";
import Review from "./review/Review";

const StepsPageRouter = () => {
  const currentStep = useSelector(tournamentsCreateStep);
  return (
    <>
      <Form style={{ width: "100%", padding: "16px" }} layout={"vertical"}>
        {currentStep === 0 ? (
          <General />
        ) : currentStep === 1 ? (
          <Activation />
        ) : currentStep === 2 ? (
          <Rules />
        ) : currentStep === 3 ? (
          <Achievement />
        ) : currentStep === 4 ? (
          <Review />
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};

export default StepsPageRouter;
