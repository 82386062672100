import { Avatar, Button, Tag } from "antd";
import Category from "./Category";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  changeSelectedGamesKeysTournament,
  changeSelectedGamesTournament,
} from "../../../../../../../../redux/slices/games/gamesSlice";
import { useSelector } from "react-redux";
import {
  selectedGamesKeysTournamentSelector,
  selectedGamesTournamentSelector,
} from "../../../../../../../../redux/slices/games/gamesSelectors";

const RemoveSelectedGame = ({ item_id }) => {
  const dispatch = useDispatch();
  const selectedGames = useSelector(selectedGamesTournamentSelector);
  const selectedGamesKeys = useSelector(selectedGamesKeysTournamentSelector);

  const filterItems = () => {
    dispatch(
      changeSelectedGamesTournament(
        selectedGames.filter((game) => game.id !== item_id)
      )
    );

    dispatch(
      changeSelectedGamesKeysTournament(
        selectedGamesKeys.filter((id) => id !== item_id)
      )
    );
  };

  return (
    <Button
      icon={<DeleteOutlined />}
      type="text"
      danger
      onClick={filterItems}
    />
  );
};

export const gamesColumns = [
  {
    title: "Id",
    width: "65px",
    render: (game) => {
      return <div>{game.id}</div>;
    },
    sorter: (a, b) => {
      const first = a.id;
      const second = b.id;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    title: "Name",
    width: "300px",
    render: (game) => {
      if (!game) return;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {game && (
            <Avatar
              shape="circle"
              src={game.photo}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{game.name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.name;
      const second = b.name;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    title: "Provider",
    width: "220px",
    render: (game) => {
      if (!game) return;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game && (
            <Avatar
              shape="circle"
              src={game.provider.photo}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{game.provider.name}</div>
        </div>
      );
    },
    // sorter: (a, b) => {
    //   const first = a.provider.name;
    //   const second = b.provider.name;
    //   return first > second ? -1 : first < second ? 1 : 0;
    // },
  },
  {
    title: "Category",
    width: "100px",
    render: (game) => {
      if (!game) return;
      return <Category category={game.game_categories} />;
    },
  },
  {
    title: "Flags",
    render: (game) => {
      if (!game.flags) return;
      if (game.flags.length === 0) return;

      const color = game.flags[0].name === "New" ? "volcano" : "purple";
      return (
        game.flags[0] && (
          <Tag key={game.flags[0].id} color={color}>
            {game.flags[0].name}
          </Tag>
        )
      );
    },
  },
];

export const selectedGamesColumns = [
  {
    title: "Id",
    width: "65px",
    render: (game) => {
      return <div>{game.id}</div>;
    },
    sorter: (a, b) => {
      const first = a.id;
      const second = b.id;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    title: "Name",
    width: "200px",
    render: (game) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {game && (
            <Avatar
              shape="circle"
              src={game.photo}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{game.name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.name;
      const second = b.name;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    title: "Provider",
    width: "200px",
    render: (game) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game && (
            <Avatar
              shape="circle"
              src={game.provider.photo}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{game.provider.name}</div>
        </div>
      );
    },
  },
  {
    title: "Actions",
    render: (game) => {
      if (!game) return;

      return <RemoveSelectedGame item_id={game.id} />;
    },
  },
];
