import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getStatisticsDataReducers } from "./extra-reducers/getStatisticsDataReducers";
import { getGamesByProviderStatReducer } from "../games/extra-reducers/getGamesByProvider";
import getDefaultTimezone from "../../../helper/getDefaultTimezone";

const defaultTimezone = getDefaultTimezone();

const initialState = {
  filterBy: 1,
  sortDirection: "desc",
  orderBy: "bet",
  statisticsGameValue: 0,
  statisticsProvider: 0,
  statisticsGames: 0,
  statisticsGamesDisabled: true,
  statisticsGamesByProvider: [],
  statisticsCategories: 0,
  statisticsDateFrom: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
  statisticsDateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  statisticsTimezone: defaultTimezone,
  statisticsTableData: [],
  additionalData: {
    bonuses: {
      data: [],
      win: 0,
    },
    freespins: {
      data: [],
      win: 0,
    },
    game_bonuses: {
      data: [],
      win: 0,
    },
    jackpots: {
      data: [],
      win: 0,
    },
  },
  total: {
    avg_bet: 0,
    bet: 0,
    rtp: 0,
    ticket_count: 0,
    turnover: 0,
    win: 0,
  },
  ui: {
    isDataLoading: false,
  },
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    resetStatisticsSlice: (state) => initialState,
    restartGamesByProvider: (state) => {
      state.statisticsGamesByProvider = [];
    },
    setStatisticsGameValue: (state, action) => {
      state.statisticsGameValue = action.payload;
    },
    changeStatisticsTimezone: (state, action) => {
      state.statisticsTimezone = action.payload;
    },
    changeFilterBy: (state, action) => {
      state.filterBy = action.payload;
    },
    changeStatisticsOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    changeStatisticsSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    changeStatisticsProvider: (state, action) => {
      state.statisticsProvider = action.payload;
      state.statisticsGames = 0;
    },
    changeStatisticsGames: (state, action) => {
      state.statisticsGames = action.payload;
    },
    changeStatisticsCategories: (state, action) => {
      state.statisticsCategories = action.payload;
    },
    changeStatisticsDateFrom: (state, action) => {
      state.statisticsDateFrom = action.payload.slice(0, 10) + " 00:00:00";
    },
    changeStatisticsDateTo: (state, action) => {
      state.statisticsDateTo = action.payload.slice(0, 10) + " 23:59:59";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatisticsDataReducers.pending, (state) => {
      state.ui.isDataLoading = true;
      state.statisticsTableData = [];
    });
    builder.addCase(getStatisticsDataReducers.fulfilled, (state, action) => {
      state.ui.isDataLoading = false;
      const data = action.payload.main_data.data;

      if (action.payload.main_data?.avg_bet && state.filterBy !== 1) {
        const obj = {
          avg_bet: action.payload.main_data?.avg_bet,
          bet: action.payload.main_data?.bet,
          rtp: action.payload.main_data?.rtp,
          ticket_count: action.payload.main_data?.ticket_count,
          turnover: action.payload.main_data?.turnover,
          win: action.payload.main_data?.win,
        };
        if (state.filterBy === 3 || state.filterBy === 2) {
          obj.provider_name = "Total ";
        } else if (state.filterBy === 1) {
          obj.out = "Total ";
        } else {
          obj.player_id = "Total ";
        }
        data.push(obj);
      }

      if (state.filterBy === 3 || state.filterBy === 2) {
        action.payload.additional_data.Freespins.provider_name = "Freespins";
        action.payload.additional_data.Bonuses.provider_name = "Bonuses";
        action.payload.additional_data.GameBonus.provider_name = "GameBonus";
        action.payload.additional_data.Jackpots.provider_name = "Jackpots";
        action.payload.total.provider_name = "Total";
      } else if (state.filterBy === 1) {
        action.payload.additional_data.Freespins.bet = "Freespins";
        action.payload.additional_data.GameBonus.provider_name = "GameBonus";
        action.payload.additional_data.Bonuses.bet = "Bonuses";
        action.payload.additional_data.Jackpots.bet = "Jackpots";
        action.payload.total.bet = "Total";
      } else {
        action.payload.additional_data.Freespins.player_id = "Freespins";
        action.payload.additional_data.GameBonus.provider_name = "GameBonus";
        action.payload.additional_data.Bonuses.player_id = "Bonuses";
        action.payload.additional_data.Jackpots.player_id = "Jackpots";
        action.payload.total.player_id = "Total";
      }

      if (action.payload.additional_data.Bonuses.win > 0) {
        // action.payload.additional_data.Bonuses.turnover =
        //   action.payload.additional_data.Bonuses.win;
        data.push(action.payload.additional_data.Bonuses);
      }
      if (action.payload.additional_data.GameBonus.win > 0) {
        // action.payload.additional_data.GameBonus.turnover =
        //   action.payload.additional_data.GameBonus.win;
        data.push(action.payload.additional_data.GameBonus);
      }
      if (action.payload.additional_data.Freespins.win > 0) {
        // action.payload.additional_data.Freespins.turnover =
        //   action.payload.additional_data.Freespins.win;
        data.push(action.payload.additional_data.Freespins);
      }
      if (action.payload.additional_data.Jackpots.win > 0) {
        // action.payload.additional_data.Jackpots.turnover =
        //   action.payload.additional_data.Jackpots.win;
        data.push(action.payload.additional_data.Jackpots);
      }

      data.push(action.payload.total);

      const newData = data.map((item) => ({
        ...item,
        turnover: ["Bonuses", "Jackpots"].includes(item.bet)
          ? null
          : item.turnover,
      }));

      state.statisticsTableData = newData;
    });
    builder.addCase(getStatisticsDataReducers.rejected, (state, action) => {
      state.ui.isDataLoading = false;
    });
    builder.addCase(getGamesByProviderStatReducer.pending, (state, action) => {
      state.statisticsGamesDisabled = true;
    });
    builder.addCase(
      getGamesByProviderStatReducer.fulfilled,
      (state, action) => {
        state.statisticsGamesDisabled = false;
        state.statisticsGamesByProvider = action.payload;
      }
    );
    builder.addCase(getGamesByProviderStatReducer.rejected, (state, action) => {
      state.statisticsGamesDisabled = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeStatisticsTimezone,
  setStatisticsGameValue,
  restartGamesByProvider,
  resetStatisticsSlice,
  changeFilterBy,
  changeStatisticsOrderBy,
  changeStatisticsSortDirection,
  changeStatisticsProvider,
  changeStatisticsGames,
  changeStatisticsCategories,
  changeStatisticsDateFrom,
  changeStatisticsDateTo,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
