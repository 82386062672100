/* eslint-disable array-callback-return */
import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  gamesListSelector,
  loadingGamesListSelector,
} from "../../../../../../../../redux/slices/games/gamesSelectors";
import { tournamentsGameRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  editTournamentsCreateRulesGameRules,
  removeTournamentsCreateRulesGameRules,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import { getGamesReducer } from "../../../../../../../../redux/slices/games/extra-reducers/getGamesReducer";

const SingleGameRule = ({ name, id, percent, i }) => {
  const gamesList = useSelector(gamesListSelector);
  const loadingGamesList = useSelector(loadingGamesListSelector);
  const gameRUles = useSelector(tournamentsGameRules);

  const dispatch = useDispatch();

  return (
    <div className="rules_single_game">
      <Select
        showSearch
        value={name && name}
        dropdownMatchSelectWidth={false}
        loading={loadingGamesList}
        onChange={(x) => {
          dispatch(
            editTournamentsCreateRulesGameRules({
              i,
              name: x,
              id: gamesList.find((game) => game.name === x).id,
              percent,
            })
          );
        }}
        style={{ width: "100%" }}
        placeholder="Select game"
        autoClearSearchValue
      >
        {gamesList.map((item) => {
          let available = true;
          gameRUles.forEach((x) => {
            if (x.id === item.id && x.id !== id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.name}>
                <Avatar
                  src={item?.provider?.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      <Input
        value={percent}
        suffix="%"
        type="number"
        onChange={(e) => {
          dispatch(
            editTournamentsCreateRulesGameRules({
              i,
              name,
              id,
              percent: e.target.value,
            })
          );
        }}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        size="middle"
        danger={true}
        onClick={() => {
          dispatch(removeTournamentsCreateRulesGameRules(i));
        }}
      />
    </div>
  );
};

export default SingleGameRule;
