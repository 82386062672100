import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../../../../layouts/components/Loader";

import {
  tournamentsCreateIsEdit,
  tournamentsTableDataLoading,
} from "../../../../redux/slices/tournaments/tournamentsSelector";
import {
  changeTournamentsIsEdit,
  resetTournamentState,
} from "../../../../redux/slices/tournaments/tournamentsSlice";
import Header from "./Header";
import TournamentsSteps from "./tournaments-steps/TournamentsSteps";
import StepsPageRouter from "./tournaments-steps/steps-pages/StepsPageRouter";
import { getSingleTournaments } from "../../../../redux/slices/tournaments/extra-reducers/getSingleTournaments";

const TournamentsCreateEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isDataLoading = useSelector(tournamentsTableDataLoading);
  const isEdit = useSelector(tournamentsCreateIsEdit);

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      const regex = /\/(\d+)\/round\/(\d+)/;
      const matches = location.pathname.match(regex);

      // Extracted numbers
      const id = matches && matches[1];
      const idRound = matches && matches[2];

      dispatch(changeTournamentsIsEdit(true));
      dispatch(getSingleTournaments({ id, idRound }));
    } else {
      dispatch(changeTournamentsIsEdit(false));
    }
    return () => {
      dispatch(resetTournamentState());
    };
  }, [dispatch, location.pathname]);

  return (
    <div>
      {isEdit && isDataLoading ? (
        <Loader />
      ) : (
        <>
          {/* Same as */}
          <Header />
          <TournamentsSteps />
          <StepsPageRouter />
        </>
      )}
    </div>
  );
};

export default TournamentsCreateEdit;
