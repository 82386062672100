export const statisticColumnsGamesHeaders = [
  {
    label: "Game Provider",
    key: "provider_name",
  },
  {
    label: "Game",
    key: "game_name",
  },
  {
    label: "Bet",
    key: "bet",
  },
  {
    label: "Win",
    key: "win",
  },
  {
    label: "Net",
    key: "turnover",
  },
  {
    label: "RTP %",
    key: "rtp",
  },
  {
    label: "Tickets",
    key: "tickets",
  },
  {
    label: "Players",
    key: "players",
  },
  {
    label: "Ø Bet",
    key: "avg_bet",
  },
  {
    label: "Ø Ticket/Player",
    key: "tickets_player",
  },
];

export const statisticColumnsTotalHeaders = [
  {
    label: "Bet",
    key: "bet",
  },
  {
    label: "Win",
    key: "win",
  },

  {
    label: "Net",
    key: "turnover",
  },
  {
    label: "RTP %",
    key: "rtp",
  },
  {
    label: "Tickets",
    key: "tickets",
  },
  {
    label: "Players",
    key: "players",
  },
  {
    label: "Ø  Bet",
    key: "avg_bet",
  },
  {
    label: "Ø Ticket/Player",
    key: "tickets_player",
  },
];

export const statisticColumnsProvidersHeaders = [
  {
    label: "Provider",
    key: "provider_name",
  },
  {
    label: "Bet",
    key: "bet",
  },

  {
    label: "Win",
    key: "win",
  },
  {
    label: "Net",
    key: "turnover",
  },

  {
    label: "Players",
    key: "players",
  },

  {
    label: "RTP %",
    key: "rtp",
  },
  {
    label: "Tickets",
    key: "tickets",
  },

  {
    label: "Ø Bet",
    key: "avg_bet",
  },
  {
    label: "Ø Ticket/Player",
    key: "tickets_player",
  },
];

export const statisticColumnsPlayersHeaders = [
  {
    label: "Player ID",
    key: "player_id",
  },
  {
    label: "Bet",
    key: "bet",
  },
  {
    label: "Win",
    key: "win",
  },

  {
    label: "Net",
    key: "turnover",
  },

  {
    label: "RTP %",
    key: "rtp",
  },
  {
    label: "Tickets",
    key: "tickets",
  },
  {
    label: "Ø  Bet",
    key: "avg_bet",
  },
  {
    label: "Ø Ticket/Player",
    key: "tickets_player",
  },
];

export const statisticColumnsPlayerGamesHeaders = [
  {
    label: "Player ID",
    key: "player_id",
  },
  {
    label: "Game Name",
    key: "game_name",
  },
  {
    label: "Bet",
    key: "bet",
  },

  {
    label: "Win",
    key: "win",
  },

  {
    label: "Net",
    key: "turnover",
  },

  {
    label: "RTP %",
    key: "rtp",
  },
  {
    label: "Tickets",
    key: "tickets",
  },
  {
    label: "Ø  Bet",
    key: "avg_bet",
  },
  {
    label: "Ø Ticket/Player",
    key: "tickets_player",
  },
];
