import { tableColumnHandler } from "../../../../../utils/tableColumnHandler";

export const freeGamesMoreDetailsColumns = [
  {
    ...tableColumnHandler("Game", "Name"),
  },
  {
    ...tableColumnHandler("Bet type", "bet_type"), //TODO? Ne salje se sa back-a
  },
  {
    ...tableColumnHandler("Bet amount", "bet"), //TODO? Ne salje se sa back-a
  },
];
