import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  freeGamesCreateBetSelector,
  freeGamesCreateBetTypeSelector,
  freeGamesCreateCoinCountSelector,
  freeGamesCreateCoinSizeSelector,
  freeGamesCreateGameSelector,
  freeGamesCreateLinesSelector,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import {
  addFreeGamesArray,
  changeFreeGamesCreateGame,
} from "../../../../../redux/slices/free-games/freeGamesSlice";
import {
  gamesListSelector,
  gameWithIdSelector,
} from "../../../../../redux/slices/games/gamesSelectors";

const FreeGamesButtonAdd = () => {
  const dispatch = useDispatch();
  const game_id = useSelector(freeGamesCreateGameSelector);
  const bet_type = useSelector(freeGamesCreateBetTypeSelector);
  const bet_lines = useSelector(freeGamesCreateLinesSelector);
  const coin_size = useSelector(freeGamesCreateCoinSizeSelector);
  const coins = useSelector(freeGamesCreateCoinCountSelector);
  const bet = useSelector(freeGamesCreateBetSelector);

  const gameList = useSelector(gamesListSelector);

  let game_name;

  gameList.forEach((x) => {
    if (x.id === game_id) {
      game_name = x.name;
    }
  });

  const game = useSelector((state) => gameWithIdSelector(state, game_id));

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        onClick={() => {
          if (game_id === null) return;
          //TODO: FIX THIS according to payload in service
          dispatch(
            addFreeGamesArray({
              game_id: game.id,
              identifier: game.identifier,
              bet_type: bet_type === 0 ? "TOTAL" : "PER LINE",
              photo: game.photo,
              bet_lines,
              coin_size,
              coins,
              bet,
              Name: game_name,
            })
          );
          dispatch(changeFreeGamesCreateGame(null));
        }}
      >
        Add game
      </Button>
    </div>
  );
};

export default FreeGamesButtonAdd;
