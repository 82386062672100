import { Form, Image } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { tournamentsCreateBackgroundImageUrl } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";

const BackgroundImagePreview = () => {
  const url = useSelector(tournamentsCreateBackgroundImageUrl);
  return (
    <>
      {url ? (
        <Form.Item
          label="Background image preview"
          name=""
          style={{ minWidth: "100%", height: "100%" }}
        >
          <Image src={url ?? "error"} style={{ borderRadius: "4px" }} />
        </Form.Item>
      ) : null}
    </>
  );
};

export default BackgroundImagePreview;
