import { createAsyncThunk } from "@reduxjs/toolkit";
import bonusesServices from "../../../../services/bonusesServices";

export const getBonusesRoundsData = createAsyncThunk(
  "getBonusesRoundsData",
  async (id, thunkApi) => {
    const activeCasino = thunkApi.getState().user.activeCasino;
    const state = thunkApi.getState();
    try {
      const res = await bonusesServices.getRounds(id, {
        casino_id: activeCasino,
        sort_direction: "desc",
        page: state.bonuses.pageNumber,
        per_page: parseInt(state.bonuses.pageSize),
      });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
