import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { cloneDeep } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateAchievementActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesAchievementActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";

const PopupDisplay = ({ i }) => {
  const data = useSelector(bonusesCreateAchievementActivitiesAfterBonus);
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <TextArea
        columns={4}
        placeholder="Enter text..."
        value={data[i]?.data?.text}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.text = e.target.value;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
      />
      <div style={{ display: "flex", gap: "8px" }}>
        <Input
          placeholder="Url of popup image"
          value={data[i]?.data?.urlImage}
          onChange={(e) => {
            const newData = cloneDeep(data);
            newData[i].data.urlImage = e.target.value;
            dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
          }}
        />
        <Input
          placeholder="Hit value min"
          value={data[i]?.data?.hit_value_min}
          onChange={(e) => {
            const newData = cloneDeep(data);
            newData[i].data.hit_value_min = e.target.value;
            dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
          }}
          type="number"
        />
        <Input
          placeholder="Hit value max"
          value={data[i]?.data?.hit_value_max}
          onChange={(e) => {
            const newData = cloneDeep(data);
            newData[i].data.hit_value_max = e.target.value;
            dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
          }}
          type="number"
        />
      </div>
    </div>
  );
};

export default PopupDisplay;
