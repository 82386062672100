import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoriesListSelector,
  loadingCategoriesListSelector,
} from "../../../../redux/slices/categories/categoriesSelectors";
import { getCategoriesReducer } from "../../../../redux/slices/categories/extra-reducers/getCategoriesReducer";
import { statisticsCategoriesSelector } from "../../../../redux/slices/statistics/statisticsSelector";
import { changeStatisticsCategories } from "../../../../redux/slices/statistics/statisticsSlice";

const StatisticCategories = () => {
  const categoriesList = useSelector(categoriesListSelector);
  const loadingCategoriesList = useSelector(loadingCategoriesListSelector);
  const statisticsCategories = useSelector(statisticsCategoriesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesReducer());
  }, [dispatch]);

  return (
    <Select
      value={statisticsCategories}
      dropdownMatchSelectWidth={false}
      loading={loadingCategoriesList}
      onChange={(x) => {
        dispatch(changeStatisticsCategories(x));
      }}
      options={[
        {
          value: 0,
          label: "All Game categories",
        },
        ...categoriesList.map((element) => {
          return { value: element.id, label: element.name };
        }),
      ]}
    />
  );
};

export default StatisticCategories;
