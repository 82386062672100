import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import Router from "../routes/Router";
import { useDispatch } from "react-redux";
import { changeIsLoggedIn } from "../redux/slices/user/userSlice";
import { useSelector } from "react-redux";
import {
  activeCasinoSelector,
  isLoggedInSelector,
} from "../redux/slices/user/userSelectors";
import Loader from "./components/Loader";

const Main = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);

  useEffect(() => {
    if (!isLoggedIn) {
      if (localStorage.getItem("token") !== null) {
        dispatch(changeIsLoggedIn(true));
      }
    }
  }, [dispatch, isLoggedIn]);

  const activeCasino = useSelector(activeCasinoSelector);

  return (
    <>
      {activeCasino == 0 ? (
        <div style={{ height: "100vh" }}>
          <Loader></Loader>
        </div>
      ) : (
        <Content style={{ minHeight: "calc(100vh - 96px)" }}>
          <Router />
        </Content>
      )}
    </>
  );
};

export default Main;
