import { Button, PageHeader } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { resetTournamentState } from "../../../../redux/slices/tournaments/tournamentsSlice";
import {
  failedToCreateTournamentMessageSelector,
  failedToCreateTournamentSelector,
  tournamentsAllDataEntered,
  tournamentsCreateIsEdit,
  tournamentsCreateSubmitting,
  tournamentsIsTournamentCreated,
} from "../../../../redux/slices/tournaments/tournamentsSelector";
import "react-toastify/dist/ReactToastify.css";

import { createEditTournament } from "../../../../redux/slices/tournaments/extra-reducers/createEditTournament";
import { ToastContainer, toast } from "react-toastify";

const Header = () => {
  const isEdit = useSelector(tournamentsCreateIsEdit);

  const allDataEntered = useSelector(tournamentsAllDataEntered);
  const failedToCreateTournament = useSelector(
    failedToCreateTournamentSelector
  );

  const failedToCreateTournamentMessage = useSelector(
    failedToCreateTournamentMessageSelector
  );

  const submitting = useSelector(tournamentsCreateSubmitting);
  const tournamentCreated = useSelector(tournamentsIsTournamentCreated);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const hanleGoBack = () => history.goBack();

  const regex = /\/(\d+)\/round\/(\d+)/;
  const matches = location.pathname.match(regex);

  // Extracted numbers
  const id = matches && matches[1];
  const round_id = matches && matches[2];

  if (tournamentCreated) {
    dispatch(resetTournamentState());
    history.push("/tournaments");
  }

  const notify = () => {
    return toast(failedToCreateTournamentMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  useEffect(() => {
    if (failedToCreateTournamentMessage) {
      notify();
    } else return;
  }, [failedToCreateTournamentMessage]);

  return (
    <PageHeader
      ghost={false}
      onBack={hanleGoBack}
      title={isEdit ? "Edit tournament" : "Create tournament"}
      extra={
        <Button
          disabled={submitting || !allDataEntered || failedToCreateTournament}
          loading={submitting}
          type="primary"
          htmlType="submit"
          onClick={() => {
            setTimeout(() => {
              dispatch(createEditTournament({ id, round_id }));
            }, 1);
          }}
        >
          {isEdit ? "Edit" : "Create"}
        </Button>
      }
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </PageHeader>
  );
};

export default Header;
