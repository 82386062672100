import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getFreeGamesReducer } from "./extra-reducers/getFreeGamesReducer";
import { getSingleFreeGamesReducer } from "./extra-reducers/getSingleFreeGamesReducer";
import { getSingleFreeGamesDetailsReducer } from "./extra-reducers/getSingleFreeGamesDetailsReducer";
import { getFreeGamesWithoutPaginationReducer } from "./extra-reducers/getFreeGamesWithoutPagination";
import { createFreeGamesReducer } from "./extra-reducers/createFreeGamesReducer";

const initialState = {
  dataTable: [],
  create: {
    freeGamesIsEdit: null,
    freeGamesCreateName: "",
    freeGamesCreateCode: "",
    freeGamesCreateDescription: "",
    freeGamesCreateDateFrom: moment()
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss"),
    freeGamesCreateDateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    freeGamesCreateType: 0,
    freeGamesCreateSpins: 0,
    freeGamesCreateTime: 0,
    freeGamesCreateWins: null,
    freeGamesCreateGameProvider: null,
    freeGamesCreateGame: null,
    freeGamesCreateBetType: 0,
    freeGamesCreateLines: null,
    freeGamesCreateCoinSize: null,
    freeGamesCreateCoinCount: null,
    freeGamesCreateBet: 0,
    freeGamesFreeSpinsAvailable: 0,
    freeGamesTypeDisabled: 0,
    freeGamesMultipleGames: null,
    freeGamesArray: [],
  },
  filter: {
    state: null,
    dateFrom: moment("1900-01-01 00:00").startOf("day").format("YYYY-MM-DD"),
    dateTo: moment("2100-01-01 00:00").endOf("day").format("YYYY-MM-DD"),
    provider: null,
    search: "",
  },
  pagination: {
    total: null,
    page: 1,
    lastPage: null,
    perPage: 10,
  },
  ui: {
    loadingDataTable: false,
    freeGamesCreated: false,
  },
  details: {
    searchDetails: "",
    data: [],
    loading: false,
  },
  freeGamesDataWithoutPagination: {
    data: [],
    loading: false,
  },
};

export const freeGamesSlice = createSlice({
  name: "freeGamesSlice",
  initialState,
  reducers: {
    changeFreeGamesFreeSpinsAvailable: (state, action) => {
      state.create.freeGamesFreeSpinsAvailable = action.payload;
    },
    changeFreeGamesTypeDisabled: (state, action) => {
      state.create.freeGamesTypeDisabled = action.payload;
    },
    changeFreeGamesDetailSearch: (state, action) => {
      state.details.searchDetails = action.payload;
    },
    changeFreeGamesType: (state, action) => {
      state.create.freeGamesCreateType = action.payload;
    },
    changeFreeGamesPaginationPage: (state, action) => {
      state.pagination.page = action.payload;
    },

    changeFreeGamesMultipleGames: (state, action) => {
      state.freeGamesMultipleGames = action.payload;
    },

    changeFreeGamesCreateName: (state, action) => {
      state.create.freeGamesCreateName = action.payload;
    },
    changeFreeGamesCreateCode: (state, action) => {
      state.create.freeGamesCreateCode = action.payload;
    },
    changeFreeGamesDescription: (state, action) => {
      state.create.freeGamesCreateDescription = action.payload;
    },
    changeFreeGamesIsEdit: (state, action) => {
      state.create.freeGamesIsEdit = action.payload;
    },
    changeFreeGamesCreateDateFrom: (state, action) => {
      state.create.freeGamesCreateDateFrom =
        action.payload.slice(0, 10) + " 00:00:00";
    },
    changeFreeGamesCreateDateTo: (state, action) => {
      state.create.freeGamesCreateDateTo =
        action.payload.slice(0, 10) + " 23:59:59";
    },
    changeFreeGamesCreateSpins: (state, action) => {
      state.create.freeGamesCreateSpins = action.payload;
    },
    changeFreeGamesTime: (state, action) => {
      state.create.freeGamesCreateTime = action.payload;
    },
    changeFreeGamesCreateWins: (state, action) => {
      state.create.freeGamesCreateWins = action.payload;
    },
    // changeFreeGamesCreateBudgetSpentAction: (state, action) => {
    //   state.create.freeGamesCreateBudgetSpentAction = action.payload;
    // },
    // changeFreeGamesCreateBudget: (state, action) => {
    //   state.create.freeGamesCreateBudget = action.payload;
    // },
    changeFreeGamesCreateGameProvider: (state, action) => {
      state.create.freeGamesCreateGameProvider = action.payload;
    },
    changeFreeGamesCreateGame: (state, action) => {
      state.create.freeGamesCreateGame = action.payload;
      if (action.payload === null) {
        state.create.freeGamesCreateLines = 0;
        state.create.freeGamesCreateCoinSize = 0;
        state.create.freeGamesCreateCoinCount = 0;
        state.create.freeGamesCreateBet = 0;
        state.create.freeGamesCreateBetType = 0;
      }
    },
    changeFreeGamesCreateBetType: (state, action) => {
      state.create.freeGamesCreateBetType = action.payload;
    },
    changeFreeGamesCreateLines: (state, action) => {
      state.create.freeGamesCreateLines = action.payload;
    },
    changeFreeGamesCreateCoinSize: (state, action) => {
      state.create.freeGamesCreateCoinSize = action.payload;
    },
    changeFreeGamesCreateCoinCount: (state, action) => {
      state.create.freeGamesCreateCoinCount = action.payload;
    },
    changeFreeGamesCreateBet: (state, action) => {
      state.create.freeGamesCreateBet = action.payload;
    },
    changeFreeGamesArray: (state, action) => {
      state.create.freeGamesArray = action.payload;
    },
    addFreeGamesArray: (state, action) => {
      state.create.freeGamesArray = [
        ...state.create.freeGamesArray,
        action.payload,
      ];
    },
    removeFreeGamesArray: (state, action) => {
      state.create.freeGamesArray =
        // eslint-disable-next-line array-callback-return
        state.create.freeGamesArray.filter((a, i) => {
          if (a.game_id !== action.payload) {
            return a;
          }
        });
    },
    changeFreeGamesFilterState: (state, action) => {
      state.filter.state = action.payload;
    },
    changeFreeGamesFilterDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload;
    },
    changeFreeGamesFilterDateTo: (state, action) => {
      state.filter.dateTo = action.payload;
    },
    changeFreeGamesFilterProvider: (state, action) => {
      state.filter.provider = action.payload;
    },
    changeFreeGamesFilterSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    changeFreeGamesLoadingDataTable: (state, action) => {
      state.ui.loadingDataTable = action.payload;
    },
    changeFreeGamesCreated: (state, action) => {
      state.ui.freeGamesCreated = action.payload;
    },
    resetFreeGamesSlice: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getFreeGamesReducer.pending, (state) => {
      state.ui.loadingDataTable = true;
    });
    builder.addCase(getFreeGamesReducer.fulfilled, (state, action) => {
      state.ui.loadingDataTable = false;
      state.dataTable = action.payload.data;

      // pagination
      state.pagination.page = action.payload.current_page;
      state.pagination.lastPage = action.payload.last_page;
      state.pagination.total = action.payload.total;
    });
    builder.addCase(getFreeGamesReducer.rejected, (state) => {
      state.ui.loadingDataTable = false;
    });
    /////////////////
    builder.addCase(getSingleFreeGamesReducer.pending, (state) => {
      state.ui.loadingDataTable = true;
    });
    builder.addCase(getSingleFreeGamesReducer.fulfilled, (state, action) => {
      state.ui.loadingDataTable = false;

      const {
        type,
        account_type,
        code,
        description,
        end_at,
        games,
        name,
        provider_id,
        spins,
        start_at,
      } = action.payload;

      state.create = {
        freeGamesIsEdit: true,
        freeGamesCreateName: name,
        freeGamesCreateCode: code,
        freeGamesCreateDescription: description,
        freeGamesCreateDateFrom: moment(start_at).format("YYYY-MM-DD HH:mm:ss"),
        freeGamesCreateDateTo: moment(end_at).format("YYYY-MM-DD HH:mm:ss"),
        freeGamesCreateSpins: spins,
        freeGamesCreateWins: account_type,
        freeGamesCreateGameProvider: provider_id,
        freeGamesCreateGame: null,
        freeGamesCreateBetType: type,
        freeGamesCreateLines: null,
        freeGamesCreateCoinSize: null,
        freeGamesCreateCoinCount: null,
        freeGamesCreateBet: 0,
        freeGamesProviderName:
          action.payload.games[0] && action.payload.games[0].provider
            ? action.payload.games[0].provider.name
            : "",
        freeGamesProviderImage:
          action.payload.games[0] && action.payload.games[0].provider
            ? action.payload.games[0].provider.photo
            : "",
        freeGamesArray: [
          ...games.map((x) => {
            return {
              game_id: x.identifier,
              Name: x.name,
              photo: x.photo,
              ...x.pivot,
            };
          }),
        ],
      };
    });
    builder.addCase(getSingleFreeGamesReducer.rejected, (state) => {
      state.ui.loadingDataTable = false;
    });
    builder.addCase(getSingleFreeGamesDetailsReducer.pending, (state) => {
      state.details.loading = true;
    });
    builder.addCase(
      getSingleFreeGamesDetailsReducer.fulfilled,
      (state, action) => {
        state.details.loading = false;
        state.details.data = action.payload;
      }
    );
    builder.addCase(getSingleFreeGamesDetailsReducer.rejected, (state) => {
      state.ui.loading = false;
    });
    builder.addCase(getFreeGamesWithoutPaginationReducer.pending, (state) => {
      state.freeGamesDataWithoutPagination.loading = true;
    });
    builder.addCase(
      getFreeGamesWithoutPaginationReducer.fulfilled,
      (state, action) => {
        state.freeGamesDataWithoutPagination.loading = false;
        state.freeGamesDataWithoutPagination.data = Object.values(
          action.payload
        );
      }
    );
    builder.addCase(getFreeGamesWithoutPaginationReducer.rejected, (state) => {
      state.freeGamesDataWithoutPagination.loading = false;
    });
    builder.addCase(createFreeGamesReducer.fulfilled, (state) => {
      state.ui.freeGamesCreated = true;
    });
  },
});

export const {
  changeFreeGamesFreeSpinsAvailable,
  changeFreeGamesTypeDisabled,
  changeFreeGamesDetailSearch,
  changeFreeGamesMultipleGames,
  changeFreeGamesPaginationPage,
  changeFreeGamesTime,
  changeFreeGamesType,
  changeFreeGamesCreateName,
  changeFreeGamesCreateCode,
  changeFreeGamesDescription,
  changeFreeGamesCreateDateFrom,
  changeFreeGamesCreateDateTo,
  changeFreeGamesCreateSpins,
  changeFreeGamesCreateWins,
  changeFreeGamesCreateBudgetSpentAction,
  changeFreeGamesCreateBudget,
  changeFreeGamesCreateGameProvider,
  changeFreeGamesCreateGame,
  changeFreeGamesCreateBetType,
  changeFreeGamesCreateLines,
  changeFreeGamesCreateCoinSize,
  changeFreeGamesCreateCoinCount,
  changeFreeGamesCreateBet,
  changeFreeGamesFilterState,
  changeFreeGamesFilterDateFrom,
  changeFreeGamesFilterDateTo,
  changeFreeGamesFilterProvider,
  changeFreeGamesFilterSearch,
  changeFreeGamesArray,
  addFreeGamesArray,
  removeFreeGamesArray,
  changeFreeGamesLoadingDataTable,
  changeFreeGamesCreated,
  resetFreeGamesSlice,
  changeFreeGamesIsEdit,
} = freeGamesSlice.actions;

export default freeGamesSlice.reducer;
