import { Divider } from "antd";
import React, { useEffect } from "react";
import HitValueCalculation from "./HitValueCalculation";
import MinimalValue from "./MinimalValue";
import "./Achievement.css";
import ActivitiesAfterActivation from "./activities-after-bonus/ActivitiesAfterActivation";
import { useDispatch } from "react-redux";
import { getBonusesDataReducers } from "../../../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";
import { useSelector } from "react-redux";
import { bonusesTableDataSelector, callFromSelector } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeCallFrom } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const Achievement = () => {
  const dispatch = useDispatch();

  const x = useSelector(bonusesTableDataSelector);
  const calledFrom = useSelector(callFromSelector)

  useEffect(() => {
    // if (x[0] === null) {
      if(calledFrom !== 'manual' || x[0] === null){
        dispatch(changeCallFrom('manual'))
        dispatch(
          getBonusesDataReducers({
            callFrom: 'manual',
            perPage: 1000,
            activationMode: 1,
          })
        );
      }
    // }
  }, [x]);

  return (
    <div className="bonuses_achievement_container">
      <div className="bonuses_achievement_section">
        <Divider> Activation info</Divider>
        <div className="achievement_general_info">
          <HitValueCalculation />
          <MinimalValue />
        </div>
      </div>
      <div className="bonuses_achievement_section">
        <Divider>Activities after bonus achievement</Divider>
        <ActivitiesAfterActivation />
      </div>
    </div>
  );
};

export default Achievement;
