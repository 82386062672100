import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFreeGamesReducer } from "../../../../../redux/slices/free-games/extra-reducers/createFreeGamesReducer";
import {
  freeGamesCreateCodeSelector,
  freeGamesCreateDescriptionSelector,
  freeGamesCreateFreeGamesArray,
  freeGamesCreateNameSelector,
  freeGamesCreateSpinsSelector,
  freeGamesCreateWinsSelector,
  freeGamesIsEditSelector,
} from "../../../../../redux/slices/free-games/freeGamesSelector";

const FreeGamesSaveButton = ({ id }) => {
  const dispatch = useDispatch();

  const freeGameName = useSelector(freeGamesCreateNameSelector);
  const freeGameSpins = useSelector(freeGamesCreateSpinsSelector);
  const freeGamesAccount = useSelector(freeGamesCreateWinsSelector);
  const freeGamesCode = useSelector(freeGamesCreateCodeSelector);
  const freeGamesDescription = useSelector(freeGamesCreateDescriptionSelector);

  const freeGames = useSelector(freeGamesCreateFreeGamesArray);

  const isEdit = useSelector(freeGamesIsEditSelector);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        disabled={
          freeGameName.length > 0 &&
          freeGameSpins > 0 &&
          freeGamesAccount &&
          freeGamesCode.length > 0 &&
          freeGamesDescription.length > 0 &&
          freeGames.length > 0
            ? false
            : true
        }
        onClick={() => {
          var baseUrl = window.location.href;
          var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);
          dispatch(createFreeGamesReducer(id));
        }}
      >
        {isEdit ? "Edit" : "Save"}
      </Button>
    </div>
  );
};

export default FreeGamesSaveButton;
