import React from "react";
import { useSelector } from "react-redux";
import { bonusesCreateRulesGameGroupsRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import SingleGameGroupRule from "./SingleGameGroupRule";

const ListGameGroupsRules = () => {
  const gameGroupsRules = useSelector(
    bonusesCreateRulesGameGroupsRulesSelector
  );

  return (
    <div
      className="rules_list_all_providers"
      style={{ marginBottom: gameGroupsRules?.length === 0 ? "0px" : "32px" }}
    >
      {gameGroupsRules &&
        gameGroupsRules.map((rule, i) => {
          let id;
          let percent;

          if (!rule.ruleable) {
            id = rule.id;
            percent = rule.percent;
          }
          if (rule.ruleable) {
            id = rule.ruleable.id;
            percent = rule.percentage;
          }

          return (
            <SingleGameGroupRule
              id={id}
              percent={percent}
              i={i}
              key={rule.id}
            />
          );
        })}
    </div>
  );
};

export default ListGameGroupsRules;
