import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  changeGamesIsEdit,
  resetGamesSlice,
} from "../../../../redux/slices/games/gamesSlice";
import GamesAntForm from "./GamesAntForm";
import GamesCreateEditHeader from "./GamesCreateEditHeader";
import "./GamesCreateEdit.css";
import { getSingleGameReducer } from "../../../../redux/slices/games/extra-reducers/getSingleGameReducer";
import { gamesButtonWaitingResponseSelector } from "../../../../redux/slices/games/gamesSelectors";
import Loader from "../../../../layouts/components/Loader";

const GamesCreateEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector(gamesButtonWaitingResponseSelector);
  useEffect(() => {
    if (location.pathname.includes("edit")) {
      var baseUrl = window.location.href;
      var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);
      dispatch(getSingleGameReducer(id));
      dispatch(changeGamesIsEdit(true));
    } else {
      dispatch(changeGamesIsEdit(false));
    }

    return () => {
      dispatch(resetGamesSlice());
    };
  }, [dispatch, location.pathname]);

  return (
    <div>
      <GamesCreateEditHeader />
      <div className="games_create_edit">
        {loading ? <Loader /> : <GamesAntForm />}
      </div>
    </div>
  );
};

export default GamesCreateEdit;
