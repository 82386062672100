import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bonusesCreateActivationActivationMode,
  bonusesCreateActivationActivityNeeded,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivityNeeded } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const automaticOptions = [
  {
    value: 0,
    label: "Next entry to casino",
  },
  // {
  //   value: 1,
  //   label: "Select from offer",
  // },
  {
    value: 2,
    label: "First entry to casino",
  },
];

const manualOptions = [
  {
    value: 0,
    label: "Next entry to casino",
  },
  // {
  //   value: 1,
  //   label: "Select from offer",
  // },
  // {
  //   value: 2,
  //   label: "First entry to casino",
  // },
];

const ActivityNeeded = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateActivationActivityNeeded);
  const visible = useSelector(bonusesCreateActivationActivationMode) !== 2;

  const activationMode = useSelector(bonusesCreateActivationActivationMode);

  const [options, setOptions] = useState(automaticOptions);
  const [selectValue, setSelectValue] = useState(options[0].label);

  useEffect(() => {
    if (activationMode === 0) {
      setOptions(automaticOptions);
      if (value === 0) {
        setSelectValue(automaticOptions[0].label);
      }
      if (value === 2) {
        setSelectValue(automaticOptions[1].label);
      }
    }

    if (activationMode === 1) {
      setOptions(manualOptions);
      setSelectValue(manualOptions[0].label);
    }
  }, [activationMode, value]);

  return (
    <>
      {visible && (
        <Form.Item
          label="Activity needed for starting"
          name="activity_for_starting"
          initialValue={selectValue}
          rules={[{ required: true, message: "This field is required" }]}
        >
          {
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              options={options}
              value={selectValue}
              onChange={(e, i) => {
                setSelectValue(i.label);
                dispatch(changeBonusesCreateActivityNeeded(e));
              }}
            ></Select>
          }
          <div className="hidden_div"></div>{" "}
          {/* !!! DON'T TOUCH --> re-renderer */}
        </Form.Item>
      )}
    </>
  );
};

export default ActivityNeeded;
