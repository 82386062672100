import { Button, Steps } from "antd";
import "./TournamentsSteps.css";
import { useDispatch, useSelector } from "react-redux";
import {
  BookOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  SettingOutlined,
  StarOutlined,
} from "@ant-design/icons";
import {
  tournamentRulesAllDataEntered,
  tournamentsAchievementAllDataEntered,
  tournamentsActivationAllDataEntered,
  tournamentsCreateStep,
  tournamentsGeneralAllDataEntered,
} from "../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsStep } from "../../../../../redux/slices/tournaments/tournamentsSlice";
const { Step } = Steps;

const TournamentsSteps = () => {
  const currentStep = useSelector(tournamentsCreateStep);
  const dispatch = useDispatch();
  const allDataGeneral = useSelector(tournamentsGeneralAllDataEntered);
  const allDataActivation = useSelector(tournamentsActivationAllDataEntered);
  const allDataRules = useSelector(tournamentRulesAllDataEntered);
  const allDataAchievement = useSelector(tournamentsAchievementAllDataEntered);

  return (
    <>
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={(a) => {
          dispatch(changeTournamentsStep(a));
        }}
        className="bonus_create_steps"
      >
        <Step
          title="General"
          status={
            allDataGeneral === null
              ? "wait"
              : allDataGeneral === false
              ? "error"
              : "finish"
          }
          icon={<SettingOutlined />}
        />
        <Step
          title="Activation"
          icon={<ScheduleOutlined />}
          status={
            allDataActivation === null
              ? "wait"
              : allDataActivation === false
              ? "error"
              : "finish"
          }
        />
        <Step
          title="Rules"
          icon={<FileProtectOutlined />}
          status={
            allDataRules === null
              ? "wait"
              : allDataRules === false
              ? "error"
              : "finish"
          }
        />
        <Step
          title="Achievement"
          icon={<StarOutlined />}
          status={
            allDataAchievement === null
              ? "wait"
              : allDataAchievement === false
              ? "error"
              : "finish"
          }
        />
        <Step title="Review" icon={<BookOutlined />} />
      </Steps>
      <div
        style={{
          padding: "16px",
          paddingBottom: 0,
          width: "100%",
          display: "flex",
          justifyContent: currentStep === 0 ? "flex-end" : "space-between",
        }}
      >
        {currentStep !== 0 && (
          <Button
            type="primary"
            onClick={() => {
              dispatch(changeTournamentsStep(currentStep - 1));
            }}
          >
            Back
          </Button>
        )}
        {currentStep !== 4 && (
          <Button
            type="primary"
            onClick={() => {
              dispatch(changeTournamentsStep(currentStep + 1));
            }}
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default TournamentsSteps;
