import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import React from "react";
import { handleLogout } from "../../../components/ProfileMenu";
import "./UserIcon.css";

const items = [
  {
    key: "logout",
    label: "Logout",
  },
];

const UserIcon = () => {
  const onClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    }
  };
  return (
    <Dropdown
      menu={{
        items,
        onClick,
      }}
      className="user_icon"
    >
      <Avatar
        size={32}
        src={<UserOutlined />}
        style={{ background: "#fff", color: "rgb(2,21,41)", minWidth: "32px" }}
      />
    </Dropdown>
  );
};

export default UserIcon;
