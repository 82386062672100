import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../layouts/components/Loader";
import { getGameGroupsReducer } from "../../../redux/slices/game-groups/extra-reducers/getGameGroupsReducer";
import {
  gameGroupsListSelector,
  loadingGameGroupsListSelector,
} from "../../../redux/slices/game-groups/gameGroupSelectors";
import { changeActiveGameGroup } from "../../../redux/slices/game-groups/gameGroupSlice";
import { gameGroupServices } from "../../../services/gameGroupServices";
import { activeCasinoSelector } from "../../../redux/slices/user/userSelectors";

const GameGroupsList = () => {
  const gameGroupList = useSelector(gameGroupsListSelector);
  const active_casino = useSelector(activeCasinoSelector);
  const loadingGameGroupList = useSelector(loadingGameGroupsListSelector);

  const [newNameGroupName, setNewNameGroupName] = useState();

  const dispatch = useDispatch();

  return (
    <div className="game_group_list_container">
      <div
        className="game_group_list"
        style={{
          justifyContent: loadingGameGroupList ? "center" : "flex-start",
        }}
      >
        {loadingGameGroupList ? (
          <Loader />
        ) : (
          <>
            {gameGroupList.map((group, i) => {
              return (
                <div
                  className="game_group_list_row"
                  onClick={() => {
                    dispatch(changeActiveGameGroup(i));
                  }}
                  key={group.id}
                >
                  <span>{group.name}</span>
                  <Popconfirm
                    icon={<DeleteOutlined />}
                    title="Are you sure you want to delete this group?"
                    okText="Delete"
                    cancelText="Cancel"
                    onConfirm={(e) => {
                      e.stopPropagation();
                      gameGroupServices.deleteGameGroup(group.id).then(() => {
                        dispatch(changeActiveGameGroup(null));
                        dispatch(getGameGroupsReducer());
                      });
                    }}
                    okButtonProps={{ shape: "round" }}
                    cancelButtonProps={{ shape: "round" }}
                    showArrow={false}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      danger={true}
                      type="primary"
                      style={{ marginRight: "16px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="game_group_add_group">
        <Input
          placeholder="Create new group"
          value={newNameGroupName}
          onChange={(e) => {
            setNewNameGroupName(e.target.value);
          }}
        />
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          size="middle"
          onClick={() => {
            if (!newNameGroupName) return;
            setNewNameGroupName("");
            gameGroupServices
              .createGameGroup({
                name: newNameGroupName,
                casino_id: active_casino,
              })
              .then((res) => {
                dispatch(changeActiveGameGroup(null));
                dispatch(getGameGroupsReducer());
              });
          }}
        />
      </div>
    </div>
  );
};

export default GameGroupsList;
