import axios from "axios";

const defaultRoute = "casinos";

const getCasinos = () => {
  const result = axios(`/${defaultRoute}`, {});
  return result;
};
const casinoServices = { getCasinos };

export default casinoServices;
