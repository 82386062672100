import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import stakesServices from "../../../../services/stakesServices";

export const getStakesDataReducers = createAsyncThunk(
  "getStakesDataReducers",
  async (q, thunkApi) => {
    const stakesState = thunkApi.getState().stakes;
    const casino_id = thunkApi.getState().user.activeCasino;
    const params = {
      date_from: moment(stakesState.stakesDateFrom).utc().toISOString(),
      date_to: moment(stakesState.stakesDateTo).utc().toISOString(),
      casino_id: casino_id,
      page: stakesState.pageNumber,
      per_page: stakesState.pageSize,
    };

    if (stakesState.stakesSortBy) {
      params.sort_column = stakesState.stakesSortBy;
    }

    if (stakesState.stakesSortDirection) {
      params.sort_direction = stakesState.stakesSortDirection;
    }

    if (stakesState.stakesPlayerId) {
      params.user_uuid = stakesState.stakesPlayerId;
    }

    if (stakesState.stakesGames !== 0) {
      params.game_id = stakesState.stakesGames;
    }
    if (stakesState.stakesTransactionId) {
      params.round_id = stakesState.stakesTransactionId;
    }
    if (stakesState.stakesProvider) {
      params.provider_id = stakesState.stakesProvider;
    }
    try {
      const res = await stakesServices.getStakes(params);
      if (res.status === 200 && stakesState) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
