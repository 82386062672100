import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationPlayers } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationPlayers } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const Players = () => {
  const players = useSelector(bonusesCreateActivationPlayers);
  const initialData = players.length > 0 ? players.join("\n") : "";

  const [visible, setVisible] = useState();
  const [rawData, setRawData] = useState(initialData);

  const dispatch = useDispatch();

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        Add Players
      </Button>
      <Modal
        title="Add players"
        centered
        open={visible}
        onOk={() => {
          const data = rawData.split("\n");
          dispatch(changeBonusesCreateActivationPlayers(data));
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
        width={"min(90vw,1200px)"}
        okText="Add"
      >
        <div
          style={{ background: "#D9EDF7", color: "#6080B8", padding: "8px" }}
        >
          To add players into bonus place every PlayerID on separate line and
          click Add.
        </div>

        <TextArea
          style={{ marginTop: "16px", height: "min(90vh, 500px)" }}
          datatype="number"
          he
          value={rawData}
          onChange={(e) => {
            setRawData(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default Players;
