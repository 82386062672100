import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContentEdit } from "../../../../services/lobbyServices";
import {
  setContentEditAreaTypes,
  setContentArea,
  setCurrentContentId,
} from "../lobbySlice";
const _ = require("lodash");

export const getContentEditReducer = createAsyncThunk(
  "getContentEditReducer",
  async (id, thunkAPI) => {
    const { lobby } = thunkAPI.getState();
    const casino_id = thunkAPI.getState().user.activeCasino;
    thunkAPI.dispatch(setCurrentContentId(id));

    try {
      const res = await getContentEdit(id, casino_id);

      if (res.status !== 200 && !lobby) throw new Error();

      const deepCopyData = _.cloneDeep(res.data.data);
      const deepCopyDataTypes = _.cloneDeep(res.data.type_data);

      const mapedDataTypes = deepCopyDataTypes.map((item, index) => {
        if (item.type === 0) {
          deepCopyDataTypes[index].id = item.id;
          deepCopyDataTypes[index].type_id = item.type_id;
          deepCopyDataTypes[index].type = item.type;
          deepCopyDataTypes[index].size = item.size;
          deepCopyDataTypes[index].visibility = item.visibility;
          deepCopyDataTypes[index].area_type_name = item.data.name;
          deepCopyDataTypes[index].area_type_image = item.data.photo;
          deepCopyDataTypes[index].area_type_category =
            item.data.game_categories[0].lobby;
          deepCopyDataTypes[index].area_type_provider_name =
            item.data.provider.name;
          deepCopyDataTypes[index].area_type_provider_image =
            item.data.provider.photo;

          return item;
        }
        if (item.type === 1) {
          deepCopyDataTypes[index].id = item.id;
          deepCopyDataTypes[index].type_id = item.type_id;
          deepCopyDataTypes[index].type = item.type;
          deepCopyDataTypes[index].size = item.size;
          deepCopyDataTypes[index].widget_id = item.id;
          deepCopyDataTypes[index].widget_title = item.data.widget_title;
          deepCopyDataTypes[index].widget_url = item.data.widget_url;
          deepCopyDataTypes[index].order = item.order;
          deepCopyDataTypes[index].zone = item.data.zone;
          deepCopyDataTypes[index].title = item.data.title;
          return item;
        }

        return [...deepCopyDataTypes];
      });

      thunkAPI.dispatch(setContentArea(deepCopyData));
      thunkAPI.dispatch(setContentEditAreaTypes(mapedDataTypes));
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
