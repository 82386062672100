import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesCreateDescriptionSelector } from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesDescription } from "../../../../../redux/slices/free-games/freeGamesSlice";

const { TextArea } = Input;

const FreeGamesDescription = () => {
  const dispatch = useDispatch();
  const value = useSelector(freeGamesCreateDescriptionSelector);
  return (
    <Form.Item label="Description">
      <TextArea
        columns={4}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeFreeGamesDescription(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default FreeGamesDescription;
