import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesCreateWinsSelector } from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesCreateWins } from "../../../../../redux/slices/free-games/freeGamesSlice";

const FreeGamesWinsSelect = () => {
  const dispatch = useDispatch();
  const value = useSelector(freeGamesCreateWinsSelector);

  return (
    <Form.Item label="Account">
      <Select
        value={value}
        defaultValue={value}
        dropdownMatchSelectWidth={false}
        onChange={(x) => {
          dispatch(changeFreeGamesCreateWins(x));
        }}
        placeholder="- Account Type -"
        options={[
          {
            value: "INTERNET",
            label: "Internet",
          },
          {
            //TODO: Check value for thi option if "LB"
            value: "FB",
            label: "Free Bet",
          },
        ]}
      />
    </Form.Item>
  );
};

export default FreeGamesWinsSelect;
