export const stakeHeader = [
  {
    label: "ID",
    key: "id",
  },
  {
    label: "Player ID",
    key: "user_uuid",
  },
  {
    label: "Provider",
    key: "provider_name",
  },
  {
    label: "Game",
    key: "game_name",
  },
  {
    label: "Wallet In",
    key: "in",

    align: "right",
  },
  {
    label: "Wallet Out",
    key: "out",

    align: "right",
  },
  {
    label: "Turnover",
    key: "turnover",

    align: "right",
  },
  {
    label: "Timestamp",
    key: "timestamp",
  },
];
