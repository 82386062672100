import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  freeGamesCreateSpinsSelector,
  freeGamesCreateTypeSelector,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesCreateSpins } from "../../../../../redux/slices/free-games/freeGamesSlice";

const FreeGamesSpins = () => {
  const dispatch = useDispatch();
  const value = useSelector(freeGamesCreateSpinsSelector);
  const type = useSelector(freeGamesCreateTypeSelector);

  return (
    <Form.Item label={`${type === 0 ? "Spins:" : "Seconds:"}`}>
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeFreeGamesCreateSpins(parseInt(e.target.value)));
        }}
        type="number"
      />
    </Form.Item>
  );
};

export default FreeGamesSpins;
