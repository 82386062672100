import { Input, Select } from "antd";
import { cloneDeep } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateAchievementActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesAchievementActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";

const LockAmountDisplay = ({ i }) => {
  const data = useSelector(bonusesCreateAchievementActivitiesAfterBonus);
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Select
        value={data[i]?.data?.account}
        onChange={(x) => {
          const newData = cloneDeep(data);
          newData[i].data.account = x;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        options={[
          { label: "Financial", value: 0 },
          { label: "Loyalty", value: 1 },
        ]}
      />
      <Input
        placeholder="Fixed or min amount"
        value={data[i]?.data?.rewardFixed}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.rewardFixed = e.target.value;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        type="number"
      />
      <Input
        placeholder="Percentage of hit value"
        value={data[i]?.data?.percentage}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.percentage = e.target.value;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        type="number"
      />
      <Input
        placeholder="Max amount"
        value={data[i]?.data?.max}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.max = e.target.value;
          dispatch(changeBonusesAchievementActivitiesAfterBonus(newData));
        }}
        type="number"
      />
    </div>
  );
};

export default LockAmountDisplay;
