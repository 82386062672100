import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { getFreeGamesReducer } from "../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";

const FreeGamesFilter = () => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(getFreeGamesReducer());
      }}
      type="primary"
      shape="round"
      icon={<FilterOutlined />}
      size={"middle"}
    >
      Filter
    </Button>
  );
};

export default FreeGamesFilter;
