import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContentById } from "../../../../services/lobbyServices";
import { setContentArea } from "../lobbySlice";

export const getLobbyContentReducers = createAsyncThunk(
  "getLobbyContentReducers",
  async (id, thunkAPI) => {
    try {
      const { lobby } = await thunkAPI.getState();
      const casino_id = thunkAPI.getState().user.activeCasino;
      const { activeCategoryId } = await lobby;

      const res = await getContentById(id, casino_id);

      if (res.status !== 200 && !lobby) throw new Error();

      thunkAPI.dispatch(setContentArea({ page_id: activeCategoryId }));

      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
