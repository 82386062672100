export const gameGroupsListSelector = (state) => {
  return state.gameGroup.gameGroupsList;
};
export const activeGameGroupSelector = (state) => {
  return state.gameGroup.activeGameGroup;
};
export const changesMadeSelector = (state) => {
  return state.gameGroup.changesMade;
};

export const gameGroupEditingNameSelector = (state) => {
  return state.gameGroup.gameGroupEditingName;
};

export const gameGroupEditingGamesSelector = (state) => {
  return state.gameGroup.gameGroupEditingGames;
};

export const loadingGameGroupsListSelector = (state) => {
  return state.gameGroup.ui.loadingGameGroupsList;
};
