import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesIframeDemoUrlSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesIframeDemoUrl } from "../../../../../redux/slices/games/gamesSlice";

const GamesIframeDemoUrl = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesIframeDemoUrlSelector);
  return (
    <Form.Item label="IFrame Demo URL" name="iframe_demo_url">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeGamesIframeDemoUrl(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default GamesIframeDemoUrl;
