/* eslint-disable array-callback-return */
import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersReducer } from "../../../../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import {
  loadingProvidersListSelector,
  providersListSelector,
} from "../../../../../../../../redux/slices/providers/providersSelectors";
import { tournamentsProviderRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import {
  addTournamentsCreateRulesProviderRules,
  removeTournamentsCreateRulesProviderRules,
} from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const SelectProvider = () => {
  const providersList = useSelector(providersListSelector);
  const loadingProvidersList = useSelector(loadingProvidersListSelector);
  const providerRules = useSelector(tournamentsProviderRules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  useEffect(() => {
    if (providerRules.length === 0) {
      dispatch(
        addTournamentsCreateRulesProviderRules({
          id: null,
          percent: 100,
        })
      );
    }
  }, [dispatch]);

  return (
    <div className="calculate_by_providers_select">
      <Select
        value={null}
        dropdownMatchSelectWidth={false}
        loading={loadingProvidersList}
        onChange={(x) => {
          dispatch(
            addTournamentsCreateRulesProviderRules({
              id: x,
              percent: 100,
            })
          );
        }}
        placeholder="Select provider"
        style={{ width: "100%" }}
      >
        {providersList.map((item) => {
          let available = true;
          providerRules.forEach((x) => {
            if (x.id === item.id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      {/* <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="middle"
        onClick={() => {
          if (selectedProvider) {
           
            setSelectedProvider(null);
          }
        }}
      /> */}
    </div>
  );
};

export default SelectProvider;
