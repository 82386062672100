import { createAsyncThunk } from "@reduxjs/toolkit";
import flagsServices from "../../../../services/flagsServices";

export const getFlagsReducer = createAsyncThunk(
  "getFlagsReducer",
  async (q, thunkApi) => {
    try {
      const res = await flagsServices.getFlags();
      if (res.status === 200) {
        return res.data.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
