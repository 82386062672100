import { Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeBonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";
import { bonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { cloneDeep } from "lodash";
const RewardDisplay = ({ i }) => {
  const data = useSelector(bonusesCreateActivationActivitiesAfterBonus);
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Select
        placeholder="Select"
        value={data[i]?.data?.account}
        onChange={(x) => {
          // const newData = cloneDeep(data);
          // newData[i].data.account = x;
          // dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));

          dispatch(
            changeBonusesCreateActivationActivitiesAfterBonus({
              index: i,
              data: {
                account: x,
                freespins_id: x,
              },
            })
          );
        }}
        options={[
          { label: "Internet", value: 0 },
          { label: "Free Bet", value: 1 },
        ]}
      />
      <Input
        placeholder="Fixed or min amount"
        defaultValue={parseFloat(data[i]?.data?.rewardFixed)}
        onChange={(e) => {
          // const newData = cloneDeep(data);
          // newData[i].data.rewardFixed = e.target.value;
          // dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));

          dispatch(
            changeBonusesCreateActivationActivitiesAfterBonus({
              index: i,
              data: {
                rewardFixed: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
      {/* <Input
        placeholder="Percentage of hit value"
        value={data[i]?.data?.percentage}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.percentage = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
        type="number"
      /> */}
      {/* <Input
        placeholder="Max amount"
        value={data[i]?.data?.max}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.max = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
        type="number"
      /> */}
    </div>
  );
};

export default RewardDisplay;
