import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesListSelector } from "../../../../../../../redux/slices/games/gamesSelectors";
import { providersListSelector } from "../../../../../../../redux/slices/providers/providersSelectors";
import RowReview from "./RowReview";
import {
  tournamentRulesAllDataEntered,
  tournamentsCreateData,
} from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { getProvidersReducer } from "../../../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";

const valueForCalculation = {
  0: "Stake",
  1: "Win",
  2: "Net Loss",
  3: "Net Win",
  4: "Ticket Count",
  5: "Highest win / bet ",
};

const RulesReview = () => {
  const dispatch = useDispatch();
  const createData = useSelector(tournamentsCreateData);

  const providers = useSelector(providersListSelector);
  const games = useSelector(gamesListSelector);

  const rulesAllData = useSelector(tournamentRulesAllDataEntered);

  useEffect(() => {
    dispatch(getProvidersReducer);
  }, [dispatch]);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        Rules
        <span style={{ marginLeft: "8px" }}>
          {rulesAllData === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview
        name="Value for calculation"
        data={
          createData.rules.valueForCalculation !== null
            ? valueForCalculation[createData.rules.valueForCalculation]
            : null
        }
        required={true}
      />
      <RowReview
        name="Value filter from"
        data={
          createData.rules.valueFilterFrom &&
          parseFloat(createData.rules.valueFilterFrom).toFixed(2)
        }
      />
      <RowReview
        name="Value filter to"
        data={
          createData.rules.valueFilterTo &&
          parseFloat(createData.rules.valueFilterTo).toFixed(2)
        }
      />
      <RowReview
        name="Calculate By"
        data={
          createData.rules.calculateBy === 0
            ? "Providers"
            : createData.rules.calculateBy === 1
            ? "Games"
            : createData.rules.calculateBy === 2
            ? "Groups"
            : ""
        }
      />

      {createData.rules.gameRules.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Games:</div>
          {createData.rules.gameRules.map((gameRule) => {
            return (
              <div style={{ display: "flex", marginLeft: "12px", gap: "8px" }}>
                <div>
                  {games.map((g) => {
                    if (g.id === gameRule.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          -{" "}
                          <img
                            src={g.provider?.photo}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "100%",
                            }}
                          />
                          <div>{g.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{gameRule.percent}%</div>
              </div>
            );
          })}
        </div>
      )}

      {createData.rules.providerRules.length > 0 && (
        <div style={{ marginLeft: "4px" }}>
          <div>Providers :</div>
          {createData.rules.providerRules.map((providerRule) => {
            return (
              <div style={{ display: "flex", marginLeft: "12px", gap: "8px" }}>
                <div>
                  {providers.map((p) => {
                    if (p.id === providerRule.id) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          -{" "}
                          <img
                            src={p.photo}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "100%",
                            }}
                          />
                          <div>{p.name}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
                <div>{providerRule.percent}%</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RulesReview;
