import { createAsyncThunk } from "@reduxjs/toolkit";
import { gamesServices } from "../../../../services/gamesServices";

export const editGameReducer = createAsyncThunk(
  "editGameReducer",
  async (id, thunkApi) => {
    try {
      const casino_id = thunkApi.getState().user.activeCasino;
      const {
        name,
        provider,
        integration,
        iframeUrl,
        iframeDemoUrl,
        enabled,
        freeSpinsActive,
        usesWhitelist,
        gameCategories,
        flags,
        widgetWidth,
        image,
        ordinalNumber,
      } = thunkApi.getState().games.createEdit;
      const res = await gamesServices.editGame(
        {
          casino_id,
          name,
          provider_id: provider,
          integration_id: integration,
          i_frame_url: iframeUrl,
          i_frame_url_demo: iframeDemoUrl,
          enabled,
          freespins: freeSpinsActive,
          uses_whitelist: usesWhitelist,
          game_categories: gameCategories,
          flags,
          widget_width: widgetWidth,
          image,
          ordinal_number: ordinalNumber,
        },
        id
      );
      if (res.status === 200) {
        return true;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
