import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import BannerImagePreview from "../general/info/BannerImagePreview";
import RowReview from "./RowReview";
import {
  tournamentsCreateData,
  tournamentsGeneralAllDataEntered,
} from "../../../../../../../redux/slices/tournaments/tournamentsSelector";
import BackgroundImagePreview from "../general/info/BackgroundImagePreview";

const stateOptions = {
  0: "Created",
  1: "Active",
  2: "Finished",
  3: "Evaluated",
  4: "Waiting",
};

const typeOptions = {
  0: "Daily",
  1: "Weekly",
  2: "Monthly",
  3: "Custom",
};

const GeneralReview = () => {
  const createData = useSelector(tournamentsCreateData);
  const generalAllData = useSelector(tournamentsGeneralAllDataEntered);

  return (
    <div className="review_section">
      <Typography.Title level={2}>
        General
        <span style={{ marginLeft: "8px" }}>
          {generalAllData ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>
      <RowReview name="Name" data={createData.general.name} required={true} />
      <RowReview name="Identifier" data={createData.general.identifier} />
      <RowReview
        name="Recurring"
        data={createData.general.type ? "Yes" : "No"}
      />
      <RowReview name="Widget width" data={createData.general.widgetWidth} />
      <RowReview
        name="Date From"
        data={
          createData.general.dateFrom
            ? moment(createData.general.dateFrom).format("DD.MM.YYYY HH:mm:ss")
            : ""
        }
        required={true}
      />
      <RowReview
        name="Date To"
        data={
          createData.general.dateTo
            ? moment(createData.general.dateTo).format("DD.MM.YYYY HH:mm:ss")
            : ""
        }
        required={true}
      />

      <RowReview
        name="State"
        data={
          createData.general.state !== null
            ? stateOptions[createData.general.state]
            : null
        }
      />
      <RowReview name="Description" data={createData.general.description} />
      <RowReview name="Public title" data={createData.general.publicTitle} />
      <RowReview name="Short text" data={createData.general.shortText} />
      <RowReview name="Long text " data={createData.general.longText} />
      <RowReview
        name="Banner image URL"
        data={createData.general.bannerImageUrl}
      />
      <div style={{ maxWidth: "500px", marginLeft: "4px" }}>
        {" "}
        <BannerImagePreview />
      </div>
      <RowReview
        name="Banner image URL"
        data={createData.general.backgroundImageUrl}
      />
      <div style={{ maxWidth: "500px", marginLeft: "4px" }}>
        {" "}
        <BackgroundImagePreview />
      </div>
    </div>
  );
};

export default GeneralReview;
