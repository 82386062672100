import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateAchievementHitValueCalculation } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesAchievementHitValueCalculation } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const HitValueCalculation = () => {
  const value = useSelector(bonusesCreateAchievementHitValueCalculation);
  const dispatch = useDispatch();
  return (
    <Form.Item
      label="Hit value calculation"
      name="hit_value_calculation_type"
      initialValue={value}
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        value={value}
        defaultValue={value}
        disabled
        onChange={(x) => {
          dispatch(changeBonusesAchievementHitValueCalculation(x));
        }}
        style={{ width: "100%" }}
        placeholder="Please select"
        options={[
          { label: "Value", value: 1 },
          { label: "Time", value: 0 },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default HitValueCalculation;
