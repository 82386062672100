import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateDescriptionSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateDescription } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const Description = () => {
  const desc = useSelector(bonusesCreateDescriptionSelector);
  const dispatch = useDispatch();
  return (
    <Form.Item label="Description" name="description">
      <TextArea
        value={desc}
        defaultValue={desc}
        onChange={(e) => {
          dispatch(changeBonusesCreateDescription(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default Description;
