import { createAsyncThunk } from "@reduxjs/toolkit";
import { gameGroupServices } from "../../../../services/gameGroupServices";

export const getGameGroupsReducer = createAsyncThunk(
  "getGameGroupsReducer",
  async (q, thunkApi) => {
    const active_casino = thunkApi.getState().user.activeCasino;
    try {
      const res = await gameGroupServices.getGameGroups({
        casino_id: active_casino,
      });
      if (res.status === 200) {
        const finalRes = res.data.data;
        return finalRes;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
