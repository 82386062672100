import moment from "moment";
import { createSlice, current } from "@reduxjs/toolkit";
import { getLobbyDataReducers } from "./extra-reducers/getData";
import { getLobbyFeaturesReducers } from "./extra-reducers/getFeatures";
import { getLobbyFeaturesEditReducers } from "./extra-reducers/getEditedFeatures";
import { getLobbyContentReducers } from "./extra-reducers/getContent";
import { getCategoryEditReducer } from "./extra-reducers/getEditedCategory";
import { getContentEditReducer } from "./extra-reducers/getEditedContent";
import { getWidgetsReducer } from "./extra-reducers/getWidgets";
const _ = require("lodash");

const addContentAreaType = (contentItems, itemsToAdd) => {
  const currentContentItems = current(contentItems);
  const isGame = itemsToAdd.find((item) => item.type === 0);
  const isExsist = currentContentItems.some((currentContent) =>
    itemsToAdd.some((item) => item.type_id === currentContent.type_id)
  );

  if (isExsist && isGame) {
    const getUniqueContentItems = itemsToAdd.filter(
      (item) =>
        !currentContentItems.some(
          (currentContent) => currentContent.type_id === item.type_id
        )
    );

    return [...currentContentItems, ...getUniqueContentItems];
  }

  return [...currentContentItems, ...itemsToAdd];
};

const changeAreaGame = (contentItems, itemsToAdd, singleAreaType) => {
  const deepCopyData = _.cloneDeep(current(contentItems));
  const singleAreaId = current(singleAreaType).id;
  const singleItemsToAdd = itemsToAdd[0];

  const indexToUpdate = deepCopyData.findIndex(
    (item) => item.id === singleAreaId
  );

  deepCopyData[indexToUpdate] = singleItemsToAdd;

  return deepCopyData;
};

const deleteContentAreaType = (contentItems, contentProps) => {
  const currentContentItems = current(contentItems);

  return currentContentItems.filter(
    (currentContent) => currentContent.id !== contentProps.id
  );
};

const editContentAreaType = (
  contentItems,
  currentStateForChangeRaw,
  contentToEditValues
) => {
  const deepCopyData = _.cloneDeep(current(contentItems));
  const { type_id } = contentToEditValues;
  const currentStateForChange = current(currentStateForChangeRaw);
  const values = Object.entries(currentStateForChange);

  const indexToUpdate = deepCopyData.findIndex(
    (obj) => obj.type_id === type_id
  );

  for (const [key, val] of values) {
    deepCopyData[indexToUpdate][key] = val;
  }

  return deepCopyData;
};

const resetContentAreaTypeChanges = (contentItems, previousContentState) => {
  const deepCopyData = _.cloneDeep(current(contentItems));

  const indexToUpdate = deepCopyData.findIndex(
    (obj) => obj.type_id === previousContentState.type_id
  );

  deepCopyData[indexToUpdate] = previousContentState;

  return deepCopyData;
};

const addRecord = (games, contentItems, singleAreaType, gameToAdd) => {
  const currentGames = current(games);
  const areaContent = current(contentItems);
  const singleGame = current(singleAreaType);
  const { record, selected, isRadio } = gameToAdd;

  if (isRadio) {
    const existInArea = areaContent?.findIndex(
      (item) => item.type_id === record.id
    );

    if (existInArea === -1) {
      return [record];
    }

    return [singleGame].map((game) => ({
      id: game.type_id,
      game_name: game.area_type_name,
      game_image: game.area_type_image,
      game_category:
        game?.data?.game_categories[0].name || game.area_type_category,
      provider_name: game.area_type_provider_name,
      provider_image: game.area_type_provider_image,
      flags: game?.data?.flags[0] || null,
    }));
  }

  const isExsist = currentGames.some(
    (currentGame) => record.id === currentGame.id
  );

  if (isExsist && !selected) {
    return currentGames.filter((game) => game.id !== record.id);
  }

  return [...currentGames, record];
};

const addAllRecords = (games, gamesToAdd) => {
  const currentGames = current(games);
  const { changeRows, selected } = gamesToAdd;

  if (!selected) {
    return currentGames.filter(
      (game) => !changeRows.some((changedGames) => game.id === changedGames.id)
    );
  }

  return [...currentGames, ...changeRows];
};

const removeRecord = (games, removeGameId) => {
  const currentGames = current(games);

  return currentGames.filter((game) => game.id !== removeGameId);
};

const initialState = {
  categories: [],
  loading: false,
  error: false,
  isMobile: 0,
  activeCategoryId: null,
  isEdit: false,
  isGameChanged: false,
  categoryModalOpen: false,
  category: {
    lobby_id: 1,
    mobile: 0,
    enabled: 1,
    page_id: null,
    title: null,
    icon_id: 1,
    starts_at: moment().format("1900-1-1").toString(),
    ends_at: moment().format("2100-1-1").toString(),
    visibility: 0,
  },
  categoryEdit: {
    data: [],
    loading: false,
    error: false,
  },
  content: {
    data: [],
    isContentEdit: false,
    currentId: 1,
    contentArea: {
      lobby_id: 1,
      page_id: null,
      order: 1,
      type: 0,
      title: null,
      starts_at: moment().format("1900-1-1").toString(),
      ends_at: moment().format("2100-1-1").toString(),
      visibility: 0,
      type_data: [],
      currentGames: [],
      selectedRowKeys: [],
    },
    loading: false,
    error: false,
    areaModalOpen: false,
    addGamesModalOpen: false,
    areaFeatureModalOpen: false,
    editAreaTypeModalOpen: false,
    singleAreaType: {},
  },
  contentEdit: {
    data: [],
    loading: false,
    error: false,
    currentStateForChange: {},
  },
  widgets: {
    data: [],
    loading: false,
    error: false,
  },
  features: {
    loading: false,
    left: [],
    right: [],
  },
  featuresEdit: {
    data: {},
    loading: false,
  },
};

export const lobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    changeLobbyActiveCategoryId: (state, action) => {
      state.activeCategoryId = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    handleIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    handleIsGameChanged: (state, action) => {
      state.isGameChanged = action.payload;
    },
    handleIsContentEdit: (state, action) => {
      state.content.isContentEdit = action.payload;
    },
    setCurrentContentId: (state, action) => {
      state.content.currentId = action.payload;
    },
    setCurrentGames: (state, action) => {
      state.content.contentArea.currentGames = addRecord(
        state.content.contentArea.currentGames,
        state.content.contentArea.type_data,
        state.content.singleAreaType,
        action.payload
      );
    },
    setAllCurrentGames: (state, action) => {
      state.content.contentArea.currentGames = addAllRecords(
        state.content.contentArea.currentGames,
        action.payload
      );
    },
    removeCurrentGame: (state, action) => {
      state.content.contentArea.currentGames = removeRecord(
        state.content.contentArea.currentGames,
        action.payload
      );
    },
    resetCurrentGames: (state) => {
      state.content.contentArea.currentGames =
        initialState.content.contentArea.currentGames;
    },
    onSelectedRowKeys: (state, action) => {
      state.content.contentArea.selectedRowKeys = action.payload;
    },
    resetSelectedRowKeys: (state) => {
      state.content.contentArea.selectedRowKeys =
        initialState.content.contentArea.selectedRowKeys;
    },
    setCategoryModal: (state, action) => {
      state.categoryModalOpen = action.payload;
    },
    setCategory: (state, action) => {
      state.category = { ...state.category, ...action.payload };
    },
    resetCategory: (state) => {
      state.category = initialState.category;
    },
    setContentAreaModal: (state, action) => {
      state.content.areaModalOpen = action.payload;
    },
    setContentArea: (state, action) => {

      state.content.contentArea = {
        ...state.content.contentArea,
        ...action.payload,
      };
    },
    setContentEditAreaTypes: (state, action) => {
      state.content.contentArea.type_data = action.payload;
    },
    setContentAreaTypeData: (state, action) => {
      state.content.contentArea.type_data = addContentAreaType(
        state.content.contentArea.type_data,
        action.payload
      );
    },
    changedSingleAreaGame: (state, action) => {
      state.content.contentArea.type_data = changeAreaGame(
        state.content.contentArea.type_data,
        action.payload,
        state.content.singleAreaType
      );
    },
    removeContentAreaTypeData: (state, action) => {
      state.content.contentArea.type_data = deleteContentAreaType(
        state.content.contentArea.type_data,
        action.payload
      );
    },
    editContentAreaTypeData: (state, action) => {
      state.content.contentArea.type_data = editContentAreaType(
        state.content.contentArea.type_data,
        state.contentEdit.currentStateForChange,
        action.payload
      );
    },
    resetContentAreaTypeDataPreviousChanges: (state, action) => {
      state.content.contentArea.type_data = resetContentAreaTypeChanges(
        state.content.contentArea.type_data,
        action.payload
      );
    },
    resetContentArea: (state) => {
      state.content.contentArea = initialState.content.contentArea;
    },
    resetContentAreaTypeData: (state) => {
      state.content.contentArea.type_data = [];
    },
    setAddGamesAreaModal: (state, action) => {
      state.content.addGamesModalOpen = action.payload;
    },
    setAreaFeatureModal: (state, action) => {
      state.content.areaFeatureModalOpen = action.payload;
    },
    setEditAreaTypeModal: (state, action) => {
      state.content.editAreaTypeModalOpen = action.payload;
    },
    setSingleAreaType: (state, action) => {
      state.content.singleAreaType = action.payload;
    },
    setFeatureEdit: (state, action) => {
      state.featuresEdit.data = action.payload;
    },
    setCurrentStateGameEdit: (state, action) => {
      state.contentEdit.currentStateForChange = {
        ...state.contentEdit.currentStateForChange,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLobbyDataReducers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLobbyDataReducers.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload.pages;
    });
    builder.addCase(getLobbyDataReducers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLobbyFeaturesReducers.pending, (state) => {
      state.features.loading = true;
    });
    builder.addCase(getLobbyFeaturesReducers.fulfilled, (state, action) => {
      state.features.loading = false;
      state.features.left = action.payload.features.left;
      state.features.right = action.payload.features.right;
    });
    builder.addCase(getLobbyFeaturesReducers.rejected, (state) => {
      state.features.loading = false;
    });
    builder.addCase(getLobbyFeaturesEditReducers.pending, (state) => {
      state.featuresEdit.loading = true;
    });
    builder.addCase(getLobbyFeaturesEditReducers.fulfilled, (state, action) => {
      state.featuresEdit.loading = false;
      state.featuresEdit.data = action.payload;
    });
    builder.addCase(getLobbyFeaturesEditReducers.rejected, (state) => {
      state.featuresEdit.loading = false;
    });
    builder.addCase(getCategoryEditReducer.pending, (state) => {
      state.categoryEdit.loading = true;
    });
    builder.addCase(getCategoryEditReducer.fulfilled, (state, action) => {
      state.categoryEdit.loading = false;
      state.categoryEdit.data = action.payload;
    });
    builder.addCase(getCategoryEditReducer.rejected, (state) => {
      state.categoryEdit.loading = false;
    });
    builder.addCase(getLobbyContentReducers.pending, (state) => {
      state.content.loading = true;
    });
    builder.addCase(getLobbyContentReducers.fulfilled, (state, action) => {
      state.content.loading = false;
      state.content.data = action.payload;
    });
    builder.addCase(getLobbyContentReducers.rejected, (state) => {
      state.content.loading = false;
    });
    builder.addCase(getContentEditReducer.pending, (state) => {
      state.contentEdit.loading = true;
    });
    builder.addCase(getContentEditReducer.fulfilled, (state, action) => {
      state.contentEdit.loading = false;
      state.contentEdit.data = action.payload;
    });
    builder.addCase(getContentEditReducer.rejected, (state) => {
      state.contentEdit.loading = false;
    });
    builder.addCase(getWidgetsReducer.pending, (state) => {
      state.widgets.loading = true;
    });
    builder.addCase(getWidgetsReducer.fulfilled, (state, action) => {
      state.widgets.loading = false;
      state.widgets.data = action.payload;
    });
    builder.addCase(getWidgetsReducer.rejected, (state) => {
      state.widgets.loading = false;
    });
  },
});

export const {
  changeLobbyActiveCategoryId,
  setIsMobile,
  handleIsEdit,
  handleIsGameChanged,
  handleIsContentEdit,
  setCurrentContentId,
  setCurrentGames,
  setAllCurrentGames,
  removeCurrentGame,
  resetCurrentGames,
  onSelectedRowKeys,
  resetSelectedRowKeys,
  setCategoryModal,
  setCategory,
  resetCategory,
  setContentAreaModal,
  setContentArea,
  setContentAreaTypeData,
  changedSingleAreaGame,
  setContentEditAreaTypes,
  removeContentAreaTypeData,
  editContentAreaTypeData,
  setAddGamesAreaModal,
  setAreaFeatureModal,
  setEditAreaTypeModal,
  setSingleAreaType,
  resetContentAreaTypeData,
  resetContentAreaTypeDataPreviousChanges,
  resetContentArea,
  setFeatureEdit,
  setCurrentStateGameEdit,
} = lobbySlice.actions;

export default lobbySlice.reducer;
