import { Form } from "antd";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  freeGamesCreateDateFromSelector,
  freeGamesCreateDateToSelector,
} from "../../../../../redux/slices/free-games/freeGamesSelector";
import {
  changeFreeGamesCreateDateFrom,
  changeFreeGamesCreateDateTo,
} from "../../../../../redux/slices/free-games/freeGamesSlice";
const { RangePicker } = DatePicker;

const FreeGamesDataRange = () => {
  const dispatch = useDispatch();
  const dateFrom = useSelector(freeGamesCreateDateFromSelector);
  const dateTo = useSelector(freeGamesCreateDateToSelector);

  return (
    <Form.Item label="Period (from/to)">
      <RangePicker
        format="DD-MM-YYYY"
        onChange={(x) => {
          if (!x) return;

          dispatch(
            changeFreeGamesCreateDateFrom(
              `${moment(x[0]).utc(true).toISOString()}`
            )
          );
          dispatch(
            changeFreeGamesCreateDateTo(
              `${moment(x[1]).utc(true).toISOString()}`
            )
          );
        }}
        value={[moment(dateFrom), moment(dateTo)]}
        style={{ minWidth: "250px" }}
      />
    </Form.Item>
  );
};

export default FreeGamesDataRange;
