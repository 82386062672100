import axios from "axios";

const defaultRoute = "freespins";

const getFreeGames = (params) => {
  const result = axios(`/${defaultRoute}`, { params });
  return result;
};

const createFreeGame = async (body) => {
  const result = await axios.post(`/${defaultRoute}`, { ...body });
  return result;
};

const copyFreeGame = (id) => {
  const result = axios.get(`/${defaultRoute}/copy/${id}`);
  return result;
};

const getSingleFreeGame = (id) => {
  const result = axios.get(`/${defaultRoute}/${id}`);
  return result;
};

const deleteFreeGame = (id) => {
  const result = axios(`/${defaultRoute}/delete/${id}`);
  return result;
};

const editFreeGame = (body, id) => {
  const result = axios.post(`/${defaultRoute}/update/${id}`, { ...body });
  return result;
};

const freeGameDetail = (id) => {
  return axios.get(`/${defaultRoute}/statistics/${id}`);
};

export const deleteFreeGameDetail = (payload) => {
  return axios.post(`/${defaultRoute}/remove`, { ...payload });
};

export const addFreeGamePlayers = (fields) => {
  return axios.post(`/${defaultRoute}/add`, { ...fields });
};

export const allFreeSpins = (params) =>
  axios.get(`/${defaultRoute}/all`, { params });

const freeGamesServices = {
  getFreeGames,
  createFreeGame,
  copyFreeGame,
  deleteFreeGame,
  getSingleFreeGame,
  editFreeGame,
  freeGameDetail,
};

export default freeGamesServices;
