import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import Tooltip from "antd/es/tooltip";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { getBonusesDataReducers } from "../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";
import bonusesServices from "../../../../../services/bonusesServices";
import { useSelector } from "react-redux";
import {
  bonusesPageNumberSelector,
  bonusesTableDataSelector,
} from "../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesPageNumber } from "../../../../../redux/slices/bonuses/bonusesSlice";

const ActionsColumn = ({ record }) => {
  const dispatch = useDispatch();

  const bonusesData = useSelector(bonusesTableDataSelector);
  const bonusesPageNum = useSelector(bonusesPageNumberSelector);

  return (
    <div style={{ display: "flex", gap: "16px", justifyContent: "center" }}>
      <Tooltip title="View bonuses rounds">
        <NavLink to={"/bonuses/rounds/" + record.id}>
          <Button
            type="default"
            shape="circle"
            size="middle"
            icon={<EyeOutlined />}
          />
        </NavLink>
      </Tooltip>

      <Popconfirm
        icon={<CopyOutlined className="bonuses_table_copy_icon" />}
        title="Are you sure you want to copy this item?"
        okText="Copy"
        cancelText="Cancel"
        onConfirm={() => {
          bonusesServices.copyBonus(record.id).then((res) => {
            dispatch(getBonusesDataReducers());
          });
        }}
        okButtonProps={{ shape: "round" }}
        cancelButtonProps={{ shape: "round" }}
        showArrow={false}
      >
        <Tooltip title="Copy bonus">
          <Button
            type="primary"
            shape="circle"
            size="middle"
            icon={<CopyOutlined />}
          />
        </Tooltip>
      </Popconfirm>

      <Popconfirm
        icon={<DeleteOutlined className="bonuses_table_delete_icon" />}
        title="Are you sure you want to delete this item?"
        okText="Delete"
        cancelText="Cancel"
        onConfirm={() => {
          if (bonusesData.length === 1 && bonusesPageNum > 1) {
            dispatch(changeBonusesPageNumber(bonusesPageNum - 1));
          }

          bonusesServices.deleteBonus(record.id).then((res) => {
            dispatch(getBonusesDataReducers());
          });
        }}
        okButtonProps={{ danger: true, shape: "round" }}
        cancelButtonProps={{ shape: "round" }}
        showArrow={false}
      >
        <Tooltip title="Delete bonus">
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            size="middle"
            danger={true}
          />
        </Tooltip>
      </Popconfirm>
    </div>
  );
};

export default ActionsColumn;
