import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWidgetsReducer = createAsyncThunk(
  "getWidgetsReducer",
  async (_, thunkAPI) => {
    try {
      const casino_id = thunkAPI.getState().user.activeCasino;
      const lobbyType = localStorage.getItem("lobby");

      const response = await fetch(
        `${window.WIDGET_LIST_URL}/optionsActiveWidgetsList?casino_id=${casino_id}&lobby=${lobbyType}`
      );

      if (response.status !== 200) throw new Error();

      const data = await response.json();

      return data.map(({ name, api }) => ({
        label: name.trim(),
        value: api,
      }));
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
