import React, { useState } from "react";
import { Button, Form, message, Tooltip, Upload } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  changeGamesImage,
  changeGamesPreviewImage,
} from "../../../../../redux/slices/games/gamesSlice";
import { gamesPreviewImageSelector } from "../../../../../redux/slices/games/gamesSelectors";

const GamesPhoto = () => {
  const previewImage = useSelector(gamesPreviewImageSelector);

  const dispatch = useDispatch();

  const handleUpload = ({ file }) => {
    const formatAllowed = [
      "image/jpeg",
      "image/png",
      "image/apng",
      "image/gif",
    ].includes(file.type);
    if (!formatAllowed) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if (formatAllowed && isLt2M) {
      dispatch(changeGamesImage(file));
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        file.thumbUrl = e.target.result;
        dispatch(changeGamesPreviewImage(file.thumbUrl));
      };
    }
  };

  return (
    <>
      <Form.Item label="Photo" className="games_photo">
        <Upload
          onChange={handleUpload}
          beforeUpload={() => false}
          maxCount={1}
          multiple={false}
          listType={false}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Upload Photo</Button>
        </Upload>
        {previewImage && (
          <>
            <Tooltip title="Remove" style={{ display: null }}>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => {
                  dispatch(changeGamesPreviewImage(null));
                  dispatch(changeGamesImage(null));
                }}
                danger={true}
              />
            </Tooltip>
          </>
        )}
      </Form.Item>
      {previewImage && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src={previewImage}
            alt="game preview icon"
            className="games_photo_preview"
          />
        </div>
      )}
    </>
  );
};

export default GamesPhoto;
