import { Avatar, Table } from "antd";
import { statisticColumnsPlayerGames } from "../statisticColumns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { providersListSelector } from "../../../../redux/slices/providers/providersSelectors";
import {
  statisticsOrderBy,
  statisticsSortDirection,
  statisticsUiIsDataLoading,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsOrderBy,
  changeStatisticsSortDirection,
} from "../../../../redux/slices/statistics/statisticsSlice";
import { formatAmount } from "../../../../helpers";
import { currencySelector } from "../../../../redux/slices/user/userSelectors";

const StatisticsTablePlayerGames = ({ dataSource }) => {
  const providersList = useSelector(providersListSelector);
  const isDataLoading = useSelector(statisticsUiIsDataLoading);
  const orderBy = useSelector(statisticsOrderBy);
  const currency = useSelector(currencySelector);
  const sortDirection = useSelector(statisticsSortDirection);

  const dispatch = useDispatch();

  return (
    <Table
      columns={[
        {
          title: "Player ID",
          dataIndex: "player_id",
          key: "player_id",
          searchable: false,

          width: "200px",

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return <p style={{ fontWeight, fontSize }}>{text && text}</p>;
          },
        },
        {
          title: "Game Name",
          dataIndex: "game_name",
          key: "game_name",
          searchable: false,

          render: (text, game) => {
            if (!game.game_photo) return;
            return (
              <div>
                <Avatar
                  shape="circle"
                  src={game.game_photo}
                  style={{ marginRight: "4px" }}
                  size="default"
                />
                {game.game_name}{" "}
              </div>
            );
          },
          width: "220px",
        },
        {
          title: "Bet",
          dataIndex: "bet",
          key: "bet",
          searchable: false,

          width: "200px",
          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "Win",
          dataIndex: "win",
          key: "win",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "Net",
          dataIndex: "turnover",
          key: "turnover",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },

        {
          title: "RTP %",
          dataIndex: "rtp",
          key: "rtp",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;

            if (!text) return null;
            return <p style={{ fontWeight, fontSize }}>{text}%</p>;
          },
        },
        {
          title: "Tickets",
          dataIndex: "tickets",
          key: "tickets",
          searchable: false,
        },
        {
          title: "Ø  Bet",
          dataIndex: "avg_bet",
          key: "avg_bet",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Ø Ticket/Player",
          dataIndex: "tickets_player",
          key: "tickets_player",
          searchable: false,
        },
      ]}
      className="statistics_table"
      dataSource={[
        ...dataSource.map((x) => {
          let game_photo = x.game_photo;
          providersList.forEach((provider) => {
            if (provider.name === x.game_name) {
              game_photo = provider.photo;
            }
          });
          return { ...x, id: x.player_id, game_photo };
        }),
      ]}
      rowKey={(record, i) => {
        if (record.id) {
          return record.id;
        }

        if (!record.id) {
          return `${record.bet}-${i}`;
        }
      }}
      // onChange={handleTableChange}
      pagination={false}
      loading={isDataLoading}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              columns={[
                {
                  title: "--- Name ---",
                  dataIndex: "name",
                  key: "name",
                  searchable: false,
                  width: "620px",
                },
                {
                  title: "--- Win ---",
                  dataIndex: "win",
                  key: "win",
                  searchable: false,
                },
              ]}
              dataSource={[...record.data]}
              rowKey={(record) => record.id}
              pagination={false}
            ></Table>
          );
        },
        rowExpandable: (record) => record.data?.length > 0,
      }}
      onChange={(a, b, c) => {
        if (c.column.key !== orderBy || sortDirection === null) {
          dispatch(changeStatisticsOrderBy(c.column.key));
          dispatch(changeStatisticsSortDirection("asc"));
        } else if (sortDirection === "asc") {
          dispatch(changeStatisticsOrderBy(c.column.key));
          dispatch(changeStatisticsSortDirection("desc"));
        } else if (sortDirection === "desc") {
          dispatch(changeStatisticsOrderBy(null));
          dispatch(changeStatisticsSortDirection(null));
        }
      }}
    ></Table>
  );
};

export default StatisticsTablePlayerGames;
