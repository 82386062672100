import {
  ApartmentOutlined,
  BarChartOutlined,
  DribbbleOutlined,
  EnvironmentOutlined,
  FlagOutlined,
  GiftOutlined,
  GlobalOutlined,
  GoldOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  SketchOutlined,
  SlidersOutlined,
  SwitcherOutlined,
  TrophyOutlined,
  BlockOutlined,
} from "@ant-design/icons";

export const MENU_LINKS = [
  {
    path: "/statistics",
    label: "Statistics",
    icon: <BarChartOutlined />,
  },
  {
    path: "/stakes",
    label: "Stakes",
    icon: <SwitcherOutlined />,
  },
  {
    path: "/bonuses",
    label: "Bonuses",
    icon: <PlusCircleOutlined />,
  },
  {
    path: "/jackpots",
    label: "Jackpots",
    icon: <SketchOutlined />,
  },
  {
    path: "/tournaments",
    label: "Tournaments",
    icon: <TrophyOutlined />,
  },
  {
    path: "/free-games",
    label: "Free Games",
    icon: <GiftOutlined />,
  },
  {
    path: "/games",
    label: "Games",
    key: "games",
    icon: <DribbbleOutlined />,
  },
  {
    path: "/slides",
    label: "Banners Management",
    icon: <MedicineBoxOutlined />,
  },
  {
    path: "/lobbies-management",
    label: "Lobbies Management",
    key: "lobbiesManagement",
    icon: <BlockOutlined />,
  },
  // {
  //   path: "/flags",
  //   label: "Flags",
  //   key: "flags",
  //   icon: <FlagOutlined />,
  // },
];

export const SETTINGS_LINKS = [
  {
    key: "home",
    path: "/",
    label: "Home",
    icon: <HomeOutlined />,
  },
];
