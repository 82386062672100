import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateGeneralInfoUrlSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateInfoUrl } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const InfoUrl = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateGeneralInfoUrlSelector);
  return (
    <Form.Item label="Info url" name="info_url">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateInfoUrl(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default InfoUrl;
