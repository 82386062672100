import { Tag } from "antd";
import moment from "moment";
import ActionsColumn from "./ActionsColumn";

export const tournamentsTableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Identifier",
    dataIndex: "identifier",
    key: "identifier",
    sorter: false,
  },
  // {
  //   title: "Widget width",
  //   dataIndex: "widget_width",
  //   key: "widget_width",
  //   searchable: false,
  //   render: (text, record) => <div>{record.widget_width}</div>,
  // },
  {
    title: "Starts at",
    dataIndex: "starts_at",
    key: "starts_at",
    render: (text, record) => {
      let round = record.active_round ?? record.next_round;
      return (
        <div>
          {moment
            .utc(round?.starts_at ?? record.created_at)
            .local()
            .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      );
    },
  },
  {
    title: "Ends at",
    dataIndex: "ends_at",
    key: "ends_at",
    render: (text, record) => {
      let round = record.active_round ?? record.next_round;
      return (
        <div>
          {moment
            .utc(round?.ends_at ?? record.created_at)
            .local()
            .format("DD-MM-YYYY HH:mm:ss")}
        </div>
      );
    },
  },
  {
    title: "State",
    dataIndex: "active_round.state_pretty",
    key: "active_round.state_pretty",
    render: (text, record) => {
      if (record.active_round) {
        return <Tag color={"green"}>{"Active"}</Tag>;
      }
      return <Tag color={"orange"}>{"Inactive"}</Tag>;
    },
  },
  {
    title: "Actions",
    dataIndex: "",
    key: "actions",
    render: (text, record) => <ActionsColumn record={record} />,
  },
];
