import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Button, Modal, Form, Input, Alert, Space, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { addFreeGamePlayers } from "../../../../../../services/freeGamesServices";
import { activeCasinoSelector } from "../../../../../../redux/slices/user/userSelectors";
import { IS_REQUIRED } from "../../../../lobbies-management/constants/isRequired";
import { getSingleFreeGamesDetailsReducer } from "../../../../../../redux/slices/free-games/extra-reducers/getSingleFreeGamesDetailsReducer";
import { freeGameDetailSearch } from "../../../../../../redux/slices/free-games/freeGamesSelector";
import {
  changeFreeGamesDetailSearch,
  changeFreeGamesSearch,
} from "../../../../../../redux/slices/free-games/freeGamesSlice";
const { TextArea } = Input;

const FreeGamesAddPlayersControls = () => {
  const dispatch = useDispatch();
  const searchDetail = useSelector(freeGameDetailSearch);
  const activeCasino = useSelector(activeCasinoSelector);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [form] = Form.useForm();
  const [idsInfo, setIdsInfo] = useState({
    successful_count: 0,
    unsuccessful_count: 0,
    unsuccessful_player_ids: [],
  });

  const handleIsModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleOk = useCallback(async () => {
    setLoadingButton(true);

    try {
      const { ids } = await form.getFieldsValue();

      if (!ids) {
        setLoadingButton(false);
        return;
      }
      const filteredIds = ids.split(/\s+/).filter(Boolean);

      if (!filteredIds.length) {
        setLoadingButton(false);
        return;
      }

      const payload = {
        casino_id: activeCasino,
        freespins_id: id,
        player_ids: filteredIds,
      };

      const { data } = await addFreeGamePlayers(payload);
      const { result } = await data;

      setIdsInfo(result);
      setLoadingButton(false);
      setIsModalOpen(false);

      form.resetFields();

      dispatch(getSingleFreeGamesDetailsReducer(id));
    } catch (error) {
      message.error(
        `Please fill all required fields: ${error?.errorFields[0]?.name}`
      );
      setLoadingButton(false);
      setIsModalOpen(false);
    }
  }, [activeCasino, dispatch, id, form]);

  const handleCloseSuccessfulAlert = () => {
    setIdsInfo((prevState) => ({ ...prevState, successful_count: 0 }));
  };

  const handleCloseUnsuccessfulAlert = () => {
    setIdsInfo((prevState) => ({
      ...prevState,
      unsuccessful_count: 0,
      unsuccessful_player_ids: [],
    }));
  };

  return (
    <Row style={{ justifyContent: "space-between", padding: "10px 20px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <Input
          style={{ maxWidth: "250px" }}
          placeholder="- Search Players By Id -"
          value={searchDetail}
          onChange={(e) => {
            dispatch(changeFreeGamesDetailSearch(e.target.value));
          }}
        ></Input>
      </div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleIsModalOpen}
      >
        Add players
      </Button>
      <Modal
        title="Add players"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loadingButton}
        okText="Save"
        centered
      >
        <Form layout="horizontal" form={form}>
          <Form.Item name={"ids"} rules={[IS_REQUIRED]}>
            <TextArea rows={4} autoSize={{ minRows: 16 }} />
          </Form.Item>
        </Form>
      </Modal>
      <Space style={{ width: "100%" }}>
        {idsInfo.successful_count ? (
          <Alert
            message={`Successful IDS: ${idsInfo.successful_count}`}
            type="success"
            showIcon
            closable
            onClose={handleCloseSuccessfulAlert}
            style={{ marginTop: "10px", textWrap: "nowrap" }}
          />
        ) : null}
        {idsInfo.unsuccessful_count ? (
          <Alert
            message={`Unsuccessful IDS(${
              idsInfo.unsuccessful_count
            }): ${idsInfo.unsuccessful_player_ids.map((id) => `${id} `)}`}
            type="error"
            showIcon
            closable
            onClose={handleCloseUnsuccessfulAlert}
            style={{ marginTop: "10px" }}
          />
        ) : null}
      </Space>
    </Row>
  );
};

export default FreeGamesAddPlayersControls;
