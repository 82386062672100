export const stakesPlayerIdSelector = (state) => {
  return state.stakes.playerId;
};
export const stakesSortBySelector = (state) => {
  return state.stakes.stakesSortBy;
};
export const stakesPageNumberSelector = (state) => {
  return state.stakes.pageNumber;
};
export const stakesTotalRowsSelector = (state) => {
  return state.stakes.totalRows;
};
export const stakesPageSizeSelector = (state) => {
  return state.stakes.pageSize;
};
export const stakesSortDirectionSelector = (state) => {
  return state.stakes.stakesSortDirection;
};
export const stakesTransactionIdSelector = (state) => {
  return state.stakes.transactionId;
};
export const stakesGamesSelector = (state) => {
  return state.stakes.stakesGames;
};
export const stakesProviderSelector = (state) => {
  return state.stakes.stakesProvider;
};
export const stakesDateFromSelector = (state) => {
  return state.stakes.stakesDateFrom;
};
export const stakesDateToSelector = (state) => {
  return state.stakes.stakesDateTo;
};
export const stakesTableDataSelector = (state) => {
  return state.stakes.stakesTableData;
};
export const stakesUiIsDataLoading = (state) => {
  return state.stakes.ui.isDataLoading;
};
export const selectStakesRoundIsModalOpen = (state) => {
  return state.stakes.isModalOpen;
};
export const stakeRoundSelector = (state) => {
  return state.stakes.round;
};
