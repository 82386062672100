import { createAsyncThunk } from "@reduxjs/toolkit";
import { allFreeSpins } from "../../../../services/freeGamesServices";

export const getFreeGamesWithoutPaginationReducer = createAsyncThunk(
  "getFreeGamesWithoutPaginationReducer",
  async (q, thunkApi) => {
    const state = thunkApi.getState();
    const params = {
      casino_id: state.user.activeCasino,
    };

    try {
      const { status, data } = await allFreeSpins(params);
      if (status !== 200) throw new Error();
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);

// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { allFreeSpins } from "../../../../services/freeGamesServices";
// import { activeCasinoSelector } from "../../user/userSelectors";

// export const api = createApi({
//   baseQuery: fetchBaseQuery({
//     baseUrl: window.BASE_URL,
//     prepareHeaders: (headers, { getState }) => {
//       const activeCasino = getState().user.activeCasino;
//       const token = localStorage.getItem("token");
//       if (token) {
//         headers.set("Authorization", `Bearer ${token}`);
//       }
//       return headers;
//     },
//   }),
//   endpoints: (build) => ({
//     getFreeGamesWithoutPagination: build.query({
//       query: () => {
//         return `/freespins/all`;
//       },
//       onQueryStarted: async (id, { dispatch, getState }) => {
//         const params = {
//           casino_id: getState().user.activeCasino,
//         };
//         return { params };
//       },
//     }),
//   }),
// });

// export const { useGetFreeGamesWithoutPaginationQuery } = api;
