import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { freeGamesCreateFreeGamesArray } from "../../../../../redux/slices/free-games/freeGamesSelector";
import { freeGamesGameTableColumns } from "./freeGamesGameTableColumns";

const FreeGamesTable = () => {
  const data = useSelector(freeGamesCreateFreeGamesArray);

  return (
    <Table
      columns={freeGamesGameTableColumns}
      dataSource={data}
      pagination={false}
      style={{ marginTop: "8px" }}
    ></Table>
  );
};

export default FreeGamesTable;
