import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateIdentifier } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateIdentifier } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const Identifier = () => {
  const name = useSelector(tournamentsCreateIdentifier);
  const dispatch = useDispatch();
  return (
    <Form.Item
      label="Identifier"
      name="identifier"

      // rules={[{ required: true, message: "This field is required" }]}
    >
      <Input
        name={name}
        defaultValue={name}
        disabled
        onChange={(e) => {
          dispatch(changeTournamentsCreateIdentifier(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default Identifier;
