import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateLongTextSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateLongText } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const LongText = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateLongTextSelector);
  return (
    <Form.Item
      label={'Long text for promotions page "more info"'}
      name="long_text_for_promotions"
    >
      <TextArea
        columns={6}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateLongText(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default LongText;
