import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { tournamentsServices } from "../../../../services/tournaments";

export const getTournamentsReducers = createAsyncThunk(
  "getTournamentsReducers",
  async (q, thunkApi) => {
    const tournamentsState = thunkApi.getState().tournaments;
    const casino_id = thunkApi.getState().user.activeCasino;
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    const params = {
      "period[0]": moment(tournamentsState.filter.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .utc(true)
        .toISOString(),
      "period[1]": moment(tournamentsState.filter.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .utc(true)
        .toISOString(),
      casino_id: casino_id,
      page: tournamentsState.pagination.pageNumber,
      per_page: tournamentsState.pagination.pageSize,
      sort_direction: "desc",
    };

    if (tournamentsState.filter.state !== null) {
      params.state = tournamentsState.filter.state;
    }

    if (tournamentsState.filter.search) {
      params.search = tournamentsState.filter.search;
    }
    try {
      const res = await tournamentsServices.getTournaments(params);
      if (res.status === 200 && tournamentsState) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
