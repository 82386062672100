import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateBannerImageUrlSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateBannerUrl } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const BannerImageUrl = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateBannerImageUrlSelector);
  return (
    <Form.Item label="Banner image URL" name="banner_image_url">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateBannerUrl(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default BannerImageUrl;
