import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateGeneralProgressBarSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateProgressBar } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ProgressBarStyle = () => {
  const barType = useSelector(bonusesCreateGeneralProgressBarSelector);
  const dispatch = useDispatch();
  const classNameOptions = {
    0: "",
    1: "meter red nostripes",
    2: "meter green nostripes",
    3: "meter orange nostripes",
    4: "meter red",
    5: "meter green",
    6: "meter orange",
    7: "meter red animate",
    8: "meter green animate",
    9: "meter orange animate",
  };
  return (
    <Form.Item label="Progress bar style" name="achievement_period">
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <Select
          style={{ height: "min-content", width: "50%" }}
          value={barType}
          options={[
            { label: "None", value: 0 },
            { label: "Red", value: 1 },
            { label: "Green", value: 2 },
            { label: "Orange", value: 3 },
            { label: "Red Stripped", value: 4 },
            { label: "Green Stripped", value: 5 },
            { label: "Orange Stripped", value: 6 },
            { label: "Red Stripped Animated", value: 7 },
            { label: "Green Stripped Animated", value: 8 },
            { label: "Orange Stripped Animated", value: 9 },
          ]}
          onChange={(x) => {
            dispatch(changeBonusesCreateProgressBar(x));
          }}
        />
        <div
          className={`${classNameOptions[barType]}`}
          style={{ width: "50%" }}
        >
          <span style={{ width: "50%" }}></span>
        </div>
      </div>
    </Form.Item>
  );
};

export default ProgressBarStyle;
