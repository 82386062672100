import { createAsyncThunk } from "@reduxjs/toolkit";
import { tournamentsServices } from "../../../../services/tournaments";

export const getSingleTournaments = createAsyncThunk(
  "getSingleTournaments",
  async ({ id, idRound }, thunkApi) => {
    try {
      const res = await tournamentsServices.getSingleTournament(id, idRound);

      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
