import { Button, PageHeader } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createGameReducer } from "../../../../redux/slices/games/extra-reducers/createGameReducer";
import { editGameReducer } from "../../../../redux/slices/games/extra-reducers/editGameReducer";
import {
  gameCreatedEditedSelector,
  gamesButtonWaitingResponseSelector,
  gamesIsEditSelector,
  gamesWidgetWidthSelector,
} from "../../../../redux/slices/games/gamesSelectors";
import { resetGamesSlice } from "../../../../redux/slices/games/gamesSlice";

const GamesCreateEditHeader = () => {
  const isEdit = useSelector(gamesIsEditSelector);
  const dispatch = useDispatch();
  const widgetWidth = useSelector(gamesWidgetWidthSelector);
  const loading = useSelector(gamesButtonWaitingResponseSelector);

  const history = useHistory();

  const gameCreatedEdited = useSelector(gameCreatedEditedSelector);

  if (gameCreatedEdited) {
    dispatch(resetGamesSlice());
    history.push("/games");
  }

  const title = isEdit ? "Edit Game" : "Create New Game";

  const id = useParams().id;

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={title}
      extra={
        <Button
          loading={loading === true}
          type="primary"
          htmlType="submit"
          danger={loading === null}
          onClick={() => {
            if (isEdit) {
              dispatch(editGameReducer(id));
            } else {
              dispatch(createGameReducer());
            }
          }}
        >
          {isEdit ? "Edit" : "Create"}
        </Button>
      }
    ></PageHeader>
  );
};

export default GamesCreateEditHeader;
