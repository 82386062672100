import { Form, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesEnabledSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesEnabled } from "../../../../../redux/slices/games/gamesSlice";

const GamesEnabled = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesEnabledSelector);

  return (
    <Form.Item label="Enabled" name="enabled">
      <Switch
        defaultChecked={value}
        onChange={(e) => {
          dispatch(changeGamesEnabled(e));
        }}
      />
    </Form.Item>
  );
};

export default GamesEnabled;
