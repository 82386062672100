import { Avatar, Table } from "antd";
import { statisticColumnsGames } from "../statisticColumns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statisticsOrderBy,
  statisticsSortDirection,
  statisticsUiIsDataLoading,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  changeStatisticsOrderBy,
  changeStatisticsSortDirection,
} from "../../../../redux/slices/statistics/statisticsSlice";
import { currencySelector } from "../../../../redux/slices/user/userSelectors";
import { formatAmount } from "../../../../helpers";

const StatisticsTableGames = ({ dataSource }) => {
  const dispatch = useDispatch();
  const isDataLoading = useSelector(statisticsUiIsDataLoading);
  const orderBy = useSelector(statisticsOrderBy);
  const sortDirection = useSelector(statisticsSortDirection);
  const currency = useSelector(currencySelector);

  return (
    <Table
      columns={[
        {
          title: "Game Provider",
          dataIndex: "provider_name",
          key: "provider_name",
          searchable: false,
          alignItems: "center",

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <div className="provider">
                {game.provider_name !== "Total" &&
                  game.provider_name !== "Total " &&
                  game.provider_name !== "Freespins" &&
                  game.provider_name !== "GameBonus" &&
                  game.provider_name !== "Jackpots" &&
                  game.provider_name !== "Tournaments" &&
                  game.provider_name !== "Bonuses" && (
                    <Avatar
                      shape="circle"
                      src={game.provider_photo}
                      style={{ marginRight: "4px" }}
                      size="default"
                    />
                  )}
                <p style={{ fontWeight, fontSize }}>{text && text}</p>
              </div>
            );
          },
          width: "160px",
        },
        {
          title: "Game",
          dataIndex: "game_name",
          key: "game_name",
          searchable: false,

          render: (text, game) => {
            return (
              <div>
                {game.provider_name !== "Total" &&
                  game.provider_name !== "Total " &&
                  game.provider_name !== "Freespins" &&
                  game.provider_name !== "GameBonus" &&
                  game.provider_name !== "Jackpots" &&
                  game.provider_name !== "Tournaments" &&
                  game.provider_name !== "Bonuses" && (
                    <Avatar
                      shape="circle"
                      src={game.game_photo}
                      style={{ marginRight: "4px" }}
                      size="default"
                    />
                  )}
                {game.game_name}{" "}
              </div>
            );
          },
          width: "260px",
        },
        {
          title: "Bet",
          dataIndex: "bet",
          key: "bet",
          searchable: false,

          width: "160px",

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Win",
          dataIndex: "win",
          key: "win",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Net",
          dataIndex: "turnover",
          key: "turnover",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "RTP %",
          dataIndex: "rtp",
          key: "rtp",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;

            if (!text) return null;
            return <p style={{ fontWeight, fontSize }}>{text}%</p>;
          },
        },
        {
          title: "Tickets",
          dataIndex: "tickets",
          key: "tickets",
          searchable: false,
        },
        {
          title: "Players",
          dataIndex: "players",
          key: "players",
          searchable: false,
        },
        {
          title: "Ø Bet",
          dataIndex: "avg_bet",
          key: "avg_bet",
          searchable: false,

          render: (text, game) => {
            const fontWeight =
              text === "Total" || text === "Total " ? "900" : "400";
            const fontSize = text === "Total" || text === "Total " ? 16 : 14;
            return (
              <p style={{ fontWeight, fontSize }}>
                {text && formatAmount(parseFloat(text), currency)}
              </p>
            );
          },
        },
        {
          title: "Ø Ticket/Player",
          dataIndex: "tickets_player",
          key: "tickets_player",
          searchable: false,
        },
      ]}
      className="statistics_table"
      dataSource={[
        ...dataSource.map((x) => {
          return { ...x, id: x.game_name };
        }),
      ]}
      rowKey={(record, i) => {
        if (record.id) {
          return record.id;
        }

        if (!record.id) {
          return `${record.bet}-${i}`;
        }
      }}
      pagination={false}
      loading={isDataLoading}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Table
              columns={[
                {
                  title: "--- Name ---",
                  dataIndex: "name",
                  key: "name",
                  searchable: false,
                  width: "420px",
                },
                {
                  title: "--- Win ---",
                  dataIndex: "win",
                  key: "win",
                  searchable: false,
                },
              ]}
              dataSource={[...record.data]}
              rowKey={(record, i) => i}
              pagination={false}
            ></Table>
          );
        },
        rowExpandable: (record) => record.data?.length > 0,
      }}
      onChange={(a, b, c) => {
        if (c.column.key !== orderBy || sortDirection === null) {
          dispatch(changeStatisticsOrderBy(c.column.key));
          dispatch(changeStatisticsSortDirection("asc"));
        } else if (sortDirection === "asc") {
          dispatch(changeStatisticsOrderBy(c.column.key));
          dispatch(changeStatisticsSortDirection("desc"));
        } else if (sortDirection === "desc") {
          dispatch(changeStatisticsOrderBy(null));
          dispatch(changeStatisticsSortDirection(null));
        }
      }}
    ></Table>
  );
};

export default StatisticsTableGames;
