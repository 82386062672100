import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TournamentsTable.css";
import { activeCasinoSelector } from "../../../../../redux/slices/user/userSelectors";
import { tournamentsTableColumns } from "./tournamentsTableColumns";
import { getTournamentsReducers } from "../../../../../redux/slices/tournaments/extra-reducers/getTournamentsReducers";
import {
  tournamentsTableData,
  tournamentsTableDataLoading,
  tournamentsTablePageNumber,
  tournamentsTablePageSize,
  tournamentsTableTotalRows,
} from "../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsPageNumber } from "../../../../../redux/slices/tournaments/tournamentsSlice";

const TournamentsTable = () => {
  const activeCasinoId = useSelector(activeCasinoSelector);
  const pageNumber = useSelector(tournamentsTablePageNumber);
  const totalRows = useSelector(tournamentsTableTotalRows);
  const pageSize = useSelector(tournamentsTablePageSize);

  const data = useSelector(tournamentsTableData);
  const loading = useSelector(tournamentsTableDataLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTournamentsReducers());
  }, [activeCasinoId, dispatch, pageNumber]);

  return (
    <>
      <Table
        columns={tournamentsTableColumns}
        dataSource={data}
        loading={loading}
        className="bonuses_table"
        rowKey={(record) => record.id}
        showSorterTooltip={false}
        pagination={{
          total: totalRows,
          defaultCurrent: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: (page) => {
            dispatch(changeTournamentsPageNumber(page));
          },
          totalBoundaryShowSizeChanger: true,
          position: "left",
        }}
      />
    </>
  );
};

export default TournamentsTable;
