import { PageHeader } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import StatisticsTable from "./statistics-table/StatisticsTable";
import StatisticsForm from "./statistics-form/StatisticsForm";
import "./Statistics.css";
import { useDispatch } from "react-redux";
import { resetStatisticsSlice } from "../../../redux/slices/statistics/statisticsSlice";
import { useSelector } from "react-redux";
import { activeCasinoSelector } from "../../../redux/slices/user/userSelectors";
import casinoServices from "../../../services/casinoServices";
import { changeCurrency } from "../../../redux/slices/user/userSlice";

function Statistics() {
  const dispatch = useDispatch();
  const activeCasino = useSelector(activeCasinoSelector);
  const { goBack } = useHistory();

  const hanleGoBack = () => goBack();

  const fetchCurrency = async () => {
    const res = await casinoServices.getCasinos();

    res.data.data.forEach((casino, i) => {
      if (activeCasino === casino.id) {
        dispatch(changeCurrency(casino.currency));
      }
    });
  };

  useEffect(() => {
    fetchCurrency();
    return () => {
      dispatch(resetStatisticsSlice());
    };
  }, [dispatch]);

  return (
    <div className="statistics_page">
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        title="Statistics"
      ></PageHeader>
      <div className="statistics_container">
        <div style={{ minWidth: "fit-content" }}>
          <StatisticsForm />
          <StatisticsTable />
        </div>
      </div>
    </div>
  );
}

export default Statistics;
