import React from "react";
import FreeGamesDisplay from "./display-options/FreeGamesDisplay";
import LockAmountDisplay from "./display-options/LockAmountDisplay";
import PopupDisplay from "./display-options/PopupDisplay";
import RewardDisplay from "./display-options/RewardDisplay";

const SingleActivity = ({ type, i }) => {
  return (
    <div style={{ flexGrow: "1" }}>
      {type === 0 ? (
        <RewardDisplay i={i} />
      ) : type === 1 ? (
        <PopupDisplay i={i} />
      ) : type === 2 ? (
        <FreeGamesDisplay i={i} />
      ) : type === 3 ? (
        <LockAmountDisplay i={i} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SingleActivity;
