import React from "react";
import { useSelector } from "react-redux";
import SingleGameGroupRule from "./SingleGameGroupRule";
import { tournamentsGameGroupRules } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";

const ListGameGroupsRules = () => {
  const gameGroupsRules = useSelector(tournamentsGameGroupRules);

  return (
    <div
      className="rules_list_all_providers"
      style={{ marginBottom: gameGroupsRules?.length === 0 ? "0px" : "32px" }}
    >
      {gameGroupsRules.map((rule, i) => {
        return (
          <SingleGameGroupRule
            id={rule.id}
            percent={rule.percent}
            i={i}
            key={rule.id}
          />
        );
      })}
    </div>
  );
};

export default ListGameGroupsRules;
