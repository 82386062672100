import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreatePublicTitle } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreatePublicTitle } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const PublicTitle = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsCreatePublicTitle);

  return (
    <Form.Item
      label="Public title"
      name="public_title"
      // rules={[{ required: true, message: "This field is required" }]}
    >
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeTournamentsCreatePublicTitle(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default PublicTitle;
