import { Form, Select } from "antd";
import { useSelector } from "react-redux";
import { bonusesCreateActivationCarryPlayers } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { useDispatch } from "react-redux";
import { changeBonusesCreateActivationCarryPlayers } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const options = [
  {
    value: 0,
    label: "No",
  },
  {
    value: 1,
    label: "Yes",
  },
];

const CarryPlayers = () => {
  const carryPlayers = useSelector(bonusesCreateActivationCarryPlayers);

  const dispatch = useDispatch();

  return (
    <Form.Item
      label="Carry players to the next round"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        defaultValue={carryPlayers}
        options={options}
        onChange={(x) => {
          dispatch(changeBonusesCreateActivationCarryPlayers(x));
        }}
      />
    </Form.Item>
  );
};

export default CarryPlayers;
