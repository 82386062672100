import React from "react";
import ListGameCategories from "./ListGameCategories";
import SelectCategory from "./SelectCategory";

const CalculateByCategories = () => {
  return (
    <div className="games_calculation_rules_container">
      <ListGameCategories />
      <SelectCategory />
    </div>
  );
};

export default CalculateByCategories;
