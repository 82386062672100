import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import freeGamesServices from "../../../../services/freeGamesServices";

export const createFreeGamesReducer = createAsyncThunk(
  "createFreeGamesReducer",
  async (id, thunkApi) => {
    const {
      freeGamesCreateType,
      freeGamesIsEdit,
      freeGamesCreateCode,
      freeGamesCreateDateFrom,
      freeGamesCreateDateTo,
      freeGamesCreateDescription,
      freeGamesCreateGameProvider,
      freeGamesCreateName,
      freeGamesCreateWins,
      freeGamesCreateSpins,
      freeGamesArray,
    } = thunkApi.getState().freeGames.create;

    const modifiedGames = freeGamesArray.map(({ identifier, ...rest }) => rest);

    const body = {
      casino_id: parseInt(thunkApi.getState().user.activeCasino),
      lobby: thunkApi.getState().user.lobby,
      type: freeGamesCreateType,
      provider_id: freeGamesCreateGameProvider,
      name: freeGamesCreateName,
      code: freeGamesCreateCode,
      description: freeGamesCreateDescription,
      period: [
        moment(freeGamesCreateDateFrom).unix(),
        moment(freeGamesCreateDateTo).unix(),
      ],
      //TODO: Check if amount is actually spins in create form
      amount: freeGamesCreateSpins,
      account: freeGamesCreateWins,
      game: modifiedGames,
      force: true,
    };

    if (freeGamesIsEdit) {
      try {
        const res = await freeGamesServices.editFreeGame(body, id);
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue();
      }
    } else {
      try {
        const res = await freeGamesServices.createFreeGame(body);
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        return thunkApi.rejectWithValue();
      }
    }
  }
);
