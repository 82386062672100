import { createSlice } from "@reduxjs/toolkit";
import { getFlagsReducer } from "./extra-reducers/getFlagsReducer";

const initialState = {
  flagsList: [],
  ui: {
    loadingFlagsList: false,
  },
};

export const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFlagsReducer.pending, (state) => {
      state.ui.loadingFlagsList = true;
    });
    builder.addCase(getFlagsReducer.fulfilled, (state, action) => {
      const array = [];
      action.payload.forEach((x, i) => array.push(x));
      state.flagsList = array;
      state.ui.loadingFlagsList = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = flagsSlice.actions;

export default flagsSlice.reducer;
