import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getTournamentsReducers } from "./extra-reducers/getTournamentsReducers";
import { getSingleTournaments } from "./extra-reducers/getSingleTournaments";
import { createEditTournament } from "./extra-reducers/createEditTournament";

const initialState = {
  filter: {
    state: null,
    dateFrom: moment("1900-01-01 00:00").startOf("day").format("YYYY-MM-DD"),
    dateTo: moment("2100-01-01 00:00").endOf("day").format("YYYY-MM-DD"),
    search: "",
  },
  tableData: [],
  create: {
    isEdit: false,
    step: 0,
    general: {
      name: null,
      identifier: null,
      type: 0,
      pause: 0,
      widgetWidth: 1,
      dateFrom: null,
      dateTo: null,
      state: 0,
      state_pretty: "",
      description: "",
      publicTitle: "",
      shortText: "",
      longText: "",
      bannerImageUrl: "",
      backgroundImageUrl: "",
    },
    activation: {
      activationMode: 0,
      activityNeeded: 0,
    },
    rules: {
      valueForCalculation: null,
      valueFilterFrom: null,
      valueFilterTo: null,
      calculateBy: 0,
      providerRules: [],
      gameRules: [],
      gameGroupRules: [],
    },
    achievement: {
      hitValueCalculation: 0,
      minimalValue: null,
      activitiesAfterBonus: [
        { type: 1, data: { account: 0, freespins_id: 0 } }, //freespins_id = account
      ],
      activityCreation: {
        type: 1,
        data: {},
      },
    },
  },
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalRows: 0,
  },
  ui: {
    tableDataLoading: false,
    submitting: false,
    isTournamentCreated: false,
    failedToCreateTournament: false,
    failedToCreateTournamentMessage: undefined,
  },
  rounds: {
    pagination: {
      page: 1,
      per_page: 10,
    },
    isOnCurrentPage: false,
  },
};

export const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {
    changePauseTournaments: (state, actions) => {
      state.create.general.pause = actions.payload;
    },
    resetTournamentState: (state) => initialState,
    changeTournamentsFilterState: (state, action) => {
      state.filter.state = action.payload;
    },
    changeTournamentsFilterDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload;
    },
    changeTournamentsFilterDateTo: (state, action) => {
      state.filter.dateTo = action.payload;
    },
    changeTournamentsFilterSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    changeTournamentsPageNumber: (state, action) => {
      state.pagination.pageNumber = action.payload;
    },
    //Create//
    changeTournamentsIsEdit: (state, action) => {
      state.create.isEdit = action.payload;
    },
    changeTournamentsStep: (state, action) => {
      state.create.step = action.payload;
    },
    /////// General
    changeTournamentsCreateName: (state, action) => {
      state.create.general.name = action.payload;
    },
    changeTournamentsCreateIdentifier: (state, action) => {
      state.create.general.identifier = action.payload;
    },
    changeTournamentsCreateType: (state, action) => {
      state.create.general.type = action.payload;
    },
    changeTournamentsCreateWidgetWidth: (state, action) => {
      state.create.general.widgetWidth = action.payload;
    },
    changeTournamentsCreateDateFrom: (state, action) => {
      state.create.general.dateFrom = action.payload;
    },
    changeTournamentsCreateDateTo: (state, action) => {
      state.create.general.dateTo = action.payload;
    },
    changeTournamentsCreateState: (state, action) => {
      state.create.general.state = action.payload;
    },
    changeTournamentsCreateDescription: (state, action) => {
      state.create.general.description = action.payload;
    },
    changeTournamentsCreatePublicTitle: (state, action) => {
      state.create.general.publicTitle = action.payload;
    },
    changeTournamentsCreateShortText: (state, action) => {
      state.create.general.shortText = action.payload;
    },
    changeTournamentsCreateLongText: (state, action) => {
      state.create.general.longText = action.payload;
    },
    changeTournamentsCreateBannerImageUrl: (state, action) => {
      state.create.general.bannerImageUrl = action.payload;
    },
    changeTournamentsCreateBackgroundImageUrl: (state, action) => {
      state.create.general.backgroundImageUrl = action.payload;
    },
    /////// Tournament Rounds ////////
    storeCurrentTournamentRoundsPagination: (state, action) => {
      state.rounds.pagination = action.payload;
    },
    outOfTournamentRoundsPage: (state, action) => {
      state.rounds.isOnCurrentPage = action.payload;
    },
    /// Activation
    changeTournamentsCreateActivationMode: (state, action) => {
      state.create.activation.activationMode = action.payload;
    },
    changeTournamentsCreateActivityNeeded: (state, action) => {
      state.create.activation.activityNeeded = action.payload;
    },

    /// Rules

    changeTournamentsValueForCalculation: (state, action) => {
      state.create.rules.valueForCalculation = action.payload;
    },
    changeTournamentsValueFilterTo: (state, action) => {
      state.create.rules.valueFilterTo = action.payload;
    },
    changeTournamentsValueFilterFrom: (state, action) => {
      state.create.rules.valueFilterFrom = action.payload;
    },
    changeTournamentsCalculateBy: (state, action) => {
      state.create.rules.calculateBy = action.payload;
    },
    changeTournamentsProviderRules: (state, action) => {
      state.create.rules.providerRules = action.payload;
    },
    changeTournamentsGameRules: (state, action) => {
      state.create.rules.gameRules = action.payload;
    },
    changeTournamentsGameGroupRules: (state, action) => {
      state.create.rules.gameGroupRules = action.payload;
    },
    ///
    addTournamentsCreateRulesProviderRules: (state, action) => {
      let added = false;
      state.create.rules.providerRules.forEach((rule) => {
        if (rule.id === action.payload.id) {
          added = true;
        }
      });
      if (!added) {
        state.create.rules.providerRules = [
          ...state.create.rules.providerRules,
          action.payload,
        ];
      }
    },
    removeTournamentsCreateRulesProviderRules: (state, action) => {
      state.create.rules.providerRules =
        // eslint-disable-next-line array-callback-return
        state.create.rules.providerRules.filter((a, i) => {
          if (i !== action.payload) {
            return a;
          }
        });
    },
    editTournamentsCreateRulesProviderRules: (state, action) => {
      state.create.rules.providerRules =
        state.create.rules.providerRules.filter((a, i) => {
          if (i === action.payload.i) {
            a.id = action.payload.id;
            a.percent = action.payload.percent;
          }
          return a;
        });
    },
    //
    addTournamentsCreateRulesGameRules: (state, action) => {
      let added = false;
      state.create.rules.gameRules.forEach((rule) => {
        if (rule.id === action.payload.id) {
          added = true;
        }
      });
      if (!added) {
        state.create.rules.gameRules = [
          ...state.create.rules.gameRules,
          action.payload,
        ];
      }
    },
    removeTournamentsCreateRulesGameRules: (state, action) => {
      state.create.rules.gameRules =
        // eslint-disable-next-line array-callback-return
        state.create.rules.gameRules.filter((a, i) => {
          if (i !== action.payload) {
            return a;
          }
        });
    },
    editTournamentsCreateRulesGameRules: (state, action) => {
      state.create.rules.gameRules = state.create.rules.gameRules.filter(
        (a, i) => {
          if (i === action.payload.i) {
            a.id = action.payload.id;
            a.percent = action.payload.percent;
            a.name = action.payload.name;
          }
          return a;
        }
      );
    },
    //
    addTournamentsCreateRulesGameGroupsRules: (state, action) => {
      let added = false;
      state.create.rules.gameGroupRules.forEach((rule) => {
        if (rule.id === action.payload.id) {
          added = true;
        }
      });
      if (!added) {
        state.create.rules.gameGroupRules = [
          ...state.create.rules.gameGroupRules,
          action.payload,
        ];
      }
    },
    removeTournamentsCreateRulesGameGroupsRules: (state, action) => {
      state.create.rules.gameGroupRules =
        // eslint-disable-next-line array-callback-return
        state.create.rules.gameGroupRules.filter((a, i) => {
          if (i !== action.payload) {
            return a;
          }
        });
    },
    editTournamentsCreateRulesGameGroupsRules: (state, action) => {
      state.create.rules.gameGroupRules =
        state.create.rules.gameGroupRules.filter((a, i) => {
          if (i === action.payload.i) {
            a.id = action.payload.id;
            a.percent = action.payload.percent;
          }
          return a;
        });
    },

    //// Achievement

    changeTournamentsCreateHitValueCalculation: (state, action) => {
      state.create.achievement.hitValueCalculation = action.payload;
    },
    changeTournamentsCreateMinimalValue: (state, action) => {
      state.create.achievement.minimalValue = action.payload;
    },
    changeTournamentsCreateActivitiesAfterBonus: (state, action) => {
      state.create.achievement.activitiesAfterBonus[action.payload.index].data =
        {
          // prettier-ignore
          ...state.create.achievement.activitiesAfterBonus[action.payload.index]?.data,
          ...action.payload.data,
        };
    },
    changeTournamentsAchievementActivitiesType: (state, action) => {
      const { index, type } = action.payload;
      state.create.achievement.activitiesAfterBonus[index].type = type;
    },

    addAchievementActivitiesAfterTournaments: (state) => {
      state.create.achievement.activitiesAfterBonus = [
        ...state.create.achievement.activitiesAfterBonus,
        state.create.achievement.activityCreation,
      ];
    },
    removeAchievementActivitiesAfterTournaments: (state, action) => {
      state.create.achievement.activitiesAfterBonus =
        // eslint-disable-next-line array-callback-return
        state.create.achievement.activitiesAfterBonus.filter((a, i) => {
          if (i !== action.payload) {
            return a;
          }
        });
    },

    changeTournamentsCreateAchievementActivitiesData: (state, action) => {
      state.create.achievement.activityCreation.data = action.payload;
    },

    resetTournamentsCreateAchievementActivitiesData: (state, action) => {
      state.create.achievement.activityCreation.data = {};
    },
    changeTournamentsAchievementActivitiesTypeCreation: (state, action) => {
      state.create.achievement.activityCreation.type = action.payload;
      state.create.achievement.activityCreation.data = {};
    },
    allowCreateTournament: (state) => {
      state.ui.failedToCreateTournament = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTournamentsReducers.pending, (state, action) => {
      state.ui.tableDataLoading = true;
    });
    builder.addCase(getTournamentsReducers.rejected, (state, action) => {
      state.ui.tableDataLoading = false;
    });
    builder.addCase(getTournamentsReducers.fulfilled, (state, action) => {
      state.ui.tableDataLoading = false;
      state.pagination.totalRows = action.payload.total;

      state.tableData = action.payload.data;
    });
    //
    builder.addCase(createEditTournament.pending, (state) => {
      state.ui.submitting = true;

      state.ui.failedToCreateTournament = false;

      state.ui.failedToCreateTournamentMessage = undefined;
    });
    builder.addCase(createEditTournament.fulfilled, (state, action) => {
      state.ui.submitting = false;
      state.ui.isTournamentCreated = true;
      state.ui.failedToCreateTournament = false;

      state.ui.failedToCreateTournamentMessage = undefined;
    });
    builder.addCase(createEditTournament.rejected, (state, action) => {
      state.ui.submitting = false;
      state.ui.failedToCreateTournament = true;

      state.ui.failedToCreateTournamentMessage = action.payload;
    });
    //

    builder.addCase(getSingleTournaments.pending, (stateQ, action) => {
      stateQ.ui.tableDataLoading = true;
    });

    builder.addCase(getSingleTournaments.rejected, (stateQ, action) => {
      stateQ.ui.tableDataLoading = false;
    });

    builder.addCase(getSingleTournaments.fulfilled, (stateQ, action) => {
      stateQ.ui.tableDataLoading = false;

      const {
        identifier,
        name,
        tournament_rounds,
        type,
        updated_at,
        pause,
        widget_width,
      } = action.payload.data;


      const {
        starts_at,
        ends_at,
        state,
        state_pretty,
        description,
        public_title,
        short_text_for_promotions,
        long_text_for_promotions,
        banner_image_url,
        activation_mode,
        activity_for_starting,
        value_for_calculation,
        value_filter_from,
        value_filter_to,
        hit_value_calculation_type,
        minimal_value,
        background_url,
        provider_factors,
        game_factors,
        game_category_factors,
        activities_after_achievement,
        //TODO: add game group factors in backend
        // game_group_factors,
      } = tournament_rounds[0];

      stateQ.create.general = {
        name,
        identifier,
        type,
        widgetWidth: widget_width,
        dateFrom: starts_at,
        dateTo: ends_at,
        pause: pause,
        state,
        state_pretty,
        description,
        publicTitle: public_title,
        shortText: short_text_for_promotions,
        longText: long_text_for_promotions,
        bannerImageUrl: banner_image_url,
        backgroundImageUrl: background_url,
      };

      stateQ.create.activation = {
        activationMode: activation_mode,
        activityNeeded: activity_for_starting,
      };

      stateQ.create.rules = {
        valueForCalculation: value_for_calculation,
        valueFilterFrom: value_filter_from,
        valueFilterTo: value_filter_to,
        calculateBy:
          provider_factors.length > 0
            ? 0
            : game_factors.length > 0
            ? 1
            : game_category_factors.length > 0
            ? 2
            : 0,
        providerRules: provider_factors.map((x) => {
          return {
            id: x.instance_id,
            percent: x.percentage,
          };
        }),
        gameRules: game_factors.map((x) => {
          return {
            id: x.instance_id,
            percent: x.percentage,
            name: x.ruleable.name,
          };
        }),

        gameGroupRules: game_category_factors.map((x) => {
          return {
            id: x.instance_id,
            percent: x.percentage,
            name: x.ruleable.name,
          };
        }),
      };

      stateQ.create.achievement = {
        hitValueCalculation: hit_value_calculation_type,
        minimalValue: minimal_value,
        activitiesAfterBonus: activities_after_achievement.map((x) => {
          const {
            account,
            activity_type,
            // created_at,
            freespins_id,
            // id,
            // locked_by_bonus,
            // max_amount_for_percentage,
            // ordinal_number,
            // percentage,
            // popup_text,
            position_from,
            position_to,
            reward_fixed,
            // tournament_round_id,
          } = x;

          return {
            type: activity_type,
            data: {
              account: account,
              rewardFixed: reward_fixed,
              freespins_id: freespins_id,
              positionFrom: position_from,
              positionTo: position_to,
              offer: freespins_id,
            },
          };
        }),
        activityCreation: {
          type: 1,
          data: {},
        },
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changePauseTournaments,
  resetTournamentState,
  changeTournamentsFilterState,
  changeTournamentsFilterDateFrom,
  changeTournamentsFilterDateTo,
  changeTournamentsFilterSearch,
  changeTournamentsPageNumber,
  changeTournamentsIsEdit,
  changeTournamentsStep,
  changeTournamentsCreateName,
  changeTournamentsCreateIdentifier,
  changeTournamentsCreateType,
  changeTournamentsCreateWidgetWidth,
  changeTournamentsCreateDateFrom,
  changeTournamentsCreateDateTo,
  changeTournamentsCreateState,
  changeTournamentsCreateDescription,
  changeTournamentsCreatePublicTitle,
  changeTournamentsCreateShortText,
  changeTournamentsCreateLongText,
  changeTournamentsCreateBannerImageUrl,
  changeTournamentsCreateBackgroundImageUrl,
  changeTournamentsCreateActivationMode,
  changeTournamentsCreateActivityNeeded,
  changeTournamentsValueForCalculation,
  changeTournamentsValueFilterTo,
  changeTournamentsValueFilterFrom,
  changeTournamentsCalculateBy,
  changeTournamentsProviderRules,
  changeTournamentsGameRules,
  changeTournamentsGameGroupRules,
  addTournamentsCreateRulesProviderRules,
  removeTournamentsCreateRulesProviderRules,
  editTournamentsCreateRulesProviderRules,
  addTournamentsCreateRulesGameRules,
  removeTournamentsCreateRulesGameRules,
  editTournamentsCreateRulesGameRules,
  addTournamentsCreateRulesGameGroupsRules,
  removeTournamentsCreateRulesGameGroupsRules,
  editTournamentsCreateRulesGameGroupsRules,
  changeTournamentsCreateHitValueCalculation,
  changeTournamentsCreateMinimalValue,
  addAchievementActivitiesAfterTournaments,
  removeAchievementActivitiesAfterTournaments,
  changeTournamentsAchievementActivitiesTypeCreation,
  changeTournamentsCreateAchievementActivitiesData,
  resetTournamentsCreateAchievementActivitiesData,
  changeTournamentsAchievementActivitiesType,
  changeTournamentsCreateActivitiesAfterBonus,
  storeCurrentTournamentRoundsPagination,
  outOfTournamentRoundsPage,
  allowCreateTournament,
} = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
