import { Form, InputNumber } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesValueFilterFromSelector } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateRulesValueFrom } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const ValueFilterFrom = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateRulesValueFilterFromSelector);

  return (
    <Form.Item
      label="Value filter from"
      name="value_filter_from"
    >
      <InputNumber
        style={{ width: "100%" }}
        value={value}
        defaultValue={value}
        placeholder="From"
        onChange={(e) => {
          dispatch(changeBonusesCreateRulesValueFrom(e));
        }}
      />
    </Form.Item>
  );
};

export default ValueFilterFrom;
