import React, { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  activationLockedBySelector,
  bonusesTableDataSelector,
} from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { getBonusesDataReducers } from "../../../../../../../../redux/slices/bonuses/extra-reducers/getBonusesDataReducers";
import { changeActivationLockedBy } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ActivationLockBy = () => {
  const dispatch = useDispatch();
  const [externalBonus, setExternalBonus] = useState([]);
  // const [options, setOptions] = useState([{label: 'None', value: 0}])

  const options = [
    {
      value: 0,
      label: "Registration",
    },
    {
      value: 1,
      label: "Deposit",
    },
    {
      value: 2,
      label: "Turnover",
    },
  ];

  const value = useSelector(activationLockedBySelector);
  const bonuses = useSelector(bonusesTableDataSelector);

  useEffect(() => {
    if (bonuses[0] === null) return;

    setExternalBonus(bonuses.filter((bonus) => bonus.activation_mode === 2));
  }, [bonuses]);

  // useEffect(() => {
  //   if (externalBonus.length === 0) return;

  //   const opt = externalBonus.map((bonus) => {
  //     return { label: bonus.name, value: bonus.id };
  //   });

  //   setOptions([{ label: "None", value: 0 }, ...opt]);
  // }, [externalBonus]);

  return (
    <Form.Item label="Bonus type" name="activation_locked_by">
      <Select
        value={value}
        defaultValue={value}
        options={options}
        onChange={(e) => {
          dispatch(changeActivationLockedBy(e));
        }}
      />
    </Form.Item>
  );
};

export default ActivationLockBy;
