import { Avatar } from "antd";
import GameGroupGamesListActions from "./GameGroupGamesListActions";

export const gamesTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "4px",
          }}
        >
         {/* <Avatar
            src={record.photo}
            alt={record.name + " icon"}
            style={{ width: "32px", height: "32px" }}
          /> */}
          <span>{record.name}</span>
        </div>
      );
    },
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
    render: (text, record) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "4px",
          }}
        >
          <Avatar
            src={record?.provider?.photo}
            alt={record.name + " icon"}
            style={{ width: "32px", height: "32px" }}
          />
          <span>{record?.provider?.name}</span>
        </div>
      );
    },
  },

  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            padding: "4px",
          }}
        >
          <Avatar
            src={record?.provider?.photo}
            alt={record.name + " icon"}
            style={{ visibility: "hidden", width: "0" }}
          />
          <span>{record.id}</span>
        </div>
      );
    },
  },

  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => {
      return <GameGroupGamesListActions record={record} />;
    },
  },
];
