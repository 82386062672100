import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllGamesByProvider } from "../../../../services/gamesServices";

export const getGamesByProviderReducer = createAsyncThunk(
  "getGamesByProviderReducer",
  async (id, thunkApi) => {
    try {
      const casino_id = await thunkApi.getState().user.activeCasino;

      const res = await getAllGamesByProvider(id, { casino_id });

      if (res.status !== 200) throw new Error();

      return res.data;
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);

export const getGamesByProviderStatReducer = createAsyncThunk(
  "getGamesByProviderStatReducer",
  async (id, thunkApi) => {
    try {
      const casino_id = await thunkApi.getState().user.activeCasino;

      const res = await getAllGamesByProvider(id, { casino_id });

      if (res.status !== 200) throw new Error();

      return res.data;
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
