import axios from "axios";

const defaultRoute = "/providers";
const getProviders = async () => {
  const result = await axios(defaultRoute);
  return result;
};
const getAllProviders = async (params) => {
  const result = await axios(defaultRoute + "/all", { params });
  return result;
};
export const providersServices = { getProviders, getAllProviders };
