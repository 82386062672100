import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tag, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { tableColumnHandler } from "../../../../utils/tableColumnHandler";
import {
  removeCurrentGame,
  onSelectedRowKeys,
} from "../../../../redux/slices/lobby/lobbySlice";
import { selectRowKeys } from "../../../../redux/slices/lobby/lobbySelector";

const GamePickerActions = ({ record }) => {
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector(selectRowKeys);

  const handleRemove = (id) => {
    const filterRowKeys = selectedRowKeys.filter((key) => key !== id);
    dispatch(removeCurrentGame(id));
    dispatch(onSelectedRowKeys(filterRowKeys));
  };
  return (
    <Button
      icon={<DeleteOutlined />}
      type="text"
      danger
      onClick={() => handleRemove(record.id)}
    />
  );
};

export const lobbyGamesColumns = [
  {
    ...tableColumnHandler("Id", "id"),
    width: 80,
    sorter: (a, b) => {
      const first = a.id;
      const second = b.id;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Name", "game_name"),
    width: 220,
    render: (name, game) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game.game_image && (
            <Avatar
              shape="circle"
              src={game.game_image}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.game_name.toLowerCase();
      const second = b.game_name.toLowerCase();
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Provider", "provider_name"),
    width: 200,
    render: (name, game) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game.provider_image && (
            <Avatar
              shape="circle"
              src={game.provider_image}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.provider_name.toLowerCase();
      const second = b.provider_name.toLowerCase();
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Category", "game_category"),
    width: 150,
    sorter: (a, b) => {
      const first = a.game_category.toLowerCase();
      const second = b.game_category.toLowerCase();
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Flags", "flags"),
    width: 100,
    render: (tag, game) => {
      const color = tag === "Neww" ? "volcano" : "purple";
      return (
        tag && (
          <Tag key={game.flags} color={color}>
            {tag}
          </Tag>
        )
      );
    },
  },
];

export const lobbyCurrentGamesColumns = [
  {
    ...tableColumnHandler("Id", "id"),
    width: 80,
    sorter: (a, b) => {
      const first = a.id;
      const second = b.id;
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Name", "game_name"),
    width: 220,
    render: (name, game) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game.game_image && (
            <Avatar
              shape="circle"
              src={game.game_image}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.game_name.toLowerCase();
      const second = b.game_name.toLowerCase();
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Provider", "provider_name"),
    width: 150,
    render: (name, game) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {game.provider_image && (
            <Avatar
              shape="circle"
              src={game.provider_image}
              style={{ marginRIght: "6px" }}
              size="small"
            />
          )}
          <div>{name}</div>
        </div>
      );
    },
    sorter: (a, b) => {
      const first = a.provider_name.toLowerCase();
      const second = b.provider_name.toLowerCase();
      return first > second ? -1 : first < second ? 1 : 0;
    },
  },
  {
    ...tableColumnHandler("Actions", "actions"),
    width: 100,
    align: "right",
    render: (_, record) => <GamePickerActions record={record} />,
  },
];
