import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateGeneralButtonUrlSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateButtonUrl } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ButtonUrl = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateGeneralButtonUrlSelector);
  return (
    <Form.Item label="Button url" name="button_url">
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateButtonUrl(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default ButtonUrl;
