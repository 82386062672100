import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Avatar } from "antd";
import { getProvidersReducer } from "../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import { providersListSelector } from "../../../../../redux/slices/providers/providersSelectors";
import { changeStakesProvider } from "../../../../../redux/slices/stakes/stakeSlice";
import { stakesProviderSelector } from "../../../../../redux/slices/stakes/stakesSelector";

const StakesProviderSelect = () => {
  const dispatch = useDispatch();
  const providersList = useSelector(providersListSelector);
  const providerValue = useSelector(stakesProviderSelector);

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  const handleProviderChange = (provider) => {
    dispatch(changeStakesProvider(provider));
  };

  return (
    <Select
      value={providerValue}
      dropdownMatchSelectWidth={false}
      onChange={(prop) => handleProviderChange(prop)}
      placeholder="Select Provider"
      allowClear
    >
      {providersList.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            <Avatar
              src={item.photo}
              style={{ marginRight: "4px" }}
              size="small"
            />
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default StakesProviderSelect;
