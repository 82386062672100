import "./FreeGamesCreate.css";
import { PageHeader } from "antd";
import FreeGamesAnt from "./FreeGamesFormAnt";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  changeFreeGamesIsEdit,
  resetFreeGamesSlice,
} from "../../../../redux/slices/free-games/freeGamesSlice";
import { getSingleFreeGamesReducer } from "../../../../redux/slices/free-games/extra-reducers/getSingleFreeGamesReducer";
import {
  freeGamesCreatedSelector,
  freeGamesIsEditSelector,
  freeGamesLoadingDataTable,
} from "../../../../redux/slices/free-games/freeGamesSelector";
import Loader from "../../../../layouts/components/Loader";

function FreeGamesCreate() {
  const dispatch = useDispatch();
  const location = useLocation();

  const isEdit = useSelector(freeGamesIsEditSelector);
  const isLoading = useSelector(freeGamesLoadingDataTable);

  const freeGamesCreated = useSelector(freeGamesCreatedSelector);
  const history = useHistory();

  if (freeGamesCreated) {
    history.push("/free-games");
  }

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      var baseUrl = window.location.href;
      var id = baseUrl.substring(baseUrl.lastIndexOf("/") + 1);
      dispatch(changeFreeGamesIsEdit(true));
      dispatch(getSingleFreeGamesReducer(id));
    } else {
      dispatch(changeFreeGamesIsEdit(false));
    }

    return () => {
      dispatch(resetFreeGamesSlice());
    };
  }, [dispatch, location.pathname]);

  const hanleGoBack = () => history.goBack();

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        title={isEdit ? "Edit Free Game" : "Create Free Games"}
      ></PageHeader>
      <div className="free_games_form">
        {isLoading ? <Loader /> : <FreeGamesAnt />}
      </div>
    </div>
  );
}

export default FreeGamesCreate;
