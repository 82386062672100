import Input from "antd/lib/input/Input";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStakesPlayerId } from "../../../../../redux/slices/stakes/stakeSlice";
import { stakesPlayerIdSelector } from "../../../../../redux/slices/stakes/stakesSelector";

const StakesPlayerId = () => {
  const dispatch = useDispatch();
  const playerID = useSelector(stakesPlayerIdSelector);

  return (
    <Input
      style={{ width: "250px" }}
      placeholder="Player ID"
      value={playerID}
      onChange={(e) => {
        dispatch(changeStakesPlayerId(e.target.value));
      }}
    />
  );
};

export default StakesPlayerId;
