import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesGameGroupsRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import {
  editBonusesCreateRulesGameGroupsRules,
  removeBonusesCreateRulesGameGroupsRules,
} from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
import { loadingGameGroupsListSelector } from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";
import { gameGroupsListSelector } from "../../../../../../../../redux/slices/game-groups/gameGroupSelectors";

const SingleGameGroupRule = ({ id, percent, i }) => {
  const gameGroupsList = useSelector(gameGroupsListSelector);
  const loadingGameGroupsList = useSelector(loadingGameGroupsListSelector);
  const gameGroupsRules = useSelector(
    bonusesCreateRulesGameGroupsRulesSelector
  );

  const [percentage, setPercentage] = useState(percent);

  const dispatch = useDispatch();
  return (
    <div className="rules_single_provider">
      <Select
        value={id}
        dropdownMatchSelectWidth={false}
        loading={loadingGameGroupsList}
        onChange={(x) => {
          dispatch(
            editBonusesCreateRulesGameGroupsRules({ i, id: x, percentage })
          );
        }}
        style={{ width: "100%" }}
        placeholder="Select Game Group"
      >
        {gameGroupsList.map((item) => {
          let available = true;
          gameGroupsRules.forEach((x) => {
            if (x.id === item.id && x.id !== id) {
              available = false;
            }
          });
          if (available) {
            return (
              <Select.Option key={item.id} value={item.id}>
                <Avatar
                  src={item.photo}
                  style={{ marginRight: "4px" }}
                  size="small"
                />
                {item.name}
              </Select.Option>
            );
          }
        })}
      </Select>
      <Input
        value={percentage}
        suffix="%"
        type="number"
        onChange={(e) => {
          setPercentage(e.target.value);
          dispatch(
            editBonusesCreateRulesGameGroupsRules({
              i,
              id,
              percent: e.target.value,
            })
          );
        }}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<DeleteOutlined />}
        size="middle"
        danger={true}
        onClick={() => {
          dispatch(removeBonusesCreateRulesGameGroupsRules(i));
        }}
      />
    </div>
  );
};

export default SingleGameGroupRule;
