import { Avatar, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { freeGamesFilterProvider } from "../../../../../../redux/slices/free-games/freeGamesSelector";
import { changeFreeGamesFilterProvider } from "../../../../../../redux/slices/free-games/freeGamesSlice";
import { getProvidersReducer } from "../../../../../../redux/slices/providers/extra-reducers/getProvidersReducer";
import { providersListSelector } from "../../../../../../redux/slices/providers/providersSelectors";

const GameProviderSelect = () => {
  const dispatch = useDispatch();
  const providersList = useSelector(providersListSelector);
  const value = useSelector(freeGamesFilterProvider);

  useEffect(() => {
    dispatch(getProvidersReducer());
  }, [dispatch]);

  return (
    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      onChange={(x) => {
        dispatch(changeFreeGamesFilterProvider(x));
      }}
      placeholder="- Select Provider -"
      allowClear
    >
      {providersList.map((item) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            <Avatar
              src={item.photo}
              style={{ marginRight: "4px" }}
              size="small"
            />
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default GameProviderSelect;
