import { current } from "@reduxjs/toolkit";

export const rulesReducers = {
  changeBonusesCreateRulesValueFor: (state, action) => {
    state.create.rules.valueForCalculation = action.payload;
  },
  changeBonusesCreateRulesValueFrom: (state, action) => {
    state.create.rules.valueFilterFrom = action.payload;
  },
  changeBonusesCreateRulesValueTo: (state, action) => {
    state.create.rules.valueFilterTo = action.payload;
  },
  changeBonusesCreateRulesProviderRules: (state, action) => {
    state.create.rules.providerRules = action.payload;
  },
  addBonusesCreateRulesProviderRules: (state, action) => {
    let added = false;
    state.create.rules.providerRules.forEach((rule) => {
      if (rule.id === action.payload.id) {
        added = true;
      }
    });
    if (!added) {
      state.create.rules.providerRules = [
        ...state.create.rules.providerRules,
        action.payload,
      ];
    }
  },
  removeBonusesCreateRulesProviderRules: (state, action) => {
    state.create.rules.providerRules =
      // eslint-disable-next-line array-callback-return
      state.create.rules.providerRules.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },
  editBonusesCreateRulesProviderRules: (state, action) => {
    state.create.rules.providerRules = state.create.rules.providerRules.filter(
      (a, i) => {
        if (i === action.payload.i) {
          a.id = action.payload.id;
          a.percent = action.payload.percent;
        }
        return a;
      }
    );
  },
  //
  addBonusesCreateRulesGameRules: (state, action) => {
    let added = false;
    state.create.rules.gameRules.forEach((rule) => {
      if (rule.id === action.payload.id) {
        added = true;
      }
    });
    if (!added) {
      state.create.rules.gameRules = [
        ...state.create.rules.gameRules,
        action.payload,
      ];
    }
  },
  removeBonusesCreateRulesGameRules: (state, action) => {
    state.create.rules.gameRules =
      // eslint-disable-next-line array-callback-return
      state.create.rules.gameRules.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },
  editBonusesCreateRulesGameRules: (state, action) => {
    state.create.rules.gameRules = state.create.rules.gameRules.filter(
      (a, i) => {
        if (i === action.payload.i) {
          a.id = action.payload.id;
          a.percent = action.payload.percent;
        }
        return a;
      }
    );
  },
  //
  addBonusesCreateRulesGameGroupsRules: (state, action) => {
    let added = false;
    state.create.rules.gameGroupRules.forEach((rule) => {
      if (rule.id === action.payload.id) {
        added = true;
      }
    });
    if (!added) {
      state.create.rules.gameGroupRules = [
        ...state.create.rules.gameGroupRules,
        action.payload,
      ];
    }
  },
  removeBonusesCreateRulesGameGroupsRules: (state, action) => {
    state.create.rules.gameGroupRules =
      // eslint-disable-next-line array-callback-return
      state.create.rules.gameGroupRules.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },
  editBonusesCreateRulesGameGroupsRules: (state, action) => {
    // console.log(
    //   "mijenja se",
    //   state.create,
    //   action.payload,
    //   "aaa",
    //   state.create.rules.gameGroupRules.some(
    //     (rule) => rule === action.payload.id
    //   )
    // );
    // if (
    //   state.create.rules.gameGroupRules.some(
    //     (rule) => rule.ruleable.id === action.payload.id
    //   )
    // ) {
    // *TODO: ukoliko se id poklapa potrebno je zamijeniti procenat
    // state.create.rules.gameGroupRules = state.create.rules.gameGroupRules.map(
    //   (rule) => {
    //     if (rule.rulable.id === action.payload.id) {
    //       rule.percentage = action.payload.percentage;
    //     }
    //     return rule;
    //   }
    // );
    // }

    // ^^^NE RADI^^^

    state.create.rules.gameGroupRules =
      state.create.rules.gameGroupRules.filter((a, i) => {
        if (i === action.payload.i) {
          a.id = action.payload.id;
          a.percentage = action.payload.percent; //* changed percent property on 'a' item to percentage
        }
        return a;
      });
  },
  //
  //
  addBonusesCreateRulesGameCategoriesRules: (state, action) => {
    let added = false;
    state.create.rules.gameCategories.forEach((rule) => {
      if (rule.id === action.payload.id) {
        added = true;
      }
    });
    if (!added) {
      state.create.rules.gameCategories = [
        ...state.create.rules.gameCategories,
        action.payload,
      ];
    }
  },
  removeBonusesCreateRulesGameCategoriesRules: (state, action) => {
    state.create.rules.gameCategories =
      // eslint-disable-next-line array-callback-return
      state.create.rules.gameCategories.filter((a, i) => {
        if (i !== action.payload) {
          return a;
        }
      });
  },
  editBonusesCreateRulesGameCategoriesRules: (state, action) => {
    state.create.rules.gameCategories =
      state.create.rules.gameCategories.filter((a, i) => {
        if (i === action.payload.i) {
          a.id = action.payload.id;
          a.percent = action.payload.percent;
        }
        return a;
      });
  },
  //
  changeBonusesCreateRulesGameRules: (state, action) => {
    state.create.rules.gameRules = action.payload;
  },
  changeBonusesCreateRulesCalculateBy: (state, action) => {
    state.create.rules.calculateBy = action.payload;
  },
};
