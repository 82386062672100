import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesStateSelector } from "../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesState } from "../../../../../../redux/slices/bonuses/bonusesSlice";
import { STATE_OPTIONS } from "./contants";

const State = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesStateSelector);

  return (

    <Select
      value={value}
      dropdownMatchSelectWidth={false}
      onChange={(x) => {
        dispatch(changeBonusesState(x));
      }}
      allowClear
      placeholder="Select state"
      options={STATE_OPTIONS}
    />
  );
};

export default State;
