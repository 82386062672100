import React from "react";
import { Divider, Form } from "antd";
import FreeGamesName from "./free-games-components/FreeGamesName";
import FreeGamesCode from "./free-games-components/FreeGamesCode";
import FreeGamesDescription from "./free-games-components/FreeGamesDescription";
import FreeGamesDataRange from "./free-games-components/FreeGamesDataRange";
import FreeGamesWinsSelect from "./free-games-components/FreeGamesWinsSelect";
import FreeGamesGameProvider from "./free-games-components/FreeGamesGameProvider";
import FreeGamesSelectGame from "./free-games-components/FreeGamesSelectGame";
import FreeGamesButtonAdd from "./free-games-components/FreeGamesButtonAdd";
import FreeGamesSaveButton from "./free-games-components/FreeGamesSaveButton";
import FreeGamesSpins from "./free-games-components/FreeGamesSpins";
import FreeGamesBet from "./free-games-components/FreeGamesBet";
import FreeGamesTable from "./free-games-components/FreeGamesTable";
import FreeGamesType from "./free-games-components/FreeGamesType";

const FreeGamesAnt = () => {
  return (
    <Form layout="horizontal" className="free_games_form">
      <div className="free_games_form_column">
        <Divider />
        <FreeGamesName />
        <FreeGamesCode />
        <FreeGamesDescription />
        <FreeGamesDataRange />
        <FreeGamesWinsSelect />
        <FreeGamesGameProvider />
        <FreeGamesType />
        <FreeGamesSpins />
      </div>
      <div className="free_games_form_column">
        <Divider />
        <FreeGamesSelectGame />
        {/* <FreeGamesBetType /> */}
        {/* <FreeGamesLines /> */}
        {/* <FreeGamesCoinSize /> */}
        {/* <FreeGamesCoinCount /> */}
        <FreeGamesBet />
        <FreeGamesButtonAdd />
        <FreeGamesTable />
        <Divider />
        <FreeGamesSaveButton />
      </div>
    </Form>
  );
};
export default FreeGamesAnt;
