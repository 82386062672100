import { Form, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesUsesWhitelistSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesUsersWhitelist } from "../../../../../redux/slices/games/gamesSlice";

const GamesUsesWhitelist = () => {
  const dispatch = useDispatch();
  const value = useSelector(gamesUsesWhitelistSelector);

  return (
    <Form.Item label="Uses Whitelist" name="uses_whitelist">
      <Switch
        defaultChecked={value}
        onChange={(e) => {
          dispatch(changeGamesUsersWhitelist(e));
        }}
      />
    </Form.Item>
  );
};

export default GamesUsesWhitelist;
