import React from "react";
import { useDispatch } from "react-redux";

import AchievementReview from "./AchievementReview";
import ActivationReview from "./ActivationReview";
import GeneralReview from "./GeneralReview";

import "./Review.css";
import RulesReview from "./RulesReview";
import { useSelector } from "react-redux";
import {
  failedToCreateTournamentMessageSelector,
  failedToCreateTournamentSelector,
} from "../../../../../../../redux/slices/tournaments/tournamentsSelector";

const Review = () => {
  const failedToCreateTournament = useSelector(
    failedToCreateTournamentSelector
  );
  const failedToCreateTournamentMessage = useSelector(
    failedToCreateTournamentMessageSelector
  );

  return (
    <div>
      {failedToCreateTournament && (
        <div className="failed_to_create_tournament">
          {failedToCreateTournamentMessage}
        </div>
      )}
      <div className="review_container">
        <GeneralReview />
        <ActivationReview />
        <RulesReview />
        <AchievementReview />
      </div>
    </div>
  );
};

export default Review;
