import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import {
  statisticsDateFromSelector,
  statisticsDateToSelector,
  statisticsFilterBySelector,
  statisticsTableDataSelector,
} from "../../../../redux/slices/statistics/statisticsSelector";
import {
  statisticColumnsGamesHeaders,
  statisticColumnsPlayerGamesHeaders,
  statisticColumnsPlayersHeaders,
  statisticColumnsProvidersHeaders,
  statisticColumnsTotalHeaders,
} from "./statisticsHeaders";

const StatisticsExportButton = () => {
  const statisticsTableData = useSelector(statisticsTableDataSelector);
  const statisticsDateFrom = useSelector(statisticsDateFromSelector);
  const statisticsDateTo = useSelector(statisticsDateToSelector);
  const statisticsFilterBy = useSelector(statisticsFilterBySelector);

  return (
    <CSVLink
      headers={
        statisticsFilterBy === 1
          ? statisticColumnsTotalHeaders
          : statisticsFilterBy === 2
          ? statisticColumnsGamesHeaders
          : statisticsFilterBy === 3
          ? statisticColumnsProvidersHeaders
          : statisticsFilterBy === 4
          ? statisticColumnsPlayersHeaders
          : statisticsFilterBy === 5
          ? statisticColumnsPlayerGamesHeaders
          : []
      }
      enclosingCharacter=""
      separator=";"
      data={statisticsTableData}
      filename={`${statisticsDateFrom.slice(0, 10)}-${statisticsDateTo.slice(
        0,
        10
      )}-${
        statisticsFilterBy === 1
          ? "Total"
          : statisticsFilterBy === 2
          ? "Games"
          : statisticsFilterBy === 3
          ? "Providers"
          : statisticsFilterBy === 4
          ? "Players"
          : "Players / Games"
      } `}
    >
      <Button
        type="link"
        shape="round"
        icon={<DownloadOutlined />}
        size={"middle"}
        style={{ color: "#fff" }}
      >
        Export
      </Button>
    </CSVLink>
  );
};

export default StatisticsExportButton;
