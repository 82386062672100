import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { createEditBonusReducer } from "./extra-reducers/createBonusReduces";
import { getBonusesDataReducers } from "./extra-reducers/getBonusesDataReducers";
import { getBonusesRoundsData } from "./extra-reducers/getBonusesRoundsData";
import { getParticipation } from "./extra-reducers/getParticipation";
import { getSingleBonusDataReducers } from "./extra-reducers/getSingleBonusDataReducers";
import { achievementReducers } from "./reducers/create/achievement-reducers/achievementReducers";
import { activationReducers } from "./reducers/create/activation-reducers/activationReducers";
import { generalReducers } from "./reducers/create/general-reducers/generalReducers";
import { rulesReducers } from "./reducers/create/rules-reducers/rulesReducers";

const initialState = {
  state: null,
  callFrom: "",
  dateFrom: moment().format("1900-01-01").toString(),
  dateTo: moment().format("2100-01-01").toString(),
  search: null,
  bonusesSortBy: null,
  bonusesSortDirection: null,
  pageNumber: 1,
  totalRows: 0,
  totalPagesParticipants: 0,
  toParticipants: 0,
  perPageParticipants: 20,
  currentPageParticipants: 1,
  pageSize: 10,
  bonusesTableData: [null],
  bonusesRounds: [],
  bonusParticipation: [],
  isBonusManual: false,
  searchBonusParticipants: "",
  ui: {
    isDataLoading: false,
    submitting: false,
    bonusCreated: false,
    isRoundsDataLoading: false,
  },
  create: {
    isEdit: false,
    id_bonus: null,
    step: 0,
    general: {
      name: null,
      type: 0,
      pause: 0,
      dateFrom: null,
      dateTo: null,
      achievementPeriodType: 0,
      achievementPeriodTime: 0,
      state: null,
      description: null,
      publicTitle: null,
      shortText: null,
      longText: null,
      bannerUrl: null,
      infoUrl: null,
      buttonText: null,
      buttonUrl: null,
      progressBarStyle: 0,
    },
    activation: {
      activationMode: 0,
      activityNeeded: 0,
      carryPlayers: 0,
      depositMinAmount: null,
      depositSequence: null,
      participationMode: 0,
      participationLimit: null,
      activationCode: null,
      activationLockedBy: 0,
      autoRepeat: false,
      players: [],
      activitiesAfterBonus: [
        // {
        //   type: 0,
        //   data: {},
        // },
      ],
      activityCreation: {
        type: 0,
        data: {},
      },
    },
    rules: {
      valueForCalculation: null,
      valueFilterFrom: null,
      valueFilterTo: null,
      calculateBy: 0,
      providerRules: [],
      gameRules: [],
      gameGroupRules: [],
      gameCategories: [],
    },
    achievement: {
      hitValueCalculation: 0,
      minimalValue: null,
      activitiesAfterBonus: [
        {
          type: 0,
          data: {
            account: 0,
          },
        },
      ],
      activityCreation: {
        type: 0,
        data: {},
      },
    },
    review: {},
  },
};

export const bonusesSlice = createSlice({
  name: "bonuses",
  initialState,
  reducers: {
    changeCallFrom: (state, actions) => {
      state.callFrom = actions.payload;
    },
    changeActivationLockedBy: (state, actions) => {
      state.create.activation.activationLockedBy = actions.payload;
    },
    changePause: (state, actions) => {
      state.create.general.pause = actions.payload;
    },
    changePerPageParticipants: (state, action) => {
      state.perPageParticipants = action.payload;
    },
    changeCurrentPageParticipants: (state, action) => {
      state.currentPageParticipants = action.payload;
    },
    changeSearchBonusParticipants: (state, actions) => {
      state.searchBonusParticipants = actions.payload;
    },
    restartRulesForCalculation: (state) => {
      state.create.rules.providerRules = [];
      state.create.rules.gameRules = [];
      state.create.rules.gameGroupRules = [];
    },
    setDefaultOfferAndFreespinsId: (state, action) => {
      state.create.achievement.activitiesAfterBonus[action.payload].data.offer =
        null;
      state.create.achievement.activitiesAfterBonus[
        action.payload
      ].data.freespins_id = null;
      state.create.achievement.activitiesAfterBonus[
        action.payload
      ].data.bonus_id = null;
    },
    changeBonusesCreateActivationCarryPlayers: (state, action) => {
      state.create.activation.carryPlayers = action.payload;
    },
    changeBonusesState: (state, action) => {
      state.state = action.payload;
    },
    changeBonusesCreateType: (state, action) => {
      state.create.general.type = action.payload;
    },
    changeBonusesDateFrom: (state, action) => {
      state.dateFrom = action.payload;
    },
    changeBonusesDateTo: (state, action) => {
      state.dateTo = action.payload;
    },
    changeBonusesSearch: (state, action) => {
      state.search = action.payload;
    },
    changeBonusesSortBy: (state, action) => {
      state.bonusesSortBy = action.payload;
    },
    changeBonusesSortDirection: (state, action) => {
      state.bonusesSortDirection = action.payload;
    },
    changeBonusesPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    changeBonusesPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    changeBonusesCreateStep: (state, action) => {
      state.create.step = action.payload;
    },
    changeBonusesCreateIsEdit: (state, action) => {
      state.create.isEdit = action.payload;
    },

    ...generalReducers,
    ...activationReducers,
    ...rulesReducers,
    ...achievementReducers,
    resetBonusSlice: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getBonusesDataReducers.pending, (state) => {
      state.ui.isDataLoading = true;
    });
    builder.addCase(getBonusesDataReducers.fulfilled, (state, action) => {
      state.ui.isDataLoading = false;
      state.bonusesTableData = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(getBonusesDataReducers.rejected, (state, action) => {
      state.ui.isDataLoading = false;
    });

    builder.addCase(createEditBonusReducer.pending, (state) => {
      state.ui.submitting = true;
    });
    builder.addCase(createEditBonusReducer.fulfilled, (state, action) => {
      state.ui.submitting = false;
      state.ui.bonusCreated = true;
    });
    builder.addCase(createEditBonusReducer.rejected, (state, action) => {
      state.ui.submitting = false;
    });

    builder.addCase(getBonusesRoundsData.pending, (state) => {
      state.ui.isRoundsDataLoading = true;
    });
    builder.addCase(getBonusesRoundsData.fulfilled, (state, action) => {
      state.ui.isRoundsDataLoading = false;

      state.bonusesRounds = action.payload.data;
      state.totalRows = action.payload.total;
    });
    builder.addCase(getBonusesRoundsData.rejected, (state, action) => {
      state.ui.isRoundsDataLoading = false;
    });

    //

    builder.addCase(getSingleBonusDataReducers.rejected, (state, action) => {
      state.ui.isDataLoading = false;
    });
    builder.addCase(getSingleBonusDataReducers.pending, (state, action) => {
      state.ui.isDataLoading = true;
    });

    builder.addCase(getSingleBonusDataReducers.fulfilled, (state, action) => {
      state.ui.isDataLoading = false;

      const queryString = window.location.search;
      const parameters = new URLSearchParams(queryString);
      const value = parameters.get("round");

      const {
        activation_code,
        activation_mode,
        activation_locked_by,
        carry_players,
        active_round,
        activity_for_starting,
        auto_repeat,
        banner_image_url,
        button_text,
        button_url,
        deposit_min_amount,
        deposit_sequence,
        freespins_id,
        pause,
        description,
        info_url,
        long_text_for_promotions,
        name,
        participation_limit,
        participation_mode,
        players,
        progress_bar_style,
        public_title,
        short_text_for_promotions,
        bonus_rounds,
      } = action.payload.data;

      let {
        activities_after_achievement,
        activities_after_activation,
        ends_at,
        game_rules,
        game_group_rules,
        game_categories,
        hit_value_calculation_type,
        minimal_value,
        provider_rules,
        starts_at,
        // state,
        value_filter_from,
        value_filter_to,
        value_for_calculation,
      } = value === "active" ? active_round : bonus_rounds[0];

      starts_at = moment(starts_at).utc().toISOString();
      ends_at = moment(ends_at).utc().toISOString();

      state.create.general = {
        name,
        type: auto_repeat,
        dateFrom: starts_at,
        dateTo: ends_at,
        pause: pause,
        achievementPeriodType: 0,
        achievementPeriodTime: 0,
        state: active_round ? active_round.state : bonus_rounds[0].state,
        description,
        publicTitle: public_title,
        shortText: short_text_for_promotions,
        longText: long_text_for_promotions,
        bannerUrl: banner_image_url,
        infoUrl: info_url,
        buttonText: button_text,
        buttonUrl: button_url,
        progressBarStyle: progress_bar_style,
      };

      if (value === "active") {
        state.create.general.state = 2;
      } else {
        state.create.general.state = bonus_rounds[0].state;
      }

      state.create.activation = {
        activationMode: activation_mode,
        activityNeeded: activity_for_starting,
        carryPlayers: carry_players,
        autoRepeat: auto_repeat,
        depositMinAmount: deposit_min_amount,
        depositSequence: deposit_sequence,
        participationMode: participation_mode,
        participationLimit: participation_limit,
        activationCode: activation_code,
        activationLockedBy: activation_locked_by ? activation_locked_by : 0,
        players: players ? players : [],
        activitiesAfterBonus: [
          ...activities_after_activation.map((x) => {
            const {
              account,
              activity_type,
              freespins_id,
              max_amount_for_percentage,
              percentage,
              popup_image_url,
              popup_text,
              reward_fixed,
            } = x;

            return {
              type: activity_type,
              data: {
                text: popup_text,
                urlImage: popup_image_url,
                rewardFixed: reward_fixed,
                percentage,
                max: max_amount_for_percentage,
                account,
                offer: freespins_id,
                freespins_id: freespins_id,
              },
            };
          }),
        ],
        activityCreation: {
          type: 0,
          data: null,
        },
      };

      state.create.rules = {
        valueForCalculation: value_for_calculation,
        valueFilterFrom: value_filter_from,
        valueFilterTo: value_filter_to,
        calculateBy:
          provider_rules.length > 0
            ? 0
            : game_rules.length > 0
            ? 1
            : game_group_rules.length > 0
            ? 2
            : 0,
        providerRules: [
          ...provider_rules.map((x) => {
            x.id = x.instance_id;
            x.percent = x.percentage;
            return x;
          }),
        ],
        gameRules: [
          ...game_rules.map((x) => {
            x.id = x.instance_id;
            x.percent = x.percentage;
            return x;
          }),
        ],

        //TODO: Podrzati ovo dvoje na backendu
        gameGroupRules: game_group_rules ? game_group_rules : [],
        gameCategories: game_categories ? game_categories : [],
      };

      state.create.achievement = {
        hitValueCalculation: hit_value_calculation_type,
        minimalValue: minimal_value,
        activitiesAfterBonus: activities_after_achievement.map((x) => {
          const {
            account,
            activity_type,
            freespins_id,
            max_amount_for_percentage,
            percentage,
            popup_image_url,
            popup_text,
            reward_fixed,
            hit_value_max,
            hit_value_min,
          } = x;

          return {
            type: activity_type,
            data: {
              text: popup_text,
              urlImage: popup_image_url,
              rewardFixed: reward_fixed,
              percentage,
              max: max_amount_for_percentage,
              account,
              offer: freespins_id,
              freespins_id: freespins_id,
              hit_value_min: hit_value_min,
              hit_value_max: hit_value_max,
            },
          };
        }),
        activityCreation: {
          type: 0,
          data: {},
        },
      };
    });
    //

    builder.addCase(getParticipation.pending, (state) => {
      state.ui.isDataLoading = true;
    });
    builder.addCase(getParticipation.fulfilled, (state, action) => {
      state.ui.isDataLoading = false;

      state.bonusParticipation = action.payload.array;

      state.totalPagesParticipants = action.payload.total;
      state.toParticipants = action.payload.to;
      state.perPageParticipants = action.payload.per_page;
      state.currentPageParticipants = action.payload.current_page;

      state.isBonusManual = action.payload.isManual;
    });
    builder.addCase(getParticipation.rejected, (state, action) => {
      state.ui.isDataLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCallFrom,
  changeActivationLockedBy,
  changePause,
  changePerPageParticipants,
  changeCurrentPageParticipants,
  changeSearchBonusParticipants,
  restartRulesForCalculation,
  setDefaultOfferAndFreespinsId,
  changeBonusesCreateActivationCarryPlayers,
  resetBonusSlice,
  changeBonusesState,
  changeBonusesCreateType,
  changeBonusesDateFrom,
  changeBonusesDateTo,
  changeBonusesSearch,
  changeBonusesCreateStep,
  changeBonusesCreateIsEdit,
  changeBonusesCreateName,
  changeBonusesCreateDateFrom,
  changeBonusesCreateDateTo,
  changeBonusesCreateState,
  changeBonusesCreateDescription,
  changeBonusesCreatePublicTitle,
  changeBonusesCreateShortText,
  changeBonusesCreateLongText,
  changeBonusesCreateBannerUrl,
  changeBonusesCreateActivationMode,
  changeBonusesCreateActivityNeeded,
  changeBonusesCreateActivationCode,
  changeBonusesCreateRulesValueFor,
  changeBonusesCreateRulesValueFrom,
  changeBonusesCreateRulesValueTo,
  changeBonusesCreateRulesProviderRules,
  changeBonusesCreateRulesGameRules,
  changeBonusesCreateRulesCalculateBy,
  addBonusesCreateActivationActivitiesAfterBonus,
  removeBonusesCreateActivationActivitiesAfterBonus,
  changeBonusesCreateActivitiesTypeCreation,
  changeBonusesCreateActivitiesData,
  resetBonusesCreateActivitiesData,
  changeBonusesCreateInfoUrl,
  changeBonusesCreateButtonText,
  changeBonusesCreateButtonUrl,
  changeBonusesCreateProgressBar,
  changeBonusesCreateAchievementPeriodType,
  changeBonusesCreateAchievementPeriodTime,
  changeBonusesCreateActivationActivitiesAfterBonus,
  changeActivationActivitiesType,
  changeAchievementActivitiesType,
  addBonusesCreateRulesProviderRules,
  removeBonusesCreateRulesProviderRules,
  editBonusesCreateRulesProviderRules,
  addBonusesCreateRulesGameRules,
  removeBonusesCreateRulesGameRules,
  editBonusesCreateRulesGameRules,
  changeBonusesAchievementHitValueCalculation,
  changeBonusesAchievementMinimalValue,
  changeBonusesAchievementActivitiesAfterBonus,
  addBonusesCreateAchievementActivitiesAfterBonus,
  removeBonusesCreateAchievementActivitiesAfterBonus,
  changeBonusesCreateAchievementActivitiesTypeCreation,
  changeBonusesCreateAchievementActivitiesData,
  resetBonusesCreateAchievementActivitiesData,
  changeBonusesCreateActivationDepositMinAmount,
  changeBonusesCreateActivationDepositSequence,
  changeBonusesCreateActivationParticipationMode,
  changeBonusesCreateActivationParticipationLimit,
  changeBonusesSortBy,
  changeBonusesSortDirection,
  changeBonusesPageNumber,
  changeBonusesPageSize,
  addBonusesCreateRulesGameGroupsRules,
  removeBonusesCreateRulesGameGroupsRules,
  editBonusesCreateRulesGameGroupsRules,
  changeBonusesCreateActivationPlayers,
  changeBonusesAutoRepeat,
  addBonusesCreateRulesGameCategoriesRules,
  removeBonusesCreateRulesGameCategoriesRules,
  editBonusesCreateRulesGameCategoriesRules,
} = bonusesSlice.actions;

export default bonusesSlice.reducer;
