import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsCreateBannerImageUrl } from "../../../../../../../../redux/slices/tournaments/tournamentsSelector";
import { changeTournamentsCreateBannerImageUrl } from "../../../../../../../../redux/slices/tournaments/tournamentsSlice";

const BannerImageUrl = () => {
  const dispatch = useDispatch();
  const value = useSelector(tournamentsCreateBannerImageUrl);
  return (
    <Form.Item
      label="Banner image URL (Recommended size 1120x448)"
      name="banner_image_url"
    >
      <Input
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeTournamentsCreateBannerImageUrl(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default BannerImageUrl;
