import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import GameGroupsLayout from "./GameGroupsLayout";
import "./GameGroups.css";
import { useDispatch } from "react-redux";
import { getGameGroupsReducer } from "../../../redux/slices/game-groups/extra-reducers/getGameGroupsReducer";

const GameGroups = () => {
  const [groupModalVisible, setGroupModalVisible] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGameGroupsReducer());
  }, [dispatch]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setGroupModalVisible(true);
        }}
      >
        Manage Groups
      </Button>
      <Modal
        title="Manage game groups"
        centered
        open={groupModalVisible}
        onOk={() => setGroupModalVisible(false)}
        onCancel={() => setGroupModalVisible(false)}
        width={"min(90vw,1200px)"}
        footer={null}
        destroyOnClose={true}
      >
        <GameGroupsLayout />
      </Modal>
    </>
  );
};

export default GameGroups;
