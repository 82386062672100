import { Layout } from "antd";
import React from "react";
import FooterCustom from "./footer-custom/FooterCustom";
import HeaderCustom from "./header-custom/HeaderCustom";

const LayoutCustom = ({ children }) => {
  return (
    <>
      <HeaderCustom />
      <Layout className="site-layout" style={{}}>
        {children}
      </Layout>
      {/* <FooterCustom /> */}
    </>
  );
};

export default LayoutCustom;
