import React from "react";

//TODO: Change to Lazy import
import Statistics from "../views/pages/statistics/Statistics";
import FreeGamesCreate from "../views/pages/free-games/free-games-create/FreeGamesCreate";
import FreeGamesDetails from "../views/pages/free-games/free-games-details/FreeGamesDetails";
import FreeGamesList from "../views/pages/free-games/free-games-list/FreeGamesList";
import Stakes from "../views/pages/stakes/StakesList";
import Bonuses from "../views/pages/bonuses/bonuses-list/Bonuses";
import BonusesCreateEdit from "../views/pages/bonuses/bonuses-create-edit/BonusesCreateEdit";
import GamesCreateEdit from "../views/pages/games/games-create-edit/GamesCreateEdit";
import BonusesRounds from "../views/pages/bonuses/bonuses-rounds/BonusesRounds";
import BonusesParticipation from "../views/pages/bonuses/bonuses-participation/BonusesParticipation";
import Tournaments from "../views/pages/tournaments/tournaments-list/Tournaments";
import TournamentsCreateEdit from "../views/pages/tournaments/tournaments-create-edit/TournamentsCreateEdit";

const Dashboard = React.lazy(() =>
  import("../views/pages/dashboard/Dashboard")
);
const Providers = React.lazy(() =>
  import("../views/pages/providers/Providers")
);
const ProvidersForm = React.lazy(() => import("../views/pages/providers/Form"));
const Categories = React.lazy(() =>
  import("../views/pages/game_categories/GameCategories")
);
const CategoriesForm = React.lazy(() =>
  import("../views/pages/game_categories/Form")
);
const CategoriesReorder = React.lazy(() =>
  import("../views/pages/game_categories/Reorder")
);
const Flags = React.lazy(() => import("../views/pages/flags/Flags"));
const FlagsReorder = React.lazy(() => import("../views/pages/flags/Reorder"));
const FlagsForm = React.lazy(() => import("../views/pages/flags/Form"));
const Games = React.lazy(() => import("../views/pages/games/Games"));
const Transactions = React.lazy(() =>
  import("../views/pages/transactions/Transactions")
);
const Lobbies = React.lazy(() => import("../views/pages/lobbies/Lobbies"));
const LobbieVersion = React.lazy(() =>
  import("../views/pages/lobbies/LobbieVersion")
);
const LobbyBuilder = React.lazy(() =>
  import("../views/pages/lobbies/LobbyBuilder")
);
const LobbyPreview = React.lazy(() =>
  import("../views/pages/lobbies/LobbyPreview")
);
const Page404 = React.lazy(() => import("../views/pages/page404/Page404"));
const Jackpots = React.lazy(() => import("../views/pages/jackpots/Jackpots"));
const AddJackpot = React.lazy(() => import("../views/pages/jackpots/Form"));
const JackpotRounds = React.lazy(() =>
  import("../views/pages/jackpots/JackpotRounds")
);
const EditJackpotRound = React.lazy(() =>
  import("../views/pages/jackpots/Form")
);
const BonusParticipation = React.lazy(() =>
  import("../views/pages/bonuses/Participation")
);

const TournamentsForm = React.lazy(() =>
  import("../views/pages/tournaments/Form")
);
const TournamentRound = React.lazy(() =>
  import("../views/pages/tournaments/TournamentRounds")
);
const TournamentParticipation = React.lazy(() =>
  import("../views/pages/tournaments/Participation")
);
const Slides = React.lazy(() => import("../views/pages/slides/Index"));
const SlidesForm = React.lazy(() => import("../views/pages/slides/Form"));
const SlidesActive = React.lazy(() => import("../views/pages/slides/Active"));
const UserControl = React.lazy(() =>
  import("../views/pages/player_control/PlayerControl")
);
const UserControlForm = React.lazy(() =>
  import("../views/pages/player_control/Form")
);
const CasinoIndex = React.lazy(() => import("../views/pages/casinos/Index"));
const CasinoForm = React.lazy(() => import("../views/pages/casinos/Form"));
const IntegrationIndex = React.lazy(() =>
  import("../views/pages/integrations/Index")
);
const IntegrationForm = React.lazy(() =>
  import("../views/pages/integrations/Form")
);
const Analytics = React.lazy(() =>
  import("../views/pages/analytics/Analytics")
);
const LobbiesManagement = React.lazy(() =>
  import("../views/pages/lobbies-management/LobbyGlobalComponent")
);

const ROUTES = [
  {
    route: "/dashboard",
    component: Dashboard,
  },
  {
    route: "/statistics",
    component: Statistics,
  },
  {
    route: "/providers",
    component: Providers,
  },
  {
    route: "/providers/edit/:id",
    component: ProvidersForm,
  },
  {
    route: "/categories",
    component: Categories,
  },
  {
    route: "/categories/reorder",
    component: CategoriesReorder,
  },
  {
    route: "/categories/create",
    component: CategoriesForm,
  },
  {
    route: "/categories/edit/:id",
    component: CategoriesForm,
  },
  // {
  //   route: "/flags",
  //   component: Flags,
  // },
  // {
  //   route: "/flags/reorder",
  //   component: FlagsReorder,
  // },
  // {
  //   route: "/flags/create",
  //   component: FlagsForm,
  // },
  // {
  //   route: "/flags/edit/:id",
  //   component: FlagsForm,
  // },
  {
    route: "/games",
    component: Games,
  },
  {
    route: "/games/create",
    component: GamesCreateEdit,
  },
  // {
  //   route: "/games/edit/:id",
  //   component: GamesCreateEdit,
  // },
  {
    route: "/transactions",
    component: Transactions,
  },
  {
    route: "/lobbies",
    component: Lobbies,
  },
  {
    route: "/lobbies/:id",
    component: LobbieVersion,
  },
  {
    route: "/lobby-builder/:id?",
    component: LobbyBuilder,
  },
  {
    route: "/lobby-preview/:id",
    component: LobbyPreview,
  },
  {
    route: "/stakes",
    component: Stakes,
  },
  {
    route: "/jackpots",
    component: Jackpots,
  },
  {
    route: "/jackpot/create",
    component: AddJackpot,
  },
  {
    route: "/jackpot/edit/:id",
    component: AddJackpot,
  },
  {
    route: "/jackpot/:id/rounds",
    component: JackpotRounds,
  },
  {
    route: "/jackpot/:id/createRound",
    component: EditJackpotRound,
  },
  {
    route: "/jackpot-rounds/edit/:id",
    component: EditJackpotRound,
  },
  {
    route: "/bonuses",
    component: Bonuses,
  },
  {
    route: "/bonuses/:id/participation",
    component: BonusParticipation,
  },
  {
    route: "/bonuses/create",
    component: BonusesCreateEdit,
  },
  {
    route: "/bonuses/rounds/:id",
    component: BonusesRounds,
  },
  {
    route: "/bonuses/edit/:id/round/:bonusId",
    component: BonusesCreateEdit,
  },
  {
    route: "/bonuses/edit/:id",
    component: BonusesCreateEdit,
  },
  {
    route: "/bonuses/participation/:id",
    component: BonusesParticipation,
  },
  {
    route: "/tournaments",
    component: Tournaments,
  },
  {
    route: "/tournaments/:id/participation",
    component: TournamentParticipation,
  },
  {
    route: "/tournaments/create",
    component: TournamentsCreateEdit,
  },
  {
    route: "/tournaments/edit/:id/round/:bonusId",
    component: TournamentsCreateEdit,
  },
  {
    route: "/tournaments/:id/rounds",
    component: TournamentRound,
  },
  {
    route: "/slides",
    component: Slides,
  },
  {
    route: "/slides/create",
    component: SlidesForm,
  },
  {
    route: "/slides/edit/:id",
    component: SlidesForm,
  },
  {
    route: "/slides/active",
    component: SlidesActive,
  },
  {
    route: "/player-control",
    component: UserControl,
  },
  {
    route: "/player-control/create",
    component: UserControlForm,
  },
  {
    route: "/player-control/edit/:id",
    component: UserControlForm,
  },
  {
    route: "/404",
    component: Page404,
  },
  {
    route: "/",
    component: Dashboard,
  },
  {
    route: "/casinos",
    component: CasinoIndex,
  },
  {
    route: "/casinos/create",
    component: CasinoForm,
  },
  {
    route: "/casinos/edit/:id",
    component: CasinoForm,
  },
  {
    route: "/integrations",
    component: IntegrationIndex,
  },
  {
    route: "/integrations/create",
    component: IntegrationForm,
  },
  {
    route: "/integrations/edit/:id",
    component: IntegrationForm,
  },
  {
    route: "/analytics",
    component: Analytics,
  },
  {
    route: "/free-games",
    component: FreeGamesList,
  },
  {
    route: "/free-games/create",
    component: FreeGamesCreate,
  },
  // {
  //   route: "/free-games/edit/:id",
  //   component: FreeGamesCreate,
  // },
  {
    route: "/free-games/statistics/:id",
    component: FreeGamesDetails,
  },
  {
    route: "/lobbies-management",
    component: LobbiesManagement,
  },
];

export default ROUTES;
