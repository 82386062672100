import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gamesIsEditSelector,
  gamesNameSelector,
} from "../../../../../redux/slices/games/gamesSelectors";
import { changeGamesName } from "../../../../../redux/slices/games/gamesSlice";

const GamesName = () => {
  const dispatch = useDispatch();
  const isEdit = useSelector(gamesIsEditSelector);
  const value = useSelector(gamesNameSelector);

  return (
    <Form.Item label="Name" name="name">
      <Input
        disabled={isEdit}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeGamesName(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default GamesName;
