import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import freeGamesServices from "../../../../services/freeGamesServices";

export const getFreeGamesReducer = createAsyncThunk(
  "getFreeGamesReducer",
  async (q, thunkApi) => {
    const state = thunkApi.getState();
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    const params = {
      casino_id: state.user.activeCasino,
      //TODO: Support this on backend
      "period[0]": moment(state.freeGames.filter.dateFrom)
        .startOf("day")
        .add(timezoneOffset, "hours")
        .utc(true)
        .toISOString(),
      "period[1]": moment(state.freeGames.filter.dateTo)
        .endOf("day")
        .add(timezoneOffset, "hours")
        .utc(true)
        .toISOString(),
      state: state.freeGames.filter.state,
      provider_id: state.freeGames.filter.provider,
      search: state.freeGames.filter.search,
      per_page: state.freeGames.pagination.perPage,
      page: state.freeGames.pagination.page,
    };

    try {
      const res = await freeGamesServices.getFreeGames(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
