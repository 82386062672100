import { Button, PageHeader } from "antd";
import React, { useEffect, useId } from "react";
import { useHistory } from "react-router-dom";
import FreeGamesListForm from "./components/form/FreeGamesListForm";
import FreeGamesListTable from "./components/table/FreeGamesListTable";
import { useDispatch } from "react-redux";
import { resetFreeGamesSlice } from "../../../../redux/slices/free-games/freeGamesSlice";

function FreeGamesList() {
  const dispatch = useDispatch();
  const id = useId();
  const { goBack, push } = useHistory();

  useEffect(() => {
    return () => {
      dispatch(resetFreeGamesSlice());
    };
  }, [dispatch]);

  const hanleGoBack = () => goBack();
  const handleNavigation = () => push("/free-games/create");

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={hanleGoBack}
        extra={
          <Button key={id} type="primary" onClick={handleNavigation}>
            <span>Create new</span>
          </Button>
        }
        title="Free Games"
      ></PageHeader>
      <div className="free_games_list_container">
        <div style={{ minWidth: "fit-content" }}>
          <FreeGamesListForm />
          <FreeGamesListTable />
        </div>
      </div>
    </div>
  );
}

export default FreeGamesList;
