import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { cloneDeep } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationActivitiesAfterBonus } from "../../../../../../../../../redux/slices/bonuses/bonusesSlice";

const PopupDisplay = ({ i }) => {
  const data = useSelector(bonusesCreateActivationActivitiesAfterBonus);
  const dispatch = useDispatch();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <TextArea
        columns={4}
        placeholder="Enter text..."
        value={data[i]?.data?.text}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.text = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
      />
      <Input
        placeholder="Url of popup image"
        value={data[i]?.data?.urlImage}
        onChange={(e) => {
          const newData = cloneDeep(data);
          newData[i].data.urlImage = e.target.value;
          dispatch(changeBonusesCreateActivationActivitiesAfterBonus(newData));
        }}
      />
    </div>
  );
};

export default PopupDisplay;
