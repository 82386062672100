import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationParticipationMode } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationParticipationMode } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ParticipationMode = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateActivationParticipationMode);
  return (
    <Form.Item
      label="Participation Mode"
      name="participation_mode"
      initialValue={value}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Please select"
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateActivationParticipationMode(e));
        }}
        options={[
          {
            value: 0,
            label: "Single",
          },
          {
            value: 1,
            label: "Multi",
          },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default ParticipationMode;
