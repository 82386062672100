import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobile: "Desktop",
};

export const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    changeIfIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { changeIfIsMobile } = sliderSlice.actions;

export default sliderSlice.reducer;
