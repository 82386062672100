import {
  BookOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  SettingOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  bonusesCreateAchievementAllDataEntered,
  bonusesCreateActivationAllDataEntered,
  bonusesCreateGeneralAllDataEntered,
  bonusesCreateRulesAllDataEnteredSelector,
  bonusesCreateStepSelector,
} from "../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateStep } from "../../../../../redux/slices/bonuses/bonusesSlice";
import "./BonusesSteps.css";
const { Step } = Steps;

const BonusesSteps = () => {
  const currentStep = useSelector(bonusesCreateStepSelector);
  const dispatch = useDispatch();
  const allDataGeneral = useSelector(bonusesCreateGeneralAllDataEntered);
  const allDataActivation = useSelector(bonusesCreateActivationAllDataEntered);
  const allDataRules = useSelector(bonusesCreateRulesAllDataEnteredSelector);
  const allDataAchievement = useSelector(
    bonusesCreateAchievementAllDataEntered
  );

  return (
    <>
      <Steps
        type="navigation"
        size="small"
        current={currentStep}
        onChange={(a) => {
          dispatch(changeBonusesCreateStep(a));
        }}
        className="bonus_create_steps"
      >
        <Step
          title="General"
          status={
            allDataGeneral === null
              ? "wait"
              : allDataGeneral === false
              ? "error"
              : "finish"
          }
          icon={<SettingOutlined />}
        />
        <Step
          title="Activation"
          icon={<ScheduleOutlined />}
          status={
            allDataActivation === null
              ? "wait"
              : allDataActivation === false
              ? "error"
              : "finish"
          }
        />
        <Step
          title="Rules"
          icon={<FileProtectOutlined />}
          status={
            allDataRules === null
              ? "wait"
              : allDataRules === false
              ? "error"
              : "finish"
          }
        />
        <Step
          title="Achievement"
          icon={<StarOutlined />}
          status={
            allDataAchievement === null
              ? "wait"
              : allDataAchievement === false
              ? "error"
              : "finish"
          }
        />
        <Step title="Review" icon={<BookOutlined />} />
      </Steps>
      <div
        style={{
          padding: "16px",
          paddingBottom: 0,
          width: "100%",
          display: "flex",
          justifyContent: currentStep === 0 ? "flex-end" : "space-between",
        }}
      >
        {currentStep !== 0 && (
          <Button
            type="primary"
            onClick={() => {
              dispatch(changeBonusesCreateStep(currentStep - 1));
            }}
          >
            Back
          </Button>
        )}
        {currentStep !== 4 && (
          <Button
            type="primary"
            onClick={() => {
              dispatch(changeBonusesCreateStep(currentStep + 1));
            }}
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default BonusesSteps;
