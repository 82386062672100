import { Input } from "antd";
import { useDispatch } from "react-redux";
import { changeSearchBonusParticipants } from "../../../../redux/slices/bonuses/bonusesSlice";

const SearchBonusParticipants = () => {
  const dispatch = useDispatch();

  return (
    <Input
      placeholder="Insert Player ID"
      onChange={(e) => {
        dispatch(changeSearchBonusParticipants(e.target.value));
      }}
    />
  );
};

export default SearchBonusParticipants;
