import React from "react";
import { useSelector } from "react-redux";
import SingleCategory from "./SingleCategory";
import { bonusesCreateRulesGameCategoriesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";

const ListGameCategories = () => {
  const list = useSelector(bonusesCreateRulesGameCategoriesSelector);

  return (
    <div
      className="rules_list_all_games"
      style={{ marginBottom: list.length === 0 ? "0px" : "32px" }}
    >
      {list.map((category, i) => {
        return (
          <SingleCategory
            id={category.id}
            percent={category.percent}
            i={i}
            key={category.id}
          />
        );
      })}
    </div>
  );
};

export default ListGameCategories;
