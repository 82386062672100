export const gamesListSelector = (state) => {
  return state.games.gamesList;
};
export const gamesPageNumber = (state) => {
  return state.games.pageNumber;
};
export const gamesTotalRows = (state) => {
  return state.games.totalRows;
};
export const totalGamesSelector = (state) => state.games.totalItems;

export const gamesPageSize = (state) => {
  return state.games.pageSize;
};
export const gameWithIdSelector = (state, x) => {
  let game = null;
  state.games.gamesList.forEach((q) => {
    if (q.id === x) {
      game = q;
    }
  });
  return game;
};
export const jackpotGameRulesGamesSelector = (state) =>
  state.games.jackpotGameRulesGames;
export const selectLobbyGames = (state) => {
  return state.games.gamesList.map((item) => ({
    id: item.id,
    game_name: item.name,
    game_image: item.photo,
    game_category: item.game_categories[0].name,
    provider_name: item.provider.name,
    provider_image: item.provider.original_photo,
    flags: item.flags[0]?.name || null,
  }));
};

export const categoryFilterSelector = (state) => state.games.filter.category;

export const gameNameFilterSelector = (state) => state.games.filter.name;
export const gamesFilterProvidersSelector = (state) => {
  return state.games.filter.provider;
};

export const gamesFilterGameGroupsSelector = (state) => {
  return state.games.filter.gameGroups;
};

export const loadingGamesListSelector = (state) => {
  return state.games.ui.loadingGamesList;
};
export const gameCreateEditFinishedSelector = (state) => {
  return state.games.ui.gameCreateEditFinished;
};
export const gamesButtonWaitingResponseSelector = (state) => {
  return state.games.ui.buttonWaitingResponse;
};

export const gameCreatedEditedSelector = (state) => {
  return state.games.createEdit.gameCreatedEdited;
};
export const gamesIsEditSelector = (state) => {
  return state.games.createEdit.isEdit;
};

export const gamesNameSelector = (state) => {
  return state.games.createEdit.name;
};
export const gamesProviderSelector = (state) => {
  return state.games.createEdit.provider;
};
export const gamesIntegrationSelector = (state) => {
  return state.games.createEdit.integration;
};

export const gamesIframeUrlSelector = (state) => {
  return state.games.createEdit.iframeUrl;
};
export const gamesIframeDemoUrlSelector = (state) => {
  return state.games.createEdit.iframeDemoUrl;
};
export const gamesEnabledSelector = (state) => {
  return state.games.createEdit.enabled;
};
export const gamesFreeSpinsActiveSelector = (state) => {
  return state.games.createEdit.freeSpinsActive;
};
export const gamesUsesWhitelistSelector = (state) => {
  return state.games.createEdit.usesWhitelist;
};
export const gamesGameCategoriesSelector = (state) => {
  return state.games.createEdit.gameCategories;
};
export const gamesFlagsSelector = (state) => {
  return state.games.createEdit.flags;
};
export const gamesWidgetWidthSelector = (state) => {
  return state.games.createEdit.widgetWidth;
};
export const gamesOrdinalNumberSelector = (state) => {
  return state.games.createEdit.ordinalNumber;
};
export const gamesImageSelector = (state) => {
  return state.games.createEdit.image;
};
export const gamesPreviewImageSelector = (state) => {
  return state.games.createEdit.previewImage;
};

export const selectGamesByProvider = (state) => state.games.providerGames.data;

export const selectGamesByProviderLoading = (state) =>
  state.games.providerGames.loading;

export const selectFilteredLobbyGames = (state) =>
  state.games.filteredLobbyGames.data;

export const selectFilteredLobbyGamesLoading = (state) =>
  state.games.filteredLobbyGames.loading;

export const selectFilteredLobbyProps = (state) =>
  state.games.filteredLobbyGames.filter;

export const selectLobbyProviderFilter = (state) =>
  state.games.filteredLobbyGames.filter.provider;

export const selectFilteredLobbyTotalRows = (state) =>
  state.games.filteredLobbyGames.filter.total;

export const selectFilteredLobbyGamesPage = (state) =>
  state.games.filteredLobbyGames.filter.page;

export const selectFilteredLobbyGamesPerPage = (state) =>
  state.games.filteredLobbyGames.filter.perPage;

export const selectedGamesTournamentSelector = (state) =>
  state.games.selectedGamesTournament;

export const selectedGamesKeysTournamentSelector = (state) =>
  state.games.selectedGamesKeysTournament;
