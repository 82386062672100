import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllWidgets } from "../../../../services/lobbyServices";

export const getLobbyFeaturesReducers = createAsyncThunk(
  "getLobbyFeaturesReducers",
  async (_, thunkAPI) => {
    const casino_id = thunkAPI.getState().user.activeCasino;
    const { lobby } = await thunkAPI.getState();
    const isMobile = await lobby.isMobile;
    try {
      const res = await getAllWidgets(casino_id, isMobile);

      if (res.status !== 200) throw new Error();

      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);
