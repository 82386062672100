import { Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateActivationParticipationLimit } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateActivationParticipationLimit } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";
const ParticipationLimit = () => {
  const dispatch = useDispatch();

  const value = useSelector(bonusesCreateActivationParticipationLimit);

  return (
    <Form.Item label="Participation Limit" name="participation_limit">
      <Input
         value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(
            changeBonusesCreateActivationParticipationLimit(e.target.value)
          );
        }}
      />
    </Form.Item>
  );
};

export default ParticipationLimit;
