import React from "react";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  statisticsDateFromSelector,
  statisticsDateToSelector,
} from "../../../../redux/slices/statistics/statisticsSelector";
import moment from "moment";
import {
  changeStatisticsDateFrom,
  changeStatisticsDateTo,
} from "../../../../redux/slices/statistics/statisticsSlice";
const { RangePicker } = DatePicker;

const StatisticsDateRange = () => {
  const statisticsDateFrom = useSelector(statisticsDateFromSelector);
  const statisticsDateTo = useSelector(statisticsDateToSelector);

  const dispatch = useDispatch();

  return (
    <RangePicker
      format="DD-MM-YYYY"
      onChange={(x) => {
        dispatch(
          changeStatisticsDateFrom(
            `${moment(x[0]).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")}`
          )
        );
        dispatch(
          changeStatisticsDateTo(
            moment(x[1]).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
          )
        );
      }}
      value={[moment(statisticsDateFrom), moment(statisticsDateTo)]}
      style={{ minWidth: "250px" }}
    />
  );
};

export default StatisticsDateRange;
