import React from "react";
import { useSelector } from "react-redux";
import { providersListSelector } from "../../../../../../../../redux/slices/providers/providersSelectors";
import { categoriesListSelector } from "../../../../../../../../redux/slices/categories/categoriesSelectors";

const Category = ({ category }) => {
  const categories = useSelector(categoriesListSelector);
  const cat = category[0];

  return (
    <div>
      {categories &&
        categories.map((item) => {
          if (item.id === cat) {
            return item.name;
          } else {
            return "";
          }
        })}
    </div>
  );
};

export default Category;
