import { Form, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateRulesValueForCalculationSelector } from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateRulesValueFor } from "../../../../../../../redux/slices/bonuses/bonusesSlice";

const ValueForCalculation = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateRulesValueForCalculationSelector);
  return (
    <Form.Item
      label="Value for calculation"
      name="value_for_calculation"
      rules={[{ required: true, message: "This field is required" }]}
    >
      <Select
        placeholder="Please select"
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateRulesValueFor(e));
        }}
        options={[
          {
            value: 0,
            label: "Stake",
          },
          {
            value: 1,
            label: "Win",
          },
          {
            value: 2,
            label: "Net loss",
          },
          {
            value: 3,
            label: "Net win",
          },
          {
            value: 4,
            label: "Tickets count",
          },
          {
            value: 5,
            label: "Highest win / bet ",
          },
        ]}
      ></Select>
    </Form.Item>
  );
};

export default ValueForCalculation;
