import { createSelector } from "reselect";
import { onSort } from "../../../utils/onSort";

const selectLobbyReducer = (state) => state.lobby;

const selectLobbyContentReducer = createSelector(
  [selectLobbyReducer],
  (contentSlice) => contentSlice.content
);

const selectLobbyFeaturesReducer = createSelector(
  [selectLobbyReducer],
  (featureSlice) => featureSlice.features
);

const selectLobbyFeaturesEditReducer = createSelector(
  [selectLobbyReducer],
  (featureEditSlice) => featureEditSlice.featuresEdit
);

const selectWidgetsReducer = createSelector(
  [selectLobbyReducer],
  (widgetsSlice) => widgetsSlice.widgets
);

const selectLobbyCategoryEditReducer = createSelector(
  [selectLobbyReducer],
  (categoryEditSlice) => categoryEditSlice.categoryEdit
);

const selectLobbyContentEditReducer = createSelector(
  [selectLobbyReducer],
  (contentEditSlice) => contentEditSlice.contentEdit
);

export const selectCategories = createSelector(
  [selectLobbyReducer],
  (categorySlice) => categorySlice.categories
);

export const selectIsCategoriesLoading = createSelector(
  [selectLobbyReducer],
  (categorySlice) => categorySlice.loading
);

export const selectCategoriesError = createSelector(
  [selectLobbyReducer],
  (categorySlice) => categorySlice.error
);

export const selectActiveCategoryId = createSelector(
  [selectLobbyReducer],
  (categorySlice) => categorySlice.activeCategoryId
);

export const selectIsMobile = createSelector(
  [selectLobbyReducer],
  (lobbySlice) => lobbySlice.isMobile
);

export const selectIsEdit = createSelector(
  [selectLobbyReducer],
  (lobbySlice) => lobbySlice.isEdit
);

export const selectIsGameChanged = createSelector(
  [selectLobbyReducer],
  (lobbySlice) => lobbySlice.isGameChanged
);

export const selectIsContentEdit = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.isContentEdit
);

export const selectCurrentContentId = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.currentId
);

export const selectCategory = createSelector(
  [selectLobbyReducer],
  (categorySlice) => {
    const mobile = categorySlice.isMobile;
    return { ...categorySlice.category, mobile };
  }
);

export const selectCategoryEdit = createSelector(
  [selectLobbyCategoryEditReducer],
  (categoryEditSlice) => categoryEditSlice.data
);

export const selectCategoryEditLoading = createSelector(
  [selectLobbyCategoryEditReducer],
  (categoryEditSlice) => categoryEditSlice.loading
);

export const selectIsCategoryModalOpen = createSelector(
  [selectLobbyReducer],
  (categorySlice) => categorySlice.categoryModalOpen
);

export const selectIsContentAreaModalOpen = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.areaModalOpen
);

export const selectContent = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.data
);

export const selectContentLoading = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.loading
);

export const selectContentEdit = createSelector(
  [selectLobbyContentEditReducer],
  (contentEditSlice) => contentEditSlice.data
);

export const selectContentEditLoading = createSelector(
  [selectLobbyContentEditReducer],
  (contentEditSlice) => contentEditSlice.loading
);

export const selectContentArea = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => {
    return {
      ...contentSlice.contentArea,
      icon_id: contentSlice.contentArea.icon_id
        ? contentSlice.contentArea.icon_id
        : 1,
    };
  }
);

export const selectContentAreaDataTypes = createSelector(
  [selectContentArea],
  (contentAreaSlice) => contentAreaSlice.type_data
);

export const selectRowKeys = createSelector(
  [selectContentArea],
  (contentAreaSlice) => contentAreaSlice.selectedRowKeys
);

export const selectCurrentGames = createSelector(
  [selectContentArea],
  (contentAreaSlice) => contentAreaSlice.currentGames
);

export const selectIsAddGamesModalOpen = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.addGamesModalOpen
);

export const selectAreaFeatureModalOpen = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.areaFeatureModalOpen
);

export const selectIsEditAreaTypeModalOpen = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.editAreaTypeModalOpen
);

export const selectSingleAreaType = createSelector(
  [selectLobbyContentReducer],
  (contentSlice) => contentSlice.singleAreaType
);

export const selectWidgetsData = createSelector(
  [selectWidgetsReducer],
  (widgetsSlice) => widgetsSlice.data
);

export const selectWidgetsLoading = createSelector(
  [selectWidgetsReducer],
  (widgetsSlice) => widgetsSlice.loading
);

export const selectWidgetsError = createSelector(
  [selectWidgetsReducer],
  (widgetsSlice) => widgetsSlice.error
);

export const selectLeftFeatures = createSelector(
  [selectLobbyFeaturesReducer],
  (featureSlice) => {
    if (featureSlice.loading) return;
    const copyFeatures = [...featureSlice.left];
    return onSort(copyFeatures, "order");
  }
);

export const selectRightFeatures = createSelector(
  [selectLobbyFeaturesReducer],
  (featureSlice) => {
    if (featureSlice.loading) return;
    const copyFeatures = [...featureSlice.right];
    return onSort(copyFeatures, "order");
  }
);

export const selectFeatureLoading = createSelector(
  [selectLobbyFeaturesReducer],
  (featureSlice) => featureSlice.loading
);

export const selectFeaturesEdit = createSelector(
  [selectLobbyFeaturesEditReducer],
  (featureEditSlice) => featureEditSlice.data
);

export const selectFeaturesEditLoading = createSelector(
  [selectLobbyFeaturesEditReducer],
  (featureEditSlice) => featureEditSlice.loading
);
