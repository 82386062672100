import React from "react";
import { useSelector } from "react-redux";
import { bonusesCreateRulesGameRulesSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import SingleGameRule from "./SingleGameRule";

const ListGamesRules = () => {
  const gameRules = useSelector(bonusesCreateRulesGameRulesSelector);
  return (
    <div
      className="rules_list_all_games"
      style={{
        marginBottom: gameRules.length === 0 ? "0px" : "32px",
      }}
    >
      {gameRules.map((rule, i) => {
        return (
          <SingleGameRule
            name={rule.name ? rule.name : rule.ruleable.name}
            id={rule.id}
            percent={rule.percent}
            i={i}
            key={rule.id}
          />
        );
      })}
    </div>
  );
};

export default ListGamesRules;
