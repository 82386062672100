import { createSlice } from "@reduxjs/toolkit";
import { authUser } from "./user-extra-reducers/authUserReducer";

const initialState = {
  isLoggedIn: null,
  token: "",
  type: "",
  name: "",
  activeCasino: "0",
  activeCasinoName: "",
  currency: "NGN",
  lobby: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeIsLoggedInToNull: (state) => {
      state.isLoggedIn = null;
    },
    changeIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    changeActiveCasino: (state, action) => {
      state.activeCasino = action.payload;
    },
    changeActiveCasinoName: (state, action) => {
      state.activeCasinoName = action.payload;
    },
    changeLobby: (state, action) => {
      state.lobby = action.payload;
    },
    changeCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.rejected, (state, action) => {
      state.isLoggedIn = false;
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.currency = action.payload.currency;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  changeIsLoggedIn,
  changeIsLoggedInToNull,
  changeActiveCasino,
  changeActiveCasinoName,
  changeLobby,
  changeCurrency,
} = userSlice.actions;

export default userSlice.reducer;
