import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bonusesCreateShortTextSelector } from "../../../../../../../../redux/slices/bonuses/bonusesSelectors";
import { changeBonusesCreateShortText } from "../../../../../../../../redux/slices/bonuses/bonusesSlice";

const ShortText = () => {
  const dispatch = useDispatch();
  const value = useSelector(bonusesCreateShortTextSelector);
  return (
    <Form.Item
      label="Short text for promotions page title"
      name="short_text_for_promotions"
    >
      <TextArea
        columns={4}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          dispatch(changeBonusesCreateShortText(e.target.value));
        }}
      />
    </Form.Item>
  );
};

export default ShortText;
