export const isLoggedInSelector = (state) => {
  return state.user.isLoggedIn;
};

export const activeCasinoSelector = (state) => {
  return state.user.activeCasino;
};

export const activeCasinoName = (state) => {
  return state.user.activeCasinoName;
};
export const lobbySelector = (state) => {
  return state.user.lobby;
};
export const currencySelector = (state) => {
  return state.user.currency;
};
