import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import {
  bonusesCreateAchievementAllDataEntered,
  bonusesCreateSelector,
  bonusesTableDataSelector,
  unlockBonusOffer,
} from "../../../../../../../redux/slices/bonuses/bonusesSelectors";
import RowReview from "./RowReview";
import { selectFreeGamesDataWithoutPagination } from "../../../../../../../redux/slices/free-games/freeGamesSelector";

const AchievementReview = () => {
  const createData = useSelector(bonusesCreateSelector);
  const allDataEntered = useSelector(bonusesCreateAchievementAllDataEntered);

  const freeGames = useSelector(selectFreeGamesDataWithoutPagination);

  const bonuses = useSelector(bonusesTableDataSelector);

  if (bonuses[0] === null) return;

  return (
    //TODO: Handle activate bonus option

    <div className="review_section">
      <Typography.Title level={2}>
        Achievement
        <span style={{ marginLeft: "8px" }}>
          {allDataEntered === true ? (
            <CheckCircleOutlined style={{ color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )}
        </span>
      </Typography.Title>

      <RowReview
        name="Hit value calculation"
        data={createData.achievement.hitValueCalculation === 0 ? "Time" : null}
        required={true}
      />
      <RowReview
        name="Minimal value"
        data={
          createData.achievement.minimalValue &&
          parseFloat(createData.achievement.minimalValue).toFixed(2)
        }
      />

      <div style={{ marginLeft: "4px" }}>
        <span style={{ borderBottom: "2px solid #f0f0f0" }}>
          Activities after bonus achievement :
        </span>
      </div>

      {createData.achievement.activitiesAfterBonus.map((activity) => {
        if (activity.type === 5) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Activate Bonus : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Offer"
                  data={
                    activity.data.offer &&
                    bonuses.filter(
                      (bonus) => bonus.id === activity.data.offer
                    )[0].name
                  }
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Hit value min"
                  data={
                    activity.data.hit_value_min &&
                    parseFloat(activity.data.hit_value_min).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Hit value max"
                  data={
                    activity.data.hit_value_max &&
                    parseFloat(activity.data.hit_value_max).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        }
        if (activity.type === 0) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Reward : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity?.data?.account === 0 ? "Internet" : "Free Bet"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Fixed or min amount"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Percentage of hit value"
                  data={activity.data.percentage && activity.data.percentage}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Max amount"
                  data={
                    activity.data.max &&
                    parseFloat(activity.data.max).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Hit value min"
                  data={
                    activity.data.hit_value_min &&
                    parseFloat(activity.data.hit_value_min).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Hit value max"
                  data={
                    activity.data.hit_value_max &&
                    parseFloat(activity.data.hit_value_max).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 1) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Lock Amount : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity.data.account === 0 ? "Financial" : "Loyalty"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Fixed or min amount"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Percentage of hit value"
                  data={parseFloat(activity.data.percentage).toFixed(2)}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Max amount"
                  data={
                    activity.data.max &&
                    parseFloat(activity.data.max).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 4) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Unlock Amount : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Account"
                  data={activity.data.account === 0 ? "Financial" : "Loyalty"}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Fixed or min amount"
                  data={
                    activity.data.rewardFixed &&
                    parseFloat(activity.data.rewardFixed).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Percentage of hit value"
                  data={parseFloat(activity.data.percentage).toFixed(2)}
                  style={{ marginBottom: "0px" }}
                />
                <RowReview
                  name="Max amount"
                  data={
                    activity.data.max &&
                    parseFloat(activity.data.max).toFixed(2)
                  }
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 3) {
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Popup : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Popup text"
                  data={activity.data.text}
                  style={{ marginBottom: "0px" }}
                />

                <RowReview
                  name="Url of popup image"
                  data={activity.data.urlImage}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else if (activity.type === 2) {
          let name;
          freeGames.map((x) => {
            if (x.id === activity.data?.offer) {
              name = x.name;
            }
          });
          return (
            <div style={{ marginLeft: "16px" }}>
              <div style={{ marginTop: "4px" }}>- Free Games : </div>
              <div style={{ marginLeft: "16px" }}>
                <RowReview
                  name="Offer"
                  data={name}
                  style={{ marginBottom: "0px" }}
                />
              </div>
            </div>
          );
        } else return <></>;
      })}
    </div>
  );
};

export default AchievementReview;
