import { Divider, Form } from "antd";
import React from "react";
import GameIntegrations from "./components/GameIntegrations";
import GamesEnabled from "./components/GamesEnabled";
import GamesFlags from "./components/GamesFlags";
import GamesGameCategories from "./components/GamesGameCategories";
import GamesIframeDemoUrl from "./components/GamesIframeDemoUrl";
import GamesIframeUrl from "./components/GamesIframeUrl";
import GamesName from "./components/GamesName";
import GamesPhoto from "./components/GamesPhoto";
import GamesProvider from "./components/GamesProvider";
import GamesUsesWhitelist from "./components/GamesUsesWhitelist";
import GamesWidgetWidth from "./components/GamesWidgetWidth";
import GamesOrdinalNumber from "./components/GamesOrdinalNumber";
import FreeSpinsActive from "./components/FreeSpinsActive";

const GamesAntForm = () => {
  return (
    <Form layout="horizontal" className="games_create_edit">
      <div className="games_create_edit_column">
        <Divider />
        <GamesName />
        <GamesProvider />
        <GameIntegrations />
        <GamesIframeUrl />
        <GamesIframeDemoUrl />
        <GamesEnabled />
        <GamesUsesWhitelist />
        <FreeSpinsActive />
      </div>
      <div className="games_create_edit_column">
        <Divider />
        <GamesGameCategories />
        <GamesFlags />
        <GamesWidgetWidth />
        <GamesOrdinalNumber />
        <GamesPhoto />
      </div>
    </Form>
  );
};

export default GamesAntForm;
