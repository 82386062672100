import { createSlice } from "@reduxjs/toolkit";
import { getGameGroupsReducer } from "./extra-reducers/getGameGroupsReducer";

const initialState = {
  gameGroupsList: [],
  activeGameGroup: null,
  changesMade: false,
  gameGroupEditingName: null,
  gameGroupEditingGames: [],
  ui: {
    loadingGameGroupsList: false,
  },
};

export const gameGroupSlice = createSlice({
  name: "game-group",
  initialState,
  reducers: {
    changeActiveGameGroup: (state, action) => {
      state.activeGameGroup = action.payload;

      state.gameGroupEditingGames =
        action.payload !== null
          ? state.gameGroupsList[action.payload].games
          : [];

      state.gameGroupEditingName =
        action.payload !== null
          ? state.gameGroupsList[action.payload].name
          : "";

      state.changesMade = false;
    },
    changeChangesMade: (state, action) => {
      state.changesMade = action.payload;
    },
    addGameInGameGroup: (state, action) => {
      state.changesMade = true;
      if (
        state.gameGroupEditingGames.some(
          (game) => game.id === action.payload.id
        )
      )
        return;

      state.gameGroupEditingGames = [
        ...state.gameGroupEditingGames,
        action.payload,
      ];
    },
    removeGameInGameGroup: (state, action) => {
      state.changesMade = true;
      state.gameGroupEditingGames = state.gameGroupEditingGames.filter(
        (game) => {
          if (game.id !== action.payload) {
            return game;
          }
        }
      );
    },
    changeGameGroupEditingName: (state, action) => {
      state.gameGroupEditingName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGameGroupsReducer.pending, (state) => {
      state.ui.loadingGameGroupsList = true;
    });
    builder.addCase(getGameGroupsReducer.fulfilled, (state, action) => {
      state.gameGroupsList = action.payload;
      state.ui.loadingGameGroupsList = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeActiveGameGroup,
  changeChangesMade,
  changeGameGroupEditingName,
  addGameInGameGroup,
  removeGameInGameGroup,
} = gameGroupSlice.actions;

export default gameGroupSlice.reducer;
