import { createAsyncThunk } from "@reduxjs/toolkit";
import { withError } from "antd/lib/modal/confirm";
import userServices from "../../../../services/userServices";
import { setRequestInterceptor } from "../../../../views/pages/login/Interceptor";
import { changeLobby } from "../userSlice";

export const authUser = createAsyncThunk(
  "authUser",
  async (values, thunkApi) => {
    try {
      const result = await userServices.authUser(values);
      const token = result.data.token;
      const adm_type = result.data.type;
      const lobby = result.data.lobby;

      localStorage.setItem("lobby", lobby);
      thunkApi.dispatch(changeLobby(lobby));

      if (token) {
        localStorage.setItem("token", token);
        setRequestInterceptor();
      } else {
        throw withError;
      }
      if (adm_type) {
        localStorage.setItem("type", adm_type);
      } else {
        localStorage.setItem("type", "admin");
      }

      return result.data;
    } catch (err) {
      return thunkApi.rejectWithValue();
    }
  }
);
