import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGameGroupsReducer } from "../../../redux/slices/game-groups/extra-reducers/updateGameGroupsReducer";
import { changesMadeSelector } from "../../../redux/slices/game-groups/gameGroupSelectors";
import {
  changeActiveGameGroup,
  changeChangesMade,
} from "../../../redux/slices/game-groups/gameGroupSlice";
import AddGameGroup from "./AddGameGroup";
import GameGroupGamesList from "./GameGroupGamesList";
import GameGroupsList from "./GameGroupsList";

const GameGroupsLayout = () => {
  const dispatch = useDispatch();
  const changesMade = useSelector(changesMadeSelector);
  useEffect(() => {
    return () => {
      dispatch(changeActiveGameGroup(null));
      dispatch(changeChangesMade(false));
    };
  }, [dispatch]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          danger={true}
          type="primary"
          disabled={!changesMade}
          onClick={() => {
            dispatch(updateGameGroupsReducer());
          }}
        >
          Save
        </Button>
      </div>
      <div className="game_groups_layout">
        <GameGroupsList />
        <div className="game_group_manipulation_container">
          <GameGroupGamesList />
          <AddGameGroup />
        </div>
      </div>
    </div>
  );
};

export default GameGroupsLayout;
