import { Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tournamentsActivitiesAfter } from "../../../../../../../../../redux/slices/tournaments/tournamentsSelector";
// import {
//   freeGamesDataTableSelector,
//   freeGamesLoadingDataTable,
// } from "../../../../../../../../../redux/slices/free-games/freeGamesSelector";
import { changeTournamentsCreateActivitiesAfterBonus } from "../../../../../../../../../redux/slices/tournaments/tournamentsSlice";
import {
  selectFreeGamesDataWithoutPagination,
  selectFreeGamesDataWithoutPaginationLoading,
} from "../../../../../../../../../redux/slices/free-games/freeGamesSelector";
import { useGetFreeGamesWithoutPaginationQuery } from "../../../../../../../../../redux/slices/free-games/extra-reducers/getFreeGamesWithoutPagination";
import { cloneDeep } from "lodash";

const FreeGamesDisplay = ({ i }) => {
  const dispatch = useDispatch();
  const [freeSpinsName, setFreeSpinsName] = useState("");
  const data = useSelector(tournamentsActivitiesAfter);
  const isLoading = useSelector(selectFreeGamesDataWithoutPaginationLoading);
  const freeGamesData = useSelector(selectFreeGamesDataWithoutPagination);
  //TO DO try to cache query
  // const { data, isLoading, error } = useGetFreeGamesWithoutPaginationQuery();

  const handleSelect = (combinedValue) => {
    const [selectedId, selectedName] = combinedValue.split("|");
    const updatedData = data.map((item, x) => {
      if (x === i) {
        return {
          index: Number(selectedId),
          ...item,
          data: {
            ...item.data,
            freespins_name: selectedName,
            freespins_id: parseInt(selectedId),
            offer: selectedId,
            name: selectedName,
          },
        };
      }
      return item;
    });
    dispatch(changeTournamentsCreateActivitiesAfterBonus(updatedData));
  };

  useEffect(() => {
    if (freeGamesData.length === 0) return;
    setFreeSpinsName(
      freeGamesData.find((game) => game.id === +data[i].data.offer)?.name
    );
  }, [freeGamesData, data]);

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      {freeSpinsName !== "" && (
        <Select
          dropdownMatchSelectWidth={false}
          loading={isLoading}
          defaultValue={freeSpinsName}
          value={data[i]?.data.name}
          onChange={(x) => {
            const [selectedId, selectedName] = x.split("|");

            dispatch(
              changeTournamentsCreateActivitiesAfterBonus({
                index: i,
                data: {
                  offer: selectedId,
                  freespins_id: selectedId,
                },
              })
            );
          }}
          placeholder="- Select offer -"
          style={{
            maxWidth: "300px",
            width: "30%",
            height: "min-content",
          }}
        >
          {freeGamesData &&
            Object.keys(freeGamesData).map((key) => {
              const item = freeGamesData[key];
              const freeGamesId = item?.id;
              const freeGamesName = item?.name;
              // const img = item?.games[0].original_photo;
              return (
                <Select.Option
                  key={item.id}
                  value={`${freeGamesId}|${freeGamesName}`}
                >
                  {/* <Avatar src={img} style={{ marginRight: "4px" }} size="small" /> */}
                  {item.name}
                </Select.Option>
              );
            })}
        </Select>
      )}
      <Input
        placeholder="Position from"
        value={data[i]?.data?.positionFrom}
        onChange={(e) => {
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                positionFrom: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
      <Input
        placeholder="Position to"
        value={data[i]?.data?.positionTo}
        onChange={(e) => {
          dispatch(
            changeTournamentsCreateActivitiesAfterBonus({
              index: i,
              data: {
                positionTo: e.target.value,
              },
            })
          );
        }}
        type="number"
      />
    </div>
  );
};

export default FreeGamesDisplay;
