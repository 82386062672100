import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFreeGamesFilterDateFrom,
  changeFreeGamesFilterDateTo,
} from "../../../../../../redux/slices/free-games/freeGamesSlice";
import {
  freeGamesFilterDateFrom,
  freeGamesFilterDateTo,
} from "../../../../../../redux/slices/free-games/freeGamesSelector";
const { RangePicker } = DatePicker;

const DateFromToRange = () => {
  const dispatch = useDispatch();

  const dateFrom = useSelector(freeGamesFilterDateFrom);
  const dateTo = useSelector(freeGamesFilterDateTo);

  return (
    <RangePicker
      format="DD-MM-YYYY"
      onChange={(x) => {
        if (!x) return;

        dispatch(
          changeFreeGamesFilterDateFrom(
            `${moment(x[0]).utc(true).toISOString()}`
          )
        );
        dispatch(
          changeFreeGamesFilterDateTo(`${moment(x[1]).utc(true).toISOString()}`)
        );
      }}
      value={[moment(dateFrom), moment(dateTo)]}
      style={{ minWidth: "250px" }}
    />
  );
};

export default DateFromToRange;
