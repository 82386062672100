import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFreeGamesCreateCoinCount,
  changeFreeGamesPaginationPage,
  removeFreeGamesArray,
} from "../../../../../redux/slices/free-games/freeGamesSlice";
import { gamesEnabledSelector } from "../../../../../redux/slices/games/gamesSelectors";
import { getFreeGamesReducer } from "../../../../../redux/slices/free-games/extra-reducers/getFreeGamesReducer";
import {
  freeGamesCreateFreeGamesArray,
  freeGamesDataTableSelector,
  freeGamesPaginationCurrentPage,
} from "../../../../../redux/slices/free-games/freeGamesSelector";

export const freeGamesGameTableColumns = [
  {
    title: "Game",
    dataIndex: "Name",
    key: "Name",
    render: (text, item) => {
      return (
        <>
          <Avatar
            src={item.photo}
            style={{ marginRight: "4px" }}
            size="small"
          />
          {item.Name}
        </>
      );
    },
  },
  {
    title: "Bet type",
    dataIndex: "bet_type",
    key: "bet_type",
    render: (text, record) => {
      if (!record.bet_type) return;

      return (
        <>
          {record.bet_type.charAt(0) + record.bet_type.slice(1).toLowerCase()}
        </>
      );
    },
  },
  {
    title: "Bet amount",
    dataIndex: "bet",
    key: "bet",
  },
  {
    title: "Actions",
    dataIndex: "bet",
    key: "bet",
    render: (text, record) => {
      return <DeleteButton record={record} />;
    },
  },
];

const DeleteButton = ({ record }) => {
  const dispatch = useDispatch();

  const freeGamesData = useSelector(freeGamesDataTableSelector);
  const freeGamesPageNum = useSelector(freeGamesPaginationCurrentPage);

  return (
    <Button
      shape="circle"
      icon={<DeleteOutlined />}
      danger={true}
      onClick={() => {
        if (freeGamesData.length === 1 && freeGamesPageNum > 1) {
          dispatch(changeFreeGamesPaginationPage(freeGamesPageNum - 1));
        }
        dispatch(removeFreeGamesArray(record.game_id));
      }}
    />
  );
};
